import React, { useCallback, useEffect, useState } from "react";
import Header from "./components/header/header.jsx";
import TopNav from "./components/top-nav/top-nav.jsx";
import Sidebar from "./components/sidebar/sidebar.jsx";
import Content from "./components/content/content.jsx";
import Footer from "./components/footer/footer.jsx";
import Weather from "./components/weather";
import TurkishDateComponent from "./components/TurkishDateComponent";
import PageLoader from "./components/PageLoader";
import ThemePanel from "./components/theme-panel/theme-panel.jsx";
import { AppSettings } from "./config/app-settings.js";

// import menus from "./config/app-menu.jsx";

// import axios from "axios";

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "./ApiClient.js";
import moment from "moment";
import "moment/locale/tr";
import Select from "react-select";

import { Card, CardBody } from "./components/card/card.jsx";
import CreateGraphicComponent from "./components/create-graphic/index.jsx";
// import { CSVLink } from "react-csv";
import ImportFile from "./components/import-file/index.jsx";
import CreatePage from "./components/create-page/index.jsx";
import DeletePage from "./components/delete-page/index.jsx";
import { ToastContainer } from "react-toastify";
import DeleteGraphic from "./components/delete-graphic/index.jsx";
import TalepFormu from "./components/talep-formu/index.jsx";
import { LoadScript } from "@react-google-maps/api";
import translations from "./config/locales.js";
import { io } from "socket.io-client";
import toastMessage from "./components/ToastMessage.js";
import AIComponent from "./components/AIComponent.jsx";

function App() {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "tr"
  );
  const t = translations[language];

  const [importFileOpen, setImportFileOpen] = useState(false);

  const listData = [
    {
      category: "Genel Görünüm",
      title: "Giriş Sokak Tabelası",
      answers: [
        {
          title: "İki Tarafta da <br /> Var Problemsiz",
          color: "success",
        },
        {
          title: "Var <br /> Problemli",
          color: "unnecessary",
        },
        {
          title: "Var <br /> Yanlış",
          color: "warning",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Metruk Bina",
      answers: [
        {
          title: "Yok",
          color: "success",
        },
        {
          title: "Var <br /> Problemsiz",
          color: "warning",
        },
        {
          title: "Yok <br /> Problemli",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Boş Arsa",
      answers: [
        {
          title: "Yok",
          color: "success",
        },
        {
          title: "Var <br /> Problemsiz",
          color: "warning",
        },
        {
          title: "Yok <br /> Problemli",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Metruk Araç",
      answers: [
        {
          title: "Yok",
          color: "success",
        },
        {
          title: "Var <br /> Problemsiz",
          color: "warning",
        },
        {
          title: "Yok <br /> Problemli",
          color: "danger",
        },
      ],
    },
    {
      category: "Temizlik Hizmetleri",
      title: "Genel Temizlik",
      answers: [
        {
          title: "Yeterince <br /> İyi",
          color: "success",
        },
        {
          title: "İdare Eder <br /> Seviyede",
          color: "warning",
        },
        {
          title: "Acil <br /> Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Temizlik Hizmetleri",
      title: "Süpürme Gerekliliği",
      answers: [
        {
          title: "Yeterince <br /> İyi",
          color: "success",
        },
        {
          title: "İdare Eder <br /> Seviyede",
          color: "warning",
        },
        {
          title: "Acil <br /> Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Temizlik Hizmetleri",
      title: "Çöp Toplama Gerekliliği",
      answers: [
        {
          title: "Yeterince <br /> İyi",
          color: "success",
        },
        {
          title: "İdare Eder <br /> Seviyede",
          color: "warning",
        },
        {
          title: "Acil <br /> Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Temizlik Hizmetleri",
      title: "Yıkama Gerekliliği",
      answers: [
        {
          title: "Evet",
          color: "success",
        },
        {
          title: "Hayır",
          color: "danger",
        },
      ],
    },
    {
      category: "Temizlik Hizmetleri",
      title: "Konteyner ve Çevresinin Temizliği",
      answers: [
        {
          title: "5",
          color: "success",
        },
        {
          title: "4",
          color: "no-urgent",
        },
        {
          title: "3",
          color: "unnecessary",
        },
        {
          title: "2",
          color: "warning",
        },
        {
          title: "1",
          color: "danger",
        },
      ],
    },
    {
      category: "Temizlik Hizmetleri",
      title: "Konteyner Dışı Çöp Atma",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Yol / Asfalt / Kilit Taşı",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Yaya Yolu / Kaldırım",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Engelli Yolu / Engelli Rampası",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Bebek Arabası Kullanımı",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "Kaldırım <br /> İşgal edilmiş",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Rögar / Mazgal Kapağı",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Yağmur Suyu Kanalı",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "Temizlik <br /> Gerekli",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Hız Kesici",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "İhtiyaç <br /> Yok",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Halk Sağlığı",
      title: "Duman / Kötü Koku",
      answers: [
        {
          title: "Yok",
          color: "success",
        },
        {
          title: "Var",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Aydınlatma Ekipmanları",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "3 <br /> Yok <br /> Gereksiz",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Aydınlatma Yeterli mi?",
      answers: [
        {
          title: "25m - 30m <br /> Aralığında  Evet Var",
          color: "success",
        },
        {
          title: "25m - 30m <br /> Aralığında Hayır Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Tehlite Arz Eden Yapı",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Fen İşleri",
      title: "Tehlite Teşkil Eden Elektirik Kabloları",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Zabıta Hizmetleri",
      title: "Kaldırım İşgali",
      answers: [
        {
          title: "Araç Parkı",
          color: "unnecessary",
          type: "checkbox",
        },
        {
          title: "Esnaf / İşyeri",
          color: "unnecessary",
          type: "checkbox",
        },
        {
          title: "Beton / Büyük Saksı",
          color: "unnecessary",
          type: "checkbox",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Zabıta Hizmetleri",
      title: "Devam Eden İnşaat Çalışması",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Zabıta Hizmetleri",
      title: "Devam Eden Tadilat Çalışması",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Halk Sağlığı",
      title: "Gürültü Kirliliği",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Zabıta Hizmetleri",
      title: "Asayiş / Güvenlik Problemi",
      answers: [
        {
          title: "Haneler İçin",
          color: "unnecessary",
          type: "checkbox",
        },
        {
          title: "Esnaf / İşyerleri İçin",
          color: "unnecessary",
          type: "checkbox",
        },
        {
          title: "Yayalar İçin",
          color: "unnecessary",
          type: "checkbox",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Zabıta Hizmetleri",
      title: "Trafik Akaşı Sorunu",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Yeşil Alan / Park ve Bahçeler",
      title: "Yeşil Alanlar",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "3 <br /> Yok",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Yeşil Alan / Park ve Bahçeler",
      title: "Ağaç ve Çiçekler",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "3 <br /> Yok",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Halk Sağlığı",
      title: "Başıboş Hayvanlar",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Halk Sağlığı",
      title: "İlaçlama İhtiyacı",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Yeşil Alan / Park ve Bahçeler",
      title: "Kaldırımlarda Budanması Gereken Ağaçlar",
      answers: [
        {
          title: "Evet",
          color: "success",
        },
        {
          title: "Hayır",
          color: "danger",
        },
      ],
    },
    {
      category: "Güvenlik",
      title: "İtfaiye geçişine uygun mu?",
      answers: [
        {
          title: "Evet",
          color: "success",
        },
        {
          title: "Hayır",
          color: "danger",
        },
      ],
    },
    {
      category: "Sosyal Alan",
      title: "Yetişkinler için Kahvehane",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Sosyal Alan",
      title: "Yetişkinler İçin Kafe / Kafeterya",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Sosyal Alan",
      title: "Sokakta Oynayan Çocuklar",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Görülen Yaklaşık Hane Sayısı",
      answers: [],
    },
    {
      category: "Genel Görünüm",
      title: "Görülen Yaklaşık Esnaf / İşyeri Sayısı",
      answers: [
        {
          title: "0 - 10",
          color: "success",
        },
        {
          title: "11 - 25",
          color: "no-urgent",
        },
        {
          title: "26 - 50",
          color: "unnecessary",
        },
        {
          title: "51 - 100",
          color: "warning",
        },
        {
          title: "101+",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Gün İçinde Görülen Yaklaşık Araç Sayısı",
      answers: [
        {
          title: "0 - 10",
          color: "success",
        },
        {
          title: "11 - 25",
          color: "no-urgent",
        },
        {
          title: "26 - 50",
          color: "unnecessary",
        },
        {
          title: "51 - 100",
          color: "warning",
        },
        {
          title: "101+",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Numarataj",
      answers: [
        {
          title: "Var <br /> Problemsiz",
          color: "success",
        },
        {
          title: "Yok",
          color: "warning",
        },
        {
          title: "Var <br /> Problemli",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Durak (Durak Adını Yazınız)",
      answers: [
        {
          title: "5 <br /> Var <br /> Sorunsuz",
          color: "success",
        },
        {
          title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
          color: "no-urgent",
        },
        {
          title: "3 <br /> Yok",
          color: "unnecessary",
        },
        {
          title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
          color: "warning",
        },
        {
          title: "1 <br /> Yok <br /> Acil Gerekli",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Islah Gerekli Kanal / Dere / Birikinti",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Okul",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Cami",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "STK / Dernek",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Belediye Sosyal Tesis / Bina",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Ekili Dikili Alan Var mı?",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Hayvansal Üretim Yeri Var mı?",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
    {
      category: "Genel Görünüm",
      title: "Sanayi Tesisi Var mı?",
      answers: [
        {
          title: "Var",
          color: "success",
        },
        {
          title: "Yok",
          color: "danger",
        },
      ],
    },
  ];

  // const handleZoomIn = () => {
  //   setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
  // };

  // const handleZoomOut = () => {
  //   setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.1));
  // };

  // const zoomStyle = {
  //   transform: `scale(${zoomLevel})`,
  //   transition: "transform 0.3s ease-in-out",
  // };

  // useEffect(() => {
  //   document.querySelector("body").style.zoom = zoomLevel;
  // }, [zoomLevel]);

  // const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();

  // function handleChange(date) {
  //   setStartDate(date);
  // }

  var defaultOptions = {
    appMode: "",
    appTheme: "",
    appCover: "",
    appHeaderNone: false,
    appSidebarNone: false,
    appSidebarCollapsed: false,
    appContentNone: false,
    appContentClass: "",
    appContentFullHeight: false,
    appBoxedLayout: false,
    appWeather: true,
    appScrollButton: true,
    themePanel: true,
    appFooter: true,
    appFooterFull: false,
    appTopNav: false,
    chartColor: null,
  };
  const [loading, setLoading] = useState(true);

  const [appHeaderNone, setAppHeaderNone] = useState(
    defaultOptions.appHeaderNone
  );
  const [appSidebarNone, setAppSidebarNone] = useState(
    defaultOptions.appSidebarNone
  );
  const [appSidebarCollapsed, setAppSidebarCollapsed] = useState(
    defaultOptions.appSidebarCollapsed
  );
  const [appContentNone, setAppContentNone] = useState(
    defaultOptions.appContentNone
  );
  const [appContentClass, setAppContentClass] = useState(
    defaultOptions.appContentClass
  );
  const [appContentFullHeight, setAppContentFullHeight] = useState(
    defaultOptions.appContentFullHeight
  );
  const [appBoxedLayout, setAppBoxedLayout] = useState(
    defaultOptions.appBoxedLayout
  );
  const [appWeather, setAppWeather] = useState(defaultOptions.appWeather);
  const [appScrollButton, setAppScrollButton] = useState(
    defaultOptions.appScrollButton
  );
  const [, setAppFooter] = useState(defaultOptions.appFooter);
  const [themePanel, setThemePanel] = useState(defaultOptions.themePanel);
  const [, setAppFooterFull] = useState(defaultOptions.appFooterFull);
  const [appTopNav, setAppTopNav] = useState(defaultOptions.appTopNav);
  const [chartColor, setChartColor] = useState(defaultOptions.chartColor);
  const [theme, setTheme] = useState("dark");

  var handleSetAppTheme = (value) => {
    if (value) {
      var newTheme = value;
      for (var x = 0; x < document.body.classList.length; x++) {
        if (
          document.body.classList[x].indexOf("theme-") > -1 &&
          document.body.classList[x] !== newTheme
        ) {
          document.body.classList.remove(document.body.classList[x]);
        }
      }

      if (localStorage && value) {
        // localStorage.appTheme = value;
      }

      document.body.classList.add(newTheme);
      document.dispatchEvent(new Event("theme-reload"));
    }
  };

  var handleSetAppMode = (value) => {
    if (value) {
      document.documentElement.setAttribute("data-bs-theme", value);
    }
  };

  // var handleSetAppCover = (value) => {
  //   if (value) {
  //     var htmlElm = document.querySelector("html");
  //     for (var x = 0; x < document.documentElement.classList.length; x++) {
  //       var targetClass = document.documentElement.classList[x];
  //       if (targetClass.search("bg-cover-") > -1) {
  //         htmlElm.classList.remove(targetClass);
  //       }
  //     }
  //     htmlElm.classList.add(value);

  //     if (localStorage && value) {
  //       localStorage.appCover = value;
  //     }
  //   }
  // };

  useEffect(() => {
    if (defaultOptions.appMode) {
      handleSetAppMode(defaultOptions.appMode);
    }
    if (defaultOptions.appTheme) {
      handleSetAppTheme(defaultOptions.appTheme);
    }
    // if (defaultOptions.appCover) {
    //   handleSetAppCover(defaultOptions.appCover);
    // }

    if (localStorage) {
      if (typeof localStorage.appMode !== "undefined") {
        handleSetAppMode(localStorage.appMode);
      }
      // if (typeof localStorage.appTheme !== "undefined") {
      //   handleSetAppTheme(localStorage.appTheme);
      // }
      // if (typeof localStorage.appCover !== "undefined") {
      //   handleSetAppCover(localStorage.appCover);
      // }
    }

    // eslint-disable-next-line
  }, []);

  //--

  // Şu anki URL'i almak için bir state oluşturalım
  // const [currentPath, setCurrentPath] = useState(window.location.pathname);

  // URL değiştikçe state'i güncelleyelim
  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     setCurrentPath(window.location.pathname);
  //   };

  //   window.addEventListener("popstate", handleRouteChange);

  //   return () => {
  //     window.removeEventListener("popstate", handleRouteChange);
  //   };
  // }, []);

  const [breakingNewsSwiper, setBreakingNewsSwiper] = useState(null);

  const [scrollToTopShow] = useState(false);
  // const [scrollToTopClass, setScrollToTopClass] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (document.querySelector(".scroll-up-btn")) {
  //       if (window.scrollY > window.innerHeight / 2) {
  //         setScrollToTopShow(true);
  //       } else {
  //         setScrollToTopShow(false);
  //       }

  //       if (
  //         document.querySelector(".breaking-news").classList.contains("active")
  //       ) {
  //         document.querySelector(".scroll-up-btn").classList.add("near-news");
  //       } else {
  //         document
  //           .querySelector(".scroll-up-btn")
  //           .classList.remove("near-news");
  //       }

  //       if (
  //         window.scrollY + window.innerHeight >
  //         document.querySelector("footer").offsetTop
  //       ) {
  //         document.querySelector(".scroll-up-btn").classList.add("near-footer");
  //       } else {
  //         document
  //           .querySelector(".scroll-up-btn")
  //           .classList.remove("near-footer");
  //       }
  //     }
  //   };

  //   // Scroll event listener'ını ekleyin
  //   window.addEventListener("scroll", handleScroll);

  //   // Clean-up: Component kaldırıldığında event listener'ını kaldırın
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [fileActive, setFileActive] = useState(true);
  const [fileBottomActive, setFileBottomActive] = useState(false);

  const newsToggle = () => {
    setFileBottomActive(!fileBottomActive);

    var elm = document.querySelector(".breaking-news");
    elm.classList.toggle("active");

    if (elm.classList.contains("active")) {
      document.querySelector(".scroll-up-btn").classList.add("near-news");
    } else {
      document.querySelector(".scroll-up-btn").classList.remove("near-footer");
      document.querySelector(".scroll-up-btn").classList.remove("near-news");
    }
  };

  // const [programData, setProgramData] = useState(null);

  // useEffect(() => {
  //   // const fetchData = async () => {
  //   //   try {
  //   //     const response = await axios.get("http://localhost:3001/api/data");
  //   //     setProgramData(response.data);
  //   //   } catch (error) {
  //   //     console.error("Error fetching data:", error);
  //   //   }
  //   // };

  //   // fetchData();

  //   getMe();
  // }, []);

  //---------
  function hexToRgba(hex, opacity) {
    // Hex kodundan başındaki # işaretini kaldır
    if (hex) {
      hex = hex.replace("#", "");

      // R, G, B değerlerini al
      var r = parseInt(hex.substring(0, 2), 16);
      var g = parseInt(hex.substring(2, 4), 16);
      var b = parseInt(hex.substring(4, 6), 16);

      // Opacity (saydamlık) değerini 0 ile 1 arasında normalize et
      var alpha = opacity || 1;

      // rgba formatında geri döndür
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    }
  }

  // Get Me
  const [me, setMe] = useState(null);

  const getMe = async () => {
    if (localStorage.getItem("token")) {
      try {
        const response = await apiClient.get("/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response) {
          setMe(response.data);

          const filteredRoles = allRolesSelect.filter((role) => {
            if (response.data?.id === 10) {
              return true; // Hepsi gözükecek
            } else if (response.data?.role?.id === 4) {
              return role.value !== 1 && role.value !== 2; // 1 ve 2 olanlar gözükmeyecek
            } else {
              return role.value !== 1; // 1 olan gözükmeyecek
            }
          });

          setAllRolesSelect(filteredRoles);

          if (
            response?.data?.customer &&
            response?.data?.customer?.settings?.theme
          ) {
            // document.getElementById("header").style.background = hexToRgba(
            //   response?.data?.customer?.settings?.theme.split("|")[0],
            //   0.8
            // );
            // document.getElementById("sidebar").style.background = hexToRgba(
            //   response?.data?.customer?.settings?.theme.split("|")[0],
            //   0.4
            // );
            // document.querySelector("body").style.background = hexToRgba(
            //   response?.data?.customer?.settings?.theme.split("|")[0],
            //   0.4
            // );
            // document.querySelector(
            //   ".app"
            // ).style.background = `linear-gradient(180deg, ${
            //   response?.data?.customer?.settings?.theme.split("|")[1]
            // } -190%, rgb(0, 7, 23) 90%)`;
            // document.body.classList.add(
            //   "theme-" + response?.data?.customer?.settings?.theme.split("|")[1]
            // );
            localStorage.setItem(
              "theme",
              response?.data?.customer?.settings?.theme.split("|")[0] +
                "|" +
                response?.data?.customer?.settings?.theme.split("|")[1]
            );

            if (loading) {
              document.querySelector(
                ".app"
              ).style.background = `linear-gradient(180deg, ${
                response?.data?.customer?.settings?.theme.split("|")[1]
              } -190%, rgb(0, 7, 23) 90%)`;
            }
          } else {
            // document.body.classList.add("theme-theme-5");
            // localStorage.setItem("theme", "#3cd2a5|theme-5");
          }

          setTimeout(() => {
            setLoading(false);
          }, 1000);
          localStorage.setItem("user", JSON.stringify(response.data));

          if (
            response.data.role.id !== 1 &&
            response.data.customer.settings.domain
          ) {
            if (
              window.location.host.split(".")[0] !==
              response.data.customer.settings.domain
            ) {
              setMe(null);
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              navigate("/giris-yap");
            }
          }

          //--

          if (response.data.role.id !== 5) {
            getRate(response.data);
            getAllCustomer(response.data);
          }

          //--

          fetchSocket(response.data.id);
        }
      } catch (error) {
        // console.log(error);
        // if (error) {
        //   if (error.response.status === 401) {
        //     setMe(null);
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("user");
        //   }
        // }
      }
    }
  };

  // Get Pages
  const [menus, setMenus] = useState([
    {
      slug: "/kentmetre-raporu",
      icon: null,
      name: t?.kentmetre_raporu,
      children: [
        { slug: "/kentmetre-raporu/rapor-1", name: "1. " + t?.rapor },
        { slug: "/kentmetre-raporu/rapor-2", name: "2. " + t?.rapor },
        { slug: "/kentmetre-raporu/rapor-3", name: "3. " + t?.rapor },
      ],
    },
    {
      slug: "#",
      icon: null,
      name: t?.contact,
      children: [
        {
          slug: "mailto:info@kentmetre.com",
          name: t?.email,
        },
        {
          slug: "tel:08503086600",
          name: "0 850 308 66 00",
        },
        {
          slug: "talep-formu",
          name: t?.talep_formu,
        },
      ],
    },
    // { is_header: true, name: t?.yonetim },
    {
      slug: "/yonetim",
      // icon: "bi bi-gem",
      name: t?.yonetim,
      children: [
        { slug: "/yonetim/musteriler", name: t?.customers },
        { slug: "/yonetim/kullanicilar", name: t?.users },
        {
          slug: "/yonetim/log-kayitlari",
          name: t?.log_kayitlari,
        },
        {
          slug: "/yonetim/ornek-dosyalar",
          name: t?.fixed_files,
        },
        // {
        //   slug: "cache-temizle",
        //   name: t?.clear_cache,
        // },
      ],
    },
  ]);
  const [pages, setPages] = useState(null);
  const [, setPageFetch] = useState(false);
  const [updatePage, setUpdatePage] = useState(null);
  const [deletePage, setDeletePage] = useState(null);

  function handleFilteredPagesArray(array) {
    return array.filter(function (menu) {
      const user = me;
      if (!user) return false;

      const userRole = user.role.id;

      if (
        menu.slug === "/kentmetre-raporu" &&
        user.customer &&
        Array.isArray(user.customer.reports)
      ) {
        user.customer.reports.forEach((report, index) => {
          if (report && report.label && menu.children[index]) {
            menu.children[index].name = `${index + 1}. ${report.label}`;
          }
        });
      }

      if (userRole === 3) {
        return menu.slug === "strateji-haritasi" || menu.name === "İletişim";
      }

      if (userRole === 5) {
        return menu.slug === "arastirma-raporu" || menu.name === "İletişim";
      }

      if (userRole === 6) {
        return (
          menu.slug === "halkla-iletisim-yonetimi" || menu.name === "İletişim"
        );
      }

      if (userRole === 7) {
        return menu.slug === "veri-girisi" || menu.name === "İletişim";
      }

      if (userRole === 8) {
        return menu.name === "İletişim";
      }

      if (userRole === 4) {
        return (
          menu.slug === "hane-ve-birey-analizi" ||
          menu.slug === "halkla-iletisim-yonetimi" ||
          menu.slug === "hazir-raporlar" ||
          menu.name === "İletişim"
        );
      } else if (userRole !== 1) {
        return menu.slug !== "/yonetim" && menu.name !== "Admin";
      }

      return true;
    });
  }

  const getPages = useCallback(
    async (customerId = null) => {
      setPages(null);

      let customerData = me?.customer;

      if (customerId) {
        customerData = await getCustomer(customerId);
      } else {
        customerData = me?.customer;
      }

      if (localStorage.getItem("token")) {
        try {
          const response = await apiClient.get(
            `/page${customerId ? `?customer_id=${customerId}` : ""}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response) {
            if (
              customerData?.settings?.pages &&
              customerData?.settings?.pages.length !== 0
            ) {
              const pageIds =
                customerData?.settings?.pages.map((page) => page.id) || [];

              // 'page_type' custom olanları filtrele
              const customPages = response.data.filter(
                (item) => item.page_type === "custom"
              );

              // 'page.id' ile eşleşenleri filtrele
              const filteredItems = response.data.filter((item) =>
                pageIds.includes(item.id)
              );

              // 'name' "Anket Sayfası" ile başlayan sayfaları filtrele
              const surveyPages = response.data.filter(
                (item) =>
                  item.name?.startsWith("Anket Sayfası") &&
                  item.page_type === "default"
              );

              // Filtrelenen sayfaları, custom sayfaları ve "Anket Sayfası" sayfalarını birleştir
              const pagesArray = filteredItems.concat(
                customPages,
                surveyPages,
                menus
              );

              setPages(handleFilteredPagesArray(pagesArray));
            } else {
              const pagesArray = [].concat(menus);
              setPages(handleFilteredPagesArray(pagesArray));
            }

            setPageFetch(true); // Veriyi bir kere çektikten sonra pageFetch'i true yapar
          }
        } catch (error) {
          console.error("Get Pages Error", error);
        }
      }
    },
    [me, t, menus] // Sadece pageFetch değiştiğinde getPages fonksiyonu yeniden oluşturulur
  );

  async function getOncePages(customerId = null) {
    if (localStorage.getItem("token")) {
      try {
        const response = await apiClient.get(
          `/page${customerId ? `?customer_id=${customerId}` : ""}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response) {
          const pagesArray = response.data.concat(menus);
          handleFilteredPagesArray(pagesArray);
          return handleFilteredPagesArray(pagesArray);
        }
      } catch (error) {
        console.error("Get Pages Error", error);
      }
    }
  }

  //--

  const [fileTypes, setFileTypes] = useState(null);

  const getFileTypes = useCallback(
    async (customerId = null, getData = false, connectionType = "local") => {
      setFileTypes(null);

      if (localStorage.getItem("token")) {
        try {
          const response = await apiClient.get(
            `/types?connection_type=${connectionType}${
              customerId ? `&customer=${customerId}` : ""
            }`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response) {
            if (getData) {
              return response.data;
            } else {
              setFileTypes(response.data);
            }
          }
        } catch (error) {
          console.error("Get File Types Error", error);
        }
      }
    },
    []
  );

  // Get Customer
  // const [customer, setCustomer] = useState(null);

  const getCustomer = async (value) => {
    if (localStorage.getItem("token")) {
      try {
        const response = await apiClient.get(`/customer/${value}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        return response.data;
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  };

  // All Customer
  const [allCustomerSelect, setAllCustomerSelect] = useState([]);

  const getAllCustomer = useCallback(
    async (value) => {
      if (localStorage.getItem("token")) {
        try {
          const response = await apiClient.get(
            `/customer?sort=id&direction=asc&limit=50`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data.data.length !== 0) {
            response.data.data.forEach((element) => {
              setAllCustomerSelect((oldArray) => [
                ...oldArray,
                {
                  value: element.id,
                  label: element.customer.name,
                  user_id: element.user[0]
                    ? element.user[0].length !== 0
                      ? element.user[0].id
                      : null
                    : null,
                },
              ]);
            });
          }
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            navigate("/giris-yap");
          }
        }
      }
    },
    [navigate]
  );

  // All Roles
  const [allRoles, setAllRoles] = useState(null);
  const [allRolesSelect, setAllRolesSelect] = useState([
    {
      value: 1,
      label: t?.yonetici,
    },
    {
      value: 2,
      label: t?.institution_official,
    },
    {
      value: 3,
      label: t?.strateji_haritasi,
    },
    {
      value: 4,
      label: t?.public_relations,
    },
    {
      value: 5,
      label: t?.gozlemci,
    },
    {
      value: 6,
      label: t?.halkla_iliskiler_gozlemci,
    },
    {
      value: 7,
      label: t?.veri_girisi,
    },
    {
      value: 8,
      label: t?.role_for_group,
    },
  ]);

  useEffect(() => {
    setAllRolesSelect([
      {
        value: 1,
        label: t?.yonetici,
      },
      {
        value: 2,
        label: t?.institution_official,
      },
      {
        value: 3,
        label: t?.strateji_haritasi,
      },
      {
        value: 4,
        label: t?.public_relations,
      },
      {
        value: 5,
        label: t?.gozlemci,
      },
      {
        value: 6,
        label: t?.halkla_iliskiler_gozlemci,
      },
      {
        value: 7,
        label: t?.veri_girisi,
      },
      {
        value: 8,
        label: t?.role_for_group,
      },
    ]);
    setMenus([
      {
        slug: "/kentmetre-raporu",
        icon: null,
        name: t?.kentmetre_raporu,
        children: [
          { slug: "/kentmetre-raporu/rapor-1", name: "1. " + t?.rapor },
          { slug: "/kentmetre-raporu/rapor-2", name: "2. " + t?.rapor },
          { slug: "/kentmetre-raporu/rapor-3", name: "3. " + t?.rapor },
        ],
      },
      {
        slug: "#",
        icon: null,
        name: t?.contact,
        children: [
          {
            slug: "mailto:info@kentmetre.com",
            name: t?.email,
          },
          {
            slug: "tel:08503086600",
            name: "0 850 308 66 00",
          },
          {
            slug: "talep-formu",
            name: t?.talep_formu,
          },
        ],
      },
      // { is_header: true, name: t?.yonetim },
      {
        slug: "/yonetim",
        // icon: "bi bi-gem",
        name: t?.yonetim,
        children: [
          { slug: "/yonetim/musteriler", name: t?.customers },
          { slug: "/yonetim/kullanicilar", name: t?.users },
          {
            slug: "/yonetim/log-kayitlari",
            name: t?.log_kayitlari,
          },
          {
            slug: "/yonetim/ornek-dosyalar",
            name: t?.fixed_files,
          },
          // {
          //   slug: "cache-temizle",
          //   name: t?.clear_cache,
          // },
        ],
      },
    ]);
  }, [t]);

  // const getAllRoles = useCallback(async () => {
  //   if (localStorage.getItem("token")) {
  //     try {
  //       const response = await apiClient.get("/roles", {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       });
  //       setAllRoles(response.data);

  //       if (response.data.length !== 0) {
  //         response.data.forEach((element) => {
  //           setAllRolesSelect((oldArray) => [
  //             ...oldArray,
  //             {
  //               value: element.id,
  //               label: element.name,
  //             },
  //           ]);
  //         });
  //       }
  //     } catch (error) {
  //       if (error.response.status === 401) {
  //         setAllRoles(null);
  //         localStorage.removeItem("token");
  //         localStorage.removeItem("user");
  //       }
  //     }
  //   } else {
  //     setAllRoles(null);
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("user");
  //   }
  // }, [navigate]);

  // All City
  const [allCity, setAllCity] = useState([]);

  const getAllCity = useCallback(async () => {
    if (localStorage.getItem("token")) {
      try {
        const response = await apiClient.get("/cities", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.data.length !== 0) {
          const data = response.data
            .filter((item) => item.name)
            .map(({ id: value, name: label, ...rest }) => ({
              value,
              label: removeTurkishCharacters(label).toUpperCase(),
              ...rest,
            }))
            .sort((a, b) => {
              const order = [34, 35, 6];
              const aIndex = order.indexOf(a.value);
              const bIndex = order.indexOf(b.value);

              if (aIndex !== -1 || bIndex !== -1) {
                if (aIndex === -1) return 1;
                if (bIndex === -1) return -1;
                return aIndex - bIndex;
              }

              return a.label.localeCompare(b.label);
            });

          setAllCity(data);
        }
      } catch (error) {
        // if (error.response.status !== 401 || error.response.status !== 403) {
        //   setAllCity(null);
        //   localStorage.removeItem("token");
        //   localStorage.removeItem("user");
        // }
      }
    } else {
      setAllCity(null);
      // localStorage.removeItem("token");
      // localStorage.removeItem("user");
    }
  }, []);

  // All District
  const getAllDistrict = async (value) => {
    if (localStorage.getItem("token")) {
      try {
        const response = await apiClient.get(`/district/${value}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.data.length !== 0) {
          const data = response.data
            .map(({ id: value, name: label, ...rest }) => {
              const processedLabel =
                removeTurkishCharacters(label).toUpperCase();

              return {
                value,
                label: processedLabel.includes("MERKEZ")
                  ? "MERKEZ"
                  : processedLabel === "EYUP"
                  ? "EYUPSULTAN"
                  : processedLabel,
                ...rest,
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

          return data;
        }
      } catch (error) {
        if (error.response.status === 401) {
          // setAllDistrict(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  };

  // All Neighborhood
  const getAllNeighborhood = useCallback(
    async (value) => {
      if (localStorage.getItem("token")) {
        try {
          const response = await apiClient.get(`/neighborhoods/${value}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          if (response.data.length !== 0) {
            const data = response.data
              .map(({ id: value, name: label, ...rest }) => ({
                value: label,
                label: removeTurkishCharacters(label).toUpperCase() + " MAH.",
                ...rest,
              }))
              .sort((a, b) => a.label.localeCompare(b.label));

            return data;
          }
        } catch (error) {
          if (error.response.status === 401) {
            // setAllNeighborhood(null);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            navigate("/giris-yap");
          }
        }
      }
    },
    [navigate]
  );

  // All Street
  // const getAllStreet = useCallback(
  //   async (value) => {
  //     if (localStorage.getItem("token")) {
  //       try {
  //         const response = await apiClient.get(`/street/${value}`, {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //         });

  //         if (response.data.length !== 0) {
  //           const data = response.data.map(
  //             ({ id: value, name: label, ...rest }) => ({
  //               value,
  //               label,
  //               ...rest,
  //             })
  //           );

  //           return data;
  //         }
  //       } catch (error) {
  //         if (error.response.status === 401) {
  //           // setAllStreet(null);
  //           localStorage.removeItem("token");
  //           localStorage.removeItem("user");
  //         }
  //       }
  //     } else {
  //       localStorage.removeItem("token");
  //       localStorage.removeItem("user");
  //     }
  //   },
  //   [navigate]
  // );

  // NEWS
  const [newsData, setNewsData] = useState(null);
  const [newsCategory, setNewsCategory] = useState(null);

  async function getNews() {
    if (me?.role?.id !== 5) {
      setNewsData(null);

      if (localStorage.getItem("token")) {
        try {
          const response = await apiClient.get(
            `/news?category=${newsCategory ? newsCategory : "guncel"}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data.data.length !== 0) {
            setNewsData(response.data.data);
          } else {
            setNewsData([]);
          }
        } catch (error) {
          if (error.response.status === 401) {
            // setAllStreet(null);
            // localStorage.removeItem("token");
            // localStorage.removeItem("user");
          }
        }
      } else {
        // localStorage.removeItem("token");
        // localStorage.removeItem("user");
      }
    }
  }

  // Verilen il ve ilçe bilgileri
  const data = [
    {
      il: "Adana",
      plaka: 1,
      ilceleri: [
        "Aladağ",
        "Ceyhan",
        "Çukurova",
        "Feke",
        "İmamoğlu",
        "Karaisalı",
        "Karataş",
        "Kozan",
        "Pozantı",
        "Saimbeyli",
        "Sarıçam",
        "Seyhan",
        "Tufanbeyli",
        "Yumurtalık",
        "Yüreğir",
      ],
    },
    {
      il: "Adıyaman",
      plaka: 2,
      ilceleri: [
        "Besni",
        "Çelikhan",
        "Gerger",
        "Gölbaşı",
        "Kahta",
        "Merkez",
        "Samsat",
        "Sincik",
        "Tut",
      ],
    },
    {
      il: "Afyonkarahisar",
      plaka: 3,
      ilceleri: [
        "Başmakçı",
        "Bayat",
        "Bolvadin",
        "Çay",
        "Çobanlar",
        "Dazkırı",
        "Dinar",
        "Emirdağ",
        "Evciler",
        "Hocalar",
        "İhsaniye",
        "İscehisar",
        "Kızılören",
        "Merkez",
        "Sandıklı",
        "Sinanpaşa",
        "Şuhut",
        "Sultandağı",
      ],
    },
    {
      il: "Ağrı",
      plaka: 4,
      ilceleri: [
        "Diyadin",
        "Doğubayazıt",
        "Eleşkirt",
        "Hamur",
        "Merkez",
        "Patnos",
        "Taşlıçay",
        "Tutak",
      ],
    },
    {
      il: "Aksaray",
      plaka: 68,
      ilceleri: [
        "Ağaçören",
        "Eskil",
        "Gülağaç",
        "Güzelyurt",
        "Merkez",
        "Ortaköy",
        "Sarıyahşi",
      ],
    },
    {
      il: "Amasya",
      plaka: 5,
      ilceleri: [
        "Göynücek",
        "Gümüşhacıköy",
        "Hamamözü",
        "Merkez",
        "Merzifon",
        "Suluova",
        "Taşova",
      ],
    },
    {
      il: "Ankara",
      plaka: 6,
      ilceleri: [
        "Akyurt",
        "Altındağ",
        "Ayaş",
        "Bala",
        "Beypazarı",
        "Çamlıdere",
        "Çankaya",
        "Çubuk",
        "Elmadağ",
        "Etimesgut",
        "Evren",
        "Gölbaşı",
        "Güdül",
        "Haymana",
        "Kalecik",
        "Kazan",
        "Keçiören",
        "Kızılcahamam",
        "Mamak",
        "Nallıhan",
        "Polatlı",
        "Pursaklar",
        "Şereflikoçhisar",
        "Sincan",
        "Yenimahalle",
      ],
    },
    {
      il: "Antalya",
      plaka: 7,
      ilceleri: [
        "Akseki",
        "Aksu",
        "Alanya",
        "Demre",
        "Döşemealtı",
        "Elmalı",
        "Finike",
        "Gazipaşa",
        "Gündoğmuş",
        "İbradı",
        "Kaş",
        "Kemer",
        "Kepez",
        "Konyaaltı",
        "Korkuteli",
        "Kumluca",
        "Manavgat",
        "Muratpaşa",
        "Serik",
      ],
    },
    {
      il: "Ardahan",
      plaka: 75,
      ilceleri: ["Çıldır", "Damal", "Göle", "Hanak", "Merkez", "Posof"],
    },
    {
      il: "Artvin",
      plaka: 8,
      ilceleri: [
        "Ardanuç",
        "Arhavi",
        "Borçka",
        "Hopa",
        "Merkez",
        "Murgul",
        "Şavşat",
        "Yusufeli",
      ],
    },
    {
      il: "Aydın",
      plaka: 9,
      ilceleri: [
        "Bozdoğan",
        "Buharkent",
        "Çine",
        "Didim",
        "Efeler",
        "Germencik",
        "İncirliova",
        "Karacasu",
        "Karpuzlu",
        "Koçarlı",
        "Köşk",
        "Kuşadası",
        "Kuyucak",
        "Merkez",
        "Nazilli",
        "Söke",
        "Sultanhisar",
        "Yenipazar",
      ],
    },
    {
      il: "Balıkesir",
      plaka: 10,
      ilceleri: [
        "Altıeylül",
        "Ayvalık",
        "Balya",
        "Bandırma",
        "Bigadiç",
        "Burhaniye",
        "Dursunbey",
        "Edremit",
        "Erdek",
        "Gömeç",
        "Gönen",
        "Havran",
        "İvrindi",
        "Karesi",
        "Kepsut",
        "Manyas",
        "Marmara",
        "Merkez",
        "Savaştepe",
        "Sındırgı",
        "Susurluk",
      ],
    },
    {
      il: "Bartın",
      plaka: 74,
      ilceleri: ["Amasra", "Kurucaşile", "Merkez", "Ulus"],
    },
    {
      il: "Batman",
      plaka: 72,
      ilceleri: ["Beşiri", "Gercüş", "Hasankeyf", "Kozluk", "Merkez", "Sason"],
    },
    {
      il: "Bayburt",
      plaka: 69,
      ilceleri: ["Aydıntepe", "Demirözü", "Merkez"],
    },
    {
      il: "Bilecik",
      plaka: 11,
      ilceleri: [
        "Bozüyük",
        "Gölpazarı",
        "İnhisar",
        "Merkez",
        "Osmaneli",
        "Pazaryeri",
        "Söğüt",
        "Yenipazar",
      ],
    },
    {
      il: "Bingöl",
      plaka: 12,
      ilceleri: [
        "Adaklı",
        "Genç",
        "Karlıova",
        "Kiğı",
        "Merkez",
        "Solhan",
        "Yayladere",
        "Yedisu",
      ],
    },
    {
      il: "Bitlis",
      plaka: 13,
      ilceleri: [
        "Adilcevaz",
        "Ahlat",
        "Güroymak",
        "Hizan",
        "Merkez",
        "Mutki",
        "Tatvan",
      ],
    },
    {
      il: "Bolu",
      plaka: 14,
      ilceleri: [
        "Dörtdivan",
        "Gerede",
        "Göynük",
        "Kıbrıscık",
        "Mengen",
        "Merkez",
        "Mudurnu",
        "Seben",
        "Yeniçağa",
      ],
    },
    {
      il: "Burdur",
      plaka: 15,
      ilceleri: [
        "Ağlasun",
        "Altınyayla",
        "Bucak",
        "Çavdır",
        "Çeltikçi",
        "Gölhisar",
        "Karamanlı",
        "Kemer",
        "Merkez",
        "Tefenni",
        "Yeşilova",
      ],
    },
    {
      il: "Bursa",
      plaka: 16,
      ilceleri: [
        "Büyükorhan",
        "Gemlik",
        "Gürsu",
        "Harmancık",
        "İnegöl",
        "İznik",
        "Karacabey",
        "Keles",
        "Kestel",
        "Mudanya",
        "Mustafakemalpaşa",
        "Nilüfer",
        "Orhaneli",
        "Orhangazi",
        "Osmangazi",
        "Yenişehir",
        "Yıldırım",
      ],
    },
    {
      il: "Çanakkale",
      plaka: 17,
      ilceleri: [
        "Ayvacık",
        "Bayramiç",
        "Biga",
        "Bozcaada",
        "Çan",
        "Eceabat",
        "Ezine",
        "Gelibolu",
        "Gökçeada",
        "Lapseki",
        "Merkez",
        "Yenice",
      ],
    },
    {
      il: "Çankırı",
      plaka: 18,
      ilceleri: [
        "Atkaracalar",
        "Bayramören",
        "Çerkeş",
        "Eldivan",
        "Ilgaz",
        "Kızılırmak",
        "Korgun",
        "Kurşunlu",
        "Merkez",
        "Orta",
        "Şabanözü",
        "Yapraklı",
      ],
    },
    {
      il: "Çorum",
      plaka: 19,
      ilceleri: [
        "Alaca",
        "Bayat",
        "Boğazkale",
        "Dodurga",
        "İskilip",
        "Kargı",
        "Laçin",
        "Mecitözü",
        "Merkez",
        "Oğuzlar",
        "Ortaköy",
        "Osmancık",
        "Sungurlu",
        "Uğurludağ",
      ],
    },
    {
      il: "Denizli",
      plaka: 20,
      ilceleri: [
        "Acıpayam",
        "Babadağ",
        "Baklan",
        "Bekilli",
        "Beyağaç",
        "Bozkurt",
        "Buldan",
        "Çal",
        "Çameli",
        "Çardak",
        "Çivril",
        "Güney",
        "Honaz",
        "Kale",
        "Merkez",
        "Merkezefendi",
        "Pamukkale",
        "Sarayköy",
        "Serinhisar",
        "Tavas",
      ],
    },
    {
      il: "Diyarbakır",
      plaka: 21,
      ilceleri: [
        "Bağlar",
        "Bismil",
        "Çermik",
        "Çınar",
        "Çüngüş",
        "Dicle",
        "Eğil",
        "Ergani",
        "Hani",
        "Hazro",
        "Kayapınar",
        "Kocaköy",
        "Kulp",
        "Lice",
        "Silvan",
        "Sur",
        "Yenişehir",
      ],
    },
    {
      il: "Düzce",
      plaka: 81,
      ilceleri: [
        "Akçakoca",
        "Çilimli",
        "Cumayeri",
        "Gölyaka",
        "Gümüşova",
        "Kaynaşlı",
        "Merkez",
        "Yığılca",
      ],
    },
    {
      il: "Edirne",
      plaka: 22,
      ilceleri: [
        "Enez",
        "Havsa",
        "İpsala",
        "Keşan",
        "Lalapaşa",
        "Meriç",
        "Merkez",
        "Süloğlu",
        "Uzunköprü",
      ],
    },
    {
      il: "Elazığ",
      plaka: 23,
      ilceleri: [
        "Ağın",
        "Alacakaya",
        "Arıcak",
        "Baskil",
        "Karakoçan",
        "Keban",
        "Kovancılar",
        "Maden",
        "Merkez",
        "Palu",
        "Sivrice",
      ],
    },
    {
      il: "Erzincan",
      plaka: 24,
      ilceleri: [
        "Çayırlı",
        "İliç",
        "Kemah",
        "Kemaliye",
        "Merkez",
        "Otlukbeli",
        "Refahiye",
        "Tercan",
        "Üzümlü",
      ],
    },
    {
      il: "Erzurum",
      plaka: 25,
      ilceleri: [
        "Aşkale",
        "Aziziye",
        "Çat",
        "Hınıs",
        "Horasan",
        "İspir",
        "Karaçoban",
        "Karayazı",
        "Köprüköy",
        "Narman",
        "Oltu",
        "Olur",
        "Palandöken",
        "Pasinler",
        "Pazaryolu",
        "Şenkaya",
        "Tekman",
        "Tortum",
        "Uzundere",
        "Yakutiye",
      ],
    },
    {
      il: "Eskişehir",
      plaka: 26,
      ilceleri: [
        "Alpu",
        "Beylikova",
        "Çifteler",
        "Günyüzü",
        "Han",
        "İnönü",
        "Mahmudiye",
        "Mihalgazi",
        "Mihalıççık",
        "Odunpazarı",
        "Sarıcakaya",
        "Seyitgazi",
        "Sivrihisar",
        "Tepebaşı",
      ],
    },
    {
      il: "Gaziantep",
      plaka: 27,
      ilceleri: [
        "Araban",
        "İslahiye",
        "Karkamış",
        "Nizip",
        "Nurdağı",
        "Oğuzeli",
        "Şahinbey",
        "Şehitkamil",
        "Yavuzeli",
      ],
    },
    {
      il: "Giresun",
      plaka: 28,
      ilceleri: [
        "Alucra",
        "Bulancak",
        "Çamoluk",
        "Çanakçı",
        "Dereli",
        "Doğankent",
        "Espiye",
        "Eynesil",
        "Görele",
        "Güce",
        "Keşap",
        "Merkez",
        "Piraziz",
        "Şebinkarahisar",
        "Tirebolu",
        "Yağlıdere",
      ],
    },
    {
      il: "Gümüşhane",
      plaka: 29,
      ilceleri: ["Kelkit", "Köse", "Kürtün", "Merkez", "Şiran", "Torul"],
    },
    {
      il: "Hakkari",
      plaka: 30,
      ilceleri: ["Çukurca", "Merkez", "Şemdinli", "Yüksekova"],
    },
    {
      il: "Hatay",
      plaka: 31,
      ilceleri: [
        "Altınözü",
        "Antakya",
        "Arsuz",
        "Belen",
        "Defne",
        "Dörtyol",
        "Erzin",
        "Hassa",
        "İskenderun",
        "Kırıkhan",
        "Kumlu",
        "Payas",
        "Reyhanlı",
        "Samandağ",
        "Yayladağı",
      ],
    },
    {
      il: "Iğdır",
      plaka: 76,
      ilceleri: ["Aralık", "Karakoyunlu", "Merkez", "Tuzluca"],
    },
    {
      il: "Isparta",
      plaka: 32,
      ilceleri: [
        "Aksu",
        "Atabey",
        "Eğirdir",
        "Gelendost",
        "Gönen",
        "Keçiborlu",
        "Merkez",
        "Şarkikaraağaç",
        "Senirkent",
        "Sütçüler",
        "Uluborlu",
        "Yalvaç",
        "Yenişarbademli",
      ],
    },
    {
      il: "İstanbul",
      plaka: 34,
      ilceleri: [
        "Adalar",
        "Arnavutköy",
        "Ataşehir",
        "Avcılar",
        "Bağcılar",
        "Bahçelievler",
        "Bakırköy",
        "Başakşehir",
        "Bayrampaşa",
        "Beşiktaş",
        "Beykoz",
        "Beylikdüzü",
        "Beyoğlu",
        "Büyükçekmece",
        "Çatalca",
        "Çekmeköy",
        "Esenler",
        "Esenyurt",
        "Eyüp",
        "Fatih",
        "Gaziosmanpaşa",
        "Güngören",
        "Kadıköy",
        "Kağıthane",
        "Kartal",
        "Küçükçekmece",
        "Maltepe",
        "Pendik",
        "Sancaktepe",
        "Sarıyer",
        "Şile",
        "Silivri",
        "Şişli",
        "Sultanbeyli",
        "Sultangazi",
        "Tuzla",
        "Ümraniye",
        "Üsküdar",
        "Zeytinburnu",
      ],
    },
    {
      il: "İzmir",
      plaka: 35,
      ilceleri: [
        "Aliağa",
        "Balçova",
        "Bayındır",
        "Bayraklı",
        "Bergama",
        "Beydağ",
        "Bornova",
        "Buca",
        "Çeşme",
        "Çiğli",
        "Dikili",
        "Foça",
        "Gaziemir",
        "Güzelbahçe",
        "Karabağlar",
        "Karaburun",
        "Karşıyaka",
        "Kemalpaşa",
        "Kiraz",
        "Kınık",
        "Konak",
        "Menderes",
        "Menemen",
        "Narlıdere",
        "Ödemiş",
        "Seferihisar",
        "Selçuk",
        "Tire",
        "Torbalı",
        "Urla",
      ],
    },
    {
      il: "Kahramanmaraş",
      plaka: 46,
      ilceleri: [
        "Afşin",
        "Andırın",
        "Çağlayancerit",
        "Dulkadiroğlu",
        "Ekinözü",
        "Elbistan",
        "Göksun",
        "Merkez",
        "Nurhak",
        "Onikişubat",
        "Pazarcık",
        "Türkoğlu",
      ],
    },
    {
      il: "Karabük",
      plaka: 78,
      ilceleri: [
        "Eflani",
        "Eskipazar",
        "Merkez",
        "Ovacık",
        "Safranbolu",
        "Yenice",
      ],
    },
    {
      il: "Karaman",
      plaka: 70,
      ilceleri: [
        "Ayrancı",
        "Başyayla",
        "Ermenek",
        "Kazımkarabekir",
        "Merkez",
        "Sarıveliler",
      ],
    },
    {
      il: "Kars",
      plaka: 36,
      ilceleri: [
        "Akyaka",
        "Arpaçay",
        "Digor",
        "Kağızman",
        "Merkez",
        "Sarıkamış",
        "Selim",
        "Susuz",
      ],
    },
    {
      il: "Kastamonu",
      plaka: 37,
      ilceleri: [
        "Abana",
        "Ağlı",
        "Araç",
        "Azdavay",
        "Bozkurt",
        "Çatalzeytin",
        "Cide",
        "Daday",
        "Devrekani",
        "Doğanyurt",
        "Hanönü",
        "İhsangazi",
        "İnebolu",
        "Küre",
        "Merkez",
        "Pınarbaşı",
        "Şenpazar",
        "Seydiler",
        "Taşköprü",
        "Tosya",
      ],
    },
    {
      il: "Kayseri",
      plaka: 38,
      ilceleri: [
        "Akkışla",
        "Bünyan",
        "Develi",
        "Felahiye",
        "Hacılar",
        "İncesu",
        "Kocasinan",
        "Melikgazi",
        "Özvatan",
        "Pınarbaşı",
        "Sarıoğlan",
        "Sarız",
        "Talas",
        "Tomarza",
        "Yahyalı",
        "Yeşilhisar",
      ],
    },
    {
      il: "Kilis",
      plaka: 79,
      ilceleri: ["Elbeyli", "Merkez", "Musabeyli", "Polateli"],
    },
    {
      il: "Kırıkkale",
      plaka: 71,
      ilceleri: [
        "Bahşili",
        "Balışeyh",
        "Çelebi",
        "Delice",
        "Karakeçili",
        "Keskin",
        "Merkez",
        "Sulakyurt",
        "Yahşihan",
      ],
    },
    {
      il: "Kırklareli",
      plaka: 39,
      ilceleri: [
        "Babaeski",
        "Demirköy",
        "Kofçaz",
        "Lüleburgaz",
        "Merkez",
        "Pehlivanköy",
        "Pınarhisar",
        "Vize",
      ],
    },
    {
      il: "Kırşehir",
      plaka: 40,
      ilceleri: [
        "Akçakent",
        "Akpınar",
        "Boztepe",
        "Çiçekdağı",
        "Kaman",
        "Merkez",
        "Mucur",
      ],
    },
    {
      il: "Kocaeli",
      plaka: 41,
      ilceleri: [
        "Başiskele",
        "Çayırova",
        "Darıca",
        "Derince",
        "Dilovası",
        "Gebze",
        "Gölcük",
        "İzmit",
        "Kandıra",
        "Karamürsel",
        "Kartepe",
        "Körfez",
      ],
    },
    {
      il: "Konya",
      plaka: 42,
      ilceleri: [
        "Ahırlı",
        "Akören",
        "Akşehir",
        "Altınekin",
        "Beyşehir",
        "Bozkır",
        "Çeltik",
        "Cihanbeyli",
        "Çumra",
        "Derbent",
        "Derebucak",
        "Doğanhisar",
        "Emirgazi",
        "Ereğli",
        "Güneysınır",
        "Hadim",
        "Halkapınar",
        "Hüyük",
        "Ilgın",
        "Kadınhanı",
        "Karapınar",
        "Karatay",
        "Kulu",
        "Meram",
        "Sarayönü",
        "Selçuklu",
        "Seydişehir",
        "Taşkent",
        "Tuzlukçu",
        "Yalıhüyük",
        "Yunak",
      ],
    },
    {
      il: "Kütahya",
      plaka: 43,
      ilceleri: [
        "Altıntaş",
        "Aslanapa",
        "Çavdarhisar",
        "Domaniç",
        "Dumlupınar",
        "Emet",
        "Gediz",
        "Hisarcık",
        "Merkez",
        "Pazarlar",
        "Şaphane",
        "Simav",
        "Tavşanlı",
      ],
    },
    {
      il: "Malatya",
      plaka: 44,
      ilceleri: [
        "Akçadağ",
        "Arapgir",
        "Arguvan",
        "Battalgazi",
        "Darende",
        "Doğanşehir",
        "Doğanyol",
        "Hekimhan",
        "Kale",
        "Kuluncak",
        "Merkez",
        "Pütürge",
        "Yazıhan",
        "Yeşilyurt",
      ],
    },
    {
      il: "Manisa",
      plaka: 45,
      ilceleri: [
        "Ahmetli",
        "Akhisar",
        "Alaşehir",
        "Demirci",
        "Gölmarmara",
        "Gördes",
        "Kırkağaç",
        "Köprübaşı",
        "Kula",
        "Merkez",
        "Salihli",
        "Sarıgöl",
        "Saruhanlı",
        "Şehzadeler",
        "Selendi",
        "Soma",
        "Turgutlu",
        "Yunusemre",
      ],
    },
    {
      il: "Mardin",
      plaka: 47,
      ilceleri: [
        "Artuklu",
        "Dargeçit",
        "Derik",
        "Kızıltepe",
        "Mazıdağı",
        "Merkez",
        "Midyat",
        "Nusaybin",
        "Ömerli",
        "Savur",
        "Yeşilli",
      ],
    },
    {
      il: "Mersin",
      plaka: 33,
      ilceleri: [
        "Akdeniz",
        "Anamur",
        "Aydıncık",
        "Bozyazı",
        "Çamlıyayla",
        "Erdemli",
        "Gülnar",
        "Mezitli",
        "Mut",
        "Silifke",
        "Tarsus",
        "Toroslar",
        "Yenişehir",
      ],
    },
    {
      il: "Muğla",
      plaka: 48,
      ilceleri: [
        "Bodrum",
        "Dalaman",
        "Datça",
        "Fethiye",
        "Kavaklıdere",
        "Köyceğiz",
        "Marmaris",
        "Menteşe",
        "Milas",
        "Ortaca",
        "Seydikemer",
        "Ula",
        "Yatağan",
      ],
    },
    {
      il: "Muş",
      plaka: 49,
      ilceleri: ["Bulanık", "Hasköy", "Korkut", "Malazgirt", "Merkez", "Varto"],
    },
    {
      il: "Nevşehir",
      plaka: 50,
      ilceleri: [
        "Acıgöl",
        "Avanos",
        "Derinkuyu",
        "Gülşehir",
        "Hacıbektaş",
        "Kozaklı",
        "Merkez",
        "Ürgüp",
      ],
    },
    {
      il: "Niğde",
      plaka: 51,
      ilceleri: [
        "Altunhisar",
        "Bor",
        "Çamardı",
        "Çiftlik",
        "Merkez",
        "Ulukışla",
      ],
    },
    {
      il: "Ordu",
      plaka: 52,
      ilceleri: [
        "Akkuş",
        "Altınordu",
        "Aybastı",
        "Çamaş",
        "Çatalpınar",
        "Çaybaşı",
        "Fatsa",
        "Gölköy",
        "Gülyalı",
        "Gürgentepe",
        "İkizce",
        "Kabadüz",
        "Kabataş",
        "Korgan",
        "Kumru",
        "Mesudiye",
        "Perşembe",
        "Ulubey",
        "Ünye",
      ],
    },
    {
      il: "Osmaniye",
      plaka: 80,
      ilceleri: [
        "Bahçe",
        "Düziçi",
        "Hasanbeyli",
        "Kadirli",
        "Merkez",
        "Sumbas",
        "Toprakkale",
      ],
    },
    {
      il: "Rize",
      plaka: 53,
      ilceleri: [
        "Ardeşen",
        "Çamlıhemşin",
        "Çayeli",
        "Derepazarı",
        "Fındıklı",
        "Güneysu",
        "Hemşin",
        "İkizdere",
        "İyidere",
        "Kalkandere",
        "Merkez",
        "Pazar",
      ],
    },
    {
      il: "Sakarya",
      plaka: 54,
      ilceleri: [
        "Adapazarı",
        "Akyazı",
        "Arifiye",
        "Erenler",
        "Ferizli",
        "Geyve",
        "Hendek",
        "Karapürçek",
        "Karasu",
        "Kaynarca",
        "Kocaali",
        "Pamukova",
        "Sapanca",
        "Serdivan",
        "Söğütlü",
        "Taraklı",
      ],
    },
    {
      il: "Samsun",
      plaka: 55,
      ilceleri: [
        "Alaçam",
        "Asarcık",
        "Atakum",
        "Ayvacık",
        "Bafra",
        "Canik",
        "Çarşamba",
        "Havza",
        "İlkadım",
        "Kavak",
        "Ladik",
        "Ondokuzmayıs",
        "Salıpazarı",
        "Tekkeköy",
        "Terme",
        "Vezirköprü",
        "Yakakent",
      ],
    },
    {
      il: "Şanlıurfa",
      plaka: 63,
      ilceleri: [
        "Akçakale",
        "Birecik",
        "Bozova",
        "Ceylanpınar",
        "Eyyübiye",
        "Halfeti",
        "Haliliye",
        "Harran",
        "Hilvan",
        "Karaköprü",
        "Siverek",
        "Suruç",
        "Viranşehir",
      ],
    },
    {
      il: "Siirt",
      plaka: 56,
      ilceleri: [
        "Baykan",
        "Eruh",
        "Kurtalan",
        "Merkez",
        "Pervari",
        "Şirvan",
        "Tillo",
      ],
    },
    {
      il: "Sinop",
      plaka: 57,
      ilceleri: [
        "Ayancık",
        "Boyabat",
        "Dikmen",
        "Durağan",
        "Erfelek",
        "Gerze",
        "Merkez",
        "Saraydüzü",
        "Türkeli",
      ],
    },
    {
      il: "Sivas",
      plaka: 58,
      ilceleri: [
        "Akıncılar",
        "Altınyayla",
        "Divriği",
        "Doğanşar",
        "Gemerek",
        "Gölova",
        "Gürün",
        "Hafik",
        "İmranlı",
        "Kangal",
        "Koyulhisar",
        "Merkez",
        "Şarkışla",
        "Suşehri",
        "Ulaş",
        "Yıldızeli",
        "Zara",
      ],
    },
    {
      il: "Şırnak",
      plaka: 73,
      ilceleri: [
        "Beytüşşebap",
        "Cizre",
        "Güçlükonak",
        "İdil",
        "Merkez",
        "Silopi",
        "Uludere",
      ],
    },
    {
      il: "Tekirdağ",
      plaka: 59,
      ilceleri: [
        "Çerkezköy",
        "Çorlu",
        "Ergene",
        "Hayrabolu",
        "Kapaklı",
        "Malkara",
        "Marmaraereğlisi",
        "Muratlı",
        "Saray",
        "Şarköy",
        "Süleymanpaşa",
      ],
    },
    {
      il: "Tokat",
      plaka: 60,
      ilceleri: [
        "Almus",
        "Artova",
        "Başçiftlik",
        "Erbaa",
        "Merkez",
        "Niksar",
        "Pazar",
        "Reşadiye",
        "Sulusaray",
        "Turhal",
        "Yeşilyurt",
        "Zile",
      ],
    },
    {
      il: "Trabzon",
      plaka: 61,
      ilceleri: [
        "Akçaabat",
        "Araklı",
        "Arsin",
        "Beşikdüzü",
        "Çarşıbaşı",
        "Çaykara",
        "Dernekpazarı",
        "Düzköy",
        "Hayrat",
        "Köprübaşı",
        "Maçka",
        "Of",
        "Ortahisar",
        "Şalpazarı",
        "Sürmene",
        "Tonya",
        "Vakfıkebir",
        "Yomra",
      ],
    },
    {
      il: "Tunceli",
      plaka: 62,
      ilceleri: [
        "Çemişgezek",
        "Hozat",
        "Mazgirt",
        "Merkez",
        "Nazımiye",
        "Ovacık",
        "Pertek",
        "Pülümür",
      ],
    },
    {
      il: "Uşak",
      plaka: 64,
      ilceleri: ["Banaz", "Eşme", "Karahallı", "Merkez", "Sivaslı", "Ulubey"],
    },
    {
      il: "Van",
      plaka: 65,
      ilceleri: [
        "Bahçesaray",
        "Başkale",
        "Çaldıran",
        "Çatak",
        "Edremit",
        "Erciş",
        "Gevaş",
        "Gürpınar",
        "İpekyolu",
        "Muradiye",
        "Özalp",
        "Saray",
        "Tuşba",
      ],
    },
    {
      il: "Yalova",
      plaka: 77,
      ilceleri: [
        "Altınova",
        "Armutlu",
        "Çiftlikköy",
        "Çınarcık",
        "Merkez",
        "Termal",
      ],
    },
    {
      il: "Yozgat",
      plaka: 66,
      ilceleri: [
        "Akdağmadeni",
        "Aydıncık",
        "Boğazlıyan",
        "Çandır",
        "Çayıralan",
        "Çekerek",
        "Kadışehri",
        "Merkez",
        "Saraykent",
        "Sarıkaya",
        "Şefaatli",
        "Sorgun",
        "Yenifakılı",
        "Yerköy",
      ],
    },
    {
      il: "Zonguldak",
      plaka: 67,
      ilceleri: ["Alaplı", "Çaycuma", "Devrek", "Ereğli", "Gökçebey", "Merkez"],
    },
  ];

  const removeTurkishCharacters = (str) => {
    return str
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ü/g, "u")
      .replace(/Ü/g, "U")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "S")
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C");
  };

  // İl seçenekleri oluştur
  const ilOptions = data.map((il) => ({
    value: removeTurkishCharacters(il.il.toUpperCase()),
    label: il.il,
  }));

  // İlçe seçenekleri için state
  const [ilceOptions, setIlceOptions] = useState([]);

  // Seçilen il ve ilçelerin değerlerini takip etmek için state
  const [selectedIl, setSelectedIl] = useState(null);
  const [selectedIlce, setSelectedIlce] = useState(null);

  // Sıfırla butonunu göstermek için state
  const [showResetButton, setShowResetButton] = useState(false);

  // İl seçildiğinde ilçe seçeneklerini güncelle
  const handleIlChange = (selectedIl) => {
    const selectedIlData = data.find(
      (il) =>
        removeTurkishCharacters(il.il.toUpperCase()) ===
        selectedIl.value.toUpperCase()
    );
    const ilceOptions = selectedIlData
      ? selectedIlData.ilceleri.map((ilce) => ({
          value: removeTurkishCharacters(ilce.toUpperCase()),
          label: ilce,
        }))
      : [];
    setIlceOptions(ilceOptions);
    setSelectedIl(selectedIl);
    setSelectedIlce(null); // İl değiştiğinde ilçeyi sıfırla
    setShowResetButton(true);
  };

  // İlçe seçildiğinde değeri takip et
  const handleIlceChange = (selectedIlce) => {
    setSelectedIlce(selectedIlce);
    setShowResetButton(true);
  };

  // Güncelle butonuna tıklandığında seçilen değerleri yazdır
  const handleGuncelleClick = () => {
    if (selectedIlce) {
      document.querySelector(".breaking-news").classList.add("active");
      document.querySelector(".scroll-up-btn").classList.add("near-news");

      setNewsCategory(selectedIlce.value);
    } else {
      if (selectedIl) {
        document.querySelector(".breaking-news").classList.add("active");
        document.querySelector(".scroll-up-btn").classList.add("near-news");
        setNewsCategory(selectedIl.value);
      }
    }
  };

  // Sıfırla butonuna tıklandığında seçimleri sıfırla
  const handleSifirlaClick = () => {
    setSelectedIl(null);
    setSelectedIlce(null);
    setIlceOptions([]);
    setShowResetButton(false);

    setNewsCategory(null);

    document.querySelector(".breaking-news").classList.add("active");
    document.querySelector(".scroll-up-btn").classList.add("near-news");
  };

  // Exchange Rate
  const [rateData, setRateData] = useState(null);

  const getRate = useCallback(
    async (value) => {
      if (localStorage.getItem("token")) {
        try {
          const response = await apiClient.get(`/tcbm`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

          var currencyData = response.data.data.Currency.filter(function (
            eleman
          ) {
            return typeof eleman["BanknoteBuying"] !== "object";
          });
          setRateData(currencyData);
        } catch (error) {
          if (error.response.status !== 404 && error.response.status !== 500) {
            // setAllStreet(null);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            navigate("/giris-yap");
          }
        }
      }
    },
    [navigate]
  );

  // Daily Program
  const [dailyData] = useState(null);

  // const getDaily = async (value) => {
  //   if (localStorage.getItem("token")) {
  //     try {
  //       const response = await apiClient.get(`/tcbb`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       });

  //       if (response.data.data.DailyProgramList[0]) {
  //         setDailyData(response.data.data.DailyProgramList[0]);
  //       } else {
  //         setDailyData([]);
  //       }
  //     } catch (error) {
  //       if (error.response.status === 401 || error.response.status === 403) {
  //         // setDailyData(null);
  //         localStorage.removeItem("token");
  //         localStorage.removeItem("user");
  //       }
  //     }
  //   } else {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("user");
  //   }
  // };

  // Cache Clear
  const [cacheLoader, setCacheLoader] = useState(false);

  async function cacheClear() {
    setCacheLoader(false);

    if (localStorage.getItem("token")) {
      try {
        const response = await apiClient.get(`/cache-clear`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response) {
          setCacheLoader(true);
          window.location.reload();
        }
      } catch (error) {
        setCacheLoader(true);
        window.location.reload();
      }
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/giris-yap");
    }
  }

  // Graphic Delete
  const [createGraphicOpen, setCreateGraphicOpen] = useState(false);

  const [graphicFetch, setGraphicFetch] = useState(null);

  const [graphicDelete, setGraphicDelete] = useState(null);

  // Graphic Update
  const [graphicUpdate, setGraphicUpdate] = useState(null);

  useEffect(() => {
    let createGraphicModal = document.getElementById("createGraphicModal");
    if (createGraphicModal) {
      createGraphicModal.addEventListener("hidden.bs.modal", function (event) {
        setGraphicUpdate(null);
      });
    }
  }, []);

  // First Load
  useEffect(() => {
    // getAllRoles();
    getMe();

    getAllCity();
    // getDaily();
  }, []);

  useEffect(() => {
    if (me) {
      getPages();
    }
  }, [getPages, me, t, menus]);

  useEffect(() => {
    if (me && me?.role?.id !== 5) {
      getFileTypes();
    }
  }, [getFileTypes, me]);

  useEffect(() => {
    if (me) {
      getNews();
    }
  }, [newsCategory, me]);

  useEffect(() => {
    const isProduction = process.env.NODE_ENV === "production";

    if (isProduction) {
      const handleContextMenu = (e) => {
        e.preventDefault();
      };

      const handlePrint = (e) => {
        if (e.keyCode === 80 && (e.ctrlKey || e.metaKey)) {
          e.preventDefault();
        }
      };

      const handleInspect = (e) => {
        if (
          (e.key === "c" && e.metaKey && e.shiftKey) ||
          e.keyCode === 123 ||
          e.keyCode === 121
        ) {
          e.preventDefault();
        }
      };

      document.addEventListener("contextmenu", handleContextMenu);
      document.addEventListener("keydown", handlePrint);
      document.addEventListener("keydown", handleInspect);

      return () => {
        document.removeEventListener("contextmenu", handleContextMenu);
        document.removeEventListener("keydown", handlePrint);
        document.removeEventListener("keydown", handleInspect);
      };
    }
  }, []);

  // Theme Colors
  const themeColorList = [
    { color: "#d23c3c", value: "theme-color-1" },
    { color: "#d23c5b", value: "theme-color-2" },
    { color: "#3ca7d2", value: "theme-color-3" },
    { color: "#3ccbd2", value: "theme-color-4" },
    { color: "#3cd2a5", value: "theme-color-5" },
    { color: "#3cd26a", value: "theme-color-6" },
    { color: "#a2d23c", value: "theme-color-7" },
    { color: "#d2bc3c", value: "theme-color-8" },
    { color: "#d2a83c", value: "theme-color-9" },
    { color: "#d26e3c", value: "theme-color-10" },
    { color: "#000000", value: "theme-color-11" },
  ];

  // Phone Format
  const phoneFormat = (phones) => {
    const phoneList = phones.includes(",")
      ? phones.split(",").map((phone) => {
          phone = phone.trim();
          if (phone.length > 10) {
            return phone.match(/.{1,10}/g).map((num, index) =>
              num === "BOŞ" ? (
                num
              ) : (
                <a
                  className="d-block"
                  key={`${num}-${index}`}
                  href={`tel:${num}`}
                >
                  {num}
                </a>
              )
            );
          } else {
            return phone === "BOŞ" ? (
              phone
            ) : (
              <a className="d-block" key={phone} href={`tel:${phone}`}>
                {phone}
              </a>
            );
          }
        })
      : phones.match(/.{1,10}/g).map((num, index) =>
          num === "BOŞ" ? (
            num
          ) : (
            <a className="d-block" key={`${num}-${index}`} href={`tel:${num}`}>
              {num}
            </a>
          )
        );

    return phoneList;
  };

  const [activeCustomer, setActiveCustomer] = useState(null);

  // Dogum Gunleri
  const [birthDayData, setBirthDayData] = useState(null);

  const fetchBirthDay = useCallback(async () => {
    setBirthDayData(null);

    const option = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `bireysel-data-customer-${me?.customer?.id}`,
      description: "",
      customer: null,
      paginate: true,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: "data",
            value: "select",
          },
        ],
        condition: [
          {
            key: "Dogum_Tarihi_toMonth",
            value: (new Date().getMonth() + 1).toString(),
            operator: "=",
            type: "and",
          },
          {
            key: "Dogum_Tarihi_dayOfMonth",
            value: new Date().getDate().toString(),
            operator: "=",
            type: "and",
          },
        ],
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=1`,
        option,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setBirthDayData(response);
    } catch (error) {
      setBirthDayData([]);
      console.error(error);
    }
  }, [me?.customer?.id]);

  useEffect(() => {
    if (me?.role?.id === 1 || me?.role?.id === 2 || me?.role?.id === 4) {
      fetchBirthDay();
    }
  }, [me?.role?.id, fetchBirthDay]);

  // Socket
  const [socketData, setSocketData] = useState();

  function fetchSocket(id) {
    const socket = io("https://api.stratejimerkezi.com/", {
      query: { user: id },
      reconnection: true, // Otomatik yeniden bağlanmayı etkinleştirir
      reconnectionAttempts: Infinity, // Yeniden bağlanma deneme sınırı
    });
    socket.on("ImportProgress", (data) => {
      const jsonData = JSON.parse(data).data;

      setSocketData(jsonData);

      if (jsonData?.progress === 100 && jsonData?.failedRecords === 0) {
        setTimeout(() => {
          setSocketData(null);
        }, 20000);
      }
    });
    // Bağlantı kurulduğunda tetiklenen fonksiyon
    socket.on("connect", () => {
      console.log("Socket.IO bağlandı");
    });
    // Bağlantı kesildiğinde tetiklenen fonksiyon
    socket.on("disconnect", () => {
      console.log("Socket.IO sunucusundan ayrıldı");
    });
  }

  // Groups Pages
  const [assignmentGroupData, setAssignmentGroupData] = useState(null);

  async function fetchAssigmentGroupsPages() {
    setAssignmentGroupData(null);

    try {
      const response = await apiClient.get(`/pages/assignment`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setAssignmentGroupData(response?.data);
      }
    } catch (error) {
      setAssignmentGroupData(null);
      console.error("Birimler", error);
    }
  }

  useEffect(() => {
    if (me?.role?.id === 8) {
      fetchAssigmentGroupsPages();
    }
  }, [me?.role?.id]);

  // AI
  const [aiData, setAiData] = useState(null);

  // Provider
  const providerValue = {
    me,
    setMe,
    t,
    language,
    setLanguage,
    assignmentGroupData,
    pages,
    getPages,
    phoneFormat,
    getOncePages,
    fileTypes,
    getFileTypes,
    getCustomer,
    allCustomerSelect,
    allRoles,
    setAllRoles,
    allRolesSelect,
    allCity,
    getAllDistrict,
    getAllNeighborhood,
    fetchAssigmentGroupsPages,
    setAiData,
    // getAllStreet,
    chartColor,
    theme,
    setTheme,
    setChartColor,
    setDeletePage,
    setUpdatePage,
    graphicUpdate,
    setGraphicUpdate,
    setGraphicDelete,
    graphicFetch,
    setGraphicFetch,
    setCreateGraphicOpen,
    removeTurkishCharacters,
    themeColorList,
    activeCustomer,
    setActiveCustomer,
    birthDayData,
    //-
    setAppHeaderNone,
    setAppSidebarNone,
    setAppSidebarCollapsed,
    setAppContentNone,
    setAppContentClass,
    setAppContentFullHeight,
    setAppBoxedLayout,
    setAppWeather,
    setAppScrollButton,
    setAppFooter,
    setThemePanel,
    setAppFooterFull,
    setAppTopNav,
  };

  return (
    <AppSettings.Provider value={providerValue}>
      {loading &&
      !pages &&
      window.location.pathname !== "/giris-yap" &&
      !window.location.pathname.startsWith("/anket/") ? (
        <PageLoader loading={loading} setLoading={setLoading} />
      ) : (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API}>
          <div
            className={
              "app " +
              (appBoxedLayout ? "app-boxed-layout " : "") +
              (appContentFullHeight ? "app-content-full-height " : "") +
              (appHeaderNone ? "app-without-header " : "") +
              (appSidebarNone ? "app-without-sidebar " : "") +
              (appSidebarCollapsed ? "app-sidebar-collapsed " : "") +
              // (appFooter ? "app-footer-fixed " : "") +
              (appTopNav ? "app-with-top-nav " : "") +
              (appContentClass ? appContentClass : "")
            }
          >
            {!appHeaderNone && <Header />}
            {appTopNav && <TopNav />}
            {!appSidebarNone && (
              <Sidebar
                importFileOpen={importFileOpen}
                setImportFileOpen={setImportFileOpen}
              />
            )}
            {!appContentNone && (
              <div className="app-content mb-4">
                <Content />
              </div>
            )}

            {socketData &&
              appWeather &&
              (me?.role?.id === 1 ||
                me?.role?.id === 2 ||
                me?.role?.id === 3 ||
                me?.role?.id === 4 ||
                me?.role?.id === 8) && (
                <div
                  className="file-progress-card"
                  style={{ bottom: fileBottomActive ? 310 : 20 }}
                >
                  <div
                    className="d-flex align-items-center justify-content-between w-100 pointer"
                    onClick={() => setFileActive(!fileActive)}
                  >
                    <h5 className="mb-0 flex-1">{t?.file}</h5>
                    {fileActive ? (
                      <i className="fas fa-angle-down"></i>
                    ) : (
                      <i className="fas fa-angle-up"></i>
                    )}
                  </div>

                  {fileActive && (
                    <div className="border-top pt-3">
                      <div
                        className="overflow-auto d-flex flex-column gap-3 pe-2 me-n2"
                        style={{
                          maxHeight: 400,
                        }}
                      >
                        <div>
                          <div className="h6 d-flex align-items-center justify-content-between">
                            <span>{socketData?.fileName}</span>

                            {socketData?.progress === 100 && (
                              <a
                                href={"/yuklenen-dosyalar"}
                                style={{ fontSize: 13 }}
                              >
                                {t?.view}
                              </a>
                            )}
                          </div>

                          <div className="progress">
                            <span className="position-absolute start-0 end-0 text-center">
                              %{socketData?.progress}
                            </span>
                            <div
                              className={`${
                                socketData?.failedRecords
                                  ? "bg-danger"
                                  : "bg-success text-black"
                              } fw-bold progress-bar progress-bar-striped progress-bar-animated`}
                              role="progressbar"
                              aria-valuenow={socketData?.progress}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: socketData?.progress + "%" }}
                            ></div>
                          </div>

                          {socketData?.failedRecords !== 0 && (
                            <span className="text-danger">
                              {socketData?.failedRecords} {t?.satir_yuklenemedi}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

            {appWeather &&
              me?.role?.id !== 5 &&
              me?.role?.id !== 6 &&
              me?.role?.id !== 7 && (
                <div className="row breaking-news">
                  <div className="breaking-news-events">
                    <span
                      className="h6 breaking-news-title"
                      onClick={newsToggle}
                    >
                      {t?.breaking_news}
                      <i className="fas fa-md fa-fw fa-arrow-down"></i>
                    </span>

                    <Select
                      options={ilOptions}
                      menuPlacement="top"
                      classNamePrefix="react-select"
                      placeholder={t?.choose_city}
                      noOptionsMessage={() => t?.no_option}
                      onChange={handleIlChange}
                      value={selectedIl}
                    />
                    <Select
                      options={ilceOptions}
                      menuPlacement="top"
                      classNamePrefix="react-select"
                      placeholder={t?.choose_district}
                      noOptionsMessage={() => t?.no_option}
                      onChange={handleIlceChange}
                      value={selectedIlce}
                    />
                    <button
                      className="btn btn-theme text-black"
                      onClick={handleGuncelleClick}
                    >
                      {t?.find}
                    </button>
                    {showResetButton && (
                      <button
                        className="btn btn-dark"
                        onClick={handleSifirlaClick}
                      >
                        {t?.reset}
                      </button>
                    )}
                  </div>

                  <div className="col-12 breaking-news-col">
                    {newsData ? (
                      newsData.length !== 0 ? (
                        <div className="row align-items-center">
                          <div className="col-12 col-md overflow-hidden">
                            <Swiper
                              onSwiper={(s) => {
                                setBreakingNewsSwiper(s);
                              }}
                              speed={800}
                              autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                              }}
                              slidesPerView={1}
                              spaceBetween={24}
                              modules={[Autoplay]}
                              breakpoints={{
                                1600: {
                                  slidesPerView: 6,
                                },
                                1370: {
                                  slidesPerView: 5,
                                },
                                1199: {
                                  slidesPerView: 4,
                                },
                                767: {
                                  slidesPerView: 3,
                                },
                              }}
                            >
                              {newsData.map((item, index) => (
                                <SwiperSlide key={index}>
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="news-item"
                                  >
                                    <p className="mb-0" title={item.title}>
                                      {item.title.includes("CHP") ? (
                                        <span style={{ color: "#ee171f" }}>
                                          {item.title}
                                        </span>
                                      ) : item.title.includes("Erdoğan") ? (
                                        <span style={{ color: "#f79b04" }}>
                                          {item.title}
                                        </span>
                                      ) : (
                                        <span>{item.title}</span>
                                      )}

                                      <span className="news-item-desc">
                                        {item.description}
                                      </span>

                                      <span className="d-flex align-items-center justify-content-between mt-1">
                                        <span className="text-date text-theme d-block">
                                          <TurkishDateComponent
                                            news={true}
                                            dateString={item.pubDate}
                                          />
                                        </span>

                                        <span className="text-date text-theme d-block">
                                          {item.link
                                            .split(".com/")[0]
                                            .split("://")[1] + ".com"}
                                        </span>
                                      </span>
                                    </p>
                                  </a>
                                </SwiperSlide>
                              ))}
                            </Swiper>

                            {rateData && (
                              <div className="border-top pt-3 mt-3">
                                <Swiper
                                  speed={800}
                                  autoplay={{
                                    delay: 3500,
                                    disableOnInteraction: false,
                                  }}
                                  slidesPerView={1}
                                  spaceBetween={24}
                                  modules={[Autoplay]}
                                  breakpoints={{
                                    1600: {
                                      slidesPerView: 7,
                                    },
                                    1370: {
                                      slidesPerView: 5,
                                    },
                                    1199: {
                                      slidesPerView: 4,
                                    },
                                    767: {
                                      slidesPerView: 3,
                                    },
                                  }}
                                >
                                  {rateData.map((item, index) => (
                                    <SwiperSlide key={index}>
                                      <div
                                        key={index}
                                        className="text-center p-2"
                                        style={{
                                          background:
                                            "rgba(255, 255, 255, 0.1)",
                                        }}
                                      >
                                        {item.Isim}
                                        <div className="d-flex align-items-center justify-content-center gap-1">
                                          <span className="text-white">
                                            {parseFloat(
                                              item.BanknoteBuying
                                            ).toFixed(4)}
                                          </span>
                                          <span className="text-theme">
                                            %
                                            {(
                                              ((parseFloat(
                                                item.BanknoteSelling
                                              ) -
                                                parseFloat(
                                                  item.BanknoteBuying
                                                )) /
                                                parseFloat(
                                                  item.BanknoteBuying
                                                )) *
                                              100
                                            ).toFixed(2)}
                                            {((parseFloat(
                                              item.BanknoteSelling
                                            ) -
                                              parseFloat(item.BanknoteBuying)) /
                                              parseFloat(item.BanknoteBuying)) *
                                              100 >
                                            0 ? (
                                              <i className="fas fa-md fa-fw fa-caret-up text-theme"></i>
                                            ) : (
                                              ((parseFloat(
                                                item.BanknoteSelling
                                              ) -
                                                parseFloat(
                                                  item.BanknoteBuying
                                                )) /
                                                parseFloat(
                                                  item.BanknoteBuying
                                                )) *
                                                100 <
                                                0 && (
                                                <i className="fas fa-md fa-fw fa-caret-down text-danger"></i>
                                              )
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </div>
                            )}
                          </div>

                          <div className="w-auto">
                            <div className="w-auto d-flex flex-column">
                              <button
                                className="d-block btn px-2 py-0 btn-outline-theme"
                                onClick={() => breakingNewsSwiper.slidePrev()}
                              >
                                {t?.prev}
                              </button>

                              <button
                                className="d-block btn px-2 py-0 btn-outline-theme mt-2"
                                onClick={() => breakingNewsSwiper.slideNext()}
                              >
                                {t?.next}
                              </button>
                            </div>

                            <div className="weather-container">
                              <Weather />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <span className="d-block text-center">
                          {t?.no_data_available}
                        </span>
                      )
                    ) : (
                      <div className="spinner-border text-theme"></div>
                    )}
                  </div>
                </div>
              )}

            <Footer appScrollButton={appScrollButton} />

            {me && themePanel && <ThemePanel hexToRgba={hexToRgba} />}

            {appScrollButton && (
              <div
                className={`scroll-up-btn near-news ${
                  scrollToTopShow && "active"
                }`}
                onClick={scrollToTop}
              >
                <i className="fas fa-lg fa-fw fa-arrow-circle-up"></i>
              </div>
            )}
          </div>

          <div className="modal modal-cover fade" id="modalCacheClear">
            <div className="modal-dialog">
              <div
                className="modal-content rounded"
                style={{ background: "var(--bs-dark4)" }}
              >
                <div className="modal-header border-bottom">
                  <h3 className="modal-title d-flex align-items-center text-nowrap gap-2">
                    {t?.clear_cache}
                  </h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  <p>{t?.info_clear_cache}</p>

                  <div className="d-flex gap-3">
                    <button
                      type="button"
                      className="btn btn-outline-danger w-100"
                      onClick={() => cacheClear()}
                      disabled={cacheLoader}
                    >
                      {t?.delete}
                      {cacheLoader && (
                        <div className="spinner-border spinner-border-sm text-theme ms-2"></div>
                      )}
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-theme w-100"
                      data-bs-dismiss="modal"
                    >
                      {t?.cancel}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal modal-cover fade" id="modalTalepFormu">
            <div className="modal-dialog" style={{ maxWidth: 600 }}>
              <div
                className="modal-content rounded"
                style={{ background: "var(--bs-dark4)" }}
              >
                <div className="modal-header border-bottom">
                  <h3 className="modal-title d-flex align-items-center text-nowrap gap-2">
                    {t?.talep_formu}
                  </h3>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  <TalepFormu />
                </div>
              </div>
            </div>
          </div>

          <div className="modal modal-cover fade" id="modalDailyProgram">
            <div className="modal-dialog">
              <div
                className="modal-content rounded"
                style={{ background: "var(--bs-dark4)" }}
              >
                <div className="modal-header border-bottom">
                  <h4 className="modal-title">
                    Cumhurbaşkanlığı Günlük Program
                    <br />
                    <span className="form-control border-0 px-0 fw-bold text-theme">
                      {moment().locale("tr").format("LL")}
                    </span>
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  {dailyData ? (
                    dailyData.length !== 0 ? (
                      <>
                        <div className="d-flex flex-column mt-2">
                          <div>
                            <span
                              className="d-inline-block text-end me-1"
                              style={{ minWidth: 30 }}
                            >
                              {dailyData?.ProgramTipi === 1 ? (
                                <>
                                  <i className="fas fa-sm fa-fw fa-info ms-n1"></i>{" "}
                                  -
                                </>
                              ) : (
                                dailyData?.ProgramTipi === 2 && (
                                  <>
                                    <i className="fas fa-sm fa-fw fa-info ms-n1"></i>
                                    <i className="fas fa-sm fa-fw fa-info ms-n1"></i>{" "}
                                    -
                                  </>
                                )
                              )}
                            </span>
                            <strong className="text-theme">
                              {dailyData?.Time}{" "}
                            </strong>
                            {dailyData?.Title}
                          </div>
                        </div>

                        <div
                          className="p-3 mt-4"
                          style={{ background: "rgb(255 255 255 / 4%)" }}
                        >
                          <div>
                            <span
                              className="d-inline-block text-end me-1"
                              style={{ minWidth: 30 }}
                            >
                              <i className="fas fa-sm fa-fw fa-info ms-n1"></i>-
                            </span>
                            İşaretli programlar sadece{" "}
                            <strong>kamuoyunu</strong> bilgilendirmek amacıyla
                            duyurulmaktadır.
                          </div>

                          <div className="mt-2">
                            <span
                              className="d-inline-block text-end me-1"
                              style={{ minWidth: 30 }}
                            >
                              <i className="fas fa-sm fa-fw fa-info ms-n1"></i>
                              <i className="fas fa-sm fa-fw fa-info ms-n1"></i>-
                            </span>
                            İşaretli programlar sadece{" "}
                            <strong>TRT, AA, DHA, İHA, AFP, AP, REUTERS</strong>{" "}
                            tarafından izlenebilecektir. Diğer programlar ise
                            basın tarafından izlenebilecektir.
                          </div>
                        </div>

                        <div className="mt-3">
                          <Link
                            to="https://www.tccb.gov.tr/program/"
                            target="_blank"
                          >
                            Tüm program için tıklayın.
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        <span>Bugün bir program yoktur.</span>

                        <div className="mt-3">
                          <Link
                            to="https://www.tccb.gov.tr/program/"
                            target="_blank"
                          >
                            Tüm program için tıklayın.
                          </Link>
                        </div>
                      </>
                    )
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="modal modal-cover fade" id="reportDetailModal">
            <div
              className="modal-dialog modal-lg py-4"
              style={{ maxWidth: 1500 }}
            >
              <div
                className="modal-content rounded"
                style={{ background: "var(--bs-dark4)" }}
              >
                <div className="modal-header">
                  <h3 className="modal-title">Araştırma Cevapları</h3>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>

                <div className="modal-body">
                  <div className="row gap-4 mb-4">
                    <div className="col-12">
                      <Card>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="table border align-middle">
                              <tbody>
                                {listData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="py-2 text-center px-0 text-white">
                                      #{index + 1}
                                    </td>
                                    <td className="py-3 border-start">
                                      {/* <span className="d-block">
                                        {item.category}
                                      </span> */}
                                      <span className="d-block h4 mt-1 mb-0">
                                        {item.title}
                                      </span>
                                    </td>
                                    <td className="py-2 border-start">
                                      {item.answers.length !== 0 ? (
                                        <div
                                          className="row g-1 overflow-hidden my-0"
                                          style={{ borderRadius: 8 }}
                                        >
                                          {item.answers.map(
                                            (answer, answerIndex) => (
                                              <div
                                                key={answerIndex}
                                                className="question-answer-button pe-none col p-0 my-0 d-flex flex-column justify-content-between"
                                              >
                                                <input
                                                  hidden
                                                  type={
                                                    answer.type
                                                      ? "checkbox"
                                                      : "radio"
                                                  }
                                                  id={
                                                    answer.type
                                                      ? `${index + 1}_${
                                                          answer.title
                                                        }`
                                                      : `${index + 1}_${
                                                          answer.color
                                                        }`
                                                  }
                                                  name={`${
                                                    index + 1
                                                  }_question_answer`}
                                                  checked={
                                                    answerIndex === 0
                                                      ? true
                                                      : false
                                                  }
                                                  readOnly
                                                />
                                                <label
                                                  htmlFor={
                                                    answer.type
                                                      ? `${index + 1}_${
                                                          answer.title
                                                        }`
                                                      : `${index + 1}_${
                                                          answer.color
                                                        }`
                                                  }
                                                  className={`py-3 h-100 d-flex pointer align-items-center justify-content-center text-center text-uppercase fw-bold text-white bg-dark`}
                                                  dangerouslySetInnerHTML={{
                                                    __html: answer.title,
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          placeholder="Cevabınızı Yazınız..."
                                          style={{
                                            resize: "none",
                                            height: 80,
                                          }}
                                          value="5 kişiyiz"
                                          readOnly
                                        />
                                      )}
                                    </td>
                                    <td className="border-start">
                                      {index === 22 ||
                                      index === 20 ||
                                      index === 15 ? (
                                        <p className="mb-0">
                                          Açıklama: <br /> Deneme açıklama metni
                                        </p>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td className="border-start">
                                      {index === 22 ? (
                                        <a
                                          data-fancybox
                                          href="http://www.golyapi.com.tr/upload/hizmetler/kaldirim_yapimi.jpg"
                                        >
                                          <img
                                            src="http://www.golyapi.com.tr/upload/hizmetler/kaldirim_yapimi.jpg"
                                            width={100}
                                            alt=""
                                          />
                                        </a>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {me?.role?.id !== 5 && (
            <>
              <div
                className="modal modal-cover fade"
                id="fileImportModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
              >
                <div className="modal-dialog py-4" style={{ maxWidth: 1000 }}>
                  <div
                    className="modal-content rounded"
                    style={{ background: "var(--bs-dark4)" }}
                  >
                    <div className="modal-header">
                      <h3 className="modal-title">{t?.file_import}</h3>

                      <button
                        type="button"
                        className="btn-close"
                        id="fileImportModalCloseButton"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <ImportFile
                        closeId="fileImportModalCloseButton"
                        importFileOpen={importFileOpen}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal modal-cover fade" id="createGraphicModal">
                <div className="modal-dialog py-4" style={{ maxWidth: 1500 }}>
                  <div
                    className="modal-content rounded"
                    style={{ border: "1px solid #141b24" }}
                  >
                    <div
                      className="modal-header"
                      style={{ background: "var(--bs-dark4)" }}
                    >
                      <h3 className="modal-title">
                        {t?.chart} {graphicUpdate ? t?.update : t?.create}
                      </h3>

                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        id="createGraphicModalCloseButton"
                      ></button>
                    </div>

                    <div
                      className="modal-body"
                      style={{ background: "var(--bs-dark4)" }}
                    >
                      <CreateGraphicComponent modalOpen={createGraphicOpen} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal modal-cover fade" id="createPageModal">
                <div className="modal-dialog py-4">
                  <div
                    className="modal-content rounded"
                    style={{ background: "var(--bs-dark4)" }}
                  >
                    <div className="modal-header">
                      <h3 className="modal-title">
                        {t?.page} {updatePage ? t?.update : t?.create}
                      </h3>

                      <button
                        type="button"
                        className="btn-close"
                        id="createPageModalCloseButton"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <CreatePage updatePage={updatePage} getPages={getPages} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal modal-cover fade" id="deletePageModal">
                <div className="modal-dialog py-4">
                  <div
                    className="modal-content rounded"
                    style={{ background: "var(--bs-dark4)" }}
                  >
                    <div className="modal-header">
                      <h3 className="modal-title">
                        {t?.are_you_sure}
                        <br />
                        <span className="h5">
                          {t?.page_name}: {deletePage?.name}
                        </span>
                      </h3>

                      <button
                        type="button"
                        className="btn-close"
                        id="deletePageModalCloseButton"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <DeletePage getPages={getPages} deletePage={deletePage} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal modal-cover fade" id="aiModal">
                <div className="modal-dialog py-4" style={{ maxWidth: 1000 }}>
                  <div
                    className="modal-content rounded"
                    style={{ background: "var(--bs-dark4)" }}
                  >
                    <div className="modal-header border-bottom">
                      <h3 className="modal-title">AI</h3>

                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <AIComponent data={aiData} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal modal-cover fade" id="graphicDeleteModal">
                <div className="modal-dialog py-4">
                  <div
                    className="modal-content rounded"
                    style={{ background: "var(--bs-dark4)" }}
                  >
                    <div className="modal-header">
                      <h3 className="modal-title">
                        {t?.are_you_sure}
                        <br />
                        <span className="h5">
                          {t?.chartName}: {graphicDelete?.title}
                        </span>
                      </h3>

                      <button
                        type="button"
                        className="btn-close"
                        id="graphicDeleteModalCloseButton"
                        data-bs-dismiss="modal"
                      ></button>
                    </div>

                    <div className="modal-body">
                      <DeleteGraphic deleteItem={graphicDelete} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <ToastContainer pauseOnFocusLoss={false} />

          {/* <div className="zoom-buttons text-end">
            <button onClick={handleZoomIn}>Zoom In</button>
            <button onClick={handleZoomOut}>Zoom Out</button>
          </div> */}
        </LoadScript>
      )}
    </AppSettings.Provider>
  );
}

export default App;
