const ru = {
    "baslik_sirala": "Сортировать по заголовку",
    "deger_sirala": "Сортировать по значению",
    "buyukten_kucuge": "По убыванию",
    "kucukten_buyuge": "По возрастанию",

    iframe_kod_link: "Код Iframe / Ссылка",
    "sokak_basina_ortalama_hane_sayisi": "Среднее количество домохозяйств на улицу",
    "sokak_basina_ortalama_ozel_is_yeri_sayisi": "Среднее количество частных предприятий на улицу",

    map_analysis_filter_warning: "Вы должны выбрать как минимум Год, Категорию 1 и Анализ.",

    cevaplari_filtrele: "Фильтровать ответы",
    proje_veritabanim: "Проектная база данных",
    benim_veritabanim: "Моя база данных",

    "veritabani_baglantisi": "Подключение к базе данных",
    "veritabani_adi": "Название базы данных",
    "host_adresi": "Адрес хоста",
    "port_numarasi": "Номер порта",

    grafik_guncelleniyor: "График обновляется",

    choose_separator: "Вы должны выбрать разделитель.",
    cevap_sec: "Выберите ответ",

    clear_file_text: "Удалите декоративные символы из текста в файле.",
    tema_rengi: "Цвет темы",
    acik: "Светлый",
    koyu: "Тёмный",

    "yillik": "Ежегодный",
    "aylik": "Ежемесячный",
    "gunluk": "Ежедневный",
    "siklik": "Частота",
    "regresyon_turu": "Тип Регрессии",

    tahmin_olcegi: "Масштаб Прогноза",
    cizgi_grafigi: "Линейный график",
    "strateji_planlari": "Стратегические планы",
    "gorevler": "Задачи",

    hatali_satir_bulunamadi: "Ошибочные строки не найдены",
    hata_var_mi: "Есть ли ошибка?",
    departman_sayfalari: "Страницы отделов",
    public_relations: "Редактор",
    departmana_olustur: "Создать Отдел",
    role_for_group: "Отдел/Команда",

    bolge_bulunamadi: "Регион не найден.",
    coklu_alan_grafigi_dikey: "Множественная диаграмма областей",
    coklu_cizgi_grafigi_dikey: "Множественная линейная диаграмма",
    coklu_cubuk_grafigi_dikey: "Вертикальная мульти-гистограмма",
    coklu_cubuk_grafigi_yatay: "Горизонтальная мульти-гистограмма",

    hiyerarsik_yapi: "Иерархическая структура",
    tum_okullarin_konumlari: "Расположение Всех Школ",
    okul_adi: "Название Школы",
    devlet_ozel: "Государственная/Частная",
    ogrenci_sayisi: "Количество Учеников",
    ogretmen_sayisi: "Количество Учителей",
    ogretmen_basina_ogrenci_sayisi: "Ученики на Учителя",
    derslik: "Классная Комната",
    ogrenci_basina_derslik_sayisi: "Ученики на Класс",
    kutuphane_kitap_sayisi: "Количество Книг в Библиотеке",
    konferans_salon_sayisi: "Количество Конференц-Залов",

    okullar: "Школы",
    width: "Ширина",
    height: "Высота",
    bilgi_karti: "Информационная карточка",
    map_import_data_info_message: 'Чтобы отобразить местоположения, установите названия столбцов как <strong>«Latitude»</strong> (Широта) и <strong>«Longitude»</strong> (Долгота).',
    coklu_cubuk_grafigi: "Множественная столбчатая диаграмма",

    afet_title: "Статистика разрешений на использование зданий",
    afet_chart_1_title: "Количество этажей",
    afet_chart_2_title: "Владелец здания",
    afet_chart_3_title: "Описание несущей системы 1",
    afet_chart_4_title: "Описание несущей системы 2",
    afet_chart_5_title: "Описание несущей системы 3",
    afet_chart_6_title: "Отопительная система",
    afet_chart_7_title: "Количество зданий",
    afet_chart_8_title: "Количество квартир",
    afet_chart_9_title: "Площадь (м2)",

    analiz: "Анализ",
    veri_girisi: "Ввод данных",
    bos_birakmayin: "Не оставляйте пустым",
    sms_default_message_1: "Ваш номер телефона назначен вашим одноразовым паролем. Вы можете получить доступ к нашему опросу по ссылке ниже и поделиться своим мнением. Ваше мнение для нас очень ценно.",
    example_data: "Пример данных",
    dosya_olustur: "Создать файл",
    sms_confirm: "СМС будет отправлено всем гражданам, указанным в таблице. Вы подтверждаете?",

    bu_anket_kullanicilara_gonderildi: "Этот опрос был отправлен пользователям",
    bu_anket_sms_olarak_gonderildi: "Этот опрос был отправлен как SMS",
    bu_anket_data_once_gonderildi: "Этот опрос был отправлен ранее",

    kullanicilara_gonder: "Отправить пользователям",
    sms_gonder: "Отправить SMS",
    yetkiniz_yoktur: "У вас нет доступа",
    zaman_grafigi: "Временной График",
    tum_saglik_tesisleri_konumlari: "Местоположения всех медицинских учреждений",
    il_ve_ilce_ve_mahalle_secmelisiniz: "Вы должны выбрать область, район и микрорайон.",
    siyaset_parti: "Партия",
    anket_aciklama: "Описание опроса",
    yukle_dosya_sutun_uyari: "В заголовках столбцов в загруженном вами файле не должно быть символов '_' и турецких букв, а также они не должны начинаться с цифры. Пожалуйста, убедитесь, что ваш файл соответствует этим правилам перед загрузкой.",
    eklenmis_bir_soru_bulunamadi: "Добавленный вопрос не найден.",
    hazir_anketler: "Готовые Опросы",
    analiz_sec: "Выбрать анализ",
    gorev_kategorileri: "Категории задач",
    "aciklama_goster": "Показать описание",
    "aciklama_gizle": "Скрыть описание",
    gorevleri_sadece_olusturan_kisi_silebilir: "Только тот, кто создал задачи, может их удалить.",

    olusturulma: "Дата создания",
    guncelleme: "Дата обновления",

    menu_rengi: "Цвет Меню",
    site_rengi: "Цвет Сайта",
    grafik_rengi: "Цвет Графика",

    "deprem": "ЗЕМЛЕТРЯСЕНИЕ",
    "risk_arastirmasi": "ИССЛЕДОВАНИЕ РИСКОВ",
    "diri_fay_hatti_yakinligi": "БЛИЗОСТЬ К АКТИВНОМУ РАЗЛОМУ",
    "deprem_risk_derece_seviyesi": "УРОВЕНЬ РИСКА ЗЕМЛЕТРЯСЕНИЯ",
    "yerlesim_yeri_envanteri": "ИНВЕНТАРИЗАЦИЯ НАСЕЛЕННОГО ПУНКТА",
    "yapim_yilina_gore_bina_sayisi": "КОЛИЧЕСТВО ЗДАНИЙ ПО ГОДУ ПОСТРОЙКИ",
    "kat_sayisina_gore_bina_sayisi": "КОЛИЧЕСТВО ЗДАНИЙ ПО КОЛИЧЕСТВУ ЭТАЖЕЙ",
    "yapim_turune_gore_bina_sayisi": "КОЛИЧЕСТВО ЗДАНИЙ ПО ТИПУ ПОСТРОЙКИ",

    rapor_basariyla_olusturuldu: "Отчет успешно создан.",
    ondalik_sayi: "Десятичное число",

    "ileri_analiz": "Расширенный Анализ",
    "genel_gorunum": "Общий Взгляд",
    "anket_ve_arastirmalar": "Опросы и Исследования",
    "ozel_sayfalar": "Специальные Страницы",
    "analiz_icin_iletisime_gecin": "Свяжитесь для Анализа",

    "ocak": "Январь",
    "subat": "Февраль",
    "mart": "Март",
    "nisan": "Апрель",
    "mayis": "Май",
    "haziran": "Июнь",
    "temmuz": "Июль",
    "agustos": "Август",
    "eylul": "Сентябрь",
    "ekim": "Октябрь",
    "kasim": "Ноябрь",
    "aralik": "Декабрь",

    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2024": "МЕСТНЫЕ ВЫБОРЫ МЭРА 2024 ГОДА",
    "BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2024": "МЕСТНЫЕ ВЫБОРЫ МЭРА БОЛЬШОГО ГОРОДА 2024 ГОДА",
    "BASKANLIK_SECIMI_2_TUR_1": "ПРЕЗИДЕНТСКИЕ ВЫБОРЫ 2023 ГОДА, 1-Й ТУР",
    "YEREL_SECIMLERI_2019": "МЕСТНЫЕ ВЫБОРЫ 2019 ГОДА",
    "CUMHURBASKANLIGI_SECIMLERI_2018": "ПРЕЗИДЕНТСКИЕ ВЫБОРЫ 2018 ГОДА",
    "MILLETVEKILLIGI_SECIMLERI_2018": "ПАРЛАМЕНТСКИЕ ВЫБОРЫ 2018 ГОДА",
    "HALK_OYLAMASI_2017": "РЕФЕРЕНДУМ 2017 ГОДА",
    "MILLETVEKILLIGI_SECIMLERI_2015": "ПАРЛАМЕНТСКИЕ ВЫБОРЫ 2015 ГОДА",
    "BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2014": "ВЫБОРЫ МЭРА БОЛЬШОГО ГОРОДА 2014 ГОДА",

    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2009": "МЕСТНЫЕ ВЫБОРЫ МЭРИИ 2009",
    "DONEM_MILLETVEKILLIGI_GENEL_SECIMI_24": "24-е ОБЩИЕ ВЫБОРЫ ДЕПУТАТОВ",
    "BELEDIYE_BASKANLIGI_SECIMI_2014": "ВЫБОРЫ МЭРИИ 2014",
    "CUMHURBASKANLIGI_SECIMI_12": "12-е ВЫБОРЫ ПРЕЗИДЕНТА",
    "DONEM_MILLETVEKILLIGI_SECIMLERI_25": "25-е ВЫБОРЫ ДЕПУТАТОВ",
    "MILLETVEKILLIGI_GENEL_SECIMLERI_26": "26-е ОБЩИЕ ВЫБОРЫ ДЕПУТАТОВ",
    "BASKANLIK_SECIMI_1": "1-е ВЫБОРЫ ПРЕЗИДЕНТА",
    "DONEM_MILLETVEKILLIGI_GENEL_SECIMLERI_27": "27-е ОБЩИЕ ВЫБОРЫ ДЕПУТАТОВ",
    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2019": "МЕСТНЫЕ ВЫБОРЫ МЭРИИ 2019",
    "YENILENEN_ISTANBUL_SECIMI_2019": "ПОВТОРНЫЕ ВЫБОРЫ В ИСТАНБУЛЕ 2019",
    "DONEM_MILLETVEKILI_GENEL_SECIMLERI_28": "28-е ОБЩИЕ ВЫБОРЫ ДЕПУТАТОВ",
    "BASKANLIK_SECIMI_2_TUR_2": "ПРЕЗИДЕНТСКИЕ ВЫБОРЫ 2023 ГОДА, 2-Й ТУР",

    "siyasal_kimlik_analiz_sonuclari": "РЕЗУЛЬТАТЫ АНАЛИЗА ПОЛИТИЧЕСКОЙ ИДЕНТИЧНОСТИ",
    "baskanlik_secimi_ve_sokaga_gore_sandik_basina_birey_sayisi_tablosu": "2. ВЫБОРЫ ПРЕЗИДЕНТА И ТАБЛИЦА КОЛИЧЕСТВА ЛИЦ НА УЧАСТКЕ ПО УЛИЦАМ",
    "yeni_secmen": "Новый Избиратель",
    "birey": "ЛИЦО",
    "mahallede_son_yil_itibariyle_toplam_sayisi": "Общее количество на последний год в районе",
    "birey_bulunmaktadir": "Лицо присутствует.",
    "hane_bulunmaktadir": "Дом присутствует.",
    "sokak_bulunmaktadir": "Улица присутствует.",
    "bina_bulunmaktadir": "Здание присутствует.",
    "gorev_mevcut_degil": "Задача отсутствует",
    "mahallede_bulunan_sendikalar": "ПРОФСОЮЗЫ В РАЙОНЕ",
    "mahallede_bulunan_dernekler": "ОБЩЕСТВЕННЫЕ ОРГАНИЗАЦИИ В РАЙОНЕ",
    "secim_analiz_raporu": "ОТЧЕТ ПО АНАЛИЗУ ВЫБОРОВ",
    "medeni_durum_analizi": "АНАЛИЗ СЕМЕЙНОГО ПОЛОЖЕНИЯ",
    "downloading_text": "Загрузка...",
    "yas_grubuna_gore_birey_durumu": "СТАТУС ЛИЦ ПО ВОЗРАСТНЫМ ГРУППАМ",
    "egitim_durumu": "УРОВЕНЬ ОБРАЗОВАНИЯ",
    "mahallede_ortalama_secmen_yasi": "Средний возраст избирателей в районе",
    "bulunmaktadir": "присутствует.",
    "strateji_merkezi_mahalle_raporu": "Отчет районного стратегического центра",
    "strateji_merkezi_sokak_raporu": "Отчет уличного стратегического центра",
    "anket_cevaplanmamis": "Опрос не завершен",
    "cevap_silindi": "Ответ удален",
    "pin_filtre": "Фильтр пинов",
    "arastirma_cevaplari": "Ответы на исследование",
    "bu_cevabi_sil": "Удалить этот ответ",
    "telefon_kontrol_edildi": "Телефон проверен",
    "haritayi_gizle": "Скрыть карту",
    "kullanici_bilgileri": "Информация о пользователе",
    "toplam_soru": "Общее количество вопросов",
    "resim_yok": "Нет изображения",
    "guncel_hali": "Актуальное состояние",

    "desteklenmeyen_dosya_formati": "Неподдерживаемый формат файла",
    "dosya_boyutu_yuksek": "Размер файла слишком большой",
    "baslik_tarihi_bos_birakmayin": "Не оставляйте заголовок пустым",
    "baslangic_tarihi_bos_birakmayin": "Не оставляйте дату начала пустой",
    "bitis_tarihi_bos_birakmayin": "Не оставляйте дату окончания пустой",
    "kategori_bos_birakmayin": "Не оставляйте категорию пустой",
    "aciklama_bos_birakmayin": "Не оставляйте описание пустым",
    "il_bos_birakmayin": "Не оставляйте провинцию пустой",
    "ilce_bos_birakmayin": "Не оставляйте район пустым",
    "mahalle_bos_birakmayin": "Не оставляйте квартал пустым",
    "koordinat_belirleyin": "Укажите координаты",
    "ilce_girmelisiniz": "Вы должны ввести район",
    "tamamlanma_yuzdesi": "Процент завершения",
    "etiketler": "Этикетки",
    "etiket_girin": "Введите этикетку",
    "dosya_kaldir": "Удалить файл",
    "resimleri_yukle": "Загрузить изображения",
    "dosyalari_buraya_birakin": "Оставьте файлы здесь",
    "surukle_birak_veya_tikla": "Перетащите или нажмите",
    "gorev_guncelle": "Обновить задачу",
    "gorev_guncellendi": "Задача обновлена",
    "gorev_olusturuldu": "Задача создана",
    "gorev_olustur": "Создать задачу",
    "kategori_duzenle": "Редактировать категорию",
    "kategori_ekle": "Добавить категорию",
    "kategori_adi": "Название категории",
    "kategori_rengi": "Цвет категории",
    "kategorisi_ve_kategoriye_bagli_basliklar_silinecektir": "Категория и связанные заголовки будут удалены.",
    "eklenmis_bir_gorev_bulunmamaktadir": "Нет добавленных задач",
    "gorev_silindi": "Задача удалена",
    "kategori_sirasi_guncellendi": "Порядок категорий обновлен",
    "liste_sirasi_guncellendi": "Порядок списка обновлен",
    "yonetici_ozetini_kapat_mesaji": "УПРАВЛЯЮЩИЙ ОТЧЕТ, НАЖМИТЕ ДРУГУЮ ЗОНУ, ЧТОБЫ ЗАКРЫТЬ.",
    "yonetici_ozetini_oku": "Прочитать отчет управляющего",
    "olusturan_kisi": "Создатель",
    "olusturulma_tarihi": "Дата создания",
    "guncelleyen_kisi": "Обновляющий",
    "olusturma_tarihi": "Дата создания",
    "guncelleme_tarihi": "Дата обновления",
    "yonetici_ozeti": "Отчет управляющего",

    "yapildi": "Сделано",
    "yapilmadi": "Не сделано",
    "kisiye_anket_ata": "Назначить опрос человеку",
    "atanmis_bir_anket_yok": "Нет назначенного опроса.",
    "kac_kisi": "СКОЛЬКО ЧЕЛОВЕК",
    "female": "ЖЕНЩИНА",
    "male": "МУЖЧИНА",
    "muhtarliklar": "МУХТАРЛЫКИ",
    "yapilacak_anketler": "ПРОВЕДЕННЫЕ ОПРОСЫ",
    "kisilere_atanmış_anketler": "ОПРОСЫ, НАЗНАЧЕННЫЕ ЛЮДЯМ",
    "olusturulmus_tablo_secin": "ВЫБЕРИТЕ СОЗДАННУЮ ТАБЛИЦУ",
    "tabloyu_goster": "Показать таблицу",
    "tabloyu_gizle": "Скрыть таблицу",
    "tabloyu_sil": "Удалить таблицу",
    "tabloyu_indir": "Скачать таблицу",
    "tablo_secin": "Выберите таблицу",
    "filtre_olustur": "Создать фильтр",
    "filtre_adi_giriniz": "Введите имя фильтра",
    "filtre_tablosu": "ТАБЛИЦА ФИЛЬТРА",
    "anket_ata": "Назначить опрос",
    "anket_atandi": "Опрос назначен",
    "anket_secmelisiniz": "Вы должны выбрать опрос",
    "tc_bulunamadi": "TC не найден",
    "tablo_silindi": "Таблица удалена",
    "filtre_adi_girmelisiniz": "Вы должны ввести имя фильтра",
    "filtre_eklendi": "Фильтр добавлен",
    "filtre_yapmalisiniz": "Вы должны создать фильтр",
    "hizmet_bulunamadi": "Услуга не найдена",
    "sikayet_bulunamadi": "Жалоба не найдена",
    "ayni_ildeki_akraba_sayisi": "Количество родственников в одной провинции",
    "ayni_ilcedeki_akraba_sayisi": "Количество родственников в одном районе",
    "duzenleyen": "Организатор",
    "memleket_il": "Провинция родины",
    "memleket_ilce": "Район родины",
    "aslen_il": "Родная провинция",
    "aslen_ilce": "Родной район",
    "aile_buyugu": "Глава семьи",
    "aile": "Семья",
    "kisi_bulunamadi": "Человек не найден",
    "not_eklendi": "Заметка добавлена",

    "not_eklenmedi": "Заметка не добавлена",
    "not_ekle": "Добавить заметку",
    "not_guncelle": "Обновить заметку",
    "not_guncellenmedi": "Заметка не обновлена",
    "not_guncellendi": "Заметка обновлена",
    "resim_eklenmedi": "Изображение не добавлено",
    "resim_eklendi": "Изображение добавлено",
    "details": "Детали",
    "detail": "Деталь",
    "hizmetler": "Услуги",
    "sikayetler": "Жалобы",
    "iletilen_sikayetler": "ПЕРЕДАННЫЕ ЖАЛОБЫ",
    "istenilen_hizmetler": "ЗАЯВЛЕННЫЕ УСЛУГИ",
    "is_kolu": "Отрасль",
    "sendika_adi": "Название профсоюза",
    "sendikalar": "ПРОФСОЮЗЫ",
    "kurum_adres": "Адрес учреждения",
    "detayli_faaliyet_alani": "Детализированная область деятельности",
    "faaliyet_alani": "Область деятельности",
    "dernekler": "Ассоциации",
    "muhtarlik": "МУХТАРЛЫК",
    "muhtar_telefon": "Телефон мутара",
    "muhtar_adi": "Имя мутара",
    "muhtarlik_adi": "Название мутара",
    "kisi_ve_uzeri_goc_eden_birey_sayisi": "Количество мигрировавших лиц и более",
    "olum": "Смерть",
    "dogum": "Рождение",
    "olum_oranlari": "Ставки смертности",
    "dogum_oranlari": "Ставки рождаемости",
    "dagilim": "Распределение",
    "universite_ve_universite_ogrenci_sayilari": "Количество университетов и студентов",
    "verilen_goc": "Выданная миграция",
    "alinan_goc": "Полученная миграция",
    "ilce_bazinda_verilen_goc": "Выданная миграция по районам",
    "ilce_bazinda_alinan_goc": "Полученная миграция по районам",
    "secmen_genel_egitim_durumu": "ОБЩЕЕ ОБРАЗОВАТЕЛЬНОЕ СОСТОЯНИЕ ИЗБИРАТЕЛЯ",
    "yillara_gore_sosyoekonomik_statu_dagilimi": "Распределение социоэкономического статуса по годам",
    "yillara_gore_dogum_ve_olum_oranlari": "Ставки рождаемости и смертности по годам",
    "yillara_gore_secmen_medeni_durum_dagilimi": "Распределение гражданского статуса избирателей по годам",
    "birey_hemsehrilik_durumu": "СТАТУС РОДСТВЕННИКОВ ЛИЦА",
    "yas_grubuna_gore_birey_sayilari": "КОЛИЧЕСТВО ЛИЦ ПО ВОЗРАСТНЫМ ГРУППАМ",
    "yas_grubu": "ВОЗРАСТНАЯ ГРУППА",
    "tahmini_1_derece_aile": "Приблизительное членство в семье 1-й степени",
    "tahmini_2_derece_aile": "Приблизительное членство в семье 2-й степени",
    "tahmini_uye_yakini_olmayan": "Приблизительное отсутствие родственников",
    "ortalama_secmen_yasi": "Средний возраст избирателей",
    "olum_sayisi": "Количество смертей",
    "dogum_sayisi": "Количество рождений",
    "ilk_defa_oy_sayisi": "КОЛИЧЕСТВО ИЗБИРАТЕЛЕЙ, КТО БУДЕТ ГОЛОСОВАТЬ ВПЕРВЫЕ",
    "toplam_secmen": "Общее количество избирателей",
    "guncel": "Актуально",
    "baskanlik_secimi": "2. ВЫБОРЫ ПРЕЗИДЕНТА",
    "tur": "Тип",
    "tur_1": "Тип 1",
    "tur_2": "Тип 2",
    "sokaga_gore_sandik_basina_secmen_sayisi": "КОЛИЧЕСТВО ИЗБИРАТЕЛЕЙ ПО УЛИЦАМ",
    "kusaklara_gore_secmen_sayisi": "КОЛИЧЕСТВО ИЗБИРАТЕЛЕЙ ПО ПОКОЛЕНИЯМ",
    "sosyoekonomik_status_dagilimi": "Распределение социоэкономического статуса",
    "sokak_bina_ve_hane_analizleri": "АНАЛИЗЫ УЛИЦ, ЗДАНИЙ И ХОЗЯЙСТВ",
    "en_buyuk_nesil": "САМОЕ БОЛЬШОЕ ПОКОЛЕНИЕ",
    "sessiz_kusak": "ТИХОЕ ПОКОЛЕНИЕ",
    "baby_boomer_kusagi": "ПОКОЛЕНИЕ БЭБИ-БУМ",
    "y_kusagi": "ПОКОЛЕНИЕ Y",
    "x_kusagi": "ПОКОЛЕНИЕ X",
    "z_kusagi": "ПОКОЛЕНИЕ Z",
    "hemsehrilik_ve_goc": "Родственные связи и миграция",

    oran: "Соотношение",
    secmen_sayisi: "Количество избирателей",
    sandik_geneli: "Общее по участку",
    sandik_no: "Номер участка",
    siyasal_kimlik: "Политическая идентичность",
    hicbir_bulunamadi_uzgunum: "Ничего не найдено - мне жаль",
    hicbir_kayit_mevcut_degil: "Нет доступной записи",
    icerisinde_bulunan: "содержащий",
    numarali_sandikta: "на номере участка",
    secmen_oy_kullanmistir: "избиратель голосовал.",
    hemsehrilik_durumu: "Состояние родства",
    sandik_sonucu: "Результат участка",
    genelinde_ikamet_eden: "проживающий в общем",
    baskanlik_sistemine_gore_secim_sandik_analizi: "2. АНАЛИЗ УЧАСТКА ГОЛОСОВАНИЯ ПО СИСТЕМЕ ПРЕЗИДЕНТСТВА",
    aradiginiz_sayfayi_bulamiyoruz: "Не можем найти запрашиваемую страницу",
    doldur_ve_en_az_bir_kosul_ekleyin: "Пожалуйста, убедитесь, что все поля заполнены и добавьте хотя бы одно условие.",
    olusturulan_anket_bulunamadi: "Созданный опрос не найден",
    tamamlanmis_anket_yukleme_alani: "Загрузка завершенного опроса",
    genel_anket_bulunamadi: "Общий опрос не найден",
    anket_secin: "Выберите опрос",
    bir_anket_secin: "Выберите один опрос",
    analiz_ici_bir_anket_secin: "Выберите опрос для анализа",
    devam_eden_bir_anket_yoktur: "Нет активных опросов.",
    kategori_adi_girmelisiniz: "Вы должны ввести название категории",
    kategori_puan_girmelisiniz: "Вы должны ввести балл категории",
    atanmis_kullanici_bulunamadi: "Назначенный пользователь не найден",
    kisi_kaldirildi: "Человек удален",
    reklam_seciniz: "Выберите рекламу",
    kullanici_secin: "Выберите пользователя",
    kullanici_atandi: "Пользователь назначен",
    anket: "Опрос",
    reklam_basliklari: "Заголовки рекламы",
    anket_silindi: "Опрос удален",
    tamamla: "Завершить",
    tamamlandi: "Завершено",
    anketler: "Опросы",
    tamamlanan_anketler: "Завершенные опросы",
    anket_tamamlandi: "Опрос завершен",
    devam_eden_anketler: "Активные опросы",
    analiz_bulunamadi: "Анализ не найден",
    status: "Статус",
    kategori_kaldir: "Удалить категорию",
    ust_kategori: "Верхняя категория",
    puan: "Баллы",
    indir: "Скачать",
    anket_indir: "Скачать опрос",
    anket_olustur: "Создать опрос",
    departman_kaldirildi: "Отдел удален",
    departman_atandi: "Отдел назначен",
    anket_duzenle: "Редактировать опрос",
    anket_detay: "Детали опроса",
    degisiklik_bulunamadi: "Изменение не найдено",
    departman_secmelisiniz: "Вы должны выбрать отдел",
    kategori_eklendi: "Категория добавлена",
    kategori_silindi: "Категория удалена",
    kategori_olustur: "Создать категорию",
    kategori_bulunamadi: "Категория не найдена",
    kategori_olusturuldu: "Категория создана",
    cok_fazla_istek_atildi: "Слишком много запросов было отправлено.",
    renk_secmelisiniz: "Вы должны выбрать цвет",
    kategori_guncelle: "Обновить категорию",
    kategori_guncellendi: "Категория обновлена",
    atanmis_kullanicilar: "Назначенные пользователи",
    kategori_sec: "Выберите категорию",
    zorunlu: "Обязательно",
    ata: "Назначить",
    isim: "Имя",
    tamamlanma_tarih_saat: "Дата и время завершения",
    toplam_sure: "Общее время",
    soyisim: "Фамилия",
    id: "ID",
    tc: "ТЦ",
    ip_adresi: "IP-адрес",
    id_bulunamadi: "ID не найдено",
    denek_id: "ID образца",
    denek_id_gor: "Посмотреть ID образца",
    genel_anketler: "Общие опросы",
    benim_anketlerim: "Мои опросы",
    turkiye_gundemi: "Повестка дня Турции",
    analiz_yukle: "Загрузить анализ",
    analiz_yukleyin: "Загрузите анализ",
    sonuc_bulunamadi: "Результат не найден",
    veri_silmek_icin_tikla: "Нажмите, чтобы удалить данные",
    satir_guncelle: "Обновить строку",
    satir_ekle: "Добавить строку",
    satir_yuklenemedi: "Строка не загружена",
    satir_yuklendi: "Строка успешно загружена",
    satir_guncellenemedi: "Строка не может быть обновлена",
    satir_guncellendi: "Строка успешно обновлена",
    veriler_silindi: "Данные удалены",
    satirlar_bos_birakilamaz: "Строки не могут быть пустыми",
    dosya_bulunamadi: "Файл не найден",
    filtrele: "Фильтровать",
    sokak_raporu: "Отчет по улице",
    mahalle_analiz_raporu: "Отчет по анализу района",
    sokak_analiz_raporu: "Отчет по анализу улицы",
    mahallede_toplam: "Всего в районе",
    iletisim_numarasi: "Контактный номер",
    mahalle_muhtari: "Музыка района",
    mahalle_raporu: "Отчет по району",
    add_image: "Добавить изображение",
    cevabinizi_yaziniz: "Введите ваш ответ",
    cevap_gonderildi: "Ответы отправлены",
    sorusu_icin_resim_konum_gerekiyor: "нужно изображение и местоположение для вопроса.",
    ekle: "Добавить",
    alan: "Область",
    atama_yap: "Назначить",
    departman_sec: "Выберите отдел",
    resim_zorunlu: "Изображение обязательно",
    sorular: "Вопросы",
    coklu_cevap: "Множественный ответ",
    tekli_cevap: "Единичный ответ",
    acik_uclu_cevap: "Открытый ответ",
    cevaplar: "Ответы",
    sorulara_don: "Вернуться к вопросам",
    resim_alani_ekle: "Добавить область изображения",
    soru_ekle: "Добавить вопрос",
    soru_kaldir: "Удалить вопрос",
    anket_adi: "Название опроса",
    arastirma_sorulari: "Вопросы исследования",
    rapor_olustur: "Создать отчет",
    tespit_raporu_olustur: "Создать отчет о выявлении",
    pin_belirleyin: "Установите пин",
    pin_eklemek_haritaya_tiklayin: "Вы можете щелкнуть по карте, чтобы добавить пин",
    gozlem_olustur: "Создать наблюдение",
    atanmis_anketler: "Назначенные опросы",
    dosya_ara: "Поиск файла",
    sizin_yukledikleriniz: "Ваши загрузки",
    kosul_olustur: "Создать условие",
    secilenleri_kaldir: "Удалить выделенные",
    uzerine_veri_yukle: "Загрузить данные",
    soru_kategori_ekle: "Добавить категорию вопроса",
    soru_kategori_guncelle: "Обновить категорию вопроса",
    soru_kategorileri: "Категории вопросов",
    anketi_kaldir: "Удалить опрос",
    kisi: "Человек",
    kisiye_atama: "Назначить человеку",
    anketi_olustur: "Создать опрос",
    anketi_duzenle: "Редактировать опрос",
    anketi_kopyala: "Скопировать опрос",
    anketi_kopyalandi: "Опрос скопирован",
    anketi_guncellendi: "Опрос обновлен",
    anketi_olusturuldu: "Опрос создан",
    gorev_ekle: "Добавить задачу",
    anket_sec: "Выберите опрос",
    tum_soru_alanlar_doldurmalisiniz: "Вы должны заполнить все поля вопросов",
    en_az_bir_soru_girmelisiniz: "Вы должны ввести хотя бы один вопрос",
    "anket_adi_girmelisiniz": "Вы должны ввести название опроса.",

    "musteri_secmelisiniz": "Вы должны выбрать клиента.",
    "tum_cevap_alanlari_doldurmalısınız": "Вы должны заполнить все поля ответа.",
    "en_az_bir_cevap_girmelisiniz": "Вы должны ввести хотя бы один ответ.",
    "cevap_ekle": "Добавить ответ",
    "metin_puani": "Оценка текста",
    "metin_puani_girmelisiniz": "Вы должны ввести оценку текста.",
    "metin_basligi": "Название текста",
    "metin_basligi_girmelisiniz": "Вы должны ввести название текста.",
    "tumunu_kaldir": "Удалить все",
    "error_delete_data_graphics": "Все данные и графики, связанные с этим файлом, будут затронуты при удалении. Вы уверены, что хотите удалить?",
    "tum_alanlari_doldurun": "Пожалуйста, заполните все поля.",
    "taleb_gonderildi": "Ваш запрос отправлен.",
    "taleb_gonderilemedi": "Ваш запрос не может быть отправлен.",
    "talep_formu": "Форма запроса",
    "contact": "Контакт",
    "rapor": "Отчет",
    "kentmetre_raporu": "Отчет о городском метре",
    "customers": "Клиенты",
    "yonetim": "Управление",
    "yonetici": "Менеджер",
    "halkla_iliskiler_gozlemci": "Наблюдатель по связям с общественностью",
    "info_hane_ve_birey_analizi": "Анализ домохозяйств и индивидуумов (может видеть только раздел опроса)",
    "kentmetre_haritasi": "Карта городского метра",
    strateji_haritasi: "Стратегическая карта",
    "hane_ve_birey_analizi": "Анализ домохозяйств и индивидуумов",
    "halkla_iletisim_yonetimi": "Управление связями с общественностью",
    "hazir_raporlar": "Готовые отчеты",
    "institution_official": "Представитель компании / организации",
    "gozlemci": "Наблюдатель",
    "arastirma_raporu": "Отчет о исследовании",
    "cannot_appoint_manager": "Невозможно назначить менеджера",
    "password": "Пароль",
    "password_confirm": "Подтвердите пароль",
    "pass_8_min": "Пароль должен содержать не менее 8 символов.",
    "pass_same": "Пароли должны совпадать.",

    "durdur": "Остановить",
    "not_dinle": "Не слушать заметку",
    "not_oku": "Не читать заметку",
    "etiket_yazin": "НАПИШИТЕ ЭТИКЕТКУ",
    "profile": "Профиль",
    "user_address": "Адрес пользователя",
    "user_title": "Должность пользователя",
    "user_role": "Роль пользователя",
    "user_activation_phone": "Телефон активации пользователя",
    "user_representative": "ФИО представителя пользователя",
    "customer_representative": "ФИО представителя клиента",
    "example_file_download": "Скачать образец файла",
    "fixed_files": "Фиксированные файлы",
    "clear": "Очистить",
    "clear_cache": "Очистить кэш",
    "info_clear_cache": "Все кэшированные данные сканирования будут удалены.",
    "error_delete_condition": "Найденные условия будут окончательно удалены и не подлежат восстановлению. <strong>Вы уверены?</strong>",

    "raporu_goruntulemek_icin_filtre_yapmalisiniz": "Вы должны отфильтровать, чтобы просмотреть отчет.",
    "footer_text": `<strong class="text-theme">${window.location.host.includes("datakokpit") ? 'datakokpit' : 'stratejimerkezi'}.com</strong> не имеет никакого отношения к каким-либо учреждениям или организациям. На этом сайте представлены только открытые исследования и прогнозные данные. <br /> <strong class="text-theme">${window.location.host.includes("datakokpit") ? 'datakokpit' : 'stratejimerkezi'}.com</strong> не гарантирует точность отображаемой информации о местах, адресах и картах, это только для информационных целей.`,
    "GROUP_OF_COMPANIES": "ГРУППА КОМПАНИЙ",
    "birth_day_persons": "Граждане с днем рождения",
    "coming_from_field": "Поступившие с поля",
    "il_secmelisiniz": "Вы должны выбрать провинцию.",
    "il_ve_ilce_filtrelemeniz_gerekiyor": "Вы должны отфильтровать провинцию и район.",
    "sokak_secmelisiniz": "Вы должны выбрать улицу.",
    "mahalle_secmelisiniz": "Вы должны выбрать район.",
    "ilce_secmelisiniz": "Вы должны выбрать район.",
    "il_ve_ilce_secmelisiniz": "Вы должны выбрать провинцию и район.",
    "no_data_available": "Нет доступных данных.",
    "noanswer_sent_you": "Вам не отправлен ответ.",
    "bina_basina_ortalama_hane_sayisi": "Среднее количество домохозяйств на здание",
    "sokak_basina_ortalama_bina_sayisi": "Среднее количество зданий на улицу",
    "dosyayi_goruntule": "Просмотреть файл",
    "description": "Описание",
    "add_sub_question": "Добавить подвопрос",
    "bosanmis": "Разведенный",
    "bekar": "Холост",
    "evli_sayisi": "Количество женатых",
    "esi_olmus": "Супруг(а) умер(ла)",
    "answer": "Ответ",
    "remove_answer": "Удалить ответ",
    "required_image": "Изображение <br /> обязательно",
    "answer_point": "Баллы за ответ",
    "write_answer": "Напишите ответ",
    "cevap_tipi": "Тип ответа",
    "soru_adi": "Название вопроса",
    "soru_puani": "Баллы за вопрос",
    "soru_kategorisi": "Категория вопроса",
    "required_question": "Обязательный вопрос",
    "esi_oldu": "СУПРУГ(А) УМЕР(ЛА)",
    "evli": "ЖЕНАТ",
    "hic_evlenmedi": "Никогда не был(а) женат(а)",
    "bosandi": "Разведенный(ая)",
    "question": "Вопрос",
    "age": "Возраст",
    "unknown": "Неизвестно",
    "gender": "Пол",
    "address": "Адрес",
    "degree": "Степень",
    "authorized_province": "Уполномоченная провинция",
    "authorized_district": "Уполномоченный район",
    "upload_image": "Загрузить изображение",
    "updated_image": "Изображение обновлено",
    "max_file_size_5mb": "Размер файла не может превышать 5 МБ.",
    "max_file_size_10mb": "Размер файла не может превышать 10 МБ.",
    "please_select_image_file": "Пожалуйста, выберите файл изображения.",
    "add_group": "Добавить отдел",
    "membership": "Членство",
    "user": "Пользователь",
    "log_kayitlari": "Лог записи",
    "users": "Пользователи",
    "edit_user": "Редактировать пользователя",
    "add_user": "Добавить пользователя",
    "user_name": "Имя пользователя",
    "person_name_surname": "ФИО ответственного",
    "user_phone": "Телефон пользователя",
    "user_email": "Email пользователя",
    "search": "Поиск",
    "reset": "Сбросить",
    "grafik_eklenmedi": "График не добавлен",
    "user_not_found": "Пользователь не найден",
    "transaction": "Транзакция",
    "transactions": "Транзакции",
    "yes": "Да",
    "no": "Нет",
    "next": "Далее",
    "department": "Отдел",
    "department_name": "Название отдела",
    "department_name_required": "Вы должны ввести название отдела",
    "updated_department": "Отдел обновлен",
    "added_department": "Отдел добавлен",
    "deleted_department": "Отдел удален",
    "departments": "Отделы",
    "choose_customer": "Выберите клиента",
    "show_users": "Показать пользователей",
    "kisiler": "Люди",
    "hide_users": "Скрыть пользователей",
    "remove": "Удалить",
    "isim_soyisim": "Имя Фамилия",
    "iletisim_bilgisi": "Контактная информация",
    "dogum_tarihi": "Дата рождения",
    "name_surname": "Имя Фамилия",
    "username": "Имя пользователя",
    "note": "Заметка",
    "role": "Роль",
    "department_not_added": "Отдел не добавлен",
    "department_edit": "Редактировать отдел",
    "department_add": "Добавить отдел",
    "save": "Сохранить",
    "copy": "Копировать",
    "edit": "Редактировать",
    "treeChart": "Деревянная диаграмма",
    "sanketChart": "Потоковая диаграмма",
    "pieRoseTypeChart": "Круговая диаграмма с розой",
    "pieRoundedChart": "Закругленная круговая диаграмма",
    "barPolarTangential": "Полярная столбчатая диаграмма",
    "analiz_silindi": "Анализ удален",
    "analiz_adi_olusturuldu": "Имя анализа создано",
    "analiz_adi_guncellendi": "Имя анализа обновлено",
    "analiz_adi_olustururken_hata": "Ошибка при создании имени анализа",
    "analiz_adi_guncellerken_hata": "Ошибка при обновлении имени анализа",
    "analysisRequired": "Введите имя анализа",
    "etiketlenen": "Этикетирован",
    "getir": "Получить",
    "analiz_adi": "Имя анализа",
    "breaking_news": "Срочные новости",
    "choose_city": "Выберите город",
    "choose_district": "Выберите район",
    "choose_neighborhood": "Выберите район",
    "choose_street": "Выберите улицу",
    "choose_building": "Выберите здание",
    "choose_apartment": "Выберите квартиру",
    "find": "Найти",
    "disaster_management": "Управление бедствиями",
    "go_back": "Вернуться",
    "city": "Город",
    "population": "Население",
    "distance": "Расстояние",
    "district": "Район",
    "neighborhood": "Район",
    "street": "Улица",
    "building": "Здание",
    "apartment": "Квартира",
    "started_date": "Дата начала",
    "end_date": "Дата окончания",
    "category": "Категория",
    "kapanis_metni": "Текст закрытия",
    "acilis_metni": "Текст открытия",
    "main_category": "Главная категория",
    "sub_category": "Подкатегория",
    "sub_category_1": "Подкатегория 1",
    "sub_category_2": "Подкатегория 2",
    "bolumler": "Отделы",
    "web_site": "Веб-сайт",
    "kurum_adi": "Название учреждения",
    "size": "Размер",
    "depth": "Глубина",
    "closestCities": "Ближайшие города",
    "time_of_creation": "Время создания",
    "lat": "Широта",
    "lng": "Долгота",
    "place": "Место",
    "requirements_table": "Таблица требований",
    "total_population": "Общее население",
    "how_many_days_required": "Сколько дней нужно?",
    "total_requirement": "Общая потребность",
    "unit": "Единица",
    "contents": "Содержимое",
    "analysis_data_not_added": "Данные анализа не добавлены",
    "healthcare_facilities": "Медицинские учреждения",
    "location": "Местоположение",
    "all_locations": "Все местоположения",
    "locations": "Местоположения",
    "all_locations_show": "Показать все местоположения",
    "locations_show": "Показать местоположения",
    "locations_hide": "Скрыть местоположения",

    "page": "Страница",
    "create_page": "Создать страницу",
    "import_file": "Загрузить файл",
    "create_graphic": "Создать график",
    "are_you_sure": "Вы уверены?",
    "iliskili_tum_kategoriler_silinecektir": "Будут удалены все связанные категории",
    "mine": "МОЕ",
    "show": "Показать",
    "hide": "Скрыть",
    "show_hide": "Показать/Скрыть",
    "updateed": "Обновлено",
    "update": "Обновить",
    "updating": "Обновление",
    "created": "Добавлено",
    "create": "Создать",
    "creating": "Добавление",
    "send": "Отправить",
    "cancel": "Отмена",
    "preview": "Предварительный просмотр",
    "prev": "Назад",
    "view": "Просмотреть",
    "delete": "Удалить",
    "no_option": "Нет вариантов",
    "max_200_mb": "Не более 200 МБ",
    "file_size_error": "Размер файла превышает допустимый",
    "server_error": "Ошибка сервера!",
    "try_again_error": "Попробуйте еще раз позже.",
    "added_to_chart": "График добавлен на страницу",
    "updated_chart": "График обновлен",
    "deleted_chart": "График удален",
    "conditions_sections_required": "Не оставляйте пустыми поля условий.",
    "columns_sections_required": "Не оставляйте пустыми столбцы.",
    "chart_sections_required": "Не оставляйте пустыми поля графика.",
    "chart_name_error": "Название графика должно быть меньше 100 символов.",
    "select_column_error": "Выберите хотя бы один столбец.",
    "choose_column": "Выберите столбец",
    "choose_column_2": "Выберите столбец",
    "group_warning": "Столбец группировки должен быть среди выбранных столбцов.",
    "value": "Значение",
    "title": "Заголовок",
    "tum_musteriler": "Все клиенты",
    "icon": "Иконка",
    "iframe": "Iframe",
    "customer": "Клиент",
    "select_customer": "Выберите клиента",
    "page_order": "Порядок страницы",
    "page_deleted": "Страница удалена",
    "parent_menu": "Родительское меню",
    "page_name": "Название страницы",
    "external_link": "Внешняя ссылка",
    "pageOrder": "Введите порядок страницы",
    "pageName": "Введите название страницы",
    "pageNameCharLimit": "Название страницы должно быть больше 3 символов",
    "externalLinkError": "Ошибка внешней ссылки",
    "iframeLinkError": "Ошибка ссылки iFrame",
    "file_import_max": "Размер файла не должен превышать 50 МБ",
    "file_important": "Файл необходим",
    "file_import": "Импорт файла",
    "data_name_important": "Название данных обязательно",
    "data_name": "Название данных",
    "file": "Файл",
    "file_name": "Имя файла",
    "data_type": "Тип данных",
    "data_type_error": "Несоответствие типа данных",
    "integer": "Целое число",
    "string": "Строка",
    "date": "Дата",
    "json": "JSON",
    "email": "Email",
    "phone": "Телефон",
    "phone_control": "Проверка телефона",
    "boolean": "Логическое (Верно/Неверно)",
    "separators": "Разделители",
    "separators_warning": "Разделители не найдены",
    "separators_error": "Использован нераспознанный разделитель в CSV-файле.",
    "choose_csv_file": "Выберите CSV-файл",
    "warning_for_date": "Предупреждение: Формат даты выбранных вами столбцов должен быть таким <strong>2024-01-01</strong> для корректной обработки.",
    "upload": "Загрузить",
    "column_warning": "Укажите тип данных для всех столбцов",
    "file_import_info": "Данные будут добавлены в систему после очередности и анализа. Этот процесс может занять несколько минут.",
    "file_import_success": "Файл успешно загружен",
    "file_import_error": "Не удалось загрузить файл",
    "mahalle_sayisi": "Количество районов",
    "sokak_sayisi": "Количество улиц",
    "bina_sayisi": "Количество зданий",
    "hane_sayisi": "Количество домохозяйств",
    "kisi_sayisi": "Количество людей",

    "graphic_preview": "Предварительный просмотр графика",
    "secilen": "Выбранный",
    "choose_page": "Выберите страницу",
    "choose_chart": "Выберите график",
    "added_chart": "График добавлен на страницу",
    "update_chart": "Обновить график",
    "chart_width": "Ширина графика",
    "chart": "График",
    "query": "Запрос",
    "group": "Группировка",
    "pageCustomer": "Страница клиента",
    "selectPage": "Выберите страницу",
    "fileCustomer": "Клиент файла",
    "selectFile": "Выберите файл",
    "chartOrder": "Порядок графика",
    "chartView": "Просмотр графика",
    "pieChart": "Круговая диаграмма",
    "barChart": "Гистограмма",
    "wordCloud": "Облако слов",
    "table": "Таблица",
    "map": "Карта",
    "chartName": "Название графика",
    "chartDescription": "Описание графика",
    "addColumn": "Добавить столбец",
    "columnNotFound": "Столбец не найден",
    "selectColumn": "Выберите столбец",
    "selectOperator": "Выберите оператор",
    "selectCondition": "Выберите условие",
    "selectAction": "Выберите действие",
    "dateGrouping": "Группировка по дате",
    "menu_section": "Раздел меню",
    "menu_align": "Выравнивание меню",
    "menu_horizontal": "Выравнивание меню (Горизонтальное)",
    "menu_vertical": "Выравнивание меню (Вертикальное)",
    "xl": "Большой (4/4)",
    "lg": "Средний большой (4/3)",
    "m": "Средний (4/2)",
    "sm": "Малый (4/1)",
    "all": "ВСЕ",
    "column": "СТОЛБЕЦ",
    "count": "СЧЕТ",
    "average": "СРЕДНЕЕ",
    "total": "ИТОГ",
    "minimum": "МИНИМУМ",
    "maximum": "МАКСИМУМ",
    "distinct": "РАЗЛИЧНЫЕ",
    "day": "ДЕНЬ",
    "month": "МЕСЯЦ",
    "year": "ГОД",
    "hour": "ЧАС",
    "minute": "МИНУТА",
    "second": "СЕКУНДА",
    "none": "НЕТ",
    "condition": "Условие",
    "add_condition": "Добавить условие",
    "choose_condition": "Выберите условие",
    "conditionNotFound": "Условие не найдено",
    "you_can_add_condition": "Вы можете добавить условие",
    "in": "ВНУТРИ",
    "out": "СНАРУЖИ",
    "like": "ПОХОЖИЙ",
    "notLike": "НЕ ПОХОЖИЙ",
    "empty": "ПУСТО",
    "filled": "ЗАПОЛНЕНО",
    "and": "И",
    "or": "ИЛИ",
    "vertical": "Вертикальный",
    "horizontal": "Горизонтальный",
    "left": "Слева",
    "center": "Центр",
    "right": "Справа",
    "up": "Вверх",
    "middle": "Посередине",
    "down": "Вниз",
    "loader_data": "ДАННЫЕ",
    "loader_gathering": "СБОР ДАННЫХ...",
    "loader_in_progress": "АНАЛИЗ...",
    "loader_visualizing": "ВИЗУАЛИЗАЦИЯ..."
};

export default ru;
