import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import JoditEditor from "jodit-react";
import { useContext } from "react";
import { AppSettings } from "../config/app-settings";

export default function DynamicNote({ value, setValue }) {
  const context = useContext(AppSettings);

  return (
    <div className="flex-1 overflow-auto d-flex flex-column h-100">
      {setValue ? (
        // <CKEditor
        //   className="h-100"
        //   value={value || ""}
        //   data={value || ""}
        //   editor={ClassicEditor}
        //   onChange={(event, editor) => {
        //     const data = editor.getData();
        //     setValue(data);
        //   }}
        // />
        <JoditEditor
          tabIndex={2}
          config={{
            theme: context?.theme,
          }}
          value={value || ""}
          onBlur={(newContent) => {
            setValue(newContent);
          }}
        />
      ) : (
        <div className="graphic-note-item">
          <CKEditor
            className="h-100"
            disabled
            data={value || ""}
            editor={ClassicEditor}
          />
        </div>
      )}
    </div>
  );
}
