import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import apiClient from "../../ApiClient";
import toastMessage from "../../components/ToastMessage";
import { AppSettings } from "../../config/app-settings";

// import PasswordInput from "../../components/PasswordInput";
import moment from "moment";
import { ToastContainer } from "react-toastify";

import tr from "date-fns/locale/tr";
registerLocale("tr", tr);

export default function UpdateModal({ updateItem, getData, pages }) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  function errorMessages(error) {
    if (error.response.status === 422) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }

    if (error.response.status === 401) {
      context.setMe(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/giris-yap");
    }

    if (error.response.status === 403) {
      alert("Bir süre sonra tekrar deneyin.");
      setTimeout(() => {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }, 1000);
    }

    if (error.response.status === 500) {
      alert("Sunucu Hatası!");
    }
  }

  // All Kentmetre Reports
  const [allKentmetreReports, setAllKentmetreReports] = useState([]);

  async function getKentmetreReports() {
    try {
      const response = await apiClient.get(
        `https://api.kentmetre.com/external/scale/all`,
        {
          headers: {
            "X-Api-Key": "ffe021-5867ee-bb773a-a09e52-c2f033",
          },
        }
      );

      if (response.status === 200) {
        const modifiedArray = response.data.map(function (item) {
          return {
            value: item.id,
            label: item.name,
          };
        });

        const newModifiedArray = modifiedArray.slice();
        newModifiedArray.unshift({
          value: null,
          label: "Seçilmedi",
        });

        setAllKentmetreReports(newModifiedArray);
      }
    } catch (error) {
      setAllKentmetreReports([]);
      errorMessages(error);
      // console.error("Kentmetre Error", error);

      // if (error.response.status !== 404 || error.response.status !== 500) {
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("user");
      //   navigate("/giris-yap");
      // }
    }
  }

  const [theme, setTheme] = useState(null);
  const [theme2, setTheme2] = useState(null);

  // function handleTheme(color) {
  // localStorage.setItem("theme", color);

  // document.body.classList.forEach((className) => {
  //   if (className.startsWith("theme-")) {
  //     document.body.classList.remove(className);
  //   }
  // });

  // document.body.classList.add("theme-" + color.split("|")[1]);
  // setTheme(color);
  // setTheme2(color);
  // }

  // Select
  const [city, setCity] = useState(null);
  const [cityValue, setCityValue] = useState({
    value: 40,
    label: "ISTANBUL",
  });

  const [districtLoader, setDistrictLoader] = useState(true);
  const [districtValue, setDistrictValue] = useState(null);
  const [allDistrict, setAllDistrict] = useState([]);

  function handleCity(e) {
    setCityValue(e);

    setDistrictLoader(false);
    setTimeout(() => {
      setDistrictLoader(true);
    }, 500);
    setDistrictValue(null);

    if (e.value === null) {
      setAllDistrict([]);
    } else {
      fetchDistrict(e.value);
    }
  }

  function handleDistrict(e) {
    setDistrictValue(e);
  }

  async function fetchDistrict(value, name) {
    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: null,
          label: "Tümü",
        });

        setAllDistrict(newData);
      }
    }
  }

  useEffect(() => {
    setCity(cityValue.value || null);
    fetchDistrict(cityValue.value || null);
    getKentmetreReports();
  }, []);

  // Update User
  const [report1, setReport1] = useState({ value: null, label: "Seçilmedi" });
  const [report2, setReport2] = useState({ value: null, label: "Seçilmedi" });
  const [report3, setReport3] = useState({ value: null, label: "Seçilmedi" });
  const [primary, setPrimary] = useState("1");

  const [createSurvey, setCreateSurvey] = useState("1");
  const [missionReport, setMissionReport] = useState("1");
  const [secimAnalizi, setSecimAnalizi] = useState("1");
  const [sandikAnalizi, setSandikAnalizi] = useState("1");
  const [temaActive, setTemaActive] = useState("tema-1");
  const [logo, setLogo] = useState(null);
  const [logoName, setLogoName] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [updateID, setUpdateID] = useState(null);
  const [submitData, setSubmitData] = useState(null);

  const [startDate, setStartData] = useState(new Date());
  const [endDate, setEndData] = useState(new Date());

  const [formData, setFormData] = useState({
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    customer_address: "",
    customer_competent: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    contact_address: "",
    contact_competent: "",
    reports: [],
    api_key: "",
    country: "",
    city: "",
    district: "",
    logo: "",
    theme: "",
    domain: "",
    rate: 100,
  });

  const [pageCheckedItems, setPageCheckedItems] = useState([]);

  const handleCheckboxChange = (id, name, isChecked) => {
    setPageCheckedItems((prevCheckedItems) => {
      if (isChecked) {
        // Add to checked items if not already present
        if (!prevCheckedItems.some((item) => item.id === id)) {
          return [...prevCheckedItems, { id, name }];
        }
      } else {
        // Remove from checked items
        return prevCheckedItems.filter((item) => item.id !== id);
      }
      return prevCheckedItems;
    });
  };

  useEffect(() => {
    setLogo(null);

    setTimeout(() => {
      if (updateItem) {
        setUpdateID(updateItem?.id);
        setCreateSurvey(updateItem?.createSurvey ? "2" : "1");
        setMissionReport(updateItem?.missionReport ? "2" : "1");

        setSecimAnalizi(updateItem?.secimAnalizi ? "2" : "1");
        setSandikAnalizi(updateItem?.sandikAnalizi ? "2" : "1");
        setTemaActive(updateItem?.temaActive || "tema-1");
        setPageCheckedItems(updateItem?.pageCheckedItems);

        setPrimary(updateItem?.primary ? "2" : "1");
        setFormData(updateItem);
        setSubmitData(updateItem);

        if (updateItem?.theme) {
          setTheme(updateItem?.theme.split("|")[0]);
          setTheme2(updateItem?.theme.split("|")[1]);
        } else {
          setTheme(null);
          setTheme2(null);
        }

        setLogo(updateItem?.logo);

        setReport1(
          updateItem?.reports[0]
            ? updateItem?.reports[0]
            : { value: null, label: "Seçilmedi" }
        );
        setReport2(
          updateItem?.reports[1]
            ? updateItem?.reports[1]
            : { value: null, label: "Seçilmedi" }
        );
        setReport3(
          updateItem?.reports[2]
            ? updateItem?.reports[2]
            : { value: null, label: "Seçilmedi" }
        );

        setCityValue(updateItem?.city);
        setDistrictValue(updateItem?.district);

        setStartData(new Date(updateItem?.started_at));
        setEndData(new Date(updateItem?.ended_at));
      } else {
        setUpdateID(null);
        setSubmitData(null);
        setPageCheckedItems([]);

        setFormData({
          customer_name: "",
          customer_email: "",
          customer_phone: "",
          customer_address: "",
          customer_competent: "",
          contact_name: "",
          contact_email: "",
          contact_phone: "",
          contact_address: "",
          contact_competent: "",
          reports: [],
          api_key: "",
          rate: 100,
          country: "",
          city: "",
          district: "",
          logo: "",
          theme: "",
          domain: "",
        });
      }
    }, 100);
  }, [updateItem]);

  const handleUploadLogo = async (e) => {
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (e.size > maxFileSize) {
      toastMessage(context?.t?.max_file_size_5mb, "error");
      return;
    }

    setLogoName(e.name);

    setButtonDisabled(true);

    const formData = new FormData();
    formData.append("file", e);

    try {
      const response = await apiClient.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setButtonDisabled(false);
        setLogo(response.data.url);
      }
    } catch (error) {
      console.error("Upload File Error", error);
      setButtonDisabled(false);
      setLogo(null);
      errorMessages(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setButtonDisabled(true);

    const data = {
      customer: {
        name: formData.customer_name === "" ? null : formData.customer_name,
        email: formData.customer_email === "" ? null : formData.customer_email,
        phone: formData.customer_phone === "" ? null : formData.customer_phone,
        address:
          formData.customer_address === "" ? null : formData.customer_address,
        competent:
          formData.customer_competent === ""
            ? null
            : formData.customer_competent,
      },
      contact: {
        name: formData.contact_name === "" ? null : formData.contact_name,
        email: formData.contact_email === "" ? null : formData.contact_email,
        phone: formData.contact_phone === "" ? null : formData.contact_phone,
        address:
          formData.contact_address === "" ? null : formData.contact_address,
        competent:
          formData.contact_competent === "" ? null : formData.contact_competent,
      },
      started_at: moment(startDate).format("YYYY-MM-DD"),
      ended_at: moment(endDate).format("YYYY-MM-DD"),
      reports: [
        report1.value ? report1 : null,
        report2.value ? report2 : null,
        report3.value ? report3 : null,
      ],
      api_key: formData.api_key || "",
      rate: formData.rate ? parseInt(formData.rate) : 100,
      settings: {
        domain: formData.domain,
        createSurvey: createSurvey === "1" ? false : true,
        missionReport: missionReport === "1" ? false : true,
        secimAnalizi: secimAnalizi === "1" ? false : true,
        sandikAnalizi: sandikAnalizi === "1" ? false : true,
        temaActive: temaActive || "tema-1",
        pages: pageCheckedItems,
        logo: logo,
        theme: theme + "|" + theme2,
        country: 1,
        city: cityValue,
        district: districtValue,
        primary: primary === "1" ? false : true,
      },
    };

    try {
      const response = await apiClient.put(`/customer/${updateItem.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setButtonDisabled(false);

        toastMessage(response.data.message, "success");

        document.getElementById("updateModalClose").click();
        getData();
      }
    } catch (error) {
      console.error("Customer Update Error", error);
      setButtonDisabled(false);

      if (error.response.status === 422) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }

      if (error.response.status === 401) {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }

      if (error.response.status === 403) {
        alert("Bir süre sonra tekrar deneyin.");
        setTimeout(() => {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }, 1000);
      }

      if (error.response.status === 500) {
        alert("Sunucu Hatası!");
      }
    }
  }

  return (
    <div className="modal modal-cover fade" id="updateModal">
      <div className="modal-dialog">
        <div
          className="modal-content rounded"
          style={{ background: "var(--bs-dark4)" }}
        >
          <div className="modal-header">
            <h3 className="modal-title">Müşteri Düzenle</h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              id="updateModalClose"
            ></button>
          </div>

          <ul className="nav nav-tabs nav-tabs-v2 ps-4 pe-4">
            <li className="nav-item me-3">
              <a
                href="#edit_uyelik_bilgisi"
                className="nav-link active"
                data-bs-toggle="tab"
              >
                Üyelik
              </a>
            </li>
            <li className="nav-item me-3">
              <a
                href="#edit_musteri_bilgisi"
                className="nav-link"
                data-bs-toggle="tab"
              >
                Müşteri
              </a>
            </li>
            <li className="nav-item me-3">
              <a
                href="#edit_iletisim_yetkilisi_bilgisi"
                className="nav-link"
                data-bs-toggle="tab"
              >
                İletişim Yetkilisi
              </a>
            </li>
          </ul>

          <form onSubmit={handleSubmit}>
            <div className="tab-content p-4">
              <div
                className="tab-pane fade show active"
                id="edit_uyelik_bilgisi"
              >
                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Üyelik Başlangıç Tarihi Seçiniz
                    <span>*</span>
                  </span>
                  <DatePicker
                    locale="tr"
                    className="form-control"
                    selected={startDate}
                    onChange={(e) => setStartData(e)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Üyelik Bitiş Tarihi Seçiniz
                    <span>*</span>
                  </span>
                  <DatePicker
                    locale="tr"
                    className="form-control"
                    selected={endDate}
                    onChange={(e) => setEndData(e)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Üyelik Kapsam Şehrini Seçiniz
                    <span>*</span>
                  </span>
                  {city ? (
                    <Select
                      options={[
                        {
                          value: null,
                          label: "Tümü",
                        },
                        ...context.allCity,
                      ]}
                      value={
                        cityValue
                          ? cityValue
                          : city &&
                            context.allCity.find(function (item) {
                              return item.value === city;
                            })
                      }
                      classNamePrefix="react-select"
                      placeholder="İl Seçiniz..."
                      noOptionsMessage={() => "Seçenek Yok"}
                      onChange={handleCity}
                      required
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Üyelik Kapsam İlçesini Seçiniz
                    <span>*</span>
                  </span>
                  {districtLoader ? (
                    <Select
                      options={allDistrict}
                      classNamePrefix="react-select"
                      value={districtValue}
                      placeholder="İlçe Seçiniz..."
                      noOptionsMessage={() => "Seçenek Yok"}
                      onChange={handleDistrict}
                      required={cityValue.value ? true : false}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">İstek Sayısı</span>
                  <input
                    type="number"
                    className="form-control"
                    name="rate"
                    value={formData.rate}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">API Key</span>
                  <input
                    type="text"
                    className="form-control"
                    name="api_key"
                    value={formData.api_key}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">Domain</span>
                  <input
                    type="text"
                    className="form-control"
                    name="domain"
                    value={formData.domain}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Kentmetre Rapor 1 Seçiniz
                  </span>
                  <Select
                    options={allKentmetreReports}
                    placeholder=""
                    classNamePrefix="react-select"
                    value={report1}
                    onChange={(e) => setReport1(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Kentmetre Rapor 2 Seçiniz
                  </span>
                  <Select
                    options={allKentmetreReports}
                    placeholder=""
                    classNamePrefix="react-select"
                    value={report2}
                    onChange={(e) => setReport2(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Kentmetre Rapor 3 Seçiniz
                  </span>
                  <Select
                    options={allKentmetreReports}
                    placeholder=""
                    classNamePrefix="react-select"
                    value={report3}
                    onChange={(e) => setReport3(e)}
                  />
                </div>

                <hr />

                <h5 className="mb-3 d-block">Sayfa İzinleri</h5>

                <span className="mb-2 mt-3 d-block">Genel Görünüm</span>

                {pages &&
                  pages
                    .filter(
                      (menu) =>
                        !menu.name.startsWith("Anket Sayfası") &&
                        menu.parent_id !== 44 &&
                        menu.page_type === "default" &&
                        !menu.iframe &&
                        menu.name !== "Veri Girişi" &&
                        menu.name !== "Hazır Raporlar" &&
                        menu.name !== "Araştırma Raporu"
                    )
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center mb-2"
                      >
                        <input
                          type="checkbox"
                          id={`update_customer_page_checkbox_${item.id}`}
                          checked={
                            !!pageCheckedItems.find(
                              (checkedItem) => checkedItem.id === item.id
                            )
                          }
                          onChange={(e) =>
                            handleCheckboxChange(
                              item.id,
                              item.name,
                              e.target.checked
                            )
                          }
                          className="form-check-input me-2"
                        />
                        <label
                          htmlFor={`update_customer_page_checkbox_${item.id}`}
                          className="form-check-label"
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}

                <span className="mb-2 mt-3 d-block">Anket ve Araştırmalar</span>
                {pages &&
                  pages
                    .filter(
                      (menu) =>
                        menu.name === "Anket Oluştur" ||
                        menu.name === "Devam Edenler" ||
                        menu.name === "Tamamlananlar"
                    )
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center mb-2"
                      >
                        <input
                          type="checkbox"
                          id={`update_customer_page_checkbox_${item.id}`}
                          checked={
                            !!pageCheckedItems.find(
                              (checkedItem) => checkedItem.id === item.id
                            )
                          }
                          onChange={(e) =>
                            handleCheckboxChange(
                              item.id,
                              item.name,
                              e.target.checked
                            )
                          }
                          className="form-check-input me-2"
                        />
                        <label
                          htmlFor={`update_customer_page_checkbox_${item.id}`}
                          className="form-check-label"
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}

                <span className="mb-2 mt-3 d-block">İşlemler</span>

                {pages &&
                  pages
                    .filter(
                      (menu) =>
                        menu.name === "Veri Girişi" ||
                        menu.name === "Hazır Raporlar" ||
                        menu.name === "Araştırma Raporu"
                    )
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center mb-2"
                      >
                        <input
                          type="checkbox"
                          id={`update_customer_page_checkbox_${item.id}`}
                          checked={
                            !!pageCheckedItems.find(
                              (checkedItem) => checkedItem.id === item.id
                            )
                          }
                          onChange={(e) =>
                            handleCheckboxChange(
                              item.id,
                              item.name,
                              e.target.checked
                            )
                          }
                          className="form-check-input me-2"
                        />
                        <label
                          htmlFor={`update_customer_page_checkbox_${item.id}`}
                          className="form-check-label"
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}

                <span className="mb-2 mt-3 d-block">İleri Analiz</span>

                {pages &&
                  pages
                    .filter(
                      (menu) => menu.page_type === "default" && menu.iframe
                    )
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center mb-2"
                      >
                        <input
                          type="checkbox"
                          id={`update_customer_page_checkbox_${item.id}`}
                          checked={
                            !!pageCheckedItems.find(
                              (checkedItem) => checkedItem.id === item.id
                            )
                          }
                          onChange={(e) =>
                            handleCheckboxChange(
                              item.id,
                              item.name,
                              e.target.checked
                            )
                          }
                          className="form-check-input me-2"
                        />
                        <label
                          htmlFor={`update_customer_page_checkbox_${item.id}`}
                          className="form-check-label"
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}

                <hr />

                <div className="mb-3 d-flex justify-content-between">
                  <span className="mb-1 d-block">Anket Oluştur</span>
                  <div className="d-flex gap-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="createSurvey"
                        id="createSurvey_gizle"
                        value={"1"}
                        checked={createSurvey === "1"}
                        onChange={(e) => setCreateSurvey(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="createSurvey_gizle"
                      >
                        Hayır
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="createSurvey"
                        value={"2"}
                        checked={createSurvey === "2"}
                        type="radio"
                        id="createSurvey_goster"
                        onChange={(e) => setCreateSurvey(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="createSurvey_goster"
                      >
                        Evet
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-3 d-flex justify-content-between">
                  <span className="mb-1 d-block">
                    Rapor Alabilir (Görevler)
                  </span>
                  <div className="d-flex gap-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="missionReport"
                        id="missionReport_gizle"
                        value={"1"}
                        checked={missionReport === "1"}
                        onChange={(e) => setMissionReport(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="missionReport_gizle"
                      >
                        Hayır
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="missionReport"
                        value={"2"}
                        checked={missionReport === "2"}
                        type="radio"
                        id="missionReport_goster"
                        onChange={(e) => setMissionReport(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="missionReport_goster"
                      >
                        Evet
                      </label>
                    </div>
                  </div>
                </div>

                {/* <div className="mb-3 d-flex justify-content-between">
                  <span className="mb-1 d-block">Seçim Analizi Görebilir</span>
                  <div className="d-flex gap-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="secimAnalizi"
                        id="secimAnalizi_gizle"
                        value={"1"}
                        checked={secimAnalizi === "1"}
                        onChange={(e) => setSecimAnalizi(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="secimAnalizi_gizle"
                      >
                        Hayır
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="secimAnalizi"
                        value={"2"}
                        checked={secimAnalizi === "2"}
                        type="radio"
                        id="secimAnalizi_goster"
                        onChange={(e) => setSecimAnalizi(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="secimAnalizi_goster"
                      >
                        Evet
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mb-3 d-flex justify-content-between">
                  <span className="mb-1 d-block">Sandık Analizi Görebilir</span>
                  <div className="d-flex gap-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="sandikAnalizi"
                        id="sandikAnalizi_gizle"
                        value={"1"}
                        checked={sandikAnalizi === "1"}
                        onChange={(e) => setSandikAnalizi(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="sandikAnalizi_gizle"
                      >
                        Hayır
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="sandikAnalizi"
                        value={"2"}
                        checked={sandikAnalizi === "2"}
                        type="radio"
                        id="sandikAnalizi_goster"
                        onChange={(e) => setSandikAnalizi(e.target.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="sandikAnalizi_goster"
                      >
                        Evet
                      </label>
                    </div>
                  </div>
                </div> */}

                <div className="mb-3 d-flex justify-content-between">
                  <span className="mb-1 d-block">Birincil Veri</span>
                  <div className="d-flex gap-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="primary"
                        id="gizle"
                        value={"1"}
                        checked={primary === "1"}
                        onChange={(e) => setPrimary(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="gizle">
                        Gizle
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        name="primary"
                        value={"2"}
                        checked={primary === "2"}
                        type="radio"
                        id="goster"
                        onChange={(e) => setPrimary(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="goster">
                        Göster
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade" id="edit_musteri_bilgisi">
                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Müşteri Adı <span>*</span>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Müşteri Yetkilisi Adı Soyadı
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="customer_competent"
                    value={formData.customer_competent}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">Müşteri Telefonu</span>
                  <input
                    type="text"
                    className="form-control"
                    name="customer_phone"
                    value={formData.customer_phone}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    Müşteri Email <span>*</span>
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    name="customer_email"
                    value={formData.customer_email}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">Müşteri Adresi</span>
                  <textarea
                    type="email"
                    className="form-control"
                    name="customer_address"
                    value={formData.customer_address}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">Müşteri Logo</span>
                  <input
                    id={"update_customer_logo_" + updateItem?.id}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    className="d-none"
                    onChange={(e) => handleUploadLogo(e.target.files[0])}
                  />
                  <label
                    htmlFor={"update_customer_logo_" + updateItem?.id}
                    className="btn btn-outline-theme w-100 h-100 d-flex align-items-center justify-content-center pointer"
                  >
                    Logo Yükle
                  </label>

                  {logo && (
                    <a
                      href={logo}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block mt-1"
                    >
                      <img src={logo} alt="" style={{ width: 150 }} />
                    </a>
                  )}
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">Müşteri Tema</span>
                  <div className="d-flex align-items-center flex-wrap gap-2 mb-2">
                    <button
                      type="button"
                      className={`btn btn-sm ${
                        temaActive === "tema-1"
                          ? "text-black btn-theme"
                          : "btn-outline-theme"
                      }`}
                      onClick={() => setTemaActive("tema-1")}
                    >
                      Tema 1
                    </button>

                    <button
                      type="button"
                      className={`btn btn-sm ${
                        temaActive === "tema-2"
                          ? "text-black btn-theme"
                          : "btn-outline-theme"
                      }`}
                      onClick={() => setTemaActive("tema-2")}
                    >
                      Tema 2
                    </button>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between mb-2 gap-1 flex-wrap">
                    <span className="d-block">Menü Rengi</span>
                    {theme && (
                      <span
                        className="btn btn-sm btn-danger"
                        onClick={() => setTheme(null)}
                      >
                        Kaldır
                      </span>
                    )}
                  </div>

                  <div className="app-theme-list">
                    {context.themeColorList.length > 0 &&
                      context.themeColorList.map((t, index) => (
                        <div
                          className={
                            "app-theme-list-item " +
                            (theme === t.color ? "active" : "")
                          }
                          style={{ flex: "none" }}
                          key={index}
                        >
                          <a
                            href="#/"
                            className={"app-theme-list-link"}
                            style={{
                              background: t.color,
                            }}
                            onClick={(event) => setTheme(t.color)}
                          >
                            &nbsp;
                          </a>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="mb-3">
                  <div className="d-flex align-items-center justify-content-between mb-2 gap-1 flex-wrap">
                    <span className="d-block">Site Rengi</span>
                    {theme2 && (
                      <span
                        className="btn btn-sm btn-danger"
                        onClick={() => setTheme2(null)}
                      >
                        Kaldır
                      </span>
                    )}
                  </div>

                  <div className="app-theme-list">
                    {context.themeColorList.length > 0 &&
                      context.themeColorList.map((t, index) => (
                        <div
                          className={
                            "app-theme-list-item " +
                            (theme2 === t.color ? "active" : "")
                          }
                          style={{ flex: "none" }}
                          key={index}
                        >
                          <a
                            href="#/"
                            className={"app-theme-list-link"}
                            style={{
                              background: t.color,
                            }}
                            onClick={(event) => setTheme2(t.color)}
                          >
                            &nbsp;
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="edit_iletisim_yetkilisi_bilgisi"
              >
                <div className="mb-3">
                  <span className="mb-1 d-block">
                    İletişim Adı <span>*</span>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="contact_name"
                    value={formData.contact_name}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    İletişim Yetkilisi Adı Soyadı
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    name="contact_competent"
                    value={formData.contact_competent}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">İletişim Telefonu</span>
                  <input
                    type="text"
                    className="form-control"
                    name="contact_phone"
                    value={formData.contact_phone}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">
                    İletişim Email <span>*</span>
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    name="contact_email"
                    value={formData.contact_email}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <span className="mb-1 d-block">İletişim Adresi</span>
                  <textarea
                    type="email"
                    className="form-control"
                    name="contact_address"
                    value={formData.contact_address}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-outline-theme w-100"
                disabled={buttonDisabled}
              >
                Kaydet
                {buttonDisabled && (
                  <div className="spinner-border spinner-border-sm text-theme ms-2"></div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
