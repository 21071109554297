import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { AppSettings } from "../../../config/app-settings.js";
import apiClient from "../../../ApiClient.js";

import PageFilter from "../../../components/PageFilter.jsx";
import CardBinaHane from "../../../components/info-cards/CardBinaHane.jsx";
import CardMedeniDurum from "../../../components/info-cards/CardMedeniDurum.jsx";
import CardOlumSayisi from "../../../components/info-cards/CardOlumSayisi.jsx";
import CardSosyoekonomik from "../../../components/info-cards/CardSosyoekonomik.jsx";

import CardHemsehrilikGoc from "../../../components/info-cards/CardHemsehrilikGoc.jsx";
import CardVatandasSayisi from "../../../components/info-cards/CardVatandasSayisi.jsx";
import DynamicCard from "../../../components/DynamicCard.jsx";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

function SecimAnalizi() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  useEffect(() => {
    if (!context?.me?.customer?.settings?.secimAnalizi) {
      navigate("/404");
    }
  }, [context, navigate]);

  // Filter
  const [cityFilter, setCityFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState(null);

  // SİYASAL KİMLİK ANALİZ SONUÇLARI & KUSAKLAR
  const [, setSiyasalKimlikAnalizData] = useState(null);
  const [kusaklarData, setKusaklarData] = useState(null);

  const fetchGenelData = useCallback(
    async (chartType) => {
      setSiyasalKimlikAnalizData(null);
      setKusaklarData(null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "-",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
            sql: {
              column:
                chartType === "siyasal-kimlik"
                  ? [
                      {
                        key: "Siyasal Kimlik 1",
                        value: "select",
                      },
                      {
                        key: "Siyasal Kimlik 2",
                        value: "select",
                      },
                      {
                        key: "TC",
                        value: "count",
                      },
                    ]
                  : chartType === "kusaklar"
                  ? [
                      {
                        key: "Dogum Tarihi",
                        value: "select",
                      },
                    ]
                  : [],
              condition: [
                cityFilter
                  ? {
                      key: "Adres Il",
                      value: context
                        .removeTurkishCharacters(cityFilter.label)
                        .toUpperCase(),
                      operator: "like",
                      type: "and",
                    }
                  : null,
                districtFilter
                  ? {
                      key: "Adres Ilce",
                      value: context
                        .removeTurkishCharacters(districtFilter.label)
                        .toUpperCase(),
                      operator: "like",
                      type: "and",
                    }
                  : null,
                neighbourhoodFilter
                  ? {
                      key: "Adres Mahalle",
                      value: context
                        .removeTurkishCharacters(neighbourhoodFilter.label)
                        .toUpperCase(),
                      operator: "like",
                      type: "and",
                    }
                  : null,
              ].filter((item) => item !== null),
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (chartType === "siyasal-kimlik") {
          if (response.length !== 0) {
            setSiyasalKimlikAnalizData(
              response.map((item) => {
                return {
                  value: parseInt(item.tc_count) / 100,
                  name:
                    item["siyasal kimlik 1"] + " - " + item["siyasal kimlik 2"],
                };
              })
            );
          } else {
            setSiyasalKimlikAnalizData([]);
          }
        }

        if (chartType === "kusaklar") {
          if (response.length !== 0) {
            // Kuşak aralıklarını tanımla
            const generations = [
              { name: context?.t?.en_buyuk_nesil, start: 1910, end: 1924 },
              { name: context?.t?.sessiz_kusak, start: 1925, end: 1945 },
              { name: context?.t?.baby_boomer_kusagi, start: 1946, end: 1960 },
              { name: context?.t?.y_kusagi, start: 1961, end: 1980 },
              { name: context?.t?.x_kusagi, start: 1981, end: 1995 },
              { name: context?.t?.z_kusagi, start: 1996, end: 2012 },
            ];

            // Kuşak adlarının sıralamasını tanımla (bu sıralama sabit olarak tanımlanır)
            const generationOrder = [
              context?.t?.en_buyuk_nesil,
              context?.t?.sessiz_kusak,
              context?.t?.baby_boomer_kusagi,
              context?.t?.y_kusagi,
              context?.t?.x_kusagi,
              context?.t?.z_kusagi,
            ];

            // Yardımcı fonksiyon: yılı kuşak adını bul
            const findGeneration = (year) => {
              for (const generation of generations) {
                if (year >= generation.start && year <= generation.end) {
                  return generation.name;
                }
              }
              return null;
            };

            // Kuşak sayımlarını tut
            const generationCounts = {};

            // Verilen tarihlerden yılları al ve kuşakları say
            response.forEach((item) => {
              const year = item["dogum tarihi"].split("-")[0];
              const generation = findGeneration(year);

              if (generation) {
                if (!generationCounts[generation]) {
                  generationCounts[generation] = 0;
                }
                generationCounts[generation]++;
              }
            });

            // Sonuçları sıralı olarak formatla
            const result = [[], []];

            // Kuşak adlarının sıralı dizisine göre sayıları ve isimleri ekle
            generationOrder.forEach((generation) => {
              if (generationCounts[generation] !== undefined) {
                result[0].push(generationCounts[generation]);
                result[1].push(generation);
              }
            });

            setKusaklarData(result);
          } else {
            setKusaklarData([]);
          }
        }
      } catch (error) {
        console.error("Genel Data Error", error);
        setSiyasalKimlikAnalizData(null);
        setKusaklarData(null);
      }
    },
    [cityFilter, context, districtFilter, neighbourhoodFilter]
  );

  useEffect(() => {
    if (context?.me?.customer?.settings?.secimAnalizi) {
      // fetchGenelData("siyasal-kimlik");
      fetchGenelData("kusaklar");
    }
  }, [context?.me?.customer?.settings?.secimAnalizi, fetchGenelData]);

  // SOKAGA GORE SANDIK BASINA SECMEN SAYISI
  const [chartInfoData1, setChartInfoData1] = useState(null);
  const [, setChartInfoData2] = useState(null);
  const [, setChartInfoData3] = useState(null);
  const [secimAnaliziSandikNoModalData] = useState(null);

  const getChartDataInfo = useCallback(
    async (graphic, city, district, neighbourhood, street, chest) => {
      setChartInfoData1(null);
      setChartInfoData2(null);
      setChartInfoData3(null);

      const conditionData = [
        city
          ? {
              key: graphic === 2 ? "Sehir" : "Adres Il",
              value: context.removeTurkishCharacters(city).toUpperCase(),
              operator: "=",
              type: "and",
            }
          : null,
        district
          ? {
              key: graphic === 2 ? "Ilce" : "Adres Ilce",
              value: context.removeTurkishCharacters(district).toUpperCase(),
              operator: "=",
              type: "and",
            }
          : null,
        neighbourhood
          ? {
              key: graphic === 2 ? "Mahalle" : "Adres Mahalle",
              value: context
                .removeTurkishCharacters(neighbourhood)
                .toUpperCase(),
              operator: "like",
              type: "and",
            }
          : null,
        street && graphic !== 2
          ? {
              key: "Adres Sokak",
              value: street.toUpperCase(),
              operator: "like",
              type: "and",
            }
          : null,
        {
          key: graphic === 2 ? "Alt_Kategori_2" : "Sandık No",
          value: chest,
          operator: "=",
          type: "and",
        },
      ];
      const conditionDataFiltered = conditionData.filter(
        (item) => item !== null
      );

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 48,
            title: "Sandık Analizi",
            type:
              graphic === 2
                ? "siyasetmetre_secim_3"
                : `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
            sql: {
              column: [
                {
                  key: graphic === 2 ? "Sehir" : "Adres Il",
                  value: "select",
                },
                {
                  key: graphic === 2 ? "Ilce" : "Adres Ilce",
                  value: "select",
                },
                {
                  key: graphic === 2 ? "Mahalle" : "Adres Mahalle",
                  value: "select",
                },
                graphic !== 2
                  ? {
                      key: "Adres Sokak",
                      value: "select",
                    }
                  : null,
                graphic !== 6
                  ? {
                      key: graphic === 2 ? "Alt_Kategori_2" : "Sandık No",
                      value: "select",
                    }
                  : null,
                graphic === 2
                  ? {
                      key: "Parametre",
                      value: "select",
                    }
                  : null,
                graphic === 2
                  ? {
                      key: "Deger",
                      value: "select",
                    }
                  : null,
                graphic !== 2 && graphic !== 6
                  ? {
                      key: "Secmen Profili",
                      value: "select",
                    }
                  : null,
                graphic !== 2 && graphic !== 6
                  ? {
                      key: "Siyasal Kimlik 1",
                      value: "select",
                    }
                  : null,
                graphic !== 2 && graphic !== 6
                  ? {
                      key: "Memleket Il",
                      value: "select",
                    }
                  : null,
                graphic !== 2 && graphic !== 6
                  ? {
                      key: "Memleket Ilce",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "TC",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Ad Soyad",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Dogum Tarihi",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Telefon NO",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Bina NO",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Daire",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Tahmini 1. Derece Ailede Uyelik",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Tahmini 2. Derece Ailede Uyelik",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Sandığa Gitme Ihtimali",
                      value: "select",
                    }
                  : null,
                graphic === 6
                  ? {
                      key: "Sandığa Gitmeme Ihtimali",
                      value: "select",
                    }
                  : null,
              ].filter((item) => item !== null),
              condition: conditionDataFiltered,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (graphic === 2) {
          if (response.length !== 0) {
            setChartInfoData1(
              response.map((element) => {
                return {
                  name: element.parametre,
                  value: element.deger,
                };
              })
            );
          } else {
            setChartInfoData1([]);
          }
        }

        if (graphic === 3) {
          if (response.length !== 0) {
            const siyasalKimlikMap = new Map();
            const siyasalKimlikOptions = [];

            response.forEach((item) => {
              const value = item["siyasal kimlik 1"];
              siyasalKimlikMap.set(
                value,
                (siyasalKimlikMap.get(value) || 0) + 1
              );
            });

            siyasalKimlikMap.forEach((count, value) => {
              const percentage = Math.floor((count / response.length) * 100);
              siyasalKimlikOptions.push({
                value: percentage,
                name: value,
              });
            });

            setChartInfoData2(siyasalKimlikOptions);
          } else {
            setChartInfoData2([]);
          }
        }

        if (graphic === 4) {
          if (response.length !== 0) {
            const secmenProfiliMap = new Map();
            const secmenProfiliOptions = [];

            response.forEach((item) => {
              const value = item["secmen profili"];
              secmenProfiliMap.set(
                value,
                (secmenProfiliMap.get(value) || 0) + 1
              );
            });

            secmenProfiliMap.forEach((count, value) => {
              const percentage = Math.floor((count / response.length) * 100);
              secmenProfiliOptions.push({
                value: percentage,
                name: value,
              });
            });

            setChartInfoData3(secmenProfiliOptions);
          } else {
            setChartInfoData3([]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [context]
  );

  useEffect(() => {
    if (
      context?.me?.customer?.settings?.secimAnalizi &&
      secimAnaliziSandikNoModalData
    ) {
      getChartDataInfo(
        2,
        secimAnaliziSandikNoModalData?.sehir,
        secimAnaliziSandikNoModalData?.ilce,
        secimAnaliziSandikNoModalData?.mahalle,
        secimAnaliziSandikNoModalData?.cadde_sokak,
        secimAnaliziSandikNoModalData?.alt_kategori_2
      );
      // getChartDataInfo(
      //   3,
      //   secimAnaliziSandikNoModalData?.sehir,
      //   secimAnaliziSandikNoModalData?.ilce,
      //   secimAnaliziSandikNoModalData?.mahalle,
      //   secimAnaliziSandikNoModalData?.cadde_sokak,
      //   secimAnaliziSandikNoModalData?.alt_kategori_2
      // );
      // getChartDataInfo(
      //   4,
      //   secimAnaliziSandikNoModalData?.sehir,
      //   secimAnaliziSandikNoModalData?.ilce,
      //   secimAnaliziSandikNoModalData?.mahalle,
      //   secimAnaliziSandikNoModalData?.cadde_sokak,
      //   secimAnaliziSandikNoModalData?.alt_kategori_2
      // );
    }
  }, [
    context?.me?.customer?.settings?.secimAnalizi,
    getChartDataInfo,
    secimAnaliziSandikNoModalData,
  ]);

  // SECİMLER
  const [dataState, setDataState] = useState([
    { title: context?.t?.DONEM_MILLETVEKILLIGI_GENEL_SECIMI_24, data: null },
    {
      title: context?.t?.BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2014,
      data: null,
    },
    { title: context?.t?.MILLETVEKILLIGI_SECIMLERI_2015, data: null },
    { title: context?.t?.HALK_OYLAMASI_2017, data: null },
    { title: context?.t?.CUMHURBASKANLIGI_SECIMLERI_2018, data: null },
    { title: context?.t?.MILLETVEKILLIGI_SECIMLERI_2018, data: null },
    { title: context?.t?.YEREL_SECIMLERI_2019, data: null },
    { title: context?.t?.DONEM_MILLETVEKILI_GENEL_SECIMLERI_28, data: null },
    { title: context?.t?.BASKANLIK_SECIMI_2_TUR_1, data: null },
    { title: context?.t?.BASKANLIK_SECIMI_2_TUR_2, data: null },
    { title: context?.t?.BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2024, data: null },
    {
      title: context?.t?.BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2024,
      data: null,
    },
  ]);

  const fetchSecimler = useCallback(
    async (city, district, neighborhood, condition, signal, type) => {
      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "-",
            type: type,
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
            sql: {
              column: [
                {
                  key: "Parametre",
                  value: "select",
                },
                {
                  key: "Deger",
                  value: "sum",
                },
              ],
              condition: [
                // {
                //   key: "Alt-Kategori-2",
                //   value: condition,
                //   operator: "=",
                //   type: "and",
                // },
                city
                  ? {
                      key: "Sehir",
                      value: context
                        .removeTurkishCharacters(city.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                district
                  ? {
                      key: "Ilce",
                      value: context
                        .removeTurkishCharacters(district.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                neighborhood
                  ? {
                      key: "Mahalle",
                      value: context
                        .removeTurkishCharacters(neighborhood.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
              ].filter((item) => item !== null),
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            signal,
          }
        );

        const newData = {
          title: condition,
          data:
            response.length !== 0
              ? response
                  .filter(
                    (item) =>
                      item.parametre !== "BAĞIMSIZ TOPLAM OY" &&
                      item.parametre !== "TOPLAM GEÇERLİ OY" &&
                      item.parametre !== "TOPLAM GEÇERLI OY" &&
                      item.parametre !== "TOPLAM GEÇERSİZ OY" &&
                      item.parametre !== "TOPLAM GEÇERSIZ OY" &&
                      item.parametre !== "OY KULLANAN SEÇMEN SAYISI" &&
                      item.parametre !== "KAYITLI SEÇMEN SAYISI" &&
                      item.parametre !== "İTİRAZSIZ GEÇERLİ OY SAYISI" &&
                      item.parametre !== "İTIRAZSIZ GEÇERLI OY SAYISI" &&
                      item.parametre !== "İTİRAZLI GEÇERLİ OY SAYISI" &&
                      item.parametre !== "İTİRAZLİ GEÇERLİ OY SAYISI" &&
                      item.parametre !== "İTIRAZLI GEÇERLI OY SAYISI"
                  )
                  .map((item) => {
                    return {
                      name: item.parametre,
                      value: item.deger_sum,
                    };
                  })
              : [],
        };

        setDataState((prevState) => {
          const existingDataIndex = prevState.findIndex(
            (item) => item.title === condition
          );

          if (existingDataIndex !== -1) {
            const updatedState = [...prevState];
            updatedState[existingDataIndex].data = newData.data;
            return updatedState;
          } else {
            return prevState;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    [context]
  );

  useEffect(() => {
    let controller = new AbortController();

    const fetchSequentially = async () => {
      try {
        setDataState([
          {
            title: context?.t?.DONEM_MILLETVEKILLIGI_GENEL_SECIMI_24,
            data: null,
          },
          {
            title: context?.t?.BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2014,
            data: null,
          },
          { title: context?.t?.MILLETVEKILLIGI_SECIMLERI_2015, data: null },
          { title: context?.t?.HALK_OYLAMASI_2017, data: null },
          { title: context?.t?.CUMHURBASKANLIGI_SECIMLERI_2018, data: null },
          { title: context?.t?.MILLETVEKILLIGI_SECIMLERI_2018, data: null },
          { title: context?.t?.YEREL_SECIMLERI_2019, data: null },
          {
            title: context?.t?.DONEM_MILLETVEKILI_GENEL_SECIMLERI_28,
            data: null,
          },
          { title: context?.t?.BASKANLIK_SECIMI_2_TUR_1, data: null },
          { title: context?.t?.BASKANLIK_SECIMI_2_TUR_2, data: null },
          {
            title: context?.t?.BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2024,
            data: null,
          },
          {
            title: context?.t?.BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2024,
            data: null,
          },
        ]);

        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.DONEM_MILLETVEKILLIGI_GENEL_SECIMI_24,
          controller.signal,
          "2011-Milletvekili-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2014,
          controller.signal,
          "2014-Belediye-Buyuksehir-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.MILLETVEKILLIGI_SECIMLERI_2015,
          controller.signal,
          "2015-Milletvekili-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.HALK_OYLAMASI_2017,
          controller.signal,
          "2017-Halk-Oylamasi"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.CUMHURBASKANLIGI_SECIMLERI_2018,
          controller.signal,
          "2018-CB-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.MILLETVEKILLIGI_SECIMLERI_2018,
          controller.signal,
          "2018-Milletvekili-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.YEREL_SECIMLERI_2019,
          controller.signal,
          "2019-Belediye-Yerel-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.DONEM_MILLETVEKILI_GENEL_SECIMLERI_28,
          controller.signal,
          "2023-Milletvekili-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.BASKANLIK_SECIMI_2_TUR_1,
          controller.signal,
          "2023-CB-1Tur-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.BASKANLIK_SECIMI_2_TUR_2,
          controller.signal,
          "2023-CB-2Tur-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2024,
          controller.signal,
          "2024-Belediye-Yerel-Secim"
        );
        await fetchSecimler(
          cityFilter,
          districtFilter,
          neighbourhoodFilter,
          context?.t?.BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2024,
          controller.signal,
          "2024-Belediye-Buyuksehir-Secim"
        );
      } catch (error) {
        if (error.name === "AbortError") {
          // console.log("Fetch aborted");
        } else {
          console.error("Error in fetchSequentially:", error);
        }
      }
    };

    fetchSequentially();

    return () => {
      controller.abort(); // Cancel ongoing fetch
    };
  }, [cityFilter, districtFilter, fetchSecimler, neighbourhoodFilter]);

  return (
    context?.me?.customer?.settings?.secimAnalizi && (
      <div className="row g-4 mb-5 pb-5 zoom-item">
        <PageFilter
          setCityFilter={setCityFilter}
          setDistrictFilter={setDistrictFilter}
          setNeighbourhoodFilter={setNeighbourhoodFilter}
          street={false}
          allActive={true}
        />

        <div className="col-12">
          <div className="row g-4">
            <CardBinaHane
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
            />

            <CardMedeniDurum
              // title={context?.t?.sokak_bina_ve_hane_analizleri}
              title={context?.t?.guncel}
              path="choice-analysis"
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={2}
              type={2}
            />

            <CardVatandasSayisi
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={3}
            />

            <CardOlumSayisi
              path="choice-analysis"
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={4}
              type={1}
            />

            <CardHemsehrilikGoc
              path="choice-analysis"
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={5}
            />

            <CardSosyoekonomik
              title={context?.t?.sosyoekonomik_status_dagilimi}
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={6}
            />
          </div>
        </div>

        {/* <div className="col-12 col-xl-6" style={{ height: 700 }}>
          <Card className="h-100">
            <CardBody className="h-100">
              <SecimAnaliziTable2
                cityFilter={cityFilter}
                districtFilter={districtFilter}
                neighbourhoodFilter={neighbourhoodFilter}
                // setSecimAnaliziSandikNoModalData={
                //   setSecimAnaliziSandikNoModalData
                // }
              />
            </CardBody>
          </Card>
        </div> */}

        <div className="col-12">
          <DynamicCard
            fullScreen={true}
            events={false}
            height={700}
            cityFilter={cityFilter}
            data={kusaklarData}
            item={{
              title: context?.t?.kusaklara_gore_secmen_sayisi,
              options: {
                options: {
                  grid: {
                    bottom: "20%",
                  },
                  xAxis: [
                    {
                      data: kusaklarData && kusaklarData[1],
                      type: "category",
                      axisLabel: {
                        rotate: 45,
                        textStyle: {
                          color: context?.theme === "light" ? "#000" : "#fff",
                          fontSize: 10,
                        },
                      },
                    },
                  ],
                  yAxis: [
                    {
                      type: "value",
                      axisLabel: {
                        textStyle: {
                          color: context?.theme === "light" ? "#000" : "#fff",
                        },
                      },
                    },
                  ],
                  series: [
                    {
                      data: kusaklarData && kusaklarData[0],
                      name: null,
                      type: "bar",
                      itemStyle: {
                        color: context?.chartColor,
                      },
                      markPoint: {
                        data: [
                          {
                            name: "Max",
                            type: "max",
                          },
                          {
                            name: "Min",
                            type: "min",
                          },
                        ],
                      },
                    },
                  ],
                  toolbox: {
                    show: true,
                    feature: {
                      restore: {
                        show: false,
                      },
                      magicType: {
                        show: true,
                        type: ["line", "bar"],
                      },
                      saveAsImage: {
                        show: false,
                      },
                    },
                    iconStyle: {
                      borderColor: context?.theme === "light" ? "#000" : "#fff",
                    },
                  },
                  tooltip: {
                    trigger: "axis",
                  },
                  darkMode: window.location.host.includes("datakokpit")
                    ? false
                    : true,
                  visualMap: context?.chartColor
                    ? {
                        min:
                          kusaklarData && kusaklarData[0]
                            ? Math.min(...kusaklarData[0].map((obj) => obj))
                            : 0,
                        max:
                          kusaklarData && kusaklarData[0]
                            ? Math.max(...kusaklarData[0].map((obj) => obj))
                            : 0,
                        show: false,
                        inRange: {
                          colorLightness: [0.2, 1],
                        },
                      }
                    : false,
                  calculable: true,
                },
              },
            }}
          />
        </div>

        {/* <div className="col-6">
          <DynamicCard
            fullScreen={true}
            events={false}
            height={700}
            cityFilter={cityFilter}
            data={siyasalKimlikAnalizData}
            item={{
              title: "SİYASAL KİMLİK ANALİZ SONUÇLARI",
              options: {
                options: {
                  series: [
                    {
                      data: siyasalKimlikAnalizData,
                      name: null,
                      type: "pie",
                      label: {
                        textStyle: {
                          fontSize: 15,
                        },
                      },
                      radius: "50%",
                      itemStyle: {
                        color: context?.chartColor,
                      },
                    },
                  ],
                  tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                      return params.data.name + "<br /> %" + params.data.value;
                    },
                  },
                  darkMode: window.location.host.includes("datakokpit")?false:true,
                  legend: {
                    show: true,
                    orient: "horizontal",
                    left: "center",
                    itemGap: 20,
                    textStyle: {
                      color: context?.theme === "light" ? "#000" : "#fff",
                    },
                  },
                  visualMap: {
                    min:
                      siyasalKimlikAnalizData &&
                      siyasalKimlikAnalizData.length !== 0
                        ? Math.min(
                            ...siyasalKimlikAnalizData.map((obj) => obj.value)
                          )
                        : 0,
                    max:
                      siyasalKimlikAnalizData &&
                      siyasalKimlikAnalizData.length !== 0
                        ? Math.max(
                            ...siyasalKimlikAnalizData.map((obj) => obj.value)
                          )
                        : 0,
                    show: false,
                    inRange: {
                      colorLightness: [0.2, 1],
                    },
                  },
                },
              },
            }}
          />
        </div> */}

        <div className="col-12">
          <div className="row g-4">
            {dataState.map((item, index) => (
              <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={index}>
                <DynamicCard
                  fullScreen={true}
                  events={false}
                  height={600}
                  cityFilter={cityFilter}
                  data={item.data}
                  item={{
                    title: item.title,
                    options: {
                      options: {
                        series: [
                          {
                            data: item.data,
                            name: null,
                            type: "pie",
                            label: {
                              textStyle: {
                                fontSize: 15,
                              },
                            },
                            radius: "50%",
                            itemStyle: {
                              color: context?.chartColor,
                            },
                          },
                        ],
                        tooltip: {
                          trigger: "item",
                        },
                        darkMode: window.location.host.includes("datakokpit")
                          ? false
                          : true,
                        legend: {
                          show: false,
                          orient: "horizontal",
                          left: "center",
                          itemGap: 20,
                          textStyle: {
                            color: context?.theme === "light" ? "#000" : "#fff",
                          },
                        },
                        visualMap: {
                          min:
                            item.data && item.data.length !== 0
                              ? Math.min(...item.data.map((obj) => obj.value))
                              : 0,
                          max:
                            item.data && item.data.length !== 0
                              ? Math.max(...item.data.map((obj) => obj.value))
                              : 0,
                          show: false,
                          inRange: {
                            colorLightness: [0.2, 1],
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            ))}

            {/* <DinamikPage
              removeGrid={true}
              filterPath={`${
                cityFilter ? `?where[]=Il:${cityFilter.label}` : ""
              }${
                districtFilter
                  ? `${cityFilter ? "&" : "?"}where[]=Ilce:${
                      districtFilter.label
                    }`
                  : ""
              }${
                neighbourhoodFilter
                  ? `${districtFilter ? "&" : "?"}where[]=Mahalle:${
                      neighbourhoodFilter.label
                    }`
                  : ""
              }`}
            />

            <DynamicCard
              fullScreen={true}
              events={false}
              height={600}
              cityFilter={cityFilter}
              data={baskanlikSecim2Data}
              buttons={
                <div className="d-flex justify-content-center gap-2">
                  <button
                    className={`btn btn-theme fw-bold ${
                      baskanlikSecim2Tur === 1 ? "" : "opacity-50"
                    }`}
                    onClick={() => setBaskanlikSecim2Tur(1)}
                  >
                    {context?.t?.tur_1}
                  </button>
                  <button
                    className={`btn btn-theme fw-bold ${
                      baskanlikSecim2Tur === 2 ? "" : "opacity-50"
                    }`}
                    onClick={() => setBaskanlikSecim2Tur(2)}
                  >
                    {context?.t?.tur_2}
                  </button>
                </div>
              }
              item={{
                title: `${context?.t?.baskanlik_secimi} - ${baskanlikSecim2Tur}. ${context?.t?.tur}`,
                options: {
                  width: { value: "col-3" },
                  options: {
                    series: [
                      {
                        data: baskanlikSecim2Data,
                        name: null,
                        type: "pie",
                        label: {
                          textStyle: {
                            fontSize: 15,
                          },
                        },
                        radius: "50%",
                        itemStyle: {
                          color: context?.chartColor,
                        },
                      },
                    ],
                    tooltip: {
                      trigger: "item",
                      formatter: function (params) {
                        return (
                          params.data.name + "<br /> %" + params.data.value
                        );
                      },
                    },
                    darkMode: window.location.host.includes("datakokpit")?false:true,
                    legend: {
                      show: true,
                      orient: "horizontal",
                      left: "center",
                      itemGap: 20,
                      textStyle: {
                        color: context?.theme === "light" ? "#000" : "#fff",
                      },
                    },
                    visualMap: context?.chartColor?{
                      min:
                        baskanlikSecim2Data && baskanlikSecim2Data.length !== 0
                          ? Math.min(
                              ...baskanlikSecim2Data.map((obj) => obj.value)
                            )
                          : 0,
                      max:
                        baskanlikSecim2Data && baskanlikSecim2Data.length !== 0
                          ? Math.max(
                              ...baskanlikSecim2Data.map((obj) => obj.value)
                            )
                          : 0,
                      show: false,
                      inRange: {
                        colorLightness: [0.2, 1],
                      },
                    }:false,
                  },
                },
              }}
            /> */}
          </div>
        </div>

        <div
          className="modal modal-cover fade details-modal"
          id="secimAnaliziSandikNoModal"
        >
          <div
            className="modal-dialog modal-fullscreen w-auto p-3"
            style={{
              minHeight: "calc(100% - 50px)",
              height: "calc(100% - 50px)",
            }}
          >
            <div
              className="modal-content rounded py-2 px-3"
              style={{ background: "var(--bs-dark4)" }}
            >
              <div className="modal-header">
                <div className="d-flex align-items-end">
                  <h3 className="modal-title mb-0">
                    {context?.t?.sandik_no}:{" "}
                    {secimAnaliziSandikNoModalData?.alt_kategori_2}
                    <br />
                    {context?.t?.secmen_sayisi}:{" "}
                    {secimAnaliziSandikNoModalData?.deger ||
                      context?.t?.unknown}
                  </h3>
                  <ol className="breadcrumb mb-0 ms-4 mb-2">
                    <li className="breadcrumb-item">
                      {secimAnaliziSandikNoModalData?.ilce ||
                        context?.t?.unknown}
                    </li>
                    <li className="breadcrumb-item">
                      {secimAnaliziSandikNoModalData?.mahalle ||
                        context?.t?.unknown}
                    </li>
                    <li className="breadcrumb-item">
                      {secimAnaliziSandikNoModalData?.cadde_sokak ||
                        context?.t?.unknown}
                    </li>
                  </ol>
                </div>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row align-items-center h-100">
                  <div className="col-12 col-lg-12 overflow-hidden h-100">
                    <DynamicCard
                      events={false}
                      height="h-full"
                      data={chartInfoData1}
                      item={{
                        title: context?.t?.sandik_sonucu,
                        options: {
                          options: {
                            series: [
                              {
                                data: chartInfoData1,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                              formatter: function (params) {
                                return (
                                  params.data.name +
                                  "<br /> %" +
                                  params.data.value
                                );
                              },
                            },
                            darkMode: window.location.host.includes(
                              "datakokpit"
                            )
                              ? false
                              : true,
                            visualMap: context?.chartColor
                              ? {
                                  min:
                                    chartInfoData1 &&
                                    chartInfoData1.length !== 0
                                      ? Math.min(
                                          ...chartInfoData1.map(
                                            (obj) => obj.value
                                          )
                                        )
                                      : 0,
                                  max:
                                    chartInfoData1 &&
                                    chartInfoData1.length !== 0
                                      ? Math.max(
                                          ...chartInfoData1.map(
                                            (obj) => obj.value
                                          )
                                        )
                                      : 0,
                                  show: false,
                                  inRange: {
                                    colorLightness: [0.2, 1],
                                  },
                                }
                              : false,
                          },
                        },
                      }}
                    />
                  </div>

                  {/* <div className="col-12 col-lg-6 overflow-hidden h-100">
                    <DynamicCard
                      events={false}
                      height="h-full"
                      data={chartInfoData2}
                      item={{
                        title: "SİYASAL KİMLİK",
                        options: {
                          options: {
                            series: [
                              {
                                data: chartInfoData2,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                              formatter: function (params) {
                                return (
                                  params.data.name +
                                  "<br /> %" +
                                  params.data.value
                                );
                              },
                            },
                            darkMode: window.location.host.includes("datakokpit")?false:true,
                            visualMap: {
                              min:
                                chartInfoData2 && chartInfoData2.length !== 0
                                  ? Math.min(
                                      ...chartInfoData2.map((obj) => obj.value)
                                    )
                                  : 0,
                              max:
                                chartInfoData2 && chartInfoData2.length !== 0
                                  ? Math.max(
                                      ...chartInfoData2.map((obj) => obj.value)
                                    )
                                  : 0,
                              show: false,
                              inRange: {
                                colorLightness: [0.2, 1],
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div> */}

                  {/* <div className="col-12 col-lg-4 overflow-hidden h-100">
                    <DynamicCard
                      events={false}
                      height="h-full"
                      cityFilter={cityFilter}
                      data={chartInfoData3}
                      item={{
                        title: "SEÇMEN PROFİLİ",
                        options: {
                          options: {
                            series: [
                              {
                                data: chartInfoData3,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                              formatter: function (params) {
                                return (
                                  params.data.name +
                                  "<br /> %" +
                                  params.data.value
                                );
                              },
                            },
                            darkMode: window.location.host.includes("datakokpit")?false:true,
                            visualMap: {
                              min:
                                chartInfoData3 && chartInfoData3.length !== 0
                                  ? Math.min(
                                      ...chartInfoData3.map((obj) => obj.value)
                                    )
                                  : 0,
                              max:
                                chartInfoData3 && chartInfoData3.length !== 0
                                  ? Math.max(
                                      ...chartInfoData3.map((obj) => obj.value)
                                    )
                                  : 0,
                              show: false,
                              inRange: {
                                colorLightness: [0.2, 1],
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default SecimAnalizi;
