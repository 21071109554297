import React, { useContext, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import { Card, CardBody } from "../card/card";

export default function CardOlumSayisi({
  show = true,
  setNeighborhoodDeathBirthData = false,
  path = "home",
  city,
  district,
  neighbourhood,
  street,
  graphic,
  type = 1,
}) {
  const context = useContext(AppSettings);

  const [data, setData] = useState();

  async function getData(dataName) {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Olum Sayısı",
          type: "demograf",
          description: "",
          customer: null,
          status: true,
          order: 1,
          connection_type: "local",
          sql: {
            column: [
              {
                key: "Deger",
                value: "sum",
              },
            ],
            condition: [
              {
                key: "Tarih",
                value: "2022-12-31",
                operator: "=",
                type: "and",
              },
              {
                key: "Alt-Kategori-1",
                value: dataName,
                operator: "=",
                type: "and",
              },
              city
                ? {
                    key: "Sehir",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              // street
              //   ? {
              //       key: "Cadde_Sokak",
              //       value: context
              //         .removeTurkishCharacters(street)
              //         .toUpperCase(),
              //       operator: "like",
              //       type: "and",
              //     }
              //   : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response;
    } catch (error) {
      return [{ deger_sum: 0 }];
    }
  }

  useEffect(() => {
    async function fetchData() {
      setData(null);

      const results = await Promise.all([getData("OLUM"), getData("DOGUM")]);

      setData(results);

      if (setNeighborhoodDeathBirthData) {
        setNeighborhoodDeathBirthData(results);
      }
    }

    // if (city) {
    fetchData();
    // }
  }, [city, district, neighbourhood, street]);

  return (
    show && (
      <div className="col-12 col-md-6 col-lg-4 col-xl">
        <Card className="h-100">
          <CardBody>
            {data !== "filter" ? (
              data ? (
                data[0][0].deger_sum !== 0 && data[1][0].deger_sum !== 0 ? (
                  <>
                    <div className="d-flex fw-bold h6 text-center">
                      <span className="flex-grow-1">{context?.t?.guncel}</span>
                      {/* <CardExpandToggler /> */}
                    </div>

                    <div className="row align-items-center text-center">
                      <div className={"col-12"}>
                        <div className={`lg text-inverse text-opacity-100`}>
                          {type === 1 ? (
                            <>
                              <div>
                                {context?.t?.olum_sayisi}
                                <br />
                                <strong className="h4">
                                  <CountUp
                                    separator="."
                                    end={data[0][0].deger_sum || 0}
                                  />
                                </strong>{" "}
                              </div>
                              <br />
                              <div>
                                {context?.t?.dogum_sayisi}
                                <br />
                                <strong className="h4">
                                  <CountUp
                                    separator="."
                                    end={data[1][0].deger_sum || 0}
                                  />
                                </strong>{" "}
                              </div>
                            </>
                          ) : (
                            type === 2 && (
                              <div>
                                {context?.t?.ilk_defa_oy_sayisi}
                                <br />
                                <strong className="h4">
                                  {/* <CountUp
                                separator="."
                                   end={data[0][1448] || 0} /> */}
                                </strong>{" "}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="d-block text-center">
                    {context?.t?.no_data_available}
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block text-center">
                {context?.t?.il_secmelisiniz}
              </span>
            )}
          </CardBody>
        </Card>

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}
