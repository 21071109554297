import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import siyasetmetreAnalizJson from "../../json/siyasetmetre_analiz.json";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../ToastMessage";

import { Card, CardBody, CardExpandToggler } from "../card/card";

export default function CardHemsehrilikGoc({
  city,
  district,
  neighbourhood,
  street,
  graphic,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [data, setData] = useState();

  async function getData(dataName) {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Hemsehrilik Göç",
          type: dataName,
          description: "",
          customer: null,
          status: true,
          order: 1,
          connection_type: "local",
          sql: {
            column: [
              {
                key: "Alt-Kategori-2",
                value: "select",
              },
              {
                key: "Parametre",
                value: "select",
              },
              {
                key: "Deger",
                value: "sum",
              },
            ],
            condition: [
              {
                key: "Tarih",
                value: "2023-12-31",
                operator: "=",
                type: "and",
              },
              {
                key: "Alt-Kategori-1",
                value: "ILCE BAZINDA HEMSEHRILIK ORANLARI",
                operator: "=",
                type: "and",
              },
              city
                ? {
                    key: "Sehir",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              // neighbourhood
              //   ? {
              //       key: "Mahalle",
              //       value: context
              //         .removeTurkishCharacters(neighbourhood)
              //         .toUpperCase(),
              //       operator: "like",
              //       type: "and",
              //     }
              //   : null,
              // street
              //   ? {
              //       key: "Adres Sokak",
              //       value: context
              //         .removeTurkishCharacters(street)
              //         .toUpperCase(),
              //       operator: "like",
              //       type: "and",
              //     }
              //   : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response;
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    async function fetchData() {
      setData(null);

      const results = await Promise.all([getData(`hemsehri-goc`)]);

      if (results[0].length !== 0) {
        const mappedData = results[0].map((item) => {
          return {
            name: `${item["alt-kategori-2"]} - ${item["parametre"]}`,
            value: item.deger_sum,
          };
        });

        const sortedData = mappedData
          .sort((a, b) => b.value - a.value)
          .slice(0, 4);

        setData(sortedData);
      } else {
        setData([]);
      }
    }

    // if (city) {
    fetchData();
    // }
  }, [city, district]);

  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl">
      <Card className="h-100">
        <CardBody>
          {data !== "filter" ? (
            data ? (
              data.length !== 0 ? (
                <>
                  <div className="d-flex fw-bold h6 mb-3 text-center">
                    <span className="flex-grow-1">
                      {context?.t?.hemsehrilik_ve_goc}
                    </span>
                  </div>

                  <div className="row align-items-center text-center">
                    <div className={"col-12"}>
                      <div className={`lg text-inverse text-opacity-100`}>
                        {data.map((item, index) => (
                          <div key={index} className="mb-2">
                            {item?.name}
                            <br />
                            <strong className="h4">
                              <CountUp
                                separator="."
                                end={parseInt(item?.value) || 0}
                              />
                            </strong>{" "}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* <div className="col-5">
                    <div className="mt-n2">
                      <Chart
                        type="bar"
                        height={100}
                        options={{
                          colors: [themeColor],
                          chart: { sparkline: { enabled: true } },
                          tooltip: {
                            x: { show: false },
                            y: {
                              title: {
                                formatter: function (seriesName) {
                                  return "";
                                },
                              },
                            },
                          },
                        }}
                        series={[
                          {
                            name: "Medeni Durumlar",
                            data: [
                              data?.[1] || 0,
                              data?.[2] || 0,
                              data?.[3] || 0,
                              data?.[4] || 0,
                            ],
                          },
                        ]}
                      />
                    </div>
                  </div> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex fw-bold h6 mb-3 text-center">
                    <span className="flex-grow-1">
                      {context?.t?.hemsehrilik_ve_goc}
                    </span>
                  </div>
                  <span className="d-block text-center">
                    {context?.t?.no_data_available}
                  </span>
                </>
              )
            ) : (
              <div className="spinner-border text-theme"></div>
            )
          ) : (
            <>
              <div className="d-flex fw-bold h6 mb-3 text-center">
                <span className="flex-grow-1">
                  {context?.t?.hemsehrilik_ve_goc}
                </span>
              </div>
              <span className="d-block text-center">
                {context?.t?.il_secmelisiniz}
              </span>
            </>
          )}
        </CardBody>
      </Card>

      <ToastContainer pauseOnFocusLoss={false} />
    </div>
  );
}
