import React, { useState, useEffect } from "react";
import Select from "react-select";
import apiClient from "../../ApiClient";
import toastMessage from "../../components/ToastMessage";

export default function MapAnalysisFilter({
  context,
  districtName,
  cityName,
  setMapPolygonCityData,
  setMapPolygonDistrictData,
  setMapPolygonNeighborhoodData,
  setLoading,
}) {
  const [dateOptions, setDateOptions] = useState(null);
  const [dateValue, setDateValue] = useState(null);

  const [category1Options, setCategory1Options] = useState([]);
  const [category1Value, setCategory1Value] = useState(null);

  const [category2Options, setCategory2Options] = useState([]);
  const [category2Value, setCategory2Value] = useState(null);

  const [category3Options, setCategory3Options] = useState([]);
  const [category3Value, setCategory3Value] = useState(null);

  const [category4Options, setCategory4Options] = useState([]);
  const [category4Value, setCategory4Value] = useState(null);

  const [parameterOptions, setParameterOptions] = useState([]);
  const [parameterValue, setParameterValue] = useState(null);

  async function fetchData(column, condition = []) {
    const options = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `map-analysis`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: column,
            value: "select",
          },
        ],
        condition: condition,
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, options, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return column === "DATE"
        ? response
        : response && response.length !== 0
        ? response[0][column.toLowerCase()] === ""
          ? []
          : response.map((item) => ({
              value: item[column.toLowerCase()],
              label: item[column.toLowerCase()],
            }))
        : [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  useEffect(() => {
    const fetchDateData = async () => {
      const dateOptions = await fetchData("DATE");
      setDateOptions(dateOptions);
    };

    fetchDateData();
  }, []);

  async function handleSelect(column, condition, state) {
    state(null);

    const data = await fetchData(column, condition);
    state(data);
  }

  // Filter
  const [filterLoading, setFilterLoading] = useState(false);

  async function handleFilter(column = "CITY", polygonCondition) {
    if (
      dateValue &&
      dateValue?.value &&
      category1Value &&
      category1Value?.value &&
      parameterValue &&
      parameterValue?.value
    ) {
      const options = {
        chart: 1,
        page_id: 23,
        title: "-",
        type: `map-analysis`,
        description: "",
        customer: null,
        status: true,
        order: 1,
        connection_type: "local",
        sql: {
          column: [
            {
              key: column,
              value: "select",
            },
            {
              key: "VALUE",
              value: "sum",
            },
          ],
          condition: [
            polygonCondition || null,
            {
              key: "DATE",
              value: dateValue?.value,
              operator: "=",
              type: "and",
            },
            {
              key: "SUB-CATEGORY-1",
              value: category1Value?.value,
              operator: "=",
              type: "and",
            },
            category2Value && category2Value?.value
              ? {
                  key: "SUB-CATEGORY-2",
                  value: category2Value?.value,
                  operator: "=",
                  type: "and",
                }
              : null,
            category3Value && category3Value?.value
              ? {
                  key: "SUB-CATEGORY-3",
                  value: category3Value?.value,
                  operator: "=",
                  type: "and",
                }
              : null,
            category4Value && category4Value?.value
              ? {
                  key: "SUB-CATEGORY-4",
                  value: category4Value?.value,
                  operator: "=",
                  type: "and",
                }
              : null,
            {
              key: "PARAMETER",
              value: parameterValue?.value,
              operator: "=",
              type: "and",
            },
          ].filter((item) => item !== null),
          group: [],
        },
      };

      setLoading(true);
      setFilterLoading(true);

      try {
        const response = await apiClient.customPost(`/query/preview`, options, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setLoading(false);
        setFilterLoading(false);

        if (column === "NEIGBOURHOOD") {
          setMapPolygonNeighborhoodData(response);
        }

        if (column === "DISTRICT") {
          setMapPolygonDistrictData(response);
        }

        if (column === "CITY") {
          setMapPolygonCityData(response);
        }
      } catch (error) {
        setLoading(false);
        setFilterLoading(false);
        console.error(error);
      }
    } else {
      toastMessage(context?.t?.map_analysis_filter_warning, "warning");
    }
  }

  // District
  useEffect(() => {
    if (
      dateValue &&
      dateValue?.value &&
      category1Value &&
      category1Value?.value &&
      parameterValue &&
      parameterValue?.value &&
      cityName
    ) {
      handleFilter(
        districtName ? "NEIGBOURHOOD" : cityName ? "DISTRICT" : "CITY",
        districtName
          ? {
              key: "DISTRICT",
              value: districtName,
              operator: "=",
              type: "and",
            }
          : cityName
          ? {
              key: "CITY",
              value: cityName,
              operator: "=",
              type: "and",
            }
          : null
      );
    }
  }, [dateValue, category1Value, parameterValue, districtName, cityName]);

  return (
    <>
      <div>
        <span
          className=" d-block fw-bold mb-1"
          style={{ lineHeight: 1, color: "black" }}
        >
          {context?.t?.year}
        </span>

        <div
          style={{
            minWidth: 180,
          }}
        >
          {dateOptions ? (
            <Select
              options={dateOptions
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((item) => {
                  return {
                    value: item.date,
                    label: item.date.split("-")[0],
                  };
                })}
              className="select-map"
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              placeholder={""}
              value={dateValue}
              onChange={(e) => {
                setDateValue(e);

                handleSelect(
                  "SUB-CATEGORY-1",
                  [{ key: "DATE", value: e.value, operator: "=", type: "and" }],
                  setCategory1Options
                );
              }}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      </div>
      <div>
        <span
          className=" d-block fw-bold mb-1"
          style={{ lineHeight: 1, color: "black" }}
        >
          {context?.t?.category} 1
        </span>

        <div
          style={{
            minWidth: 180,
            width: 180,
          }}
        >
          {category1Options ? (
            <Select
              options={category1Options}
              className="select-map"
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              placeholder={""}
              value={category1Value}
              onChange={(e) => {
                setCategory1Value(e);

                handleSelect(
                  "SUB-CATEGORY-2",
                  [
                    {
                      key: "SUB-CATEGORY-1",
                      value: e.value,
                      operator: "=",
                      type: "and",
                    },
                  ],
                  setCategory2Options
                );
                handleSelect(
                  "PARAMETER",
                  [
                    {
                      key: "SUB-CATEGORY-1",
                      value: e.value,
                      operator: "=",
                      type: "and",
                    },
                  ],
                  setParameterOptions
                );
              }}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      </div>
      <div>
        <span
          className=" d-block fw-bold mb-1"
          style={{ lineHeight: 1, color: "black" }}
        >
          {context?.t?.category} 2
        </span>

        <div
          style={{
            minWidth: 180,
            width: 180,
          }}
        >
          {category2Options ? (
            <Select
              options={category2Options}
              className="select-map"
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              placeholder={""}
              value={category2Value}
              onChange={(e) => {
                setCategory2Value(e);

                handleSelect(
                  "SUB-CATEGORY-3",
                  [
                    {
                      key: "SUB-CATEGORY-2",
                      value: e.value,
                      operator: "=",
                      type: "and",
                    },
                  ],
                  setCategory3Options
                );
                handleSelect(
                  "PARAMETER",
                  [
                    {
                      key: "SUB-CATEGORY-1",
                      value: category1Value.value,
                      operator: "=",
                      type: "and",
                    },
                    {
                      key: "SUB-CATEGORY-2",
                      value: e.value,
                      operator: "=",
                      type: "and",
                    },
                  ],
                  setParameterOptions
                );
              }}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      </div>
      <div>
        <span
          className=" d-block fw-bold mb-1"
          style={{ lineHeight: 1, color: "black" }}
        >
          {context?.t?.category} 3
        </span>

        <div
          style={{
            minWidth: 180,
            width: 180,
          }}
        >
          {category3Options ? (
            <Select
              options={category3Options}
              className="select-map"
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              placeholder={""}
              value={category3Value}
              onChange={(e) => {
                setCategory3Value(e);

                handleSelect(
                  "SUB-CATEGORY-4",
                  [
                    {
                      key: "SUB-CATEGORY-3",
                      value: e.value,
                      operator: "=",
                      type: "and",
                    },
                  ],
                  setCategory4Options
                );

                handleSelect(
                  "PARAMETER",
                  [
                    {
                      key: "SUB-CATEGORY-1",
                      value: category1Value.value,
                      operator: "=",
                      type: "and",
                    },
                    {
                      key: "SUB-CATEGORY-2",
                      value: category2Value.value,
                      operator: "=",
                      type: "and",
                    },
                    {
                      key: "SUB-CATEGORY-3",
                      value: e.value,
                      operator: "=",
                      type: "and",
                    },
                  ],
                  setParameterOptions
                );
              }}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      </div>
      <div>
        <span
          className=" d-block fw-bold mb-1"
          style={{ lineHeight: 1, color: "black" }}
        >
          {context?.t?.category} 4
        </span>

        <div
          style={{
            minWidth: 180,
            width: 180,
          }}
        >
          {category4Options ? (
            <Select
              options={category4Options}
              className="select-map"
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              placeholder={""}
              value={category4Value}
              onChange={(e) => {
                setCategory4Value(e);

                handleSelect(
                  "PARAMETER",
                  [
                    {
                      key: "SUB-CATEGORY-4",
                      value: e.value,
                      operator: "=",
                      type: "and",
                    },
                  ],
                  setParameterOptions
                );

                handleSelect(
                  "PARAMETER",
                  [
                    {
                      key: "SUB-CATEGORY-1",
                      value: category1Value.value,
                      operator: "=",
                      type: "and",
                    },
                    {
                      key: "SUB-CATEGORY-2",
                      value: category2Value.value,
                      operator: "=",
                      type: "and",
                    },
                    {
                      key: "SUB-CATEGORY-3",
                      value: category3Value.value,
                      operator: "=",
                      type: "and",
                    },
                    {
                      key: "SUB-CATEGORY-4",
                      value: e.value,
                      operator: "=",
                      type: "and",
                    },
                  ],
                  setParameterOptions
                );
              }}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      </div>
      <div>
        <span
          className=" d-block fw-bold mb-1"
          style={{ lineHeight: 1, color: "black" }}
        >
          {context?.t?.analiz_sec}
        </span>

        <div
          style={{
            minWidth: 180,
            width: 180,
          }}
        >
          {parameterOptions ? (
            <Select
              options={parameterOptions}
              className="select-map"
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              placeholder={""}
              value={parameterValue}
              onChange={(e) => {
                setParameterValue(e);
              }}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      </div>
      <div className="d-flex gap-1">
        <button
          type="button"
          className="btn btn-theme"
          data-bs-toggle="modal"
          data-bs-target="#importMapAnalysisDataModal"
        >
          <i className="fas fa-fw fa-upload"></i>
        </button>

        <button
          type="button"
          className="btn btn-theme flex-1"
          onClick={() => !filterLoading && handleFilter()}
          disabled={filterLoading}
        >
          {context?.t?.getir}
          {filterLoading && (
            <div className="spinner-border spinner-border-sm ms-1"></div>
          )}
        </button>
      </div>
      <a
        href={`${process.env.PUBLIC_URL}/download/example-map-analysis.csv`}
        className="d-block mt-2"
      >
        {context?.t?.example_file_download}
      </a>
    </>
  );
}
