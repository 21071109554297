import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import Select from "react-select";
import ReactECharts from "echarts-for-react";
import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";
import toastMessage from "../../components/ToastMessage";
import { Card, CardBody, CardExpandToggler } from "../../components/card/card";
import DynamicCountCard from "../../components/DynamicCountCard";
import DynamicNote from "../../components/DynamicNote";
import DynamicMap from "../../components/DynamicMap";
import DynamicTable from "../../components/DynamicTable";
import WordCloud from "../../components/WordCloud";
import DynamicChartItem from "../../components/create-graphic/DynamicChartItem";
import DynamicIframe from "../../components/DynamicIframe";

export default function DynamicPageCard({
  id,
  surveyCard = true,
  todoData,
  todoCategoryData,
  pureTodoData,
  setTodoData,
  ai = false,
  aiData,
  dataLength,
  triggerFetch,
  setTriggerFetch,
  item,
  result = [1],
  chart = false,
  cityFilter,
  data = [1],
  fullScreen = false,
  menu,
  isActive,
  setActiveIndex,
  readText,
  setCardNote,
  setCardName,
  animate = true,
  events = true,
  width = null,
  height = 600,
  buttons = false,
  getChartImages = false,
  allEvents = true,
  dynamicPage = false,
  removeGrid,
  filterPath,
}) {
  const chartRef = useRef(null);
  const chartRefItem = useRef(null);
  const context = useContext(AppSettings);

  const [activeCard, setActiveCard] = useState(false);
  const [cardZIndex, setCardZIndex] = useState(false);
  const [regressionType, setRegressionType] = useState(
    item?.options?.regressionType
  );
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCardZIndex(false); // Dropdown'u kapat
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  ///---

  const [graphicData, setGraphicData] = useState(null);

  const getGraphicData = useCallback(async () => {
    setGraphicData(null);

    const graphicResponse = await apiClient.get(
      `/graphics/${item.id}${filterPath || ""}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (item.chart === 1) {
      const result = graphicResponse.data.result;
      const label0 =
        graphicResponse?.data?.query?.options?.selectedColumn[0]?.label;
      const label1 =
        graphicResponse?.data?.query?.options?.selectedColumn[1]?.label;

      const formattedResult = result.map((entry) => ({
        value: entry[label0],
        name: entry[label1],
      }));

      if (graphicResponse?.data?.query?.options?.options) {
        graphicResponse.data.query.options.options.series[0].data =
          formattedResult;

        const values = formattedResult.map((entry) => entry.value);
        const maxValue = Math.max(...values);
        const minValue = Math.min(...values);

        if (graphicResponse?.data?.query?.options?.options?.visualMap) {
          graphicResponse.data.query.options.options.visualMap.max = maxValue;
          graphicResponse.data.query.options.options.visualMap.min = minValue;
        }
      }
    }

    setGraphicData(graphicResponse.data);
  }, [filterPath, item]);

  useEffect(() => {
    getGraphicData();
  }, [getGraphicData]);

  ///---

  const [tableView, setTableView] = useState(null);

  function handleTableView(tableData) {
    setTableView(tableData);
  }

  ///---

  const toggleActiveCard = () => {
    setActiveCard(!activeCard);
    readText(item.id);
  };

  useEffect(() => {
    if (isActive && item?.description) {
      window.speechSynthesis.cancel();

      const msg = new SpeechSynthesisUtterance();
      msg.text = item.description;
      msg.lang = "tr-TR";
      msg.volume = 1;
      msg.rate = 0.8;
      msg.pitch = 0.8;

      msg.onend = () => {
        setActiveCard(false);
        setActiveIndex(null);
      };

      window.speechSynthesis.speak(msg);
    } else {
      window.speechSynthesis.cancel();
    }
  }, [isActive, item?.description]);

  // Map Preview
  const [mapData, setMapData] = useState(null);

  async function mapPreview() {
    setMapData(null);

    const data = {
      chart: item.chart,
      page_id: item.page_id,
      title: item.title,
      type: item.type,
      description: item.description,
      status: item.status,
      customer: item.customer_id,
      order: item.order,
      connection_type: item.connection_type,
      sql: {
        column: item.payload.column,
        condition: item.payload.condition,
        group: item.payload.group,
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setMapData(response);
      }
    } catch (error) {
      setMapData([]);
      console.error(error);
    }
  }

  useEffect(() => {
    if (item.chart === 5) {
      mapPreview();
    }
  }, [item]);

  // Handle Sort
  const [graphicSort, setGraphicSort] = useState(item?.order);

  const handleCardSort = async (type) => {
    let newOrder = graphicSort;

    if (type === "up") {
      newOrder++;
    } else if (type === "down" && newOrder > 1) {
      newOrder--;
    }

    setGraphicSort(newOrder);

    try {
      const response = await apiClient.put(
        "/query/" + item?.id,
        {
          order: newOrder,
          description: item.description,
          connection_type: item.connection_type,
          sql: {
            column: item.payload.column,
            condition: item.payload.condition,
            group: item.payload.group,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        // toastMessage(context?.t?.grafik_guncelleniyor, "success");
        // setTriggerFetch(!triggerFetch);
        window.location.reload();
      }
    } catch (error) {
      console.error("Note Add Error", error);
    }
  };

  // Handle Width
  const [graphicWidth, setGraphicWidth] = useState(item?.options?.width?.value);

  const handleCardWidth = async (colNumber) => {
    setGraphicWidth("col-" + colNumber);

    const { id, ...payload } = item;
    payload.options = {
      ...payload.options,
      width: { value: "col-" + colNumber, label: colNumber + "/12" },
    };
    payload.sql = {
      column: item.payload.column,
      condition: item.payload.condition,
      group: item.payload.group,
    };

    try {
      const response = await apiClient.put("/query/" + item?.id, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        toastMessage(context?.t?.grafik_guncelleniyor, "success");
      }
    } catch (error) {
      console.error("Note Add Error", error);
    }
  };

  // Handle Height
  const [graphicHeight, setGraphicHeight] = useState(item?.options?.height);

  const handleCardHeight = async (number) => {
    setGraphicHeight(number);

    const { id, ...payload } = item;
    payload.options = {
      ...payload.options,
      height: number,
    };
    payload.sql = {
      column: item.payload.column,
      condition: item.payload.condition,
      group: item.payload.group,
    };

    try {
      const response = await apiClient.put("/query/" + item?.id, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        toastMessage(context?.t?.grafik_guncelleniyor, "success");
      }
    } catch (error) {
      console.error("Note Add Error", error);
    }
  };

  // Handle sorting
  const [sortedData, setSortedData] = useState([]);
  const [isAscending, setIsAscending] = useState(false);

  const handleSort = () => {
    if (!sortedData || !sortedData.length || !item?.options?.selectedColumn[0])
      return;

    const sorted = [...sortedData].sort((a, b) => {
      const valueA = a[item?.options?.selectedColumn[0].value];
      const valueB = b[item?.options?.selectedColumn[0].value];
      return isAscending ? valueA - valueB : valueB - valueA;
    });

    setSortedData(sorted);
    setIsAscending(!isAscending); // Toggle sorting order
  };

  useEffect(() => {
    if (
      item &&
      item.chart === 12 &&
      graphicData &&
      graphicData?.result.length !== 0 &&
      item?.options?.selectedColumn[0]
    ) {
      const sorted = [...graphicData?.result].sort((a, b) => {
        const valueA = a[item?.options?.selectedColumn[0].value];
        const valueB = b[item?.options?.selectedColumn[0].value];
        return valueA - valueB;
      });

      setSortedData(sorted);
    }
  }, [graphicData, item]);

  return (
    <div
      ref={dropdownRef}
      className={`graphic-item ${
        !dynamicPage
          ? graphicWidth
            ? "col-12 col-xl-" + graphicWidth.split("col-")[1]
            : "col-12"
          : removeGrid
          ? graphicWidth
            ? "col-12 col-xl-" + graphicWidth.split("col-")[1]
            : "col-12"
          : ""
      }`}
      style={
        !dynamicPage
          ? {
              height: height === "h-full" ? "100%" : "auto",
            }
          : {
              gridColumn: `span ${
                graphicWidth ? graphicWidth.split("col-")[1] : 12
              }`,
              gridRow: `span ${graphicHeight}`,
              zIndex: cardZIndex ? 1 : "initial",
            }
      }
    >
      <Card className="h-100">
        <CardBody
          style={{
            zIndex: 0,
            width: width || "auto",
            height: graphicHeight
              ? 100 * parseInt(graphicHeight) + 10
              : height === "h-full"
              ? "100%"
              : height,
          }}
        >
          <div className="d-flex flex-column h-100">
            {item.title && (
              <div
                className={`d-flex align-items-center justify-content-${
                  allEvents ? "between" : "center"
                } border-bottom pb-2 mb-3`}
              >
                <span className={`fw-bold h6 mb-0 flex-1 text-center`}>
                  {item?.title?.toUpperCase()}
                </span>

                {allEvents && (
                  <div className="d-flex align-items-center ms-2">
                    {ai &&
                      graphicData &&
                      graphicData?.result.length !== 0 &&
                      item?.chart !== 12 &&
                      item?.chart !== 10 &&
                      item?.chart !== 9 &&
                      item?.chart !== 5 &&
                      item?.chart !== 4 && (
                        <div className="me-2 pe-2 border-end">
                          <button
                            onClick={() =>
                              context?.setAiData({
                                id: id,
                                title: item.title,
                                data:
                                  graphicData?.query?.chart === 10
                                    ? graphicData?.query?.options?.noteValue
                                    : graphicData?.result,
                              })
                            }
                            data-bs-toggle="modal"
                            data-bs-target="#aiModal"
                            type="button"
                            className="btn text-theme px-0 py-0"
                          >
                            <svg
                              style={{ width: 28, height: 28 }}
                              viewBox="0 0 512 512"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  fill="currentColor"
                                  transform="translate(64.000000, 64.000000)"
                                >
                                  <path d="M320,64 L320,320 L64,320 L64,64 L320,64 Z M171.749388,128 L146.817842,128 L99.4840387,256 L121.976629,256 L130.913039,230.977 L187.575039,230.977 L196.319607,256 L220.167172,256 L171.749388,128 Z M260.093778,128 L237.691519,128 L237.691519,256 L260.093778,256 L260.093778,128 Z M159.094727,149.47526 L181.409039,213.333 L137.135039,213.333 L159.094727,149.47526 Z M341.333333,256 L384,256 L384,298.666667 L341.333333,298.666667 L341.333333,256 Z M85.3333333,341.333333 L128,341.333333 L128,384 L85.3333333,384 L85.3333333,341.333333 Z M170.666667,341.333333 L213.333333,341.333333 L213.333333,384 L170.666667,384 L170.666667,341.333333 Z M85.3333333,0 L128,0 L128,42.6666667 L85.3333333,42.6666667 L85.3333333,0 Z M256,341.333333 L298.666667,341.333333 L298.666667,384 L256,384 L256,341.333333 Z M170.666667,0 L213.333333,0 L213.333333,42.6666667 L170.666667,42.6666667 L170.666667,0 Z M256,0 L298.666667,0 L298.666667,42.6666667 L256,42.6666667 L256,0 Z M341.333333,170.666667 L384,170.666667 L384,213.333333 L341.333333,213.333333 L341.333333,170.666667 Z M0,256 L42.6666667,256 L42.6666667,298.666667 L0,298.666667 L0,256 Z M341.333333,85.3333333 L384,85.3333333 L384,128 L341.333333,128 L341.333333,85.3333333 Z M0,170.666667 L42.6666667,170.666667 L42.6666667,213.333333 L0,213.333333 L0,170.666667 Z M0,85.3333333 L42.6666667,85.3333333 L42.6666667,128 L0,128 L0,85.3333333 Z"></path>
                                </g>
                              </g>
                            </svg>
                          </button>
                        </div>
                      )}

                    {ai &&
                      graphicData &&
                      graphicData?.result.length !== 0 &&
                      item?.chart !== 12 &&
                      item?.chart !== 10 &&
                      item?.chart !== 9 &&
                      item?.chart !== 5 &&
                      item?.chart !== 4 && (
                        <div className="me-2 pe-2 border-end">
                          <button
                            type="button"
                            className="btn text-theme px-0 py-0"
                            onClick={() =>
                              tableView
                                ? setTableView(null)
                                : handleTableView(graphicData?.result)
                            }
                          >
                            <i className="fas fa-lg fa-fw fa-table"></i>
                          </button>
                        </div>
                      )}

                    {events &&
                      ((context?.me?.role?.id !== 1 &&
                        menu?.page_type !== "default" &&
                        item?.customer_id) ||
                        context?.me?.role?.id === 1) && (
                        <div
                          className="me-2 pe-2 border-end graphic-events"
                          onClick={() => setCardZIndex(!cardZIndex)}
                          // onMouseLeave={handleMouseLeave}
                        >
                          <div className="text-theme">
                            <i className="fa-lg fas fa-cog"></i>
                          </div>

                          {cardZIndex && (
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="graphic-events-dropdown"
                              style={{ width: 180 }}
                            >
                              <span
                                className="block"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {item?.type}
                              </span>

                              <hr className="my-2" />

                              <div className="d-flex gap-2">
                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createGraphicModal"
                                  className="btn btn-outline-theme btn-sm flex-1 gap-2 d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    context.setCreateGraphicOpen(true);
                                    context.setGraphicUpdate(item);
                                  }}
                                >
                                  {context?.t?.edit}
                                </button>

                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#graphicDeleteModal"
                                  className="btn btn-danger btn-sm flex-1 gap-2 d-flex justify-content-center align-items-center"
                                  onClick={() => context.setGraphicDelete(item)}
                                >
                                  {context?.t?.remove}
                                </button>
                              </div>

                              <hr className="my-2" />

                              {setCardNote &&
                                item.description &&
                                item.description !== "" && (
                                  <>
                                    <div className="d-flex gap-2">
                                      <button
                                        type="button"
                                        className="btn flex-1 btn-outline-theme btn-sm"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#pageCardNoteModal_${menu}`}
                                        onClick={() => {
                                          setCardName(item.title);
                                          setCardNote(item.description);
                                        }}
                                      >
                                        {context?.t?.not_oku}
                                      </button>

                                      <button
                                        type="button"
                                        className="btn flex-1 btn-outline-theme btn-sm"
                                        onClick={toggleActiveCard}
                                      >
                                        {isActive
                                          ? context?.t?.durdur
                                          : context?.t?.not_dinle}
                                      </button>
                                    </div>
                                  </>
                                )}

                              <hr className="my-2" />

                              <div className="d-flex gap-2">
                                <button
                                  type="button"
                                  className="btn btn-outline-theme btn-sm flex-1 gap-2 d-flex justify-content-center align-items-center"
                                  onClick={() => handleCardSort("down")}
                                >
                                  {context?.t?.up}
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-outline-theme btn-sm flex-1 gap-2 d-flex justify-content-center align-items-center"
                                  onClick={() => handleCardSort("up")}
                                >
                                  {context?.t?.down}
                                </button>
                              </div>

                              <hr className="my-2" />

                              <span
                                style={{ fontSize: 12 }}
                                className="mb-1 d-block"
                              >
                                {context?.t?.width}
                              </span>

                              <div className="d-flex flex-wrap gap-1">
                                {Array.from({ length: 11 }, (_, i) => {
                                  const colNumber = 12 - i;
                                  return (
                                    <div
                                      className={`${
                                        colNumber ==
                                        graphicWidth.split("col-")[1]
                                          ? "btn-theme text-black"
                                          : "btn-dark"
                                      } btn btn-sm py-0 px-1 d-flex align-items-center justify-content-center`}
                                      onClick={() => handleCardWidth(colNumber)}
                                    >
                                      {colNumber}/12
                                    </div>
                                  );
                                })}
                              </div>

                              <hr className="my-2" />

                              <span
                                style={{ fontSize: 12 }}
                                className="mb-1 d-block"
                              >
                                {context?.t?.height}
                              </span>

                              <input
                                type="number"
                                value={graphicHeight}
                                min={1}
                                className="form-control"
                                onChange={(e) =>
                                  handleCardHeight(e.target.value)
                                }
                              />

                              {regressionType && (
                                <>
                                  <hr className="my-2" />

                                  <div className="w-100">
                                    <span
                                      style={{ fontSize: 12 }}
                                      className="mb-1 d-block"
                                    >
                                      {context?.t?.regresyon_turu}
                                    </span>

                                    <Select
                                      options={[
                                        {
                                          value: "ARIMA",
                                          label: "ARIMA",
                                        },
                                        {
                                          value: "ETS",
                                          label: "ETS",
                                        },
                                        {
                                          value: "TBATS",
                                          label: "TBATS",
                                        },
                                        {
                                          value: "STLF",
                                          label: "STLF",
                                        },
                                      ]}
                                      placeholder=""
                                      classNamePrefix="react-select"
                                      noOptionsMessage={() =>
                                        context?.t?.no_option
                                      }
                                      value={regressionType}
                                      onChange={(e) => {
                                        setCardZIndex(null);
                                        setRegressionType(e);
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )}

                    {fullScreen && <CardExpandToggler />}

                    {events && (
                      <div
                        className="position-absolute top-0 end-0"
                        style={{
                          fontSize: 10,
                          pointerEvents: "none",
                          padding: 2,
                        }}
                      >
                        #{item.order}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <div
              className="position-relative overflow-hidden flex-1 d-flex flex-column"
              ref={chartRefItem}
            >
              {!chart ? (
                graphicData ? (
                  item?.chart === 12 ? (
                    <DynamicCountCard
                      data={sortedData}
                      previewSelectValue={item?.options?.selectedColumn[0]}
                      previewSelectLabel={item?.options?.selectedColumn[1]}
                    />
                  ) : item?.chart === 18 ? (
                    <DynamicIframe value={item?.options?.iframeValue} />
                  ) : item?.chart === 10 && graphicData?.result.length === 0 ? (
                    <DynamicNote
                      value={item?.options?.noteValue}
                      setValue={null}
                    />
                  ) : item?.chart === 5 && graphicData?.result.length === 0 ? (
                    <DynamicMap
                      data={mapData}
                      location={item?.options?.mapLocation}
                      selectedColumnValue={item?.options?.selectedColumn[0]}
                      selectedColumnLabel={item?.options?.selectedColumn[1]}
                      tableColumn={item.payload.column}
                      todoData={todoData}
                      todoCategoryData={todoCategoryData}
                      pureTodoData={pureTodoData}
                      setTodoData={setTodoData}
                      context={context}
                    />
                  ) : graphicData?.result.length !== 0 ? (
                    item?.options &&
                    item?.options?.options &&
                    item?.options?.options.length !== 0 ? (
                      tableView ? (
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              {tableView
                                .filter((item) =>
                                  Object.values(item).every(
                                    (value) =>
                                      value !== null &&
                                      value !== undefined &&
                                      value !== ""
                                  )
                                )
                                .map((item, index) => (
                                  <tr key={index}>
                                    {Object.values(item).map((value, idx) => (
                                      <td key={idx}>{value}</td>
                                    ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <>
                          {item?.chart === 5 ? (
                            <DynamicMap
                              data={mapData}
                              location={item?.options?.mapLocation}
                              selectedColumnValue={
                                item?.options?.selectedColumn
                                  ? item?.options?.selectedColumn[0]
                                  : null
                              }
                              selectedColumnLabel={
                                item?.options?.selectedColumn
                                  ? item?.options?.selectedColumn[1]
                                  : null
                              }
                              tableColumn={item.payload.column}
                              todoData={todoData}
                              todoCategoryData={todoCategoryData}
                              pureTodoData={pureTodoData}
                              setTodoData={setTodoData}
                              context={context}
                            />
                          ) : item?.chart === 4 ? (
                            <DynamicTable
                              data={item}
                              tableColumn={item.payload.column}
                            />
                          ) : item?.chart === 3 ? (
                            surveyCard ? (
                              <WordCloud
                                height={height}
                                animate={animate}
                                data={item?.options?.options}
                                surveyCard={surveyCard}
                              />
                            ) : (
                              <WordCloud
                                height={height}
                                animate={animate}
                                data={graphicData?.result}
                                previewSelectValue={
                                  item?.options?.selectedColumn
                                    ? item?.options?.selectedColumn[0]
                                    : null
                                }
                                previewSelectLabel={
                                  item?.options?.selectedColumn
                                    ? item?.options?.selectedColumn[1]
                                    : null
                                }
                                previewSelectWordCloud={
                                  item?.options?.selectedColumn
                                    ? item?.options?.selectedColumn[2]
                                    : null
                                }
                              />
                            )
                          ) : (
                            <div className="h-100">
                              <div className="flex-1 h-100">
                                {surveyCard ? (
                                  <ReactECharts
                                    ref={chartRef}
                                    option={
                                      item?.chart === 11 ||
                                      item?.chart === 13 ||
                                      item?.chart === 14 ||
                                      item?.chart === 15 ||
                                      item?.chart === 16
                                        ? item?.options?.options
                                        : item?.chart === 9
                                        ? item?.options?.options
                                        : item?.options?.options?.baseOption
                                        ? item?.options?.options
                                        : {
                                            ...item?.options?.options,
                                            visualMap: !context?.chartColor
                                              ? false
                                              : item?.options?.options
                                                  .visualMap,
                                            series: [
                                              {
                                                ...item?.options?.options
                                                  .series[0],
                                                animation: getChartImages
                                                  ? false
                                                  : true,
                                                itemStyle: {
                                                  color: context?.chartColor,
                                                },
                                              },
                                            ],
                                          }
                                    }
                                    className="flex-1 h-100"
                                  />
                                ) : item?.payload?.column ? (
                                  <DynamicChartItem
                                    refItem={chartRef}
                                    previewData={graphicData?.result}
                                    dataSortValue={item?.options?.dataSortValue}
                                    dataSortLabel={item?.options?.dataSortLabel}
                                    regressionFrequency={
                                      item?.options?.regressionFrequency
                                    }
                                    regressionType={regressionType}
                                    regressionCount={
                                      item?.options?.regressionCount
                                    }
                                    previewSelectValue={
                                      item?.options?.selectedColumn
                                        ? item?.options?.selectedColumn[0]
                                        : null
                                    }
                                    previewSelectLabel={
                                      item?.options?.selectedColumn
                                        ? item?.options?.selectedColumn[1]
                                        : null
                                    }
                                    previewSelectDate={
                                      item?.options?.selectedColumn
                                        ? item?.options?.selectedColumn[3]
                                        : null
                                    }
                                    chartView={{ value: item?.chart }}
                                  />
                                ) : (
                                  context?.t?.columnNotFound
                                )}
                              </div>

                              {buttons}
                            </div>
                          )}
                        </>
                      )
                    ) : (
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <small className="mb-2 d-block">
                          {context?.t?.grafik_eklenmedi}
                        </small>
                      </div>
                    )
                  ) : (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <span className="d-block text-center">
                        {context?.t?.no_data_available}
                      </span>
                    </div>
                  )
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="spinner-border text-theme"></div>
                  </div>
                )
              ) : (
                chart
              )}
            </div>

            <div className="d-flex align-items-center justify-content-between">
              {item &&
                item?.chart === 12 &&
                item?.options?.selectedColumn[1] &&
                sortedData &&
                sortedData.length > 1 && (
                  <button
                    onClick={() => handleSort()}
                    className="btn btn-outline-theme btn-sm"
                  >
                    {isAscending ? (
                      <i className="fas fa-sort-numeric-down" />
                    ) : (
                      <i className="fas fa-sort-numeric-up" />
                    )}
                  </button>
                )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
