const fr = {
    "baslik_sirala": "Trier par Titre",
    "deger_sirala": "Trier par Valeur",
    "buyukten_kucuge": "Décroissant",
    "kucukten_buyuge": "Croissant",

    iframe_kod_link: "Code Iframe / Lien",
    "sokak_basina_ortalama_hane_sayisi": "Nombre Moyen de Ménages par Rue",
    "sokak_basina_ortalama_ozel_is_yeri_sayisi": "Nombre Moyen d'Entreprises Privées par Rue",

    map_analysis_filter_warning: "Vous devez sélectionner au moins Année, Catégorie 1 et Analyse.",

    cevaplari_filtrele: "Filtrer les réponses",
    proje_veritabanim: "Base de données du projet",
    benim_veritabanim: "Ma base de données",

    "veritabani_baglantisi": "Connexion à la base de données",
    "veritabani_adi": "Nom de la base de données",
    "host_adresi": "Adresse de l'hôte",
    "port_numarasi": "Numéro de port",

    grafik_guncelleniyor: "Le graphique est en cours de mise à jour",

    choose_separator: "Vous devez sélectionner un séparateur.",
    cevap_sec: "Choisissez une réponse",

    clear_file_text: "Supprimez les caractères décoratifs du texte dans le fichier.",
    tema_rengi: "Couleur du Thème",
    acik: "Clair",
    koyu: "Foncé",

    "yillik": "Annuel",
    "aylik": "Mensuel",
    "gunluk": "Quotidien",
    "siklik": "Fréquence",
    "regresyon_turu": "Type de Régression",

    tahmin_olcegi: "Échelle de Prévision",
    cizgi_grafigi: "Graphique en Ligne",
    "strateji_planlari": "Plans stratégiques",
    "gorevler": "Tâches",

    hatali_satir_bulunamadi: "Aucune ligne erronée trouvée",
    hata_var_mi: "Y a-t-il une erreur ?",
    departman_sayfalari: "Pages de Département",
    public_relations: "Rédacteur",
    departmana_olustur: "Créer un Département",
    role_for_group: "Département/Équipe",

    bolge_bulunamadi: "Région introuvable.",
    coklu_alan_grafigi_dikey: "Graphique à zones multiples",
    coklu_cizgi_grafigi_dikey: "Graphique à lignes multiples",
    coklu_cubuk_grafigi_dikey: "Graphique à barres multiples vertical",
    coklu_cubuk_grafigi_yatay: "Graphique à barres multiples horizontal",

    hiyerarsik_yapi: "Structure hiérarchique",
    tum_okullarin_konumlari: "Emplacements de Toutes les Écoles",
    okul_adi: "Nom de l'École",
    devlet_ozel: "Public/Privé",
    ogrenci_sayisi: "Nombre d'Étudiants",
    ogretmen_sayisi: "Nombre d'Enseignants",
    ogretmen_basina_ogrenci_sayisi: "Étudiants par Enseignant",
    derslik: "Salle de Classe",
    ogrenci_basina_derslik_sayisi: "Étudiants par Salle",
    kutuphane_kitap_sayisi: "Nombre de Livres en Bibliothèque",
    konferans_salon_sayisi: "Nombre de Salles de Conférence",

    okullar: "Écoles",
    width: "Largeur",
    height: "Hauteur",
    bilgi_karti: "Carte d'information",
    map_import_data_info_message: 'Pour afficher les emplacements, définissez les noms de colonnes sur <strong>« Latitude »</strong> (Latitude) et <strong>« Longitude »</strong> (Longitude).',
    coklu_cubuk_grafigi: "Graphique à barres multiples",

    afet_title: "Statistiques des permis d'utilisation de bâtiment",
    afet_chart_1_title: "Nombre d'étages",
    afet_chart_2_title: "Propriétaire du bâtiment",
    afet_chart_3_title: "Description du système porteur 1",
    afet_chart_4_title: "Description du système porteur 2",
    afet_chart_5_title: "Description du système porteur 3",
    afet_chart_6_title: "Système de chauffage",
    afet_chart_7_title: "Nombre de bâtiments",
    afet_chart_8_title: "Nombre d'appartements",
    afet_chart_9_title: "Superficie (m2)",

    analiz: "Analyse",
    veri_girisi: "Saisie de Données",
    bos_birakmayin: "Ne Laissez Pas Vide",
    sms_default_message_1: "Votre numéro de téléphone a été attribué comme votre mot de passe à usage unique. Vous pouvez accéder à notre enquête via le lien ci-dessous et partager vos avis. Vos opinions sont très précieuses pour nous.",
    example_data: "Données d'exemple",
    dosya_olustur: "Créer un fichier",
    sms_confirm: "Un SMS sera envoyé à tous les citoyens figurant dans le tableau. Confirmez-vous ?",

    bu_anket_kullanicilara_gonderildi: "Ce sondage a été envoyé aux utilisateurs",
    bu_anket_sms_olarak_gonderildi: "Ce sondage a été envoyé par SMS",
    bu_anket_data_once_gonderildi: "Ce sondage a déjà été envoyé",

    kullanicilara_gonder: "Envoyer aux utilisateurs",
    sms_gonder: "Envoyer un SMS",
    yetkiniz_yoktur: "Vous n'avez pas l'autorisation",
    zaman_grafigi: "Graphique Temporel",
    tum_saglik_tesisleri_konumlari: "Emplacements de tous les établissements de santé",
    il_ve_ilce_ve_mahalle_secmelisiniz: "Vous devez sélectionner une province, un district et un quartier.",
    siyaset_parti: "Parti",
    anket_aciklama: "Description de l'enquête",
    yukle_dosya_sutun_uyari: "Les en-têtes de colonnes dans le fichier que vous avez téléchargé ne doivent pas contenir de '_' ni de caractères turcs, et ne doivent pas commencer par un chiffre. Veuillez vous assurer que votre fichier respecte ces règles avant de le télécharger.",
    eklenmis_bir_soru_bulunamadi: "Aucune question ajoutée trouvée.",
    hazir_anketler: "Enquêtes Prêtes",
    analiz_sec: "Sélectionner l'analyse",
    gorev_kategorileri: "Catégories de Tâches",
    "aciklama_goster": "Afficher la description",
    "aciklama_gizle": "Masquer la description",
    gorevleri_sadece_olusturan_kisi_silebilir: "Seule la personne qui a créé les tâches peut les supprimer.",

    olusturulma: "Date de création",
    guncelleme: "Date de mise à jour",

    menu_rengi: "Couleur du Menu",
    site_rengi: "Couleur du Site",
    grafik_rengi: "Couleur du Graphique",

    "deprem": "TREMBLEMENT DE TERRE",
    "risk_arastirmasi": "RECHERCHE SUR LES RISQUES",
    "diri_fay_hatti_yakinligi": "PROXIMITÉ À LA LIGNE DE FAILLE ACTIVE",
    "deprem_risk_derece_seviyesi": "NIVEAU DE RISQUE SISMIQUE",
    "yerlesim_yeri_envanteri": "INVENTAIRE DES HABITATIONS",
    "yapim_yilina_gore_bina_sayisi": "NOMBRE DE BÂTIMENTS PAR ANNÉE DE CONSTRUCTION",
    "kat_sayisina_gore_bina_sayisi": "NOMBRE DE BÂTIMENTS PAR NOMBRE D'ÉTAGES",
    "yapim_turune_gore_bina_sayisi": "NOMBRE DE BÂTIMENTS PAR TYPE DE CONSTRUCTION",

    rapor_basariyla_olusturuldu: "Le rapport a été créé avec succès.",
    ondalik_sayi: "Nombre décimal",

    "ileri_analiz": "Analyse Avancée",
    "genel_gorunum": "Vue Générale",
    "anket_ve_arastirmalar": "Enquêtes et Recherches",
    "ozel_sayfalar": "Pages Spéciales",
    "analiz_icin_iletisime_gecin": "Contactez pour l'Analyse",

    "ocak": "Janvier",
    "subat": "Février",
    "mart": "Mars",
    "nisan": "Avril",
    "mayis": "Mai",
    "haziran": "Juin",
    "temmuz": "Juillet",
    "agustos": "Août",
    "eylul": "Septembre",
    "ekim": "Octobre",
    "kasim": "Novembre",
    "aralik": "Décembre",

    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2024": "ÉLECTIONS MUNICIPALES DU MAIRE 2024",
    "BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2024": "ÉLECTIONS MUNICIPALES DU MAIRE DE LA MÉTROPOLE 2024",
    "BASKANLIK_SECIMI_2_TUR_1": "ÉLECTIONS PRÉSIDENTIELLES 2023, 1ER TOUR",
    "YEREL_SECIMLERI_2019": "ÉLECTIONS LOCALES 2019",
    "CUMHURBASKANLIGI_SECIMLERI_2018": "ÉLECTIONS PRÉSIDENTIELLES 2018",
    "MILLETVEKILLIGI_SECIMLERI_2018": "ÉLECTIONS GÉNÉRALES PARLEMENTAIRES 2018",
    "HALK_OYLAMASI_2017": "RÉFÉRENDUM 2017",
    "MILLETVEKILLIGI_SECIMLERI_2015": "ÉLECTIONS GÉNÉRALES PARLEMENTAIRES 2015",
    "BELEDIYE_BASKANLIGI_BUYUKSEHIR_SECIMI_2014": "ÉLECTIONS DU MAIRE DE LA MÉTROPOLE 2014",

    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2009": "ÉLECTIONS LOCALES DE LA MAIRIE 2009",
    "DONEM_MILLETVEKILLIGI_GENEL_SECIMI_24": "24ème ÉLECTION GÉNÉRALE DES DÉPUTÉS",
    "BELEDIYE_BASKANLIGI_SECIMI_2014": "ÉLECTION DE LA MAIRIE 2014",
    "CUMHURBASKANLIGI_SECIMI_12": "12ème ÉLECTION PRÉSIDENTIELLE",
    "DONEM_MILLETVEKILLIGI_SECIMLERI_25": "25ème ÉLECTION GÉNÉRALE DES DÉPUTÉS",
    "MILLETVEKILLIGI_GENEL_SECIMLERI_26": "26ème ÉLECTION GÉNÉRALE DES DÉPUTÉS",
    "BASKANLIK_SECIMI_1": "1ère ÉLECTION PRÉSIDENTIELLE",
    "DONEM_MILLETVEKILLIGI_GENEL_SECIMLERI_27": "27ème ÉLECTION GÉNÉRALE DES DÉPUTÉS",
    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2019": "ÉLECTIONS LOCALES DE LA MAIRIE 2019",
    "YENILENEN_ISTANBUL_SECIMI_2019": "ÉLECTION RÉINSTITUÉE D'ISTANBUL 2019",
    "DONEM_MILLETVEKILI_GENEL_SECIMLERI_28": "28ème ÉLECTION GÉNÉRALE DES DÉPUTÉS",
    "BASKANLIK_SECIMI_2_TUR_2": "ÉLECTIONS PRÉSIDENTIELLES 2023, 2ER TOUR",

    "siyasal_kimlik_analiz_sonuclari": "RÉSULTATS DE L'ANALYSE DE L'IDENTITÉ POLITIQUE",
    "baskanlik_secimi_ve_sokaga_gore_sandik_basina_birey_sayisi_tablosu": "TABLEAU DU NOMBRE D'INDIVIDUS PAR URNE SELON L'ÉLECTION PRÉSIDENTIELLE ET LE QUARTIER",
    "yeni_secmen": "Nouvel Électeur",
    "birey": "INDIVIDU",
    "mahallede_son_yil_itibariyle_toplam_sayisi": "Nombre total dans le quartier à la dernière année",
    "birey_bulunmaktadir": "Un individu est présent.",
    "hane_bulunmaktadir": "Un foyer est présent.",
    "sokak_bulunmaktadir": "Une rue est présente.",
    "bina_bulunmaktadir": "Un bâtiment est présent.",
    "gorev_mevcut_degil": "Aucune tâche disponible",
    "mahallede_bulunan_sendikalar": "Syndicats présents dans le quartier",
    "mahallede_bulunan_dernekler": "Associations présentes dans le quartier",
    "secim_analiz_raporu": "RAPPORT D'ANALYSE DES ÉLECTIONS",
    "medeni_durum_analizi": "ANALYSE DE L'ÉTAT CIVIL",
    "downloading_text": "Téléchargement en cours...",
    "yas_grubuna_gore_birey_durumu": "État des individus selon la tranche d'âge",
    "egitim_durumu": "ÉTAT ÉDUCATIF",
    "mahallede_ortalama_secmen_yasi": "Âge moyen des électeurs dans le quartier",
    "bulunmaktadir": "est présent.",
    "strateji_merkezi_mahalle_raporu": "Rapport de quartier du Centre de Stratégie",
    "strateji_merkezi_sokak_raporu": "Rapport de rue du Centre de Stratégie",
    "anket_cevaplanmamis": "Sondage non répondu",
    "cevap_silindi": "Réponse supprimée",
    "pin_filtre": "Filtre épingle",
    "arastirma_cevaplari": "Réponses à l'enquête",
    "bu_cevabi_sil": "Supprimer cette réponse",
    "telefon_kontrol_edildi": "Téléphone vérifié",
    "haritayi_gizle": "Masquer la carte",
    "kullanici_bilgileri": "Informations utilisateur",
    "toplam_soru": "Nombre total de questions",
    "resim_yok": "Pas d'image",
    "guncel_hali": "État actuel",

    "desteklenmeyen_dosya_formati": "Format de fichier non supporté",
    "dosya_boyutu_yuksek": "Taille du fichier trop grande",
    "baslik_tarihi_bos_birakmayin": "Ne laissez pas le titre vide",
    "baslangic_tarihi_bos_birakmayin": "Ne laissez pas la date de début vide",
    "bitis_tarihi_bos_birakmayin": "Ne laissez pas la date de fin vide",
    "kategori_bos_birakmayin": "Ne laissez pas la catégorie vide",
    "aciklama_bos_birakmayin": "Ne laissez pas la description vide",
    "il_bos_birakmayin": "Ne laissez pas le département vide",
    "ilce_bos_birakmayin": "Ne laissez pas le district vide",
    "mahalle_bos_birakmayin": "Ne laissez pas le quartier vide",
    "koordinat_belirleyin": "Déterminez les coordonnées",
    "ilce_girmelisiniz": "Vous devez entrer le district",
    "tamamlanma_yuzdesi": "Pourcentage d'achèvement",
    "etiketler": "Étiquettes",
    "etiket_girin": "Entrez une étiquette",
    "dosya_kaldir": "Supprimer le fichier",
    "resimleri_yukle": "Télécharger les images",
    "dosyalari_buraya_birakin": "Déposez les fichiers ici",
    "surukle_birak_veya_tikla": "Faites glisser ou cliquez",
    "gorev_guncelle": "Mettre à jour la tâche",
    "gorev_guncellendi": "Tâche mise à jour",
    "gorev_olusturuldu": "Tâche créée",
    "gorev_olustur": "Créer une tâche",
    "kategori_duzenle": "Modifier la catégorie",
    "kategori_ekle": "Ajouter une catégorie",
    "kategori_adi": "Nom de la catégorie",
    "kategori_rengi": "Couleur de la catégorie",
    "kategorisi_ve_kategoriye_bagli_basliklar_silinecektir": "Les catégories et les titres liés à cette catégorie seront supprimés.",
    "eklenmis_bir_gorev_bulunmamaktadir": "Aucune tâche ajoutée",
    "gorev_silindi": "Tâche supprimée",
    "kategori_sirasi_guncellendi": "Ordre de la catégorie mis à jour",
    "liste_sirasi_guncellendi": "Ordre de la liste mis à jour",
    "yonetici_ozetini_kapat_mesaji": "RÉSUMÉ DE L'ADMINISTRATEUR, CLIQUEZ DANS UN AUTRE ENDROIT POUR FERMER.",
    "yonetici_ozetini_oku": "Lire le résumé de l'administrateur",
    "olusturan_kisi": "Créateur",
    "olusturulma_tarihi": "Date de création",
    "guncelleyen_kisi": "Personne mise à jour",
    "olusturma_tarihi": "Date de création",
    "guncelleme_tarihi": "Date de mise à jour",
    "yonetici_ozeti": "Résumé de l'administrateur",

    "yapildi": "Fait",
    "yapilmadi": "Non fait",
    "kisiye_anket_ata": "Attribuer un sondage à une personne",
    "atanmis_bir_anket_yok": "Aucun sondage attribué.",
    "kac_kisi": "COMBIEN DE PERSONNES",
    "female": "FEMME",
    "male": "HOMME",
    "muhtarliklar": "COMMUNES",
    "yapilacak_anketler": "SONDAGES À FAIRE",
    "kisilere_atanmış_anketler": "SONDAGES ATTRIBUÉS AUX PERSONNES",
    "olusturulmus_tablo_secin": "CHOISISSEZ UN TABLEAU CRÉÉ",
    "tabloyu_goster": "Afficher le tableau",
    "tabloyu_gizle": "Masquer le tableau",
    "tabloyu_sil": "Supprimer le tableau",
    "tabloyu_indir": "Télécharger le tableau",
    "tablo_secin": "Sélectionnez un tableau",
    "filtre_olustur": "Créer un filtre",
    "filtre_adi_giriniz": "Veuillez entrer le nom du filtre",
    "filtre_tablosu": "TABLEAU DE FILTRE",
    "anket_ata": "Attribuer un sondage",
    "anket_atandi": "Sondage attribué",
    "anket_secmelisiniz": "Vous devez choisir un sondage",
    "tc_bulunamadi": "TC introuvable",
    "tablo_silindi": "Tableau supprimé",
    "filtre_adi_girmelisiniz": "Vous devez entrer le nom du filtre",
    "filtre_eklendi": "Filtre ajouté",
    "filtre_yapmalisiniz": "Vous devez créer un filtre",
    "hizmet_bulunamadi": "Service introuvable",
    "sikayet_bulunamadi": "Réclamation introuvable",
    "ayni_ildeki_akraba_sayisi": "Nombre de membres de la famille dans la même province",
    "ayni_ilcedeki_akraba_sayisi": "Nombre de membres de la famille dans le même district",
    "duzenleyen": "Organisateur",
    "memleket_il": "Province d'origine",
    "memleket_ilce": "District d'origine",
    "aslen_il": "Province d'origine",
    "aslen_ilce": "District d'origine",
    "aile_buyugu": "Aîné de la famille",
    "aile": "Famille",
    "kisi_bulunamadi": "Personne introuvable",
    "not_eklendi": "Note ajoutée",

    "not_eklenmedi": "Note non ajoutée",
    "not_ekle": "Ajouter une note",
    "not_guncelle": "Mettre à jour la note",
    "not_guncellenmedi": "Note non mise à jour",
    "not_guncellendi": "Note mise à jour",
    "resim_eklenmedi": "Image non ajoutée",
    "resim_eklendi": "Image ajoutée",
    "details": "Détails",
    "detail": "Détail",
    "hizmetler": "Services",
    "sikayetler": "Réclamations",
    "iletilen_sikayetler": "RÉCLAMATIONS TRANSMISES",
    "istenilen_hizmetler": "SERVICES DEMANDÉS",
    "is_kolu": "Secteur d'activité",
    "sendika_adi": "Nom du syndicat",
    "sendikalar": "SYNDICATS",
    "kurum_adres": "Adresse de l'institution",
    "detayli_faaliyet_alani": "Domaine d'activité détaillé",
    "faaliyet_alani": "Domaine d'activité",
    "dernekler": "ASSOCIATIONS",
    "muhtarlik": "MAIRIE",
    "muhtar_telefon": "Téléphone du maire",
    "muhtar_adi": "Nom du maire",
    "muhtarlik_adi": "Nom de la mairie",
    "kisi_ve_uzeri_goc_eden_birey_sayisi": "Nombre de personnes migrantes",
    "olum": "Décès",
    "dogum": "Naissance",
    "olum_oranlari": "Taux de mortalité",
    "dogum_oranlari": "Taux de natalité",
    "dagilim": "Répartition",
    "universite_ve_universite_ogrenci_sayilari": "Universités et nombre d'étudiants",
    "verilen_goc": "Migration donnée",
    "alinan_goc": "Migration reçue",
    "ilce_bazinda_verilen_goc": "Migration donnée par district",
    "ilce_bazinda_alinan_goc": "Migration reçue par district",
    "secmen_genel_egitim_durumu": "ÉTAT ÉDUCATIF GÉNÉRAL DES ÉLECTEURS",
    "yillara_gore_sosyoekonomik_statu_dagilimi": "RÉPARTITION DU STATUT SOCIO-ÉCONOMIQUE PAR ANS",
    "yillara_gore_dogum_ve_olum_oranlari": "Taux de natalité et de mortalité par an",
    "yillara_gore_secmen_medeni_durum_dagilimi": "Répartition de l'état civil des électeurs par an",
    "birey_hemsehrilik_durumu": "ÉTAT DE PARENTÉ DES INDIVIDUS",
    "yas_grubuna_gore_birey_sayilari": "NOMBRE D'INDIVIDUS PAR GROUPE D'ÂGE",
    "yas_grubu": "GROUPE D'ÂGE",
    "tahmini_1_derece_aile": "Appartenance à la famille de premier degré estimée",
    "tahmini_2_derece_aile": "Appartenance à la famille de deuxième degré estimée",
    "tahmini_uye_yakini_olmayan": "Estimé sans parenté",
    "ortalama_secmen_yasi": "Âge moyen des électeurs",
    "olum_sayisi": "Nombre de décès",
    "dogum_sayisi": "Nombre de naissances",
    "ilk_defa_oy_sayisi": "NOMBRE D'ÉLECTEURS VOTANT POUR LA PREMIÈRE FOIS",
    "toplam_secmen": "Total des électeurs",
    "guncel": "Actuel",
    "baskanlik_secimi": "DEUXIÈME ÉLECTION PRÉSIDENTIELLE",
    "tur": "Tour",
    "tur_1": "Tour 1",
    "tur_2": "Tour 2",
    "sokaga_gore_sandik_basina_secmen_sayisi": "NOMBRE D'ÉLECTEURS PAR BUREAU DE VOTE PAR RUE",
    "kusaklara_gore_secmen_sayisi": "NOMBRE D'ÉLECTEURS PAR GÉNÉRATION",
    "sosyoekonomik_status_dagilimi": "Répartition du statut socio-économique",
    "sokak_bina_ve_hane_analizleri": "ANALYSES DES RUES, DES BÂTIMENTS ET DES MÉNAGES",
    "en_buyuk_nesil": "LA PLUS GRANDE GÉNÉRATION",
    "sessiz_kusak": "GÉNÉRATION SILENCIEUSE",
    "baby_boomer_kusagi": "GÉNÉRATION BABY BOOMER",
    "y_kusagi": "GÉNÉRATION Y",
    "x_kusagi": "GÉNÉRATION X",
    "z_kusagi": "GÉNÉRATION Z",
    "hemsehrilik_ve_goc": "Parenté et migration",

    "oran": "Ratio",
    "secmen_sayisi": "Nombre d'électeurs",
    "sandik_geneli": "Général du bureau de vote",
    "sandik_no": "Numéro du bureau de vote",
    "siyasal_kimlik": "Identité politique",
    "hicbir_bulunamadi_uzgunum": "Aucun résultat trouvé - désolé",
    "hicbir_kayit_mevcut_degil": "Aucun enregistrement disponible",
    "icerisinde_bulunan": "contenu dans",
    "numarali_sandikta": "dans le bureau de vote numéroté",
    "secmen_oy_kullanmistir": "l'électeur a voté.",
    "hemsehrilik_durumu": "ÉTAT DE PAIRES",
    "sandik_sonucu": "RÉSULTAT DU BUREAU DE VOTE",
    "genelinde_ikamet_eden": "résidant au total",
    "baskanlik_sistemine_gore_secim_sandik_analizi": "2. ANALYSE DU BUREAU DE VOTE SELON LE SYSTÈME PRÉSIDENTIEL",
    "aradiginiz_sayfayi_bulamiyoruz": "Nous ne pouvons pas trouver la page que vous recherchez",
    "doldur_ve_en_az_bir_kosul_ekleyin": "Veuillez vous assurer que tous les champs sont remplis et ajoutez au moins une condition.",
    "olusturulan_anket_bulunamadi": "Aucun questionnaire créé trouvé",
    "tamamlanmis_anket_yukleme_alani": "Espace de téléchargement du questionnaire complété",
    "genel_anket_bulunamadi": "Aucun questionnaire général trouvé",
    "anket_secin": "Sélectionnez un questionnaire",
    "bir_anket_secin": "Sélectionnez un questionnaire",
    "analiz_ici_bir_anket_secin": "Sélectionnez un questionnaire pour l'analyse",
    "devam_eden_bir_anket_yoktur": "Il n'y a pas de questionnaire en cours.",
    "kategori_adi_girmelisiniz": "Vous devez entrer un nom de catégorie",
    "kategori_puan_girmelisiniz": "Vous devez entrer un score de catégorie",
    "atanmis_kullanici_bulunamadi": "Aucun utilisateur attribué trouvé",
    "kisi_kaldirildi": "Personne supprimée",
    "reklam_seciniz": "Sélectionnez la publicité",
    "kullanici_secin": "Sélectionnez un utilisateur",
    "kullanici_atandi": "Utilisateur attribué",
    "anket": "Questionnaire",
    "reklam_basliklari": "Titres de la publicité",
    "anket_silindi": "Questionnaire supprimé",
    "tamamla": "Terminer",
    "tamamlandi": "Terminé",
    "anketler": "Questionnaires",
    "tamamlanan_anketler": "Questionnaires complétés",
    "anket_tamamlandi": "Questionnaire terminé",
    "devam_eden_anketler": "Questionnaires en cours",
    "analiz_bulunamadi": "Analyse introuvable",
    "status": "Statut",
    "kategori_kaldir": "Supprimer la catégorie",
    "ust_kategori": "Catégorie principale",
    "puan": "Points",
    "indir": "Télécharger",
    "anket_indir": "Télécharger le questionnaire",
    "anket_olustur": "Créer un questionnaire",
    "departman_kaldirildi": "Département supprimé",
    "departman_atandi": "Département attribué",
    "anket_duzenle": "Modifier le questionnaire",
    "anket_detay": "Détails du questionnaire",
    "degisiklik_bulunamadi": "Aucun changement trouvé",
    "departman_secmelisiniz": "Vous devez sélectionner un département",
    "kategori_eklendi": "Catégorie ajoutée",
    "kategori_silindi": "Catégorie supprimée",
    "kategori_olustur": "Créer une catégorie",
    "kategori_bulunamadi": "Catégorie introuvable",
    "kategori_olusturuldu": "Catégorie créée",
    "cok_fazla_istek_atildi": "Trop de demandes envoyées.",
    "renk_secmelisiniz": "Vous devez choisir une couleur",
    "kategori_guncelle": "Mettre à jour la catégorie",
    "kategori_guncellendi": "Catégorie mise à jour",
    "atanmis_kullanicilar": "Utilisateurs attribués",
    "kategori_sec": "Sélectionner la catégorie",
    "zorunlu": "Obligatoire",
    "ata": "Attribuer",
    "isim": "Nom",
    "tamamlanma_tarih_saat": "Date et heure d'achèvement",
    "toplam_sure": "Durée totale",
    "soyisim": "Prénom",
    "id": "ID",
    "tc": "TC",
    "ip_adresi": "Adresse IP",
    "id_bulunamadi": "ID introuvable",
    "denek_id": "ID du sujet",
    "denek_id_gor": "Voir l'ID du sujet",
    "genel_anketler": "Questionnaires généraux",
    "benim_anketlerim": "Mes questionnaires",
    "turkiye_gundemi": "Agenda de la Turquie",
    "analiz_yukle": "Télécharger l'analyse",
    "analiz_yukleyin": "Téléchargez l'analyse",
    "sonuc_bulunamadi": "Résultat introuvable",
    "veri_silmek_icin_tikla": "Cliquez pour supprimer des données",
    "satir_guncelle": "Mettre à jour la ligne",
    "satir_ekle": "Ajouter une ligne",
    "satir_yuklenemedi": "La ligne n'a pas pu être chargée",
    "satir_yuklendi": "Ligne chargée avec succès",
    "satir_guncellenemedi": "La ligne n'a pas pu être mise à jour",
    "satir_guncellendi": "Ligne mise à jour avec succès",
    "veriler_silindi": "Données supprimées",
    "satirlar_bos_birakilamaz": "Les lignes ne peuvent pas être laissées vides",
    "dosya_bulunamadi": "Fichier introuvable",
    "filtrele": "Filtrer",
    "sokak_raporu": "Rapport de rue",
    "mahalle_analiz_raporu": "Rapport d'analyse de quartier",
    "sokak_analiz_raporu": "Rapport d'analyse de rue",
    "mahallede_toplam": "Total dans le quartier",
    "iletisim_numarasi": "Numéro de contact",
    "mahalle_muhtari": "Chef de quartier",
    "mahalle_raporu": "Rapport de quartier",
    "add_image": "Ajouter une image",
    "cevabinizi_yaziniz": "Écrivez votre réponse",
    "cevap_gonderildi": "Réponses envoyées",
    "sorusu_icin_resim_konum_gerekiyor": "une image et un emplacement sont requis pour la question.",
    "ekle": "Ajouter",
    "alan": "Champ",
    "atama_yap": "Faire une attribution",
    "departman_sec": "Sélectionnez un département",
    "resim_zorunlu": "Image requise",
    "sorular": "Questions",
    "coklu_cevap": "Réponse multiple",
    "tekli_cevap": "Réponse unique",
    "acik_uclu_cevap": "Réponse ouverte",
    "cevaplar": "Réponses",
    "sorulara_don": "Retourner aux questions",
    "resim_alani_ekle": "Ajouter un champ d'image",
    "soru_ekle": "Ajouter une question",
    "soru_kaldir": "Supprimer la question",
    "anket_adi": "Nom du questionnaire",
    "arastirma_sorulari": "Questions de recherche",
    "rapor_olustur": "Créer le rapport",
    "tespit_raporu_olustur": "Créer le rapport de constatation",
    "pin_belirleyin": "Définir un épingle",
    "pin_eklemek_haritaya_tiklayin": "Vous pouvez cliquer sur la carte pour ajouter une épingle",
    "gozlem_olustur": "Créer une observation",
    "atanmis_anketler": "Questionnaires attribués",
    "dosya_ara": "Rechercher un fichier",
    "sizin_yukledikleriniz": "Ce que vous avez téléchargé",
    "kosul_olustur": "Créer une condition",
    "secilenleri_kaldir": "Supprimer les sélectionnés",
    "uzerine_veri_yukle": "Télécharger des données dessus",
    "soru_kategori_ekle": "Ajouter une catégorie de question",
    "soru_kategori_guncelle": "Mettre à jour la catégorie de question",
    "soru_kategorileri": "Catégories de questions",
    "anketi_kaldir": "Supprimer l'enquête",
    "kisi": "Personne",
    "kisiye_atama": "Affecter à une personne",
    "anketi_olustur": "Créer une enquête",
    "anketi_duzenle": "Modifier l'enquête",
    "anketi_kopyala": "Copier l'enquête",
    "anketi_kopyalandi": "L'enquête a été copiée",
    "anketi_guncellendi": "L'enquête a été mise à jour",
    "anketi_olusturuldu": "L'enquête a été créée",
    "gorev_ekle": "Ajouter une tâche",
    "anket_sec": "Sélectionner l'enquête",
    "tum_soru_alanlar_doldurmalisiniz": "Vous devez remplir tous les champs de question",
    "en_az_bir_soru_girmelisiniz": "Vous devez entrer au moins une question",
    "anket_adi_girmelisiniz": "Vous devez entrer le nom de l'enquête",

    "musteri_secmelisiniz": "Vous devez sélectionner un client",
    "tum_cevap_alanlari_doldurmalisiniz": "Vous devez remplir tous les champs de réponse",
    "en_az_bir_cevap_girmelisiniz": "Vous devez entrer au moins une réponse",
    "cevap_ekle": "Ajouter une réponse",
    "metin_puani": "Score de texte",
    "metin_puani_girmelisiniz": "Vous devez entrer le score de texte",
    "metin_basligi": "Titre du texte",
    "metin_basligi_girmelisiniz": "Vous devez entrer le titre du texte",
    "tumunu_kaldir": "Tout supprimer",
    "error_delete_data_graphics": "La suppression affectera toutes les données et graphiques liés à ce fichier. Êtes-vous sûr de vouloir le supprimer ?",
    "tum_alanlari_doldurun": "Veuillez remplir tous les champs.",
    "taleb_gonderildi": "Votre demande a été envoyée",
    "taleb_gonderilemedi": "Votre demande n'a pas pu être envoyée",
    "talep_formu": "Formulaire de demande",
    "contact": "Contact",
    "rapor": "Rapport",
    "kentmetre_raporu": "Rapport Kentmetre",
    "customers": "Clients",
    "yonetim": "Gestion",
    "yonetici": "Gestionnaire",
    "halkla_iliskiler_gozlemci": "Observateur des relations publiques",
    "info_hane_ve_birey_analizi": "Analyse des ménages et des individus (Peut uniquement voir la partie enquête)",
    "kentmetre_haritasi": "Carte Kentmetre",
    "strateji_haritasi": "Carte Stratégique",
    "hane_ve_birey_analizi": "Analyse des ménages et des individus",
    "halkla_iletisim_yonetimi": "Gestion des relations publiques",
    "hazir_raporlar": "Rapports prêts",
    "institution_official": "Représentant de l'entreprise / de l'organisation",
    "gozlemci": "Observateur",
    "arastirma_raporu": "Rapport de recherche",
    "cannot_appoint_manager": "Impossible de nommer un gestionnaire",
    "password": "Mot de passe",
    "password_confirm": "Confirmer le mot de passe",
    "pass_8_min": "Le mot de passe doit comporter au moins 8 caractères",
    "pass_same": "Le mot de passe doit être identique",

    "durdur": "Arrêter",
    "not_dinle": "Écouter la note",
    "not_oku": "Lire la note",
    "etiket_yazin": "ÉCRIRE UNE ÉTIQUETTE",
    "profile": "Profil",
    "user_address": "Adresse de l'utilisateur",
    "user_title": "Titre de l'utilisateur",
    "user_role": "Rôle de l'utilisateur",
    "user_activation_phone": "Téléphone d'activation de l'utilisateur",
    "user_representative": "Nom et prénom du représentant de l'utilisateur",
    "customer_representative": "Nom et prénom du représentant du client",
    "example_file_download": "Télécharger le fichier exemple",
    "fixed_files": "Fichiers fixes",
    "clear": "Effacer",
    "clear_cache": "Effacer le cache",
    "info_clear_cache": "Toutes les données de navigation mises en cache seront supprimées.",
    "error_delete_condition": "Les conditions trouvées seront supprimées définitivement et il n'y aura pas de retour en arrière. <strong>Êtes-vous sûr ?</strong>",

    "raporu_goruntulemek_icin_filtre_yapmalisiniz": "Vous devez filtrer pour afficher le rapport.",
    "footer_text": `<strong class="text-theme">${window.location.host.includes("datakokpit") ? 'datakokpit' : 'stratejimerkezi'}.com</strong> n'a aucun lien avec aucune institution ou organisation. Ce site affiche uniquement des recherches en sources ouvertes et des données prédictives. <br /> <strong class="text-theme">${window.location.host.includes("datakokpit") ? 'datakokpit' : 'stratejimerkezi'}.com</strong> ne garantit pas l'exactitude des lieux, adresses, informations et données cartographiques affichées, elles sont uniquement à des fins d'information.`,
    "GROUP_OF_COMPANIES": "GROUPE D'ENTREPRISES",
    "birth_day_persons": "Citoyens ayant un anniversaire",
    "coming_from_field": "Venus du terrain",
    "il_secmelisiniz": "Vous devez sélectionner une province.",
    "il_ve_ilce_filtrelemeniz_gerekiyor": "Vous devez filtrer par province et district.",
    "sokak_secmelisiniz": "Vous devez sélectionner une rue.",
    "mahalle_secmelisiniz": "Vous devez sélectionner un quartier.",
    "ilce_secmelisiniz": "Vous devez sélectionner un district.",
    "il_ve_ilce_secmelisiniz": "Vous devez sélectionner une province et un district.",
    "no_data_available": "Aucune donnée disponible.",
    "noanswer_sent_you": "Aucune réponse ne vous a été assignée.",
    "bina_basina_ortalama_hane_sayisi": "Nombre moyen de ménages par bâtiment",
    "sokak_basina_ortalama_bina_sayisi": "Nombre moyen de bâtiments par rue",
    "dosyayi_goruntule": "Afficher le fichier",
    "description": "Description",
    "add_sub_question": "Ajouter une sous-question",
    "bosanmis": "Divorcé",
    "bekar": "Célibataire",
    "evli_sayisi": "Nombre de personnes mariées",
    "esi_olmus": "Conjoint décédé",
    "answer": "Réponse",
    "remove_answer": "Supprimer la réponse",
    "required_image": "Image <br /> requise",
    "answer_point": "Point de réponse",
    "write_answer": "Écrivez votre réponse",
    "cevap_tipi": "Type de réponse",
    "soru_adi": "Nom de la question",
    "soru_puani": "Point de la question",
    "soru_kategorisi": "Catégorie de la question",
    "required_question": "Question obligatoire",
    "esi_oldu": "CONJOINT DÉCÉDÉ",
    "evli": "MARIÉ",
    "hic_evlenmedi": "JAMAIS MARIÉ",
    "bosandi": "DIVORCÉ",
    "question": "Question",
    "age": "Âge",
    "unknown": "Inconnu",
    "gender": "Genre",
    "address": "Adresse",
    "degree": "Titre",
    "authorized_province": "Province autorisée",
    "authorized_district": "District autorisé",
    "upload_image": "Télécharger l'image",
    "updated_image": "Image mise à jour",
    "max_file_size_5mb": "La taille du fichier ne peut pas dépasser 5 Mo.",
    "max_file_size_10mb": "La taille du fichier ne peut pas dépasser 10 Mo.",
    "please_select_image_file": "Veuillez sélectionner un fichier image.",
    "add_group": "Ajouter un département",
    "membership": "Adhésion",
    "user": "Utilisateur",
    "log_kayitlari": "Enregistrements de logs",
    "users": "Utilisateurs",
    "edit_user": "Modifier l'utilisateur",
    "add_user": "Ajouter un utilisateur",
    "user_name": "Nom d'utilisateur",
    "person_name_surname": "Nom et prénom du représentant",
    "user_phone": "Téléphone de l'utilisateur",
    "user_email": "Email de l'utilisateur",
    "search": "Recherche",
    "reset": "Réinitialiser",
    "grafik_eklenmedi": "Graphique non ajouté",
    "user_not_found": "Utilisateur introuvable",
    "transaction": "Transaction",
    "transactions": "Transactions",
    "yes": "Oui",
    "no": "Non",
    "next": "Suivant",
    "department": "Département",
    "department_name": "Nom du département",
    "department_name_required": "Vous devez entrer le nom du département.",
    "updated_department": "Département mis à jour",
    "added_department": "Département ajouté",
    "deleted_department": "Département supprimé",
    "departments": "Départements",
    "choose_customer": "Sélectionnez un client",
    "show_users": "Afficher les personnes",
    "kisiler": "Personnes",
    "hide_users": "Masquer les personnes",
    "remove": "Supprimer",
    "isim_soyisim": "Nom et prénom",
    "iletisim_bilgisi": "Informations de contact",
    "dogum_tarihi": "Date de naissance",
    "name_surname": "Nom et prénom",
    "username": "Nom d'utilisateur",
    "note": "Note",
    "role": "Rôle",
    "department_not_added": "Le département n'a pas été ajouté.",
    "department_edit": "Modifier le département",
    "department_add": "Ajouter un département",
    "save": "Enregistrer",
    "copy": "Copier",
    "edit": "Modifier",
    "treeChart": "Graphique en arbre",
    "sanketChart": "Graphique de flux",
    "pieRoseTypeChart": "Graphique à secteurs en rose",
    "pieRoundedChart": "Graphique circulaire arrondi",
    "barPolarTangential": "Graphique à barres polaires",
    "analiz_silindi": "Analyse supprimée",
    "analiz_adi_olusturuldu": "Nom de l'analyse créé",
    "analiz_adi_guncellendi": "Nom de l'analyse mis à jour",
    "analiz_adi_olustururken_hata": "Erreur lors de la création du nom de l'analyse",
    "analiz_adi_guncellerken_hata": "Erreur lors de la mise à jour du nom de l'analyse",
    "analysisRequired": "Entrez le nom de l'analyse",
    "etiketlenen": "Étiqueté",
    "getir": "Apporter",
    "analiz_adi": "Nom de l'analyse",
    "breaking_news": "Dernières nouvelles",
    "choose_city": "Sélectionnez une ville",
    "choose_district": "Sélectionnez un district",
    "choose_neighborhood": "Sélectionnez un quartier",
    "choose_street": "Sélectionnez une rue",
    "choose_building": "Sélectionnez un bâtiment",
    "choose_apartment": "Sélectionnez un appartement",
    "find": "Trouver",
    "disaster_management": "Gestion des catastrophes",
    "go_back": "Retour",
    "city": "Ville",
    "population": "Population",
    "distance": "Distance",
    "district": "District",
    "neighborhood": "Quartier",
    "street": "Rue",
    "building": "Bâtiment",
    "apartment": "Appartement",
    "started_date": "Date de début",
    "end_date": "Date de fin",
    "category": "Catégorie",
    "kapanis_metni": "Texte de fermeture",
    "acilis_metni": "Texte d'ouverture",
    "main_category": "Catégorie principale",
    "sub_category": "Sous-catégorie",
    "sub_category_1": "Sous-catégorie 1",
    "sub_category_2": "Sous-catégorie 2",
    "bolumler": "Sections",
    "web_site": "Site web",
    "kurum_adi": "Nom de l'institution",
    "size": "Taille",
    "depth": "Profondeur",
    "closestCities": "Villes les plus proches",
    "time_of_creation": "Date de création",
    "lat": "Latitude",
    "lng": "Longitude",
    "place": "Lieu",
    "requirements_table": "Tableau des besoins",
    "total_population": "Population totale",
    "how_many_days_required": "Combien de jours sont nécessaires ?",
    "total_requirement": "Besoins totaux",
    "unit": "Unité",
    "contents": "Contenu",
    "analysis_data_not_added": "Données d'analyse non ajoutées",
    "healthcare_facilities": "Établissements de santé",
    "location": "Emplacement",
    "all_locations": "Tous les emplacements",
    "locations": "Emplacements",
    "all_locations_show": "Afficher tous les emplacements",
    "locations_show": "Afficher les emplacements",
    "locations_hide": "Masquer les emplacements",

    "page": "Page",
    "create_page": "Créer une Page",
    "import_file": "Importer un Fichier",
    "create_graphic": "Créer un Graphique",
    "are_you_sure": "Êtes-vous sûr ?",
    "iliskili_tum_kategoriler_silinecektir": "Toutes les catégories associées seront supprimées.",
    "mine": "LE MIEN",
    "show": "Afficher",
    "hide": "Cacher",
    "show_hide": "Afficher/Cacher",
    "updateed": "Mis à jour",
    "update": "Mettre à jour",
    "updating": "Mise à jour en cours",
    "created": "Ajouté",
    "create": "Créer",
    "creating": "Ajout en cours",
    "send": "Envoyer",
    "cancel": "Annuler",
    "preview": "Aperçu",
    "prev": "Précédent",
    "view": "Afficher",
    "delete": "Supprimer",
    "no_option": "Aucune Option",
    "max_200_mb": "Ne peut pas dépasser 200 Mo",
    "file_size_error": "Taille du fichier trop grande",
    "server_error": "Erreur du serveur !",
    "try_again_error": "Veuillez réessayer dans un moment.",
    "added_to_chart": "Graphique ajouté à la page",
    "updated_chart": "Graphique mis à jour",
    "deleted_chart": "Graphique supprimé",
    "conditions_sections_required": "Ne laissez pas les champs de conditions vides.",
    "columns_sections_required": "Ne laissez pas les champs de colonnes vides.",
    "chart_sections_required": "Ne laissez pas les champs de graphique vides.",
    "chart_name_error": "Le nom du graphique doit contenir moins de 100 caractères.",
    "select_column_error": "Sélectionnez au moins une colonne.",
    "choose_column": "Choisir une Colonne",
    "choose_column_2": "Choisissez une colonne",
    "group_warning": "La colonne de groupement doit être présente dans les colonnes sélectionnées.",
    "value": "Valeur",
    "title": "Titre",
    "tum_musteriler": "Tous les Clients",
    "icon": "Icône",
    "iframe": "Iframe",
    "customer": "Client",
    "select_customer": "Sélectionner un Client",
    "page_order": "Ordre de la Page",
    "page_deleted": "Page supprimée",
    "parent_menu": "Menu Parent",
    "page_name": "Nom de la Page",
    "external_link": "Lien Externe",
    "pageOrder": "Entrez l'Ordre de la Page",
    "pageName": "Entrez le Nom de la Page",
    "pageNameCharLimit": "Le nom de la page doit contenir plus de 3 caractères",
    "externalLinkError": "Erreur de Lien Externe",
    "iframeLinkError": "Erreur de Lien Iframe",
    "file_import_max": "La taille du fichier ne peut pas dépasser 50 Mo",
    "file_important": "Fichier requis",
    "file_import": "Importer le Fichier",
    "data_name_important": "Le nom des données est requis",
    "data_name": "Nom des Données",
    "file": "Fichier",
    "file_name": "Nom du Fichier",
    "data_type": "Type de Données",
    "data_type_error": "Incompatibilité de Type de Données",
    "integer": "Entier",
    "string": "Texte",
    "date": "Date",
    "json": "JSON",
    "email": "Email",
    "phone": "Téléphone",
    "phone_control": "Contrôle du Téléphone",
    "boolean": "Booléen (Vrai/Faux)",
    "separators": "Séparateurs",
    "separators_warning": "Séparateurs non détectés",
    "separators_error": "Un séparateur non reconnu a été utilisé dans le fichier CSV.",
    "choose_csv_file": "Choisir un Fichier CSV",
    "warning_for_date": "Avertissement : Le format de date des colonnes sélectionnées doit être <strong>2024-01-01</strong> pour que l'opération soit valide.",
    "upload": "Télécharger",
    "column_warning": "Définissez les types de données pour toutes les colonnes",
    "file_import_info": "Les données seront ajoutées au système à la fin des processus de mise en file d'attente et d'analyse. Ce processus peut prendre quelques minutes.",
    "file_import_success": "Fichier téléchargé avec succès",
    "file_import_error": "Le fichier n'a pas pu être téléchargé",
    "mahalle_sayisi": "Nombre de Quartiers",
    "sokak_sayisi": "Nombre de Rues",
    "bina_sayisi": "Nombre de Bâtiments",
    "hane_sayisi": "Nombre de Ménages",
    "kisi_sayisi": "Nombre de Personnes",

    "graphic_preview": "Aperçu du Graphique",
    "secilen": "Sélectionné",
    "choose_page": "Choisir la Page Sélectionnée",
    "choose_chart": "Choisir le Graphique Sélectionné",
    "added_chart": "Graphique ajouté à la page",
    "update_chart": "Mettre à Jour le Graphique",
    "chart_width": "Largeur du Graphique",
    "chart": "Graphique",
    "query": "Requête",
    "group": "Groupe",
    "pageCustomer": "Client de la Page",
    "selectPage": "Veuillez Sélectionner une Page",
    "fileCustomer": "Client du Fichier",
    "selectFile": "Sélectionner un Fichier",
    "chartOrder": "Ordre du Graphique",
    "chartView": "Vue du Graphique",
    "pieChart": "Graphique en Secteurs",
    "barChart": "Graphique à Barres",
    "wordCloud": "Nuage de Mots",
    "table": "Tableau",
    "map": "Carte",
    "chartName": "Nom du Graphique",
    "chartDescription": "Description du Graphique",
    "addColumn": "Ajouter une Colonne",
    "columnNotFound": "Colonne Introuvable",
    "selectColumn": "Sélectionner une Colonne",
    "selectOperator": "Sélectionner un Opérateur",
    "selectCondition": "Sélectionner une Condition",
    "selectAction": "Sélectionner une Action",
    "dateGrouping": "Regroupement par Date",
    "menu_section": "Section de Menu",
    "menu_align": "Alignement du Menu",
    "menu_horizontal": "Alignement du Menu (Horizontal)",
    "menu_vertical": "Alignement du Menu (Vertical)",
    "xl": "Grand (4/4)",
    "lg": "Moyen-Grand (4/3)",
    "m": "Moyen (4/2)",
    "sm": "Petit (4/1)",
    "all": "TOUS",
    "column": "COLONNE",
    "count": "COMPTER",
    "average": "MOYENNE",
    "total": "TOTAL",
    "minimum": "MINIMUM",
    "maximum": "MAXIMUM",
    "distinct": "DISTINCT",
    "day": "JOUR",
    "month": "MOIS",
    "year": "AN",
    "hour": "HEURE",
    "minute": "MINUTE",
    "second": "SECONDE",
    "none": "AUCUN",
    "condition": "Condition",
    "add_condition": "Ajouter une Condition",
    "choose_condition": "Choisir une Condition",
    "conditionNotFound": "Condition Introuvable",
    "you_can_add_condition": "Vous pouvez ajouter une condition",
    "in": "DANS",
    "out": "HORS",
    "like": "COMME",
    "notLike": "PAS COMME",
    "empty": "VIDE",
    "filled": "REMPLIE",
    "and": "Et",
    "or": "Ou",
    "vertical": "Vertical",
    "horizontal": "Horizontal",
    "left": "Gauche",
    "center": "Centre",
    "right": "Droite",
    "up": "Haut",
    "middle": "Milieu",
    "down": "Bas",
    "loader_data": "DONNÉES",
    "loader_gathering": "EN COURS DE COLLECTE...",
    "loader_in_progress": "EN COURS D'ANALYSE...",
    "loader_visualizing": "EN COURS DE VISUALISATION..."
};

export default fr;
