import { useEffect, useRef } from "react";

export default function DynamicIframe({ value }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (value.includes("<iframe")) {
      // DOM'daki iframe'e class ekle
      const container = containerRef.current;
      if (container) {
        const iframe = container.querySelector("iframe");
        if (iframe) {
          iframe.classList.add("w-100", "h-100");
        }
      }
    }
  }, [value]);

  return (
    <div
      ref={containerRef}
      className="h-100"
    >
      {value.includes("<iframe") ? (
        <div
          className="w-100 h-100"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ) : (
        <iframe src={value} className="w-100 h-100" />
      )}
    </div>
  );
}
