import React, { useContext, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import { Card, CardBody } from "../card/card";

export default function CardMedeniDurum({
  show = true,
  title,
  city,
  district,
  neighbourhood,
  type = 1,
  setSokakBinaCountData = false,
}) {
  const context = useContext(AppSettings);

  const [data, setData] = useState();

  async function getData() {
    setData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Medeni Durum",
          type:
            type === 1
              ? "demograf"
              : type === 2
              ? `cbsm-son-csv`
              : type === 3
              ? "hemsehrilik-durumu"
              : null,
          description: "",
          customer: null,
          status: true,
          order: 1,
          connection_type: "local",
          sql: {
            column:
              type === 1
                ? [
                    {
                      key: "Alt-Kategori-2",
                      value: "select",
                    },
                    {
                      key: "Deger",
                      value: "select",
                    },
                  ]
                : type === 2
                ? [
                    {
                      key: "CSBMADI",
                      value: "count",
                    },
                    {
                      key: "KONUT-SAYISI",
                      value: "sum",
                    },
                    {
                      key: "OZELISYERI-SAYISI",
                      value: "sum",
                    },
                    // {
                    //   key: "Daire",
                    //   value: "select",
                    // },
                  ]
                : type === 3
                ? [
                    {
                      key: "SehIr",
                      value: "select",
                    },
                    {
                      key: "IlCe",
                      value: "select",
                    },
                    {
                      key: "DEĞERİ",
                      value: "sum",
                    },
                  ]
                : [],
            condition: [
              type === 1
                ? {
                    key: "Alt-Kategori-1",
                    value: "MEDENI DURUM",
                    operator: "=",
                    type: "and",
                  }
                : null,
              city
                ? {
                    key:
                      type === 1
                        ? "Sehir"
                        : type === 2
                        ? "ILADI"
                        : type === 3
                        ? "SehIr"
                        : null,
                    value:
                      type === 1
                        ? city.toUpperCase()
                        : context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood && type !== 3
                ? {
                    key:
                      type === 1
                        ? "Ilce"
                        : type === 2
                        ? "ILCEADI"
                        : type === 3
                        ? "IlCe"
                        : null,
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood && type !== 3
                ? {
                    key:
                      type === 1
                        ? "Mahalle"
                        : type === 2
                        ? "MAHALLEADI"
                        : "Adres Mahalle",
                    value:
                      type === 2
                        ? neighbourhood.split("MAH.")[0]
                        : context
                            .removeTurkishCharacters(neighbourhood)
                            .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (type === 1) {
        if (response.length !== 0) {
          // const order = [
          //   context?.t?.evli,
          //   context?.t?.hic_evlenmedi,
          //   context?.t?.bosandi,
          //   context?.t?.esi_oldu,
          // ];

          // const result = response.reduce((acc, curr) => {
          //   const { alt_kategori_2, deger } = curr;
          //   if (!acc[alt_kategori_2]) {
          //     acc[alt_kategori_2] = 0;
          //   }
          //   acc[alt_kategori_2] += parseInt(deger, 10);
          //   return acc;
          // }, {});

          // const mergedResult = order.map((key) => ({
          //   alt_kategori_2: key,
          //   deger: result[key] || 0,
          // }));

          setData([]);
        } else {
          setData([]);
        }
      }

      if (type === 2) {
        if (response.length !== 0) {
          // const result = response.reduce((acc, item) => {
          //   const sokak = item["csbmadi"];
          //   const bina = item["konut-sayisi"];
          //   // const daire = item["daire"];

          //   if (!acc[sokak]) {
          //     acc[sokak] = { binalar: new Set(), daireSayisi: 0 };
          //   }

          //   acc[sokak].binalar.add(bina);
          //   acc[sokak].daireSayisi += 1;

          //   return acc;
          // }, {});

          // const sokakBilgileri = Object.keys(result).map((sokak) => ({
          //   sokak,
          //   binaSayisi: result[sokak].binalar.size,
          //   daireSayisi: result[sokak].daireSayisi,
          // }));

          // const toplamlar = sokakBilgileri.reduce(
          //   (acc, sokak) => {
          //     acc.toplamBinaSayisi += sokak.binaSayisi;
          //     acc.toplamDaireSayisi += sokak.daireSayisi;
          //     return acc;
          //   },
          //   { toplamBinaSayisi: 0, toplamDaireSayisi: 0 }
          // );

          setData({
            averageNumberOfBuildingsPerStreet: (
              response[0]["konut-sayisi_sum"] /
              parseInt(response[0]["csbmadi_count"])
            ).toFixed(2),
            averageNumberOfIndependentSectionsPerBuilding: (
              response[0]["ozelisyeri-sayisi_sum"] /
              parseInt(response[0]["csbmadi_count"])
            ).toFixed(2),
          });
          if (setSokakBinaCountData) {
            setSokakBinaCountData({
              averageNumberOfBuildingsPerStreet: (
                response[0]["konut-sayisi_sum"] /
                parseInt(response[0]["csbmadi_count"])
              ).toFixed(2),
              averageNumberOfIndependentSectionsPerBuilding: (
                response[0]["ozelisyeri-sayisi_sum"] /
                parseInt(response[0]["csbmadi_count"])
              ).toFixed(2),
            });
          }
        } else {
          setData([]);
          if (setSokakBinaCountData) {
            setSokakBinaCountData(null);
          }
        }
      }

      if (type === 3) {
        if (response.length !== 0) {
          setData(
            response
              .sort((a, b) => b["değeri̇_sum"] - a["değeri̇_sum"])
              .slice(0, 4)
          );
        } else {
          setData([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // if (city) {s
    getData();
    // }s
  }, [city, district, neighbourhood]);

  return (
    show && (
      <div className="col-12 col-md-6 col-lg-4 col-xl">
        <Card className="h-100">
          <CardBody>
            {data !== "filter" ? (
              data ? (
                data.length !== 0 ? (
                  <>
                    <div className="d-flex fw-bold h6 mb-4 text-center">
                      <span className="flex-grow-1">
                        {title?.toUpperCase()}
                      </span>
                    </div>

                    <div className="row align-items-center text-center">
                      <div className={"col-12"}>
                        <div className={`lg text-inverse text-opacity-100`}>
                          {type === 1 ? (
                            <>
                              <div>
                                <strong className="h4">
                                  <CountUp
                                    separator="."
                                    end={data[0].deger || 0}
                                  />
                                </strong>{" "}
                                <br />
                                {context?.t?.evli_sayisi}
                              </div>
                              <div className="mt-2">
                                <strong className="h4">
                                  <CountUp
                                    separator="."
                                    end={data[1].deger || 0}
                                  />
                                </strong>{" "}
                                <br />
                                {context?.t?.bekar}
                              </div>
                              <div className="mt-2">
                                <strong className="h4">
                                  <CountUp
                                    separator="."
                                    end={data[2].deger || 0}
                                  />
                                </strong>{" "}
                                <br />
                                {context?.t?.bosanmis}
                              </div>
                              <div className="mt-2">
                                <strong className="h4">
                                  <CountUp
                                    separator="."
                                    end={data[3].deger || 0}
                                  />
                                </strong>{" "}
                                <br />
                                {context?.t?.esi_olmus}
                              </div>
                            </>
                          ) : type === 2 ? (
                            <>
                              <div>
                                {context?.t?.sokak_basina_ortalama_hane_sayisi}
                                <br />
                                <strong className="h4">
                                  <CountUp
                                    decimal=","
                                    decimals={2}
                                    end={
                                      data.averageNumberOfBuildingsPerStreet ||
                                      0
                                    }
                                  />
                                </strong>{" "}
                              </div>
                              <div className="mt-2">
                                {context?.t?.sokak_basina_ortalama_ozel_is_yeri_sayisi}
                                <br />
                                <strong className="h4">
                                  <CountUp
                                    decimal=","
                                    decimals={2}
                                    end={
                                      data.averageNumberOfIndependentSectionsPerBuilding ||
                                      0
                                    }
                                  />
                                </strong>{" "}
                              </div>
                            </>
                          ) : (
                            type === 3 &&
                            data.map((item, index) => (
                              <div
                                key={index}
                                className={`${index > 0 && "mt-2"}`}
                              >
                                <strong className="h4">
                                  <CountUp
                                    separator="."
                                    end={item["değeri̇_sum"] || 0}
                                  />
                                </strong>
                                <br />
                                {item?.sehir || "-"} / {item?.ilce || "-"}{" "}
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="d-block text-center">
                    {context?.t?.no_data_available}
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block text-center">
                {context?.t?.il_secmelisiniz}
              </span>
            )}
          </CardBody>
        </Card>

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}
