import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import DynamicPageCard from "./DynamicPageCard";
// import TableWithPrediction from "./TableWithPrediction";

const DinamikPage = ({
  reload = false,
  menuId = null,
  width = null,
  height = 600,
  getChartImages = false,
  removeGrid = false,
  firstItem = false,
  lastItem = false,
  events = true,
  filterPath,
  todo = true,
}) => {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const location = useLocation();
  const menu = location.search.split("id=")
    ? location.search.split("id=")[1]
    : null;

  const [data, setData] = useState([]);
  const [iframe, setIframe] = useState(null);
  const [pageData, setPageData] = useState(null);

  const [triggerFetch, setTriggerFetch] = useState(false);
  const controllerRef = useRef(new AbortController());

  const [cardTodoData, setCardTodoData] = useState([]);
  const [todoData, setTodoData] = useState([]);
  const [pureTodoData, setPureTodoData] = useState(null);
  const [todoCategoryData, setTodoCategoryData] = useState(null);

  function addTokenToDomain(urlString) {
    // Domaini içeren bir URL kalıbı bulmak için düzenli ifade oluşturuyoruz
    const regex = /(https?:\/\/)?(analiz\.kentmetre\.com)(\/[^\s]*)?/g;

    // URL içeriğinde analiz.asd.com geçiyorsa parametreyi ekliyoruz
    if (regex.test(urlString) && localStorage.getItem("token")) {
      return urlString.includes("?")
        ? `${urlString}&token=${localStorage.getItem("token")}`
        : `${urlString}?token=${localStorage.getItem("token")}`;
    }

    // Eğer analiz.asd.com yoksa, URL'yi değiştirmeden geri döndürüyoruz
    return urlString;
  }

  async function getTodoData() {
    setTodoData([]);

    try {
      const response = await apiClient.get(`/todos`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        if (response.data.length !== 0) {
          const resultArray = response.data.reduce((acc, item) => {
            const existingCategory = acc.find(
              (category) => category.category.id === item.category.id
            );

            if (existingCategory) {
              existingCategory.list.push({
                id: item.id,
                title: item.title,
                description: item.description,
                summary: item.summary,
                completed: item.completed,
                tags: item.tags,
                location: item.location,
                image: item.image,
                file: item.file,
                started_at: item.started_at,
                finished_at: item.finished_at,
                sort: item.sort,
                city: item.city,
                district: item.district,
                neighbourhood: item.neighbourhood,
                street: item.street,
                building: item.building,
                independent: item.independent,
                user: item.user
                  ? {
                      id: item.user.id,
                      customer: item.user.customer,
                      role: item.user.role,
                      name: item.user.name,
                      username: item.user.username,
                      email: item.user.email,
                      phone: item.user.phone,
                      address: item.user.address,
                    }
                  : null,
                updated_user: item.updated_user
                  ? {
                      id: item.updated_user.id,
                      customer: item.updated_user.customer,
                      role: item.updated_user.role,
                      name: item.updated_user.name,
                      username: item.updated_user.username,
                      email: item.updated_user.email,
                      phone: item.updated_user.phone,
                      address: item.updated_user.address,
                    }
                  : null,
                created_at: item.created_at || null,
                updated_at: item.updated_at || null,
              });
            } else {
              acc.push({
                category: {
                  id: item.category.id,
                  name: item.category.name,
                  color: item.category.color,
                  sort: item.category.sort,
                },
                list: [
                  {
                    id: item.id,
                    title: item.title,
                    description: item.description,
                    summary: item.summary,
                    completed: item.completed,
                    tags: item.tags,
                    location: item.location,
                    image: item.image,
                    file: item.file,
                    started_at: item.started_at,
                    finished_at: item.finished_at,
                    city: item.city,
                    district: item.district,
                    neighbourhood: item.neighbourhood,
                    street: item.street,
                    building: item.building,
                    independent: item.independent,
                    sort: item.sort,
                    user: item.user
                      ? {
                          id: item.user.id,
                          customer: item.user.customer,
                          role: item.user.role,
                          name: item.user.name,
                          username: item.user.username,
                          email: item.user.email,
                          phone: item.user.phone,
                          address: item.user.address,
                        }
                      : null,
                    updated_user: item.updated_user
                      ? {
                          id: item.updated_user.id,
                          customer: item.updated_user.customer,
                          role: item.updated_user.role,
                          name: item.updated_user.name,
                          username: item.updated_user.username,
                          email: item.updated_user.email,
                          phone: item.updated_user.phone,
                          address: item.updated_user.address,
                        }
                      : null,
                    created_at: item.created_at || null,
                    updated_at: item.updated_at || null,
                  },
                ],
              });
            }

            return acc;
          }, []);

          resultArray.sort((a, b) => a.category.sort - b.category.sort);

          resultArray.forEach((category) => {
            category.list.sort((a, b) => a.sort - b.sort);
          });

          setTodoCategoryData([
            { value: "remove", label: context?.t?.reset },
            { value: null, label: context?.t?.all },
            ...resultArray
              .filter((item) => item.category !== null)
              .map(function (item) {
                return {
                  label: item.category.name,
                  value: item.category.id,
                };
              }),
          ]);
          setTodoData(resultArray);
          setPureTodoData(resultArray);
        } else {
          setTodoData([]);
        }
      }
    } catch (error) {
      console.error("Todo Error", error);
      setTodoData([]);

      if (error.response.status === 403) {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  useEffect(() => {
    if (todo) {
      getTodoData();
    }
  }, [todo]);

  const fetchPageData = async (menuId, filterPath, graphicFetch = false) => {
    // Sayfa değiştiğinde mevcut veri sıfırlanacak ve controller reset edilecek
    setData([]); // Veri sıfırlanır.
    setIframe(null); // iframe sıfırlanır.
    controllerRef.current.abort(); // Önceki fetch isteği iptal edilir.

    // Yeni controller oluşturuluyor ve state'e ekleniyor
    controllerRef.current = new AbortController();

    try {
      const response = await apiClient.get(`/page/${menuId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        signal: controllerRef.current.signal,
      });

      setPageData(response?.data);

      if (graphicFetch && response.status) {
        if (response?.data?.iframe && response?.data?.iframe !== "") {
        } else {
          let updatedData = response?.data?.graphics.sort(
            (a, b) => a.order - b.order
          );

          if (lastItem) {
            updatedData = updatedData.slice(-lastItem);
          } else if (firstItem) {
            updatedData = updatedData.slice(0, updatedData.length - firstItem);
          }

          let isPageChanged = false;

          for (const item of updatedData) {
            if (isPageChanged) break;

            const graphicResponse = await apiClient.get(
              `/graphics/${item.id}${filterPath || ""}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                signal: controllerRef.current.signal,
              }
            );

            if (graphicResponse.data.query.chart !== 4) {
              if (
                graphicResponse?.data?.query?.options?.selectedColumn &&
                graphicResponse?.data?.query?.options?.selectedColumn[0] &&
                graphicResponse?.data?.query?.options?.selectedColumn[0]
                  .label &&
                graphicResponse?.data?.query?.options?.selectedColumn[0].label.includes(
                  "_avg"
                )
              ) {
                // graphicResponse.data.query.options.options.tooltip = {
                //   trigger: "item",
                //   formatter: function (params) {
                //     return (
                //       params?.data?.name +
                //       "<br /> %" +
                //       params?.data?.value?.toFixed(2)
                //     );
                //   },
                // };
              }
            }

            if (item.chart === 1) {
              const result = graphicResponse.data.result;
              const label0 =
                graphicResponse?.data?.query?.options?.selectedColumn[0]?.label;
              const label1 =
                graphicResponse?.data?.query?.options?.selectedColumn[1]?.label;

              const formattedResult = result.map((entry) => ({
                value: entry[label0],
                name: entry[label1],
              }));

              if (graphicResponse?.data?.query?.options?.options) {
                graphicResponse.data.query.options.options.series[0].data =
                  formattedResult;

                const values = formattedResult.map((entry) => entry.value);
                const maxValue = Math.max(...values);
                const minValue = Math.min(...values);

                if (graphicResponse?.data?.query?.options?.options?.visualMap) {
                  graphicResponse.data.query.options.options.visualMap.max =
                    maxValue;
                  graphicResponse.data.query.options.options.visualMap.min =
                    minValue;
                }
              }
            }

            if (menu !== location.search.split("id=")[1]) {
              isPageChanged = true;
              break;
            }

            setData((prevData) => [...prevData, graphicResponse.data]);
          }

          setIframe(null);
        }
      } else {
        if (response?.data?.iframe) {
          setIframe(response?.data?.iframe);
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        // console.log("Fetch request aborted.");
      } else {
        console.error("Get Page", error);
      }
    }
  };

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    if (menuId) {
      fetchPageData(menuId, filterPath, true);
    } else {
      if (menu) {
        fetchPageData(menu, filterPath);
        window.speechSynthesis.cancel();
        setActiveIndex(null, filterPath);
      } else {
        if (window.location.search.split("id=")) {
          fetchPageData(
            parseInt(window.location.search.split("id=")[1]),
            filterPath
          );
        } else {
          fetchPageData(null, filterPath);
          navigate("/404");
        }
      }
    }
  }, [menuId, menu, filterPath, triggerFetch, context?.chartColor]);

  //--

  const readText = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const [cardName, setCardName] = useState("");
  const [cardNote, setCardNote] = useState("");

  //

  const renderContent = () => {
    return data ? (
      !iframe ? (
        pageData?.graphics.length !== 0 ? (
          pageData?.graphics
            .sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <DynamicPageCard
                removeGrid={removeGrid}
                dynamicPage={true}
                ai={true}
                pureTodoData={pureTodoData}
                setTodoData={setTodoData}
                todoData={todoData}
                todoCategoryData={todoCategoryData}
                id={item?.id}
                aiData={
                  item?.chart === 10 ? item?.options?.noteValue : item?.result
                }
                triggerFetch={triggerFetch}
                setTriggerFetch={setTriggerFetch}
                data={data}
                dataLength={data.length}
                key={item?.id}
                fullScreen={true}
                item={item}
                width={width}
                height={height}
                menu={menu}
                events={events}
                isActive={index === activeIndex}
                setActiveIndex={setActiveIndex}
                readText={() => readText(index)}
                setCardNote={setCardNote}
                setCardName={setCardName}
                getChartImages={getChartImages}
                surveyCard={false}
                pageData={pageData}
                filterPath={filterPath}
                result={[]}
              />
            ))
        ) : (
          <p>{context?.t?.no_data_available}</p>
        )
      ) : (
        <div className="page-iframe" style={{ gridColumn: "span 12" }}>
          <iframe src={addTokenToDomain(iframe)} title={menu?.name}></iframe>
        </div>
      )
    ) : (
      <div className="spinner-border text-theme"></div>
    );
  };

  return (
    <>
      {/* <div className="mb-5 pb-5">
        <TableWithPrediction filterPath={filterPath} />
      </div> */}
      {!removeGrid ? (
        <div
          className="parent mb-5 pb-5"
          style={{
            gridTemplateColumns: `repeat(${12}, 1fr)`,
          }}
        >
          {renderContent()}
        </div>
      ) : (
        renderContent()
      )}

      {menu && (
        <div
          className="modal modal-cover fade"
          id={`pageCardNoteModal_${menu}`}
        >
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "var(--bs-dark4)" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  {cardName} - {context?.t?.note}
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body">
                <textarea
                  className="form-control"
                  defaultValue={cardNote}
                  readOnly
                  style={{ height: 300 }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DinamikPage;
