import moment from "moment";
import React, { useContext, useState } from "react";
import toastMessage from "./ToastMessage";
import apiClient from "../ApiClient";
import TaskListItem from "./TaskListItem";
import { AppSettings } from "../config/app-settings";

function hexToRgba(hex, opacity) {
  // Hex kodundan başındaki # işaretini kaldır
  hex = hex.replace("#", "");

  // R, G, B değerlerini al
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // Opacity (saydamlık) değerini 0 ile 1 arasında normalize et
  var alpha = opacity || 1;

  // rgba formatında geri döndür
  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

export default function TaskItem({
  handleTodoEdit,
  handleTodoDelete,
  item,
  index,
  todoDataLength,
  getTodoData,
  setCreateMissionModalShow
}) {
  const context = useContext(AppSettings);

  const [isOpen, setIsOpen] = useState(false);
  const [listShow, setListShow] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  //--

  async function updateSortCategory(id, sort) {
    try {
      const response = await apiClient.put(
        "/todo/categories/" + id,
        { sort: sort },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage(context?.t?.kategori_sirasi_guncellendi, "success");
        getTodoData();
      }
    } catch (error) {
      console.error("Category Edit Sort Error", error);
    }
  }

  async function sortCategory(id, sort, direction) {
    if (direction === "up") {
      updateSortCategory(id, sort - 1);
    }

    if (direction === "down") {
      updateSortCategory(id, sort + 1);
    }
  }

  //--

  async function updateSortList(id, sort) {
    try {
      const response = await apiClient.put(
        "/todos/" + id,
        { sort: sort },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage(context?.t?.liste_sirasi_guncellendi, "success");
        getTodoData();
      }
    } catch (error) {
      console.error("List Edit Sort Error", error);
    }
  }

  async function sortList(id, sort, direction) {
    if (direction === "up") {
      updateSortList(id, sort - 1);
    }

    if (direction === "down") {
      updateSortList(id, sort + 1);
    }
  }

  //--

  function removeHTMLTags(inputString) {
    var doc = new DOMParser().parseFromString(inputString, "text/html");
    return doc.body.textContent || "";
  }

  const utterance = new SpeechSynthesisUtterance();

  const handleSpeak = (text, title) => {
    window.speechSynthesis.cancel();
    setTimeout(() => {
      utterance.text = text;
      utterance.lang = "tr-TR";
      utterance.rate = 0.8;
      utterance.pitch = 0.8;
      window.speechSynthesis.speak(utterance);
      toastMessage(
        title + " - " + context?.t?.yonetici_ozetini_kapat_mesaji,
        "info"
      );
    }, 500);
  };

  let listenItem = document.querySelector(".listen-note");
  let listenNotes = document.querySelectorAll(".listen-note");

  React.useEffect(() => {
    if (listenItem) {
      listenNotes.forEach(function (note) {
        note.addEventListener("click", function () {
          // this.classList.add("active");

          listenNotes.forEach(function (otherNote) {
            if (otherNote !== note) {
              // otherNote.classList.remove("active");
              window.speechSynthesis.cancel();
            }
          });
        });
      });

      document.addEventListener("click", function (event) {
        const target = event.target;

        if (!listenItem.contains(target)) {
          window.speechSynthesis.cancel();

          listenNotes.forEach(function (otherNote) {
            // otherNote.classList.remove("active");
          });
        }
      });
    }
  }, [listenNotes, listenItem]);

  return (
    <div
      className="card mb-3"
      style={{
        background: item?.category?.color
          ? hexToRgba(item?.category?.color, 0.2)
          : "initial",
      }}
    >
      <div
        className="card-header d-flex align-items-center bg-inverse bg-opacity-10"
        style={{ cursor: "pointer" }}
      >
        <span className="d-flex align-items-center flex-grow-1 fw-400">
          {todoDataLength >= 2 && (
            <span className="d-flex flex-column me-2">
              {index > 0 && (
                <span
                  className="todo-sort-arrow pe-1"
                  onClick={() =>
                    sortCategory(item?.category?.id, item?.category?.sort, "up")
                  }
                >
                  <i className="fas fa-angle-up"></i>
                </span>
              )}
              {index !== todoDataLength - 1 && (
                <span
                  className="todo-sort-arrow pe-1"
                  onClick={() =>
                    sortCategory(
                      item?.category?.id,
                      item?.category?.sort,
                      "down"
                    )
                  }
                >
                  <i className="fas fa-angle-down"></i>
                </span>
              )}
            </span>
          )}
          <span
            className="flex-grow-1"
            onClick={item?.list.length !== 0 && toggleAccordion}
          >
            {item?.category?.name} ({item?.list?.length})
          </span>
        </span>

        {/* {item?.list.leƒ */}
      </div>

      {/* {item?.list.length !== 0 && !isOpen && (
        <div className="py-0">
          {item?.list.map((todo, todoIndex) => (
            <div key={todoIndex} className="mx-3 my-3">
              {todoIndex > 0 && <hr className="mb-3" />}

              {todo.title && (
                <div className="fw-400 lh-20">
                  {todoIndex + 1 + ". " + todo.title}
                </div>
              )}

              {todo.summary && (
                <span
                  className="mb-2 d-block mt-2 text-theme pointer listen-note"
                  onClick={() =>
                    handleSpeak(removeHTMLTags(todo.summary), todo.title)
                  }
                >
                  Yönetici Özetini Oku
                </span>
              )}
            </div>
          ))}
        </div>
      )} */}

      {item?.list.length !== 0 &&
        item?.list.map(
          (todo, todoIndex) => (
            // isOpen && (
            <div key={todoIndex + "_todoitemlist"}>
              <TaskListItem
                handleTodoEdit={handleTodoEdit}
                handleTodoDelete={handleTodoDelete}
                item={item}
                todo={todo}
                todoDataLength={todoDataLength}
                todoIndex={todoIndex}
                sortList={sortList}
                handleSpeak={handleSpeak}
                removeHTMLTags={removeHTMLTags}
                setCreateMissionModalShow={setCreateMissionModalShow}
              />
            </div>
          )
          // )
        )}

      <div className="card-arrow">
        <div className="card-arrow-top-left"></div>
        <div className="card-arrow-top-right"></div>
        <div className="card-arrow-bottom-left"></div>
        <div className="card-arrow-bottom-right"></div>
      </div>
      <div className="card-arrow">
        <div className="card-arrow-top-left"></div>
        <div className="card-arrow-top-right"></div>
        <div className="card-arrow-bottom-left"></div>
        <div className="card-arrow-bottom-right"></div>
      </div>
    </div>
  );
}
