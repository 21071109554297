import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import egitimJson from "../../json/egitim.json";
import analizJson from "../../json/siyasetmetre_analiz.json";
import demografiJson from "../../json/demografi.json";
import { useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";
import toastMessage from "../ToastMessage";
import { ToastContainer } from "react-toastify";
import PageChartCard from "../page-chart-card/pageChartCard";
import { Card, CardBody } from "../card/card";

const ChartBarTimeLine = ({
  setSesRaporuData,
  setEgitimData,
  setMedeniDurumData,
  setYasGruplariData,

  show = true,
  setNeighborhoodAgeData = false,
  setNeighborhoodEducationData = false,
  setNeighborhoodSocioEconomicData = false,
  setNeighborhoodMaritalData = false,
  id = null,
  path = "home",
  title,
  city,
  type,
  district,
  neighbourhood,
  street,
  graphic,
  card,
}) => {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [yearData, setYearData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [chartColor, setChartColor] = useState(null);

  useEffect(() => {
    setChartColor(context?.chartColor || "random");
  }, [context?.chartColor]);

  var colors = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#3ba272",
    "#73c0de",
    "#9a60b4",
    "#5470c6",
    "#ea7ccc",
    "#91cc75",
    "#fac858",
    "#73c0de",
    "#ee6666",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
  ];

  function popRandomColor() {
    var rand = Math.random();
    var color = colors[Math.floor(rand * colors.length)];
    colors.splice(Math.floor(rand * colors.length), 1);
    return color;
  }

  const option = {
    baseOption: {
      timeline: {
        left: 0,
        right: 0,
        axisType: "category",
        autoPlay: true,
        playInterval: 3000,
        checkpointStyle: {
          borderColor: chartColor === "random" ? "#ffffff" : chartColor,
          color: chartColor === "random" ? "#ffffff" : chartColor,
        },
        lineStyle: {
          color: "white",
        },
        itemStyle: {
          color: chartColor === "random" ? "#ffffff" : chartColor,
        },
        data: yearData,
        label: {
          formatter: function (s) {
            return new Date(s).getFullYear();
          },
        },
      },
      tooltip: {},
      calculable: true,
      grid: {
        right: 10,
        left: 70,
        top: 70,
        bottom: 110,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              formatter: function (params) {
                return params.value.replace("\n", "");
              },
            },
          },
        },
      },
      xAxis: [
        {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: 45,
            textStyle: {
              color: context?.theme === "light" ? "#000" : "#fff",
            },
            formatter: function (value, index) {
              return type === "yas" || type === "ses-raporu"
                ? value
                : value.substring(0, 7) + "...";
            },
          },
          data: titleData,
          splitLine: { show: false },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "",
          axisLabel: {
            textStyle: {
              color: context?.theme === "light" ? "#000" : "#fff",
            },
          },
        },
      ],
      series: [
        {
          name: "",
          type: "bar",
        },
      ],
      visualMap:
        chartColor === "random"
          ? false
          : {
              show: false,
              min: optionsData
                ? Math.min(
                    ...optionsData.flatMap((item) =>
                      item.series.flatMap((serie) => serie.data)
                    )
                  )
                : 0,
              max: optionsData
                ? Math.max(
                    ...optionsData.flatMap((item) =>
                      item.series.flatMap((serie) => serie.data)
                    )
                  )
                : 0,
              inRange: {
                colorLightness: [0.2, 1],
              },
            },
    },
    options: optionsData,
  };

  const getData = useCallback(async () => {
    if (chartColor) {
      setLoader(true);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: title,
            type: type === "yas" || type === "medeni-durum" ? "demograf" : type,
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
            sql: {
              column: [
                {
                  key: "Tarih",
                  value: "select",
                },
                {
                  key: type === "medeni-durum" ? "Alt-Kategori-2" : "Parametre",
                  value: "select",
                },
                {
                  key: "Deger",
                  value: type === "ses-raporu" ? "avg" : "sum",
                },
              ],
              condition: [
                type === "egitim"
                  ? {
                      key: "Alt-Kategori-1",
                      value: "GENEL EGITIM DUZEYLERI",
                      operator: "=",
                      type: "and",
                    }
                  : null,
                type === "yas"
                  ? {
                      key: "Alt-Kategori-1",
                      value: "YAS DAGILIMI",
                      operator: "=",
                      type: "and",
                    }
                  : null,
                type === "medeni-durum"
                  ? {
                      key: "Alt-Kategori-1",
                      value: "MEDENI DURUM",
                      operator: "=",
                      type: "and",
                    }
                  : null,
                city
                  ? {
                      key: "Sehir",
                      value: context
                        .removeTurkishCharacters(city)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                district
                  ? {
                      key: "Ilce",
                      value: context
                        .removeTurkishCharacters(district)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                neighbourhood
                  ? {
                      key: "Mahalle",
                      value: context
                        .removeTurkishCharacters(neighbourhood)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
              ].filter((item) => item !== null),
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setLoader(false);

        if (response.length !== 0) {
          if (type === "ses-raporu") {
            const inputArray = response
              .map((item) => ({
                ...item,
                parametre:
                  item.parametre === "AA (%)"
                    ? "A"
                    : item.parametre === "A (%)"
                    ? "B"
                    : item.parametre === "B (%)"
                    ? "C1"
                    : item.parametre === "C (%)"
                    ? "C2"
                    : item.parametre.split(" (%)")[0],
              }))
              .sort((a, b) => {
                // Önce tarih sıralaması yapılır (yıla göre artan sırada)
                const yearA = parseInt(a.tarih.split("-")[0], 10);
                const yearB = parseInt(b.tarih.split("-")[0], 10);
                return yearA - yearB;
              })
              .sort((a, b) => {
                // parametreyi ters alfabetik sıraya göre karşılaştır (Z'den A'ya)
                const paramA = a.parametre;
                const paramB = b.parametre;

                if (paramA < paramB) return 1;
                if (paramA > paramB) return -1;
                return 0;
              });

            // Her öğeyi tarih ve parametreye göre grupluyoruz ve deger_avg değerlerini topluyoruz
            const result = inputArray.reduce((acc, curr) => {
              const key = `${curr.tarih.split("-")[0]}-${curr.parametre}`;

              if (!acc[key]) {
                acc[key] = {
                  tarih: curr.tarih.split("-")[0],
                  parametre: curr.parametre,
                  deger_avg: 0, // deger yerine deger_avg kullanılıyor
                };
              }

              acc[key].deger_avg += curr.deger_avg; // Yeni formatta direkt float olduğu için toplama işlemi
              return acc;
            }, {});

            // Toplam deger_avg hesaplıyoruz
            const totalDegerAvg = Object.values(result).reduce(
              (sum, item) => sum + item.deger_avg,
              0
            );

            // Her bir öğenin deger_avg'sini yüzdeye çeviriyoruz
            const mergedData = Object.values(result).map((item) => ({
              tarih: item.tarih,
              parametre: item.parametre,
              deger_avg: ((item.deger_avg / totalDegerAvg) * 100).toFixed(2), // Yüzde hesaplama ve 2 ondalık basamağa yuvarlama
            }));

            //---

            const dates = [
              ...new Set(
                mergedData.map((item) => `${item.tarih.split("-")[0]}-`)
              ),
            ];
            const parameters = [
              ...new Set(mergedData.map((item) => item.parametre)),
            ];

            const groupedData = dates.map((date) => {
              const year = date.slice(0, 4);

              const seriesData = parameters.map((param) => {
                const entry = mergedData.find(
                  (item) =>
                    item.tarih.split("-")[0] + "-" === date &&
                    item.parametre === param
                );
                return entry ? entry.deger_avg : "0";
              });

              return {
                title: {
                  text: `${year} - ` + context?.t?.dagilim,
                  textStyle: {
                    color: context?.theme === "light" ? "#000" : "#fff",
                  },
                },
                series: [
                  {
                    data: seriesData,
                    itemStyle: {
                      color: chartColor === "random" ? null : chartColor,
                    },
                  },
                ],
              };
            });

            setYearData(dates);
            setTitleData(parameters);
            setOptionsData(groupedData);

            if (setSesRaporuData) {
              setSesRaporuData({ dates, parameters, groupedData });
            }

            //--

            if (setNeighborhoodSocioEconomicData) {
              if (response.length !== 0) {
                const reportTableWidth = [];
                const reportTableKeys = parameters;
                reportTableKeys.unshift(context?.t?.year);

                reportTableKeys.forEach((item) => {
                  reportTableWidth.push("*");
                });

                const reportOptionsData = [reportTableKeys];

                groupedData.forEach((element) => {
                  reportOptionsData.push([
                    element.title.text.match(/\d+/)[0],
                    ...element.series[0].data.map(String),
                  ]);
                });

                setNeighborhoodSocioEconomicData([
                  reportTableWidth,
                  reportOptionsData,
                ]);
              } else {
                setNeighborhoodSocioEconomicData(null);
              }
            }
          }

          if (type === "egitim") {
            const order = [
              "BILINMEYEN",
              "OKURYAZAR DEGIL",
              "OKURYAZAR FAKAT BIR OKUL BITIRMEDI",
              "ILKOKUL MEZUNU",
              "ILKOGRETIM MEZUNU",
              "ORTAOKUL VEYA DENGI MEZUNU",
              "LISE VEYA DENGI MEZUNU",
              "YUKSEK LISANS MEZUNU",
              "YUKSEKOKUL VEYA FAKULTE MEZUNU",
              "DOKTORA MEZUNU",
            ];

            // Tarihlerden min ve max yılı bulalım
            const years = response
              .map((item) => parseInt(item.tarih.split("-")[0])) // Yılları çıkar
              .filter((value, index, self) => self.indexOf(value) === index) // Tekrarlayan yılları filtrele
              .sort((a, b) => a - b); // Yılları sıralar

            const formattedYears = years.map((year) => `${year}-`);

            const minYear = Math.min(...years);
            const maxYear = Math.max(...years);

            // Min ve max yıllar arasında bir dizi oluşturalım
            const yearRange = [];
            for (let year = minYear; year <= maxYear; year++) {
              yearRange.push(year.toString());
            }

            // Her yıl için title ve series yapısını oluşturuyoruz
            const result = yearRange.map((year) => {
              // O yılki verileri filtrele
              const yearData = response.filter((d) => d.tarih.startsWith(year));

              // Sabit parametre listesinden eksik olanlara 0 ekle
              const dataArray = order.map((param) => {
                const paramData = yearData.find((d) => d.parametre === param);
                return paramData ? paramData.deger_sum : 0;
              });

              return {
                title: {
                  text: `${year} - Dağılım`,
                  textStyle: {
                    color: context?.theme === "light" ? "#000" : "#fff",
                  },
                },
                series: [
                  {
                    data: dataArray,
                    itemStyle: {
                      color: null,
                    },
                  },
                ],
              };
            });

            setYearData(formattedYears);
            setTitleData(order);
            setOptionsData(result);

            if (setEgitimData) {
              setEgitimData({
                formattedYears,
                order,
                result,
              });
            }

            if (setNeighborhoodEducationData) {
              if ([...new Set(order)].length !== 0) {
                const reportTitleData = [...new Set(order)];
                reportTitleData.unshift(context?.t?.year);
                const reportTableWidth = [];

                reportTitleData.forEach((item) => {
                  reportTableWidth.push("auto");
                });

                const reportOptionsData = [reportTitleData];

                result.forEach((element) => {
                  reportOptionsData.push([
                    element.title.text.match(/\d+/)[0],
                    ...element.series[0].data.map(String),
                  ]);
                });

                setNeighborhoodEducationData([
                  reportTableWidth,
                  reportOptionsData,
                ]);
              } else {
                setNeighborhoodEducationData(null);
              }
            }
          }

          if (type === "medeni-durum") {
            const filteredResponse = response.filter((item) =>
              ["EVLI", "BOSANDI", "HIC EVLENMEDI", "ESI OLDU"].includes(
                item["alt-kategori-2"]
              )
            );

            // Tarihe göre sıralama işlemi
            const inputArray = filteredResponse.sort(
              (a, b) =>
                parseInt(a.tarih.split("-")[0]) -
                parseInt(b.tarih.split("-")[0])
            );

            // Yıllar listesini çıkar ve sıralı hale getir
            const years = [
              ...new Set(
                inputArray.map((item) => parseInt(item.tarih.split("-")[0]))
              ),
            ].sort((a, b) => a - b);

            // Parametreleri çıkar, type durumuna göre "medeni-durum" kontrolü
            const parameters = [
              ...new Set(
                inputArray.map((item) =>
                  type === "medeni-durum"
                    ? item["alt-kategori-2"]
                    : item.parametre
                )
              ),
            ];

            // Yılları uygun formatta formatla
            const formattedYears = years.map((year) => `${year}-`);

            // Chart Data'yı oluştur
            const chartData = years.map((year) => {
              // Her yıl için ilgili veriyi filtrele
              const filteredData = inputArray.filter(
                (item) => parseInt(item.tarih.split("-")[0]) === year
              );

              // O yılın chart verisini oluştur
              return {
                title: {
                  text: `${year} - ` + context?.t?.dagilim,
                  textStyle: {
                    color: context?.theme === "light" ? "#000" : "#fff",
                  },
                },
                series: [
                  {
                    data: parameters.map((param) => {
                      const foundItem = filteredData.find(
                        (item) =>
                          (type === "medeni-durum"
                            ? item["alt-kategori-2"]
                            : item.parametre) === param
                      );
                      return foundItem ? foundItem.deger_sum : 0; // Parametre yoksa 0
                    }),
                    itemStyle: {
                      color: chartColor === "random" ? null : chartColor,
                    },
                  },
                ],
              };
            });

            setYearData(formattedYears);
            setTitleData([...parameters]);
            setOptionsData(chartData);

            if (setMedeniDurumData) {
              setMedeniDurumData({
                formattedYears,
                parameters: [...parameters],
                chartData,
              });
            }

            //--

            if (setNeighborhoodMaritalData) {
              if ([...new Set(parameters)].length !== 0) {
                const reportTitleData = [...new Set(parameters)];
                reportTitleData.unshift(context?.t?.year);
                const reportTableWidth = [];

                reportTitleData.forEach((item) => {
                  reportTableWidth.push("*");
                });

                const reportOptionsData = [reportTitleData];

                chartData.forEach((element) => {
                  reportOptionsData.push([
                    element.title.text.match(/\d+/)[0],
                    ...element.series[0].data.map(String),
                  ]);
                });

                reportOptionsData.forEach((element, index) => {
                  if (index !== 0) {
                    if (element.length === 4) {
                      element.push("0");
                    }
                  }
                });

                setNeighborhoodMaritalData([
                  reportTableWidth,
                  reportOptionsData,
                ]);
              } else {
                setNeighborhoodMaritalData(null);
              }
            }
          }

          if (type === "yas") {
            const sortedResponse = response.sort((a, b) => {
              const aStart = parseInt(
                a.parametre.split("-")[0].replace("+", "")
              );
              const bStart = parseInt(
                b.parametre.split("-")[0].replace("+", "")
              );
              return aStart - bStart;
            });

            // Tarihe göre sıralama işlemi
            const inputArray = sortedResponse.sort(
              (a, b) =>
                parseInt(a.tarih.split("-")[0]) -
                parseInt(b.tarih.split("-")[0])
            );

            // Yıllar listesini çıkar ve sıralı hale getir
            const years = [
              ...new Set(
                inputArray.map((item) => parseInt(item.tarih.split("-")[0]))
              ),
            ].sort((a, b) => a - b);

            // Parametreleri çıkar, type durumuna göre "medeni-durum" kontrolü
            const parameters = [
              ...new Set(inputArray.map((item) => item.parametre)),
            ];

            // Yılları uygun formatta formatla
            const formattedYears = years.map((year) => `${year}-`);

            // Chart Data'yı oluştur
            const chartData = years.map((year) => {
              // Her yıl için ilgili veriyi filtrele
              const filteredData = inputArray.filter(
                (item) => parseInt(item.tarih.split("-")[0]) === year
              );

              // O yılın chart verisini oluştur
              return {
                title: {
                  text: `${year} - ` + context?.t?.dagilim,
                  textStyle: {
                    color: context?.theme === "light" ? "#000" : "#fff",
                  },
                },
                series: [
                  {
                    data: parameters.map((param) => {
                      const foundItem = filteredData.find(
                        (item) => item.parametre === param
                      );
                      return foundItem ? foundItem.deger_sum : 0; // Parametre yoksa 0
                    }),
                    itemStyle: {
                      color: chartColor === "random" ? null : chartColor,
                    },
                  },
                ],
              };
            });

            setYearData(formattedYears);
            setTitleData([...parameters]);
            setOptionsData(chartData);

            if (setYasGruplariData) {
              setYasGruplariData({
                formattedYears,
                parameters: [...parameters],
                chartData,
              });
            }

            //--

            if (setNeighborhoodAgeData) {
              if ([...new Set(parameters)].length !== 0) {
                const reportTitleData = [...new Set(parameters)];
                reportTitleData.unshift(context?.t?.year);
                const reportTableWidth = [];

                reportTitleData.forEach((item) => {
                  reportTableWidth.push("*");
                });

                const reportOptionsData = [reportTitleData];

                chartData.forEach((element) => {
                  reportOptionsData.push([
                    element.title.text.match(/\d+/)[0],
                    ...element.series[0].data.map(String),
                  ]);
                });

                setNeighborhoodAgeData([reportTableWidth, reportOptionsData]);
              } else {
                setNeighborhoodAgeData(null);
              }
            }
          }
        } else {
          setYearData([]);
          setTitleData([]);
          setOptionsData([]);
          setTableData([]);

          if (setNeighborhoodEducationData) {
            setNeighborhoodEducationData(null);
          }
          if (setNeighborhoodSocioEconomicData) {
            setNeighborhoodSocioEconomicData([[], [[], []]]);
          }
          if (setNeighborhoodMaritalData) {
            setNeighborhoodMaritalData([[], [[], []]]);
          }
          if (setNeighborhoodAgeData) {
            setNeighborhoodAgeData([[], [[], []]]);
          }
        }
      } catch (error) {
        setLoader(false);
        setYearData([]);
        setTitleData([]);
        setOptionsData([]);
        setTableData([]);

        console.error((show ? title : "-") + " Error", error);
      }
    }
  }, [
    chartColor,
    city,
    district,
    neighbourhood,
    setNeighborhoodEducationData,
    setNeighborhoodSocioEconomicData,
    setNeighborhoodMaritalData,
    setNeighborhoodAgeData,
    show,
    title,
    type,
  ]);

  useEffect(() => {
    getData();
  }, [getData, chartColor]);

  return (
    <>
      {show && (
        <div className="h-100">
          {!loader ? (
            optionsData.length !== 0 ? (
              card ? (
                <PageChartCard
                  id={id}
                  title={title}
                  autoIncrement={4324}
                  path={path}
                  city={city}
                  district={district}
                  neighbourhood={neighbourhood}
                  street={street}
                  graphic={graphic}
                  chart={
                    <ReactECharts
                      option={option}
                      className="w-100 pb-5"
                      style={{ height: "100%" }}
                    />
                  }
                  height="auto"
                  chartType="timeline"
                  tableData={[titleData, optionsData]}
                />
              ) : (
                <ReactECharts
                  option={option}
                  className="w-100 pb-0"
                  style={{ height: "100%" }}
                />
              )
            ) : (
              <div className="d-flex align-items-center justify-content-center h-100">
                <span className="d-block text-center">
                  {context?.t?.no_data_available}
                </span>
              </div>
            )
          ) : (
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="spinner-border text-theme"></div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ChartBarTimeLine;
