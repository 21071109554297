import React, { useState, useContext, useEffect } from "react";

import Select from "react-select";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../ToastMessage";

const iconsOptions = [
  {
    value: "fas fa-address-book",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-address-book"></i>
        <span>address-book</span>
      </div>
    ),
  },
  {
    value: "fas fa-address-card",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-address-card"></i>
        <span>address-card</span>
      </div>
    ),
  },
  {
    value: "fas fa-adjust",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-adjust"></i> <span>adjust</span>
      </div>
    ),
  },
  {
    value: "fas fa-align-center",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-align-center"></i>
        <span>align-center</span>
      </div>
    ),
  },
  {
    value: "fas fa-align-justify",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-align-justify"></i>
        <span>align-justify</span>
      </div>
    ),
  },
  {
    value: "fas fa-align-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-align-left"></i>
        <span>align-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-align-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-align-right"></i>
        <span>align-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-ambulance",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-ambulance"></i>
        <span>ambulance</span>
      </div>
    ),
  },
  {
    value: "fas fa-american-sign-language-interpreting",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-american-sign-language-interpreting"></i>
        <span>american-sign-language-interpreting</span>
      </div>
    ),
  },
  {
    value: "fas fa-anchor",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-anchor"></i> <span>anchor</span>
      </div>
    ),
  },
  {
    value: "fas fa-angle-double-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-angle-double-down"></i>
        <span>angle-double-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-angle-double-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-angle-double-left"></i>
        <span>angle-double-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-angle-double-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-angle-double-right"></i>
        <span>angle-double-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-angle-double-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-angle-double-up"></i>
        <span>angle-double-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-angle-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-angle-down"></i>
        <span>angle-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-angle-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-angle-left"></i>
        <span>angle-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-angle-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-angle-right"></i>
        <span>angle-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-angle-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-angle-up"></i>
        <span>angle-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-archive",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-archive"></i> <span>archive</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-alt-circle-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-alt-circle-down"></i>
        <span>arrow-alt-circle-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-alt-circle-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-alt-circle-left"></i>
        <span>arrow-alt-circle-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-alt-circle-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-alt-circle-right"></i>
        <span>arrow-alt-circle-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-alt-circle-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-alt-circle-up"></i>
        <span>arrow-alt-circle-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-circle-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-circle-down"></i>
        <span>arrow-circle-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-circle-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-circle-left"></i>
        <span>arrow-circle-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-circle-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-circle-right"></i>
        <span>arrow-circle-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-circle-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-circle-up"></i>
        <span>arrow-circle-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-down"></i>
        <span>arrow-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-left"></i>
        <span>arrow-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-right"></i>
        <span>arrow-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrow-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrow-up"></i>
        <span>arrow-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrows-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrows-alt"></i>
        <span>arrows-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrows-alt-h",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrows-alt-h"></i>
        <span>arrows-alt-h</span>
      </div>
    ),
  },
  {
    value: "fas fa-arrows-alt-v",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-arrows-alt-v"></i>
        <span>arrows-alt-v</span>
      </div>
    ),
  },
  {
    value: "fas fa-assistive-listening-systems",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-assistive-listening-systems"></i>
        <span>assistive-listening-systems</span>
      </div>
    ),
  },
  {
    value: "fas fa-asterisk",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-asterisk"></i>
        <span>asterisk</span>
      </div>
    ),
  },
  {
    value: "fas fa-at",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-at"></i> <span>at</span>
      </div>
    ),
  },
  {
    value: "fas fa-audio-description",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-audio-description"></i>
        <span>audio-description</span>
      </div>
    ),
  },
  {
    value: "fas fa-backward",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-backward"></i>
        <span>backward</span>
      </div>
    ),
  },
  {
    value: "fas fa-balance-scale",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-balance-scale"></i>
        <span>balance-scale</span>
      </div>
    ),
  },
  {
    value: "fas fa-ban",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-ban"></i> <span>ban</span>
      </div>
    ),
  },
  {
    value: "fas fa-barcode",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-barcode"></i> <span>barcode</span>
      </div>
    ),
  },
  {
    value: "fas fa-bars",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bars"></i> <span>bars</span>
      </div>
    ),
  },
  {
    value: "fas fa-bath",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bath"></i> <span>bath</span>
      </div>
    ),
  },
  {
    value: "fas fa-battery-empty",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-battery-empty"></i>
        <span>battery-empty</span>
      </div>
    ),
  },
  {
    value: "fas fa-battery-full",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-battery-full"></i>
        <span>battery-full</span>
      </div>
    ),
  },
  {
    value: "fas fa-battery-half",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-battery-half"></i>
        <span>battery-half</span>
      </div>
    ),
  },
  {
    value: "fas fa-battery-quarter",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-battery-quarter"></i>
        <span>battery-quarter</span>
      </div>
    ),
  },
  {
    value: "fas fa-battery-three-quarters",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-battery-three-quarters"></i>
        <span>battery-three-quarters</span>
      </div>
    ),
  },
  {
    value: "fas fa-bed",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bed"></i> <span>bed</span>
      </div>
    ),
  },
  {
    value: "fas fa-beer",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-beer"></i> <span>beer</span>
      </div>
    ),
  },
  {
    value: "fas fa-bell",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bell"></i> <span>bell</span>
      </div>
    ),
  },
  {
    value: "fas fa-bell-slash",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bell-slash"></i>
        <span>bell-slash</span>
      </div>
    ),
  },
  {
    value: "fas fa-bicycle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bicycle"></i> <span>bicycle</span>
      </div>
    ),
  },
  {
    value: "fas fa-binoculars",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-binoculars"></i>
        <span>binoculars</span>
      </div>
    ),
  },
  {
    value: "fas fa-birthday-cake",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-birthday-cake"></i>
        <span>birthday-cake</span>
      </div>
    ),
  },
  {
    value: "fas fa-blind",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-blind"></i> <span>blind</span>
      </div>
    ),
  },
  {
    value: "fas fa-bold",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bold"></i> <span>bold</span>
      </div>
    ),
  },
  {
    value: "fas fa-bolt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bolt"></i> <span>bolt</span>
      </div>
    ),
  },
  {
    value: "fas fa-bomb",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bomb"></i> <span>bomb</span>
      </div>
    ),
  },
  {
    value: "fas fa-book",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-book"></i> <span>book</span>
      </div>
    ),
  },
  {
    value: "fas fa-bookmark",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bookmark"></i>
        <span>bookmark</span>
      </div>
    ),
  },
  {
    value: "fas fa-braille",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-braille"></i> <span>braille</span>
      </div>
    ),
  },
  {
    value: "fas fa-briefcase",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-briefcase"></i>
        <span>briefcase</span>
      </div>
    ),
  },
  {
    value: "fas fa-bug",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bug"></i> <span>bug</span>
      </div>
    ),
  },
  {
    value: "fas fa-building",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-building"></i>
        <span>building</span>
      </div>
    ),
  },
  {
    value: "fas fa-bullhorn",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bullhorn"></i>
        <span>bullhorn</span>
      </div>
    ),
  },
  {
    value: "fas fa-bullseye",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bullseye"></i>
        <span>bullseye</span>
      </div>
    ),
  },
  {
    value: "fas fa-bus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-bus"></i> <span>bus</span>
      </div>
    ),
  },
  {
    value: "fas fa-calculator",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-calculator"></i>
        <span>calculator</span>
      </div>
    ),
  },
  {
    value: "fas fa-calendar",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-calendar"></i>
        <span>calendar</span>
      </div>
    ),
  },
  {
    value: "fas fa-calendar-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-calendar-alt"></i>
        <span>calendar-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-calendar-check",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-calendar-check"></i>
        <span>calendar-check</span>
      </div>
    ),
  },
  {
    value: "fas fa-calendar-minus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-calendar-minus"></i>
        <span>calendar-minus</span>
      </div>
    ),
  },
  {
    value: "fas fa-calendar-plus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-calendar-plus"></i>
        <span>calendar-plus</span>
      </div>
    ),
  },
  {
    value: "fas fa-calendar-times",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-calendar-times"></i>
        <span>calendar-times</span>
      </div>
    ),
  },
  {
    value: "fas fa-camera",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-camera"></i> <span>camera</span>
      </div>
    ),
  },
  {
    value: "fas fa-camera-retro",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-camera-retro"></i>
        <span>camera-retro</span>
      </div>
    ),
  },
  {
    value: "fas fa-car",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-car"></i> <span>car</span>
      </div>
    ),
  },
  {
    value: "fas fa-caret-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-caret-down"></i>
        <span>caret-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-caret-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-caret-left"></i>
        <span>caret-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-caret-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-caret-right"></i>
        <span>caret-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-caret-square-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-caret-square-down"></i>
        <span>caret-square-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-caret-square-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-caret-square-left"></i>
        <span>caret-square-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-caret-square-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-caret-square-right"></i>
        <span>caret-square-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-caret-square-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-caret-square-up"></i>
        <span>caret-square-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-caret-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-caret-up"></i>
        <span>caret-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-cart-arrow-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-cart-arrow-down"></i>
        <span>cart-arrow-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-cart-plus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-cart-plus"></i>
        <span>cart-plus</span>
      </div>
    ),
  },
  {
    value: "fas fa-certificate",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-certificate"></i>
        <span>certificate</span>
      </div>
    ),
  },
  {
    value: "fas fa-chart-area",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chart-area"></i>
        <span>chart-area</span>
      </div>
    ),
  },
  {
    value: "fas fa-chart-bar",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chart-bar"></i>
        <span>chart-bar</span>
      </div>
    ),
  },
  {
    value: "fas fa-chart-line",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chart-line"></i>
        <span>chart-line</span>
      </div>
    ),
  },
  {
    value: "fas fa-chart-pie",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chart-pie"></i>
        <span>chart-pie</span>
      </div>
    ),
  },
  {
    value: "fas fa-check",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-check"></i> <span>check</span>
      </div>
    ),
  },
  {
    value: "fas fa-check-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-check-circle"></i>
        <span>check-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-check-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-check-square"></i>
        <span>check-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-chevron-circle-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chevron-circle-down"></i>
        <span>chevron-circle-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-chevron-circle-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chevron-circle-left"></i>
        <span>chevron-circle-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-chevron-circle-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chevron-circle-right"></i>
        <span>chevron-circle-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-chevron-circle-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chevron-circle-up"></i>
        <span>chevron-circle-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-chevron-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chevron-down"></i>
        <span>chevron-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-chevron-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chevron-left"></i>
        <span>chevron-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-chevron-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chevron-right"></i>
        <span>chevron-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-chevron-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-chevron-up"></i>
        <span>chevron-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-child",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-child"></i> <span>child</span>
      </div>
    ),
  },
  {
    value: "fas fa-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-circle"></i> <span>circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-circle-notch",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-circle-notch"></i>
        <span>circle-notch</span>
      </div>
    ),
  },
  {
    value: "fas fa-clipboard",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-clipboard"></i>
        <span>clipboard</span>
      </div>
    ),
  },
  {
    value: "fas fa-clock",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-clock"></i> <span>clock</span>
      </div>
    ),
  },
  {
    value: "fas fa-clone",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-clone"></i> <span>clone</span>
      </div>
    ),
  },
  {
    value: "fas fa-closed-captioning",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-closed-captioning"></i>
        <span>closed-captioning</span>
      </div>
    ),
  },
  {
    value: "fas fa-cloud",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-cloud"></i> <span>cloud</span>
      </div>
    ),
  },
  {
    value: "fas fa-code",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-code"></i> <span>code</span>
      </div>
    ),
  },
  {
    value: "fas fa-code-branch",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-code-branch"></i>
        <span>code-branch</span>
      </div>
    ),
  },
  {
    value: "fas fa-coffee",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-coffee"></i> <span>coffee</span>
      </div>
    ),
  },
  {
    value: "fas fa-cog",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-cog"></i> <span>cog</span>
      </div>
    ),
  },
  {
    value: "fas fa-cogs",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-cogs"></i> <span>cogs</span>
      </div>
    ),
  },
  {
    value: "fas fa-columns",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-columns"></i> <span>columns</span>
      </div>
    ),
  },
  {
    value: "fas fa-comment",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-comment"></i> <span>comment</span>
      </div>
    ),
  },
  {
    value: "fas fa-comment-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-comment-alt"></i>
        <span>comment-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-comments",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-comments"></i>
        <span>comments</span>
      </div>
    ),
  },
  {
    value: "fas fa-compass",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-compass"></i> <span>compass</span>
      </div>
    ),
  },
  {
    value: "fas fa-compress",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-compress"></i>
        <span>compress</span>
      </div>
    ),
  },
  {
    value: "fas fa-copy",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-copy"></i> <span>copy</span>
      </div>
    ),
  },
  {
    value: "fas fa-copyright",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-copyright"></i>
        <span>copyright</span>
      </div>
    ),
  },
  {
    value: "fas fa-credit-card",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-credit-card"></i>
        <span>credit-card</span>
      </div>
    ),
  },
  {
    value: "fas fa-crop",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-crop"></i> <span>crop</span>
      </div>
    ),
  },
  {
    value: "fas fa-crosshairs",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-crosshairs"></i>
        <span>crosshairs</span>
      </div>
    ),
  },
  {
    value: "fas fa-cube",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-cube"></i> <span>cube</span>
      </div>
    ),
  },
  {
    value: "fas fa-cubes",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-cubes"></i> <span>cubes</span>
      </div>
    ),
  },
  {
    value: "fas fa-cut",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-cut"></i> <span>cut</span>
      </div>
    ),
  },
  {
    value: "fas fa-database",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-database"></i>
        <span>database</span>
      </div>
    ),
  },
  {
    value: "fas fa-deaf",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-deaf"></i> <span>deaf</span>
      </div>
    ),
  },
  {
    value: "fas fa-dollar-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-dollar-sign"></i>
        <span>dollar-sign</span>
      </div>
    ),
  },
  {
    value: "fas fa-dot-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-dot-circle"></i>
        <span>dot-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-download",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-download"></i>
        <span>download</span>
      </div>
    ),
  },
  {
    value: "fas fa-edit",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-edit"></i> <span>edit</span>
      </div>
    ),
  },
  {
    value: "fas fa-eject",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-eject"></i> <span>eject</span>
      </div>
    ),
  },
  {
    value: "fas fa-ellipsis-h",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-ellipsis-h"></i>
        <span>ellipsis-h</span>
      </div>
    ),
  },
  {
    value: "fas fa-ellipsis-v",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-ellipsis-v"></i>
        <span>ellipsis-v</span>
      </div>
    ),
  },
  {
    value: "fas fa-envelope",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-envelope"></i>
        <span>envelope</span>
      </div>
    ),
  },
  {
    value: "fas fa-envelope-open",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-envelope-open"></i>
        <span>envelope-open</span>
      </div>
    ),
  },
  {
    value: "fas fa-envelope-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-envelope-square"></i>
        <span>envelope-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-eraser",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-eraser"></i> <span>eraser</span>
      </div>
    ),
  },
  {
    value: "fas fa-euro-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-euro-sign"></i>
        <span>euro-sign</span>
      </div>
    ),
  },
  {
    value: "fas fa-exchange-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-exchange-alt"></i>
        <span>exchange-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-exclamation",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-exclamation"></i>
        <span>exclamation</span>
      </div>
    ),
  },
  {
    value: "fas fa-exclamation-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-exclamation-circle"></i>
        <span>exclamation-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-exclamation-triangle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-exclamation-triangle"></i>
        <span>exclamation-triangle</span>
      </div>
    ),
  },
  {
    value: "fas fa-expand",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-expand"></i> <span>expand</span>
      </div>
    ),
  },
  {
    value: "fas fa-expand-arrows-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-expand-arrows-alt"></i>
        <span>expand-arrows-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-external-link-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-external-link-alt"></i>
        <span>external-link-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-external-link-square-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-external-link-square-alt"></i>
        <span>external-link-square-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-eye",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-eye"></i> <span>eye</span>
      </div>
    ),
  },
  {
    value: "fas fa-eye-dropper",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-eye-dropper"></i>
        <span>eye-dropper</span>
      </div>
    ),
  },
  {
    value: "fas fa-eye-slash",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-eye-slash"></i>
        <span>eye-slash</span>
      </div>
    ),
  },
  {
    value: "fas fa-fast-backward",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-fast-backward"></i>
        <span>fast-backward</span>
      </div>
    ),
  },
  {
    value: "fas fa-fast-forward",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-fast-forward"></i>
        <span>fast-forward</span>
      </div>
    ),
  },
  {
    value: "fas fa-fax",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-fax"></i> <span>fax</span>
      </div>
    ),
  },
  {
    value: "fas fa-female",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-female"></i> <span>female</span>
      </div>
    ),
  },
  {
    value: "fas fa-fighter-jet",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-fighter-jet"></i>
        <span>fighter-jet</span>
      </div>
    ),
  },
  {
    value: "fas fa-file",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file"></i> <span>file</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-alt"></i>
        <span>file-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-archive",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-archive"></i>
        <span>file-archive</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-audio",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-audio"></i>
        <span>file-audio</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-code",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-code"></i>
        <span>file-code</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-excel",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-excel"></i>
        <span>file-excel</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-image",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-image"></i>
        <span>file-image</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-pdf",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-pdf"></i>
        <span>file-pdf</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-powerpoint",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-powerpoint"></i>
        <span>file-powerpoint</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-video",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-video"></i>
        <span>file-video</span>
      </div>
    ),
  },
  {
    value: "fas fa-file-word",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-file-word"></i>
        <span>file-word</span>
      </div>
    ),
  },
  {
    value: "fas fa-film",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-film"></i> <span>film</span>
      </div>
    ),
  },
  {
    value: "fas fa-filter",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-filter"></i> <span>filter</span>
      </div>
    ),
  },
  {
    value: "fas fa-fire",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-fire"></i> <span>fire</span>
      </div>
    ),
  },
  {
    value: "fas fa-fire-extinguisher",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-fire-extinguisher"></i>
        <span>fire-extinguisher</span>
      </div>
    ),
  },
  {
    value: "fas fa-flag",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-flag"></i> <span>flag</span>
      </div>
    ),
  },
  {
    value: "fas fa-flag-checkered",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-flag-checkered"></i>
        <span>flag-checkered</span>
      </div>
    ),
  },
  {
    value: "fas fa-flask",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-flask"></i> <span>flask</span>
      </div>
    ),
  },
  {
    value: "fas fa-folder",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-folder"></i> <span>folder</span>
      </div>
    ),
  },
  {
    value: "fas fa-folder-open",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-folder-open"></i>
        <span>folder-open</span>
      </div>
    ),
  },
  {
    value: "fas fa-font",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-font"></i> <span>font</span>
      </div>
    ),
  },
  {
    value: "fas fa-forward",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-forward"></i> <span>forward</span>
      </div>
    ),
  },
  {
    value: "fas fa-frown",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-frown"></i> <span>frown</span>
      </div>
    ),
  },
  {
    value: "fas fa-futbol",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-futbol"></i> <span>futbol</span>
      </div>
    ),
  },
  {
    value: "fas fa-gamepad",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-gamepad"></i> <span>gamepad</span>
      </div>
    ),
  },
  {
    value: "fas fa-gavel",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-gavel"></i> <span>gavel</span>
      </div>
    ),
  },
  {
    value: "fas fa-gem",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-gem"></i> <span>gem</span>
      </div>
    ),
  },
  {
    value: "fas fa-genderless",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-genderless"></i>
        <span>genderless</span>
      </div>
    ),
  },
  {
    value: "fas fa-gift",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-gift"></i> <span>gift</span>
      </div>
    ),
  },
  {
    value: "fas fa-glass-martini",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-glass-martini"></i>
        <span>glass-martini</span>
      </div>
    ),
  },
  {
    value: "fas fa-globe",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-globe"></i> <span>globe</span>
      </div>
    ),
  },
  {
    value: "fas fa-graduation-cap",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-graduation-cap"></i>
        <span>graduation-cap</span>
      </div>
    ),
  },
  {
    value: "fas fa-h-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-h-square"></i>
        <span>h-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-lizard",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-lizard"></i>
        <span>hand-lizard</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-paper",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-paper"></i>
        <span>hand-paper</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-peace",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-peace"></i>
        <span>hand-peace</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-point-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-point-down"></i>
        <span>hand-point-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-point-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-point-left"></i>
        <span>hand-point-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-point-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-point-right"></i>
        <span>hand-point-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-point-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-point-up"></i>
        <span>hand-point-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-pointer",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-pointer"></i>
        <span>hand-pointer</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-rock",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-rock"></i>
        <span>hand-rock</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-scissors",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-scissors"></i>
        <span>hand-scissors</span>
      </div>
    ),
  },
  {
    value: "fas fa-hand-spock",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hand-spock"></i>
        <span>hand-spock</span>
      </div>
    ),
  },
  {
    value: "fas fa-handshake",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-handshake"></i>
        <span>handshake</span>
      </div>
    ),
  },
  {
    value: "fas fa-hashtag",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hashtag"></i> <span>hashtag</span>
      </div>
    ),
  },
  {
    value: "fas fa-hdd",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hdd"></i> <span>hdd</span>
      </div>
    ),
  },
  {
    value: "fas fa-heading",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-heading"></i> <span>heading</span>
      </div>
    ),
  },
  {
    value: "fas fa-headphones",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-headphones"></i>
        <span>headphones</span>
      </div>
    ),
  },
  {
    value: "fas fa-heart",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-heart"></i> <span>heart</span>
      </div>
    ),
  },
  {
    value: "fas fa-heartbeat",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-heartbeat"></i>
        <span>heartbeat</span>
      </div>
    ),
  },
  {
    value: "fas fa-history",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-history"></i> <span>history</span>
      </div>
    ),
  },
  {
    value: "fas fa-home",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-home"></i> <span>home</span>
      </div>
    ),
  },
  {
    value: "fas fa-hospital",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hospital"></i>
        <span>hospital</span>
      </div>
    ),
  },
  {
    value: "fas fa-hourglass",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hourglass"></i>
        <span>hourglass</span>
      </div>
    ),
  },
  {
    value: "fas fa-hourglass-end",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hourglass-end"></i>
        <span>hourglass-end</span>
      </div>
    ),
  },
  {
    value: "fas fa-hourglass-half",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hourglass-half"></i>
        <span>hourglass-half</span>
      </div>
    ),
  },
  {
    value: "fas fa-hourglass-start",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-hourglass-start"></i>
        <span>hourglass-start</span>
      </div>
    ),
  },
  {
    value: "fas fa-i-cursor",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-i-cursor"></i>
        <span>i-cursor</span>
      </div>
    ),
  },
  {
    value: "fas fa-id-badge",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-id-badge"></i>
        <span>id-badge</span>
      </div>
    ),
  },
  {
    value: "fas fa-id-card",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-id-card"></i> <span>id-card</span>
      </div>
    ),
  },
  {
    value: "fas fa-image",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-image"></i> <span>image</span>
      </div>
    ),
  },
  {
    value: "fas fa-images",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-images"></i> <span>images</span>
      </div>
    ),
  },
  {
    value: "fas fa-inbox",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-inbox"></i> <span>inbox</span>
      </div>
    ),
  },
  {
    value: "fas fa-indent",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-indent"></i> <span>indent</span>
      </div>
    ),
  },
  {
    value: "fas fa-industry",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-industry"></i>
        <span>industry</span>
      </div>
    ),
  },
  {
    value: "fas fa-info",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-info"></i> <span>info</span>
      </div>
    ),
  },
  {
    value: "fas fa-info-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-info-circle"></i>
        <span>info-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-italic",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-italic"></i> <span>italic</span>
      </div>
    ),
  },
  {
    value: "fas fa-key",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-key"></i> <span>key</span>
      </div>
    ),
  },
  {
    value: "fas fa-keyboard",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-keyboard"></i>
        <span>keyboard</span>
      </div>
    ),
  },
  {
    value: "fas fa-language",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-language"></i>
        <span>language</span>
      </div>
    ),
  },
  {
    value: "fas fa-laptop",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-laptop"></i> <span>laptop</span>
      </div>
    ),
  },
  {
    value: "fas fa-leaf",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-leaf"></i> <span>leaf</span>
      </div>
    ),
  },
  {
    value: "fas fa-lemon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-lemon"></i> <span>lemon</span>
      </div>
    ),
  },
  {
    value: "fas fa-level-down-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-level-down-alt"></i>
        <span>level-down-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-level-up-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-level-up-alt"></i>
        <span>level-up-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-life-ring",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-life-ring"></i>
        <span>life-ring</span>
      </div>
    ),
  },
  {
    value: "fas fa-lightbulb",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-lightbulb"></i>
        <span>lightbulb</span>
      </div>
    ),
  },
  {
    value: "fas fa-link",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-link"></i> <span>link</span>
      </div>
    ),
  },
  {
    value: "fas fa-lira-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-lira-sign"></i>
        <span>lira-sign</span>
      </div>
    ),
  },
  {
    value: "fas fa-list",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-list"></i> <span>list</span>
      </div>
    ),
  },
  {
    value: "fas fa-list-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-list-alt"></i>
        <span>list-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-list-ol",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-list-ol"></i> <span>list-ol</span>
      </div>
    ),
  },
  {
    value: "fas fa-list-ul",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-list-ul"></i> <span>list-ul</span>
      </div>
    ),
  },
  {
    value: "fas fa-location-arrow",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-location-arrow"></i>
        <span>location-arrow</span>
      </div>
    ),
  },
  {
    value: "fas fa-lock",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-lock"></i> <span>lock</span>
      </div>
    ),
  },
  {
    value: "fas fa-lock-open",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-lock-open"></i>
        <span>lock-open</span>
      </div>
    ),
  },
  {
    value: "fas fa-long-arrow-alt-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-long-arrow-alt-down"></i>
        <span>long-arrow-alt-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-long-arrow-alt-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-long-arrow-alt-left"></i>
        <span>long-arrow-alt-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-long-arrow-alt-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-long-arrow-alt-right"></i>
        <span>long-arrow-alt-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-long-arrow-alt-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-long-arrow-alt-up"></i>
        <span>long-arrow-alt-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-low-vision",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-low-vision"></i>
        <span>low-vision</span>
      </div>
    ),
  },
  {
    value: "fas fa-magic",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-magic"></i> <span>magic</span>
      </div>
    ),
  },
  {
    value: "fas fa-magnet",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-magnet"></i> <span>magnet</span>
      </div>
    ),
  },
  {
    value: "fas fa-male",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-male"></i> <span>male</span>
      </div>
    ),
  },
  {
    value: "fas fa-map",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-map"></i> <span>map</span>
      </div>
    ),
  },
  {
    value: "fas fa-map-marker",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-map-marker"></i>
        <span>map-marker</span>
      </div>
    ),
  },
  {
    value: "fas fa-map-marker-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-map-marker-alt"></i>
        <span>map-marker-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-map-pin",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-map-pin"></i> <span>map-pin</span>
      </div>
    ),
  },
  {
    value: "fas fa-map-signs",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-map-signs"></i>
        <span>map-signs</span>
      </div>
    ),
  },
  {
    value: "fas fa-mars",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-mars"></i> <span>mars</span>
      </div>
    ),
  },
  {
    value: "fas fa-mars-double",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-mars-double"></i>
        <span>mars-double</span>
      </div>
    ),
  },
  {
    value: "fas fa-mars-stroke",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-mars-stroke"></i>
        <span>mars-stroke</span>
      </div>
    ),
  },
  {
    value: "fas fa-mars-stroke-h",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-mars-stroke-h"></i>
        <span>mars-stroke-h</span>
      </div>
    ),
  },
  {
    value: "fas fa-mars-stroke-v",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-mars-stroke-v"></i>
        <span>mars-stroke-v</span>
      </div>
    ),
  },
  {
    value: "fas fa-medkit",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-medkit"></i> <span>medkit</span>
      </div>
    ),
  },
  {
    value: "fas fa-meh",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-meh"></i> <span>meh</span>
      </div>
    ),
  },
  {
    value: "fas fa-mercury",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-mercury"></i> <span>mercury</span>
      </div>
    ),
  },
  {
    value: "fas fa-microchip",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-microchip"></i>
        <span>microchip</span>
      </div>
    ),
  },
  {
    value: "fas fa-microphone",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-microphone"></i>
        <span>microphone</span>
      </div>
    ),
  },
  {
    value: "fas fa-microphone-slash",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-microphone-slash"></i>
        <span>microphone-slash</span>
      </div>
    ),
  },
  {
    value: "fas fa-minus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-minus"></i> <span>minus</span>
      </div>
    ),
  },
  {
    value: "fas fa-minus-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-minus-circle"></i>
        <span>minus-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-minus-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-minus-square"></i>
        <span>minus-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-mobile-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-mobile-alt"></i>
        <span>mobile-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-money-bill-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-money-bill-alt"></i>
        <span>money-bill-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-moon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-moon"></i> <span>moon</span>
      </div>
    ),
  },
  {
    value: "fas fa-motorcycle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-motorcycle"></i>
        <span>motorcycle</span>
      </div>
    ),
  },
  {
    value: "fas fa-mouse-pointer",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-mouse-pointer"></i>
        <span>mouse-pointer</span>
      </div>
    ),
  },
  {
    value: "fas fa-music",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-music"></i> <span>music</span>
      </div>
    ),
  },
  {
    value: "fas fa-neuter",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-neuter"></i> <span>neuter</span>
      </div>
    ),
  },
  {
    value: "fas fa-newspaper",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-newspaper"></i>
        <span>newspaper</span>
      </div>
    ),
  },
  {
    value: "fas fa-object-group",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-object-group"></i>
        <span>object-group</span>
      </div>
    ),
  },
  {
    value: "fas fa-object-ungroup",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-object-ungroup"></i>
        <span>object-ungroup</span>
      </div>
    ),
  },
  {
    value: "fas fa-outdent",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-outdent"></i> <span>outdent</span>
      </div>
    ),
  },
  {
    value: "fas fa-paint-brush",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-paint-brush"></i>
        <span>paint-brush</span>
      </div>
    ),
  },
  {
    value: "fas fa-paper-plane",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-paper-plane"></i>
        <span>paper-plane</span>
      </div>
    ),
  },
  {
    value: "fas fa-paperclip",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-paperclip"></i>
        <span>paperclip</span>
      </div>
    ),
  },
  {
    value: "fas fa-paragraph",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-paragraph"></i>
        <span>paragraph</span>
      </div>
    ),
  },
  {
    value: "fas fa-paste",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-paste"></i> <span>paste</span>
      </div>
    ),
  },
  {
    value: "fas fa-pause",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-pause"></i> <span>pause</span>
      </div>
    ),
  },
  {
    value: "fas fa-pause-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-pause-circle"></i>
        <span>pause-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-paw",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-paw"></i> <span>paw</span>
      </div>
    ),
  },
  {
    value: "fas fa-pen-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-pen-square"></i>
        <span>pen-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-pencil-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-pencil-alt"></i>
        <span>pencil-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-percent",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-percent"></i> <span>percent</span>
      </div>
    ),
  },
  {
    value: "fas fa-phone",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-phone"></i> <span>phone</span>
      </div>
    ),
  },
  {
    value: "fas fa-phone-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-phone-square"></i>
        <span>phone-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-phone-volume",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-phone-volume"></i>
        <span>phone-volume</span>
      </div>
    ),
  },
  {
    value: "fas fa-plane",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-plane"></i> <span>plane</span>
      </div>
    ),
  },
  {
    value: "fas fa-play",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-play"></i> <span>play</span>
      </div>
    ),
  },
  {
    value: "fas fa-play-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-play-circle"></i>
        <span>play-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-plug",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-plug"></i> <span>plug</span>
      </div>
    ),
  },
  {
    value: "fas fa-plus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-plus"></i> <span>plus</span>
      </div>
    ),
  },
  {
    value: "fas fa-plus-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-plus-circle"></i>
        <span>plus-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-plus-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-plus-square"></i>
        <span>plus-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-podcast",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-podcast"></i> <span>podcast</span>
      </div>
    ),
  },
  {
    value: "fas fa-pound-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-pound-sign"></i>
        <span>pound-sign</span>
      </div>
    ),
  },
  {
    value: "fas fa-power-off",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-power-off"></i>
        <span>power-off</span>
      </div>
    ),
  },
  {
    value: "fas fa-print",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-print"></i> <span>print</span>
      </div>
    ),
  },
  {
    value: "fas fa-puzzle-piece",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-puzzle-piece"></i>
        <span>puzzle-piece</span>
      </div>
    ),
  },
  {
    value: "fas fa-qrcode",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-qrcode"></i> <span>qrcode</span>
      </div>
    ),
  },
  {
    value: "fas fa-question",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-question"></i>
        <span>question</span>
      </div>
    ),
  },
  {
    value: "fas fa-question-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-question-circle"></i>
        <span>question-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-quote-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-quote-left"></i>
        <span>quote-left</span>
      </div>
    ),
  },
  {
    value: "fas fa-quote-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-quote-right"></i>
        <span>quote-right</span>
      </div>
    ),
  },
  {
    value: "fas fa-random",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-random"></i> <span>random</span>
      </div>
    ),
  },
  {
    value: "fas fa-recycle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-recycle"></i> <span>recycle</span>
      </div>
    ),
  },
  {
    value: "fas fa-redo",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-redo"></i> <span>redo</span>
      </div>
    ),
  },
  {
    value: "fas fa-redo-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-redo-alt"></i>
        <span>redo-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-registered",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-registered"></i>
        <span>registered</span>
      </div>
    ),
  },
  {
    value: "fas fa-reply",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-reply"></i> <span>reply</span>
      </div>
    ),
  },
  {
    value: "fas fa-reply-all",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-reply-all"></i>
        <span>reply-all</span>
      </div>
    ),
  },
  {
    value: "fas fa-retweet",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-retweet"></i> <span>retweet</span>
      </div>
    ),
  },
  {
    value: "fas fa-road",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-road"></i> <span>road</span>
      </div>
    ),
  },
  {
    value: "fas fa-rocket",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-rocket"></i> <span>rocket</span>
      </div>
    ),
  },
  {
    value: "fas fa-rss",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-rss"></i> <span>rss</span>
      </div>
    ),
  },
  {
    value: "fas fa-rss-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-rss-square"></i>
        <span>rss-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-ruble-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-ruble-sign"></i>
        <span>ruble-sign</span>
      </div>
    ),
  },
  {
    value: "fas fa-rupee-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-rupee-sign"></i>
        <span>rupee-sign</span>
      </div>
    ),
  },
  {
    value: "fas fa-save",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-save"></i> <span>save</span>
      </div>
    ),
  },
  {
    value: "fas fa-search",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-search"></i> <span>search</span>
      </div>
    ),
  },
  {
    value: "fas fa-search-minus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-search-minus"></i>
        <span>search-minus</span>
      </div>
    ),
  },
  {
    value: "fas fa-search-plus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-search-plus"></i>
        <span>search-plus</span>
      </div>
    ),
  },
  {
    value: "fas fa-server",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-server"></i> <span>server</span>
      </div>
    ),
  },
  {
    value: "fas fa-share",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-share"></i> <span>share</span>
      </div>
    ),
  },
  {
    value: "fas fa-share-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-share-alt"></i>
        <span>share-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-share-alt-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-share-alt-square"></i>
        <span>share-alt-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-share-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-share-square"></i>
        <span>share-square</span>
      </div>
    ),
  },
  {
    value: "fas fa-shekel-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-shekel-sign"></i>
        <span>shekel-sign</span>
      </div>
    ),
  },
  {
    value: "fas fa-shield-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-shield-alt"></i>
        <span>shield-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-ship",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-ship"></i> <span>ship</span>
      </div>
    ),
  },
  {
    value: "fas fa-shopping-bag",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-shopping-bag"></i>
        <span>shopping-bag</span>
      </div>
    ),
  },
  {
    value: "fas fa-shopping-basket",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-shopping-basket"></i>
        <span>shopping-basket</span>
      </div>
    ),
  },
  {
    value: "fas fa-shopping-cart",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-shopping-cart"></i>
        <span>shopping-cart</span>
      </div>
    ),
  },
  {
    value: "fas fa-shower",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-shower"></i> <span>shower</span>
      </div>
    ),
  },
  {
    value: "fas fa-sign-in-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sign-in-alt"></i>
        <span>sign-in-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-sign-language",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sign-language"></i>
        <span>sign-language</span>
      </div>
    ),
  },
  {
    value: "fas fa-sign-out-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sign-out-alt"></i>
        <span>sign-out-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-signal",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-signal"></i> <span>signal</span>
      </div>
    ),
  },
  {
    value: "fas fa-sitemap",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sitemap"></i> <span>sitemap</span>
      </div>
    ),
  },
  {
    value: "fas fa-sliders-h",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sliders-h"></i>
        <span>sliders-h</span>
      </div>
    ),
  },
  {
    value: "fas fa-smile",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-smile"></i> <span>smile</span>
      </div>
    ),
  },
  {
    value: "fas fa-snowflake",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-snowflake"></i>
        <span>snowflake</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort"></i> <span>sort</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort-alpha-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort-alpha-down"></i>
        <span>sort-alpha-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort-alpha-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort-alpha-up"></i>
        <span>sort-alpha-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort-amount-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort-amount-down"></i>
        <span>sort-amount-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort-amount-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort-amount-up"></i>
        <span>sort-amount-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort-down"></i>
        <span>sort-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort-numeric-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort-numeric-down"></i>
        <span>sort-numeric-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort-numeric-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort-numeric-up"></i>
        <span>sort-numeric-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-sort-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sort-up"></i> <span>sort-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-space-shuttle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-space-shuttle"></i>
        <span>space-shuttle</span>
      </div>
    ),
  },
  {
    value: "fas fa-spinner",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-spinner"></i> <span>spinner</span>
      </div>
    ),
  },
  {
    value: "fas fa-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-square"></i> <span>square</span>
      </div>
    ),
  },
  {
    value: "fas fa-star",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-star"></i> <span>star</span>
      </div>
    ),
  },
  {
    value: "fas fa-star-half",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-star-half"></i>
        <span>star-half</span>
      </div>
    ),
  },
  {
    value: "fas fa-step-backward",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-step-backward"></i>
        <span>step-backward</span>
      </div>
    ),
  },
  {
    value: "fas fa-step-forward",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-step-forward"></i>
        <span>step-forward</span>
      </div>
    ),
  },
  {
    value: "fas fa-stethoscope",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-stethoscope"></i>
        <span>stethoscope</span>
      </div>
    ),
  },
  {
    value: "fas fa-sticky-note",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sticky-note"></i>
        <span>sticky-note</span>
      </div>
    ),
  },
  {
    value: "fas fa-stop",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-stop"></i> <span>stop</span>
      </div>
    ),
  },
  {
    value: "fas fa-stop-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-stop-circle"></i>
        <span>stop-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-stopwatch",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-stopwatch"></i>
        <span>stopwatch</span>
      </div>
    ),
  },
  {
    value: "fas fa-street-view",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-street-view"></i>
        <span>street-view</span>
      </div>
    ),
  },
  {
    value: "fas fa-strikethrough",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-strikethrough"></i>
        <span>strikethrough</span>
      </div>
    ),
  },
  {
    value: "fas fa-subscript",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-subscript"></i>
        <span>subscript</span>
      </div>
    ),
  },
  {
    value: "fas fa-subway",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-subway"></i> <span>subway</span>
      </div>
    ),
  },
  {
    value: "fas fa-suitcase",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-suitcase"></i>
        <span>suitcase</span>
      </div>
    ),
  },
  {
    value: "fas fa-sun",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sun"></i> <span>sun</span>
      </div>
    ),
  },
  {
    value: "fas fa-superscript",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-superscript"></i>
        <span>superscript</span>
      </div>
    ),
  },
  {
    value: "fas fa-sync",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sync"></i> <span>sync</span>
      </div>
    ),
  },
  {
    value: "fas fa-sync-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-sync-alt"></i>
        <span>sync-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-table",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-table"></i> <span>table</span>
      </div>
    ),
  },
  {
    value: "fas fa-tablet-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-tablet-alt"></i>
        <span>tablet-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-tag",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-tag"></i> <span>tag</span>
      </div>
    ),
  },
  {
    value: "fas fa-tags",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-tags"></i> <span>tags</span>
      </div>
    ),
  },
  {
    value: "fas fa-tasks",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-tasks"></i> <span>tasks</span>
      </div>
    ),
  },
  {
    value: "fas fa-taxi",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-taxi"></i> <span>taxi</span>
      </div>
    ),
  },
  {
    value: "fas fa-terminal",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-terminal"></i>
        <span>terminal</span>
      </div>
    ),
  },
  {
    value: "fas fa-text-height",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-text-height"></i>
        <span>text-height</span>
      </div>
    ),
  },
  {
    value: "fas fa-text-width",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-text-width"></i>
        <span>text-width</span>
      </div>
    ),
  },
  {
    value: "fas fa-th",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-th"></i> <span>th</span>
      </div>
    ),
  },
  {
    value: "fas fa-th-large",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-th-large"></i>
        <span>th-large</span>
      </div>
    ),
  },
  {
    value: "fas fa-th-list",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-th-list"></i> <span>th-list</span>
      </div>
    ),
  },
  {
    value: "fas fa-thermometer-empty",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-thermometer-empty"></i>
        <span>thermometer-empty</span>
      </div>
    ),
  },
  {
    value: "fas fa-thermometer-full",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-thermometer-full"></i>
        <span>thermometer-full</span>
      </div>
    ),
  },
  {
    value: "fas fa-thermometer-half",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-thermometer-half"></i>
        <span>thermometer-half</span>
      </div>
    ),
  },
  {
    value: "fas fa-thermometer-quarter",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-thermometer-quarter"></i>
        <span>thermometer-quarter</span>
      </div>
    ),
  },
  {
    value: "fas fa-thermometer-three-quarters",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-thermometer-three-quarters"></i>
        <span>thermometer-three-quarters</span>
      </div>
    ),
  },
  {
    value: "fas fa-thumbs-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-thumbs-down"></i>
        <span>thumbs-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-thumbs-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-thumbs-up"></i>
        <span>thumbs-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-thumbtack",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-thumbtack"></i>
        <span>thumbtack</span>
      </div>
    ),
  },
  {
    value: "fas fa-ticket-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-ticket-alt"></i>
        <span>ticket-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-times",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-times"></i> <span>times</span>
      </div>
    ),
  },
  {
    value: "fas fa-times-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-times-circle"></i>
        <span>times-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-tint",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-tint"></i> <span>tint</span>
      </div>
    ),
  },
  {
    value: "fas fa-toggle-off",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-toggle-off"></i>
        <span>toggle-off</span>
      </div>
    ),
  },
  {
    value: "fas fa-toggle-on",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-toggle-on"></i>
        <span>toggle-on</span>
      </div>
    ),
  },
  {
    value: "fas fa-trademark",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-trademark"></i>
        <span>trademark</span>
      </div>
    ),
  },
  {
    value: "fas fa-train",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-train"></i> <span>train</span>
      </div>
    ),
  },
  {
    value: "fas fa-transgender",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-transgender"></i>
        <span>transgender</span>
      </div>
    ),
  },
  {
    value: "fas fa-transgender-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-transgender-alt"></i>
        <span>transgender-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-trash",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-trash"></i> <span>trash</span>
      </div>
    ),
  },
  {
    value: "fas fa-trash-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-trash-alt"></i>
        <span>trash-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-tree",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-tree"></i> <span>tree</span>
      </div>
    ),
  },
  {
    value: "fas fa-trophy",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-trophy"></i> <span>trophy</span>
      </div>
    ),
  },
  {
    value: "fas fa-truck",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-truck"></i> <span>truck</span>
      </div>
    ),
  },
  {
    value: "fas fa-tty",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-tty"></i> <span>tty</span>
      </div>
    ),
  },
  {
    value: "fas fa-tv",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-tv"></i> <span>tv</span>
      </div>
    ),
  },
  {
    value: "fas fa-umbrella",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-umbrella"></i>
        <span>umbrella</span>
      </div>
    ),
  },
  {
    value: "fas fa-underline",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-underline"></i>
        <span>underline</span>
      </div>
    ),
  },
  {
    value: "fas fa-undo",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-undo"></i> <span>undo</span>
      </div>
    ),
  },
  {
    value: "fas fa-undo-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-undo-alt"></i>
        <span>undo-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-universal-access",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-universal-access"></i>
        <span>universal-access</span>
      </div>
    ),
  },
  {
    value: "fas fa-university",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-university"></i>
        <span>university</span>
      </div>
    ),
  },
  {
    value: "fas fa-unlink",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-unlink"></i> <span>unlink</span>
      </div>
    ),
  },
  {
    value: "fas fa-unlock",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-unlock"></i> <span>unlock</span>
      </div>
    ),
  },
  {
    value: "fas fa-unlock-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-unlock-alt"></i>
        <span>unlock-alt</span>
      </div>
    ),
  },
  {
    value: "fas fa-upload",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-upload"></i> <span>upload</span>
      </div>
    ),
  },
  {
    value: "fas fa-user",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-user"></i> <span>user</span>
      </div>
    ),
  },
  {
    value: "fas fa-user-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-user-circle"></i>
        <span>user-circle</span>
      </div>
    ),
  },
  {
    value: "fas fa-user-md",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-user-md"></i> <span>user-md</span>
      </div>
    ),
  },
  {
    value: "fas fa-user-plus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-user-plus"></i>
        <span>user-plus</span>
      </div>
    ),
  },
  {
    value: "fas fa-user-secret",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-user-secret"></i>
        <span>user-secret</span>
      </div>
    ),
  },
  {
    value: "fas fa-user-times",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-user-times"></i>
        <span>user-times</span>
      </div>
    ),
  },
  {
    value: "fas fa-users",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-users"></i> <span>users</span>
      </div>
    ),
  },
  {
    value: "fas fa-utensil-spoon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-utensil-spoon"></i>
        <span>utensil-spoon</span>
      </div>
    ),
  },
  {
    value: "fas fa-utensils",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-utensils"></i>
        <span>utensils</span>
      </div>
    ),
  },
  {
    value: "fas fa-venus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-venus"></i> <span>venus</span>
      </div>
    ),
  },
  {
    value: "fas fa-venus-double",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-venus-double"></i>
        <span>venus-double</span>
      </div>
    ),
  },
  {
    value: "fas fa-venus-mars",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-venus-mars"></i>
        <span>venus-mars</span>
      </div>
    ),
  },
  {
    value: "fas fa-video",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-video"></i> <span>video</span>
      </div>
    ),
  },
  {
    value: "fas fa-volume-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-volume-down"></i>
        <span>volume-down</span>
      </div>
    ),
  },
  {
    value: "fas fa-volume-off",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-volume-off"></i>
        <span>volume-off</span>
      </div>
    ),
  },
  {
    value: "fas fa-volume-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-volume-up"></i>
        <span>volume-up</span>
      </div>
    ),
  },
  {
    value: "fas fa-wheelchair",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-wheelchair"></i>
        <span>wheelchair</span>
      </div>
    ),
  },
  {
    value: "fas fa-wifi",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-wifi"></i> <span>wifi</span>
      </div>
    ),
  },
  {
    value: "fas fa-window-close",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-window-close"></i>
        <span>window-close</span>
      </div>
    ),
  },
  {
    value: "fas fa-window-maximize",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-window-maximize"></i>
        <span>window-maximize</span>
      </div>
    ),
  },
  {
    value: "fas fa-window-minimize",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-window-minimize"></i>
        <span>window-minimize</span>
      </div>
    ),
  },
  {
    value: "fas fa-window-restore",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-window-restore"></i>
        <span>window-restore</span>
      </div>
    ),
  },
  {
    value: "fas fa-won-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-won-sign"></i>
        <span>won-sign</span>
      </div>
    ),
  },
  {
    value: "fas fa-wrench",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-wrench"></i> <span>wrench</span>
      </div>
    ),
  },
  {
    value: "fas fa-yen-sign",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fas fa-yen-sign"></i>
        <span>yen-sign</span>
      </div>
    ),
  },
  {
    value: "far fa-address-book",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-address-book"></i>
        <span>address-book</span>
      </div>
    ),
  },
  {
    value: "far fa-address-card",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-address-card"></i>
        <span>address-card</span>
      </div>
    ),
  },
  {
    value: "far fa-arrow-alt-circle-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-arrow-alt-circle-down"></i>
        <span>arrow-alt-circle-down</span>
      </div>
    ),
  },
  {
    value: "far fa-arrow-alt-circle-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-arrow-alt-circle-left"></i>
        <span>arrow-alt-circle-left</span>
      </div>
    ),
  },
  {
    value: "far fa-arrow-alt-circle-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-arrow-alt-circle-right"></i>
        <span>arrow-alt-circle-right</span>
      </div>
    ),
  },
  {
    value: "far fa-arrow-alt-circle-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-arrow-alt-circle-up"></i>
        <span>arrow-alt-circle-up</span>
      </div>
    ),
  },
  {
    value: "far fa-bell",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-bell"></i> <span>bell</span>
      </div>
    ),
  },
  {
    value: "far fa-bell-slash",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-bell-slash"></i>
        <span>bell-slash</span>
      </div>
    ),
  },
  {
    value: "far fa-bookmark",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-bookmark"></i>
        <span>bookmark</span>
      </div>
    ),
  },
  {
    value: "far fa-building",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-building"></i>
        <span>building</span>
      </div>
    ),
  },
  {
    value: "far fa-calendar",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-calendar"></i>
        <span>calendar</span>
      </div>
    ),
  },
  {
    value: "far fa-calendar-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-calendar-alt"></i>
        <span>calendar-alt</span>
      </div>
    ),
  },
  {
    value: "far fa-calendar-check",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-calendar-check"></i>
        <span>calendar-check</span>
      </div>
    ),
  },
  {
    value: "far fa-calendar-minus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-calendar-minus"></i>
        <span>calendar-minus</span>
      </div>
    ),
  },
  {
    value: "far fa-calendar-plus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-calendar-plus"></i>
        <span>calendar-plus</span>
      </div>
    ),
  },
  {
    value: "far fa-calendar-times",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-calendar-times"></i>
        <span>calendar-times</span>
      </div>
    ),
  },
  {
    value: "far fa-caret-square-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-caret-square-down"></i>
        <span>caret-square-down</span>
      </div>
    ),
  },
  {
    value: "far fa-caret-square-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-caret-square-left"></i>
        <span>caret-square-left</span>
      </div>
    ),
  },
  {
    value: "far fa-caret-square-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-caret-square-right"></i>
        <span>caret-square-right</span>
      </div>
    ),
  },
  {
    value: "far fa-caret-square-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-caret-square-up"></i>
        <span>caret-square-up</span>
      </div>
    ),
  },
  {
    value: "far fa-chart-bar",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-chart-bar"></i>
        <span>chart-bar</span>
      </div>
    ),
  },
  {
    value: "far fa-check-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-check-circle"></i>
        <span>check-circle</span>
      </div>
    ),
  },
  {
    value: "far fa-check-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-check-square"></i>
        <span>check-square</span>
      </div>
    ),
  },
  {
    value: "far fa-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-circle"></i> <span>circle</span>
      </div>
    ),
  },
  {
    value: "far fa-clipboard",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-clipboard"></i>
        <span>clipboard</span>
      </div>
    ),
  },
  {
    value: "far fa-clock",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-clock"></i> <span>clock</span>
      </div>
    ),
  },
  {
    value: "far fa-clone",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-clone"></i> <span>clone</span>
      </div>
    ),
  },
  {
    value: "far fa-closed-captioning",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-closed-captioning"></i>
        <span>closed-captioning</span>
      </div>
    ),
  },
  {
    value: "far fa-comment",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-comment"></i> <span>comment</span>
      </div>
    ),
  },
  {
    value: "far fa-comment-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-comment-alt"></i>
        <span>comment-alt</span>
      </div>
    ),
  },
  {
    value: "far fa-comments",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-comments"></i>
        <span>comments</span>
      </div>
    ),
  },
  {
    value: "far fa-compass",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-compass"></i> <span>compass</span>
      </div>
    ),
  },
  {
    value: "far fa-copy",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-copy"></i> <span>copy</span>
      </div>
    ),
  },
  {
    value: "far fa-copyright",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-copyright"></i>
        <span>copyright</span>
      </div>
    ),
  },
  {
    value: "far fa-credit-card",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-credit-card"></i>
        <span>credit-card</span>
      </div>
    ),
  },
  {
    value: "far fa-dot-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-dot-circle"></i>
        <span>dot-circle</span>
      </div>
    ),
  },
  {
    value: "far fa-edit",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-edit"></i> <span>edit</span>
      </div>
    ),
  },
  {
    value: "far fa-envelope",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-envelope"></i>
        <span>envelope</span>
      </div>
    ),
  },
  {
    value: "far fa-envelope-open",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-envelope-open"></i>
        <span>envelope-open</span>
      </div>
    ),
  },
  {
    value: "far fa-eye-slash",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-eye-slash"></i>
        <span>eye-slash</span>
      </div>
    ),
  },
  {
    value: "far fa-file",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file"></i> <span>file</span>
      </div>
    ),
  },
  {
    value: "far fa-file-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-alt"></i>
        <span>file-alt</span>
      </div>
    ),
  },
  {
    value: "far fa-file-archive",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-archive"></i>
        <span>file-archive</span>
      </div>
    ),
  },
  {
    value: "far fa-file-audio",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-audio"></i>
        <span>file-audio</span>
      </div>
    ),
  },
  {
    value: "far fa-file-code",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-code"></i>
        <span>file-code</span>
      </div>
    ),
  },
  {
    value: "far fa-file-excel",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-excel"></i>
        <span>file-excel</span>
      </div>
    ),
  },
  {
    value: "far fa-file-image",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-image"></i>
        <span>file-image</span>
      </div>
    ),
  },
  {
    value: "far fa-file-pdf",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-pdf"></i>
        <span>file-pdf</span>
      </div>
    ),
  },
  {
    value: "far fa-file-powerpoint",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-powerpoint"></i>
        <span>file-powerpoint</span>
      </div>
    ),
  },
  {
    value: "far fa-file-video",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-video"></i>
        <span>file-video</span>
      </div>
    ),
  },
  {
    value: "far fa-file-word",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-file-word"></i>
        <span>file-word</span>
      </div>
    ),
  },
  {
    value: "far fa-flag",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-flag"></i> <span>flag</span>
      </div>
    ),
  },
  {
    value: "far fa-folder",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-folder"></i> <span>folder</span>
      </div>
    ),
  },
  {
    value: "far fa-folder-open",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-folder-open"></i>
        <span>folder-open</span>
      </div>
    ),
  },
  {
    value: "far fa-frown",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-frown"></i> <span>frown</span>
      </div>
    ),
  },
  {
    value: "far fa-futbol",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-futbol"></i> <span>futbol</span>
      </div>
    ),
  },
  {
    value: "far fa-gem",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-gem"></i> <span>gem</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-lizard",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-lizard"></i>
        <span>hand-lizard</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-paper",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-paper"></i>
        <span>hand-paper</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-peace",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-peace"></i>
        <span>hand-peace</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-point-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-point-down"></i>
        <span>hand-point-down</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-point-left",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-point-left"></i>
        <span>hand-point-left</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-point-right",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-point-right"></i>
        <span>hand-point-right</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-point-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-point-up"></i>
        <span>hand-point-up</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-pointer",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-pointer"></i>
        <span>hand-pointer</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-rock",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-rock"></i>
        <span>hand-rock</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-scissors",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-scissors"></i>
        <span>hand-scissors</span>
      </div>
    ),
  },
  {
    value: "far fa-hand-spock",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hand-spock"></i>
        <span>hand-spock</span>
      </div>
    ),
  },
  {
    value: "far fa-handshake",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-handshake"></i>
        <span>handshake</span>
      </div>
    ),
  },
  {
    value: "far fa-hdd",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hdd"></i> <span>hdd</span>
      </div>
    ),
  },
  {
    value: "far fa-heart",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-heart"></i> <span>heart</span>
      </div>
    ),
  },
  {
    value: "far fa-hospital",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hospital"></i>
        <span>hospital</span>
      </div>
    ),
  },
  {
    value: "far fa-hourglass",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-hourglass"></i>
        <span>hourglass</span>
      </div>
    ),
  },
  {
    value: "far fa-id-badge",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-id-badge"></i>
        <span>id-badge</span>
      </div>
    ),
  },
  {
    value: "far fa-id-card",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-id-card"></i> <span>id-card</span>
      </div>
    ),
  },
  {
    value: "far fa-image",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-image"></i> <span>image</span>
      </div>
    ),
  },
  {
    value: "far fa-images",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-images"></i> <span>images</span>
      </div>
    ),
  },
  {
    value: "far fa-keyboard",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-keyboard"></i>
        <span>keyboard</span>
      </div>
    ),
  },
  {
    value: "far fa-lemon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-lemon"></i> <span>lemon</span>
      </div>
    ),
  },
  {
    value: "far fa-life-ring",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-life-ring"></i>
        <span>life-ring</span>
      </div>
    ),
  },
  {
    value: "far fa-lightbulb",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-lightbulb"></i>
        <span>lightbulb</span>
      </div>
    ),
  },
  {
    value: "far fa-list-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-list-alt"></i>
        <span>list-alt</span>
      </div>
    ),
  },
  {
    value: "far fa-map",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-map"></i> <span>map</span>
      </div>
    ),
  },
  {
    value: "far fa-meh",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-meh"></i> <span>meh</span>
      </div>
    ),
  },
  {
    value: "far fa-minus-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-minus-square"></i>
        <span>minus-square</span>
      </div>
    ),
  },
  {
    value: "far fa-money-bill-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-money-bill-alt"></i>
        <span>money-bill-alt</span>
      </div>
    ),
  },
  {
    value: "far fa-moon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-moon"></i> <span>moon</span>
      </div>
    ),
  },
  {
    value: "far fa-newspaper",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-newspaper"></i>
        <span>newspaper</span>
      </div>
    ),
  },
  {
    value: "far fa-object-group",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-object-group"></i>
        <span>object-group</span>
      </div>
    ),
  },
  {
    value: "far fa-object-ungroup",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-object-ungroup"></i>
        <span>object-ungroup</span>
      </div>
    ),
  },
  {
    value: "far fa-paper-plane",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-paper-plane"></i>
        <span>paper-plane</span>
      </div>
    ),
  },
  {
    value: "far fa-pause-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-pause-circle"></i>
        <span>pause-circle</span>
      </div>
    ),
  },
  {
    value: "far fa-play-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-play-circle"></i>
        <span>play-circle</span>
      </div>
    ),
  },
  {
    value: "far fa-plus-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-plus-square"></i>
        <span>plus-square</span>
      </div>
    ),
  },
  {
    value: "far fa-question-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-question-circle"></i>
        <span>question-circle</span>
      </div>
    ),
  },
  {
    value: "far fa-registered",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-registered"></i>
        <span>registered</span>
      </div>
    ),
  },
  {
    value: "far fa-save",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-save"></i> <span>save</span>
      </div>
    ),
  },
  {
    value: "far fa-share-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-share-square"></i>
        <span>share-square</span>
      </div>
    ),
  },
  {
    value: "far fa-smile",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-smile"></i> <span>smile</span>
      </div>
    ),
  },
  {
    value: "far fa-snowflake",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-snowflake"></i>
        <span>snowflake</span>
      </div>
    ),
  },
  {
    value: "far fa-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-square"></i> <span>square</span>
      </div>
    ),
  },
  {
    value: "far fa-star",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-star"></i> <span>star</span>
      </div>
    ),
  },
  {
    value: "far fa-star-half",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-star-half"></i>
        <span>star-half</span>
      </div>
    ),
  },
  {
    value: "far fa-sticky-note",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-sticky-note"></i>
        <span>sticky-note</span>
      </div>
    ),
  },
  {
    value: "far fa-stop-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-stop-circle"></i>
        <span>stop-circle</span>
      </div>
    ),
  },
  {
    value: "far fa-sun",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-sun"></i> <span>sun</span>
      </div>
    ),
  },
  {
    value: "far fa-thumbs-down",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-thumbs-down"></i>
        <span>thumbs-down</span>
      </div>
    ),
  },
  {
    value: "far fa-thumbs-up",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-thumbs-up"></i>
        <span>thumbs-up</span>
      </div>
    ),
  },
  {
    value: "far fa-times-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-times-circle"></i>
        <span>times-circle</span>
      </div>
    ),
  },
  {
    value: "far fa-trash-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-trash-alt"></i>
        <span>trash-alt</span>
      </div>
    ),
  },
  {
    value: "far fa-user",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-user"></i> <span>user</span>
      </div>
    ),
  },
  {
    value: "far fa-user-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-user-circle"></i>
        <span>user-circle</span>
      </div>
    ),
  },
  {
    value: "far fa-window-close",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-window-close"></i>
        <span>window-close</span>
      </div>
    ),
  },
  {
    value: "far fa-window-maximize",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-window-maximize"></i>
        <span>window-maximize</span>
      </div>
    ),
  },
  {
    value: "far fa-window-minimize",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-window-minimize"></i>
        <span>window-minimize</span>
      </div>
    ),
  },
  {
    value: "far fa-window-restore",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 far fa-window-restore"></i>
        <span>window-restore</span>
      </div>
    ),
  },
  {
    value: "fab fa-500px",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-500px"></i> <span>500px</span>
      </div>
    ),
  },
  {
    value: "fab fa-accessible-icon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-accessible-icon"></i>
        <span>accessible-icon</span>
      </div>
    ),
  },
  {
    value: "fab fa-accusoft",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-accusoft"></i>
        <span>accusoft</span>
      </div>
    ),
  },
  {
    value: "fab fa-adn",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-adn"></i> <span>adn</span>
      </div>
    ),
  },
  {
    value: "fab fa-adversal",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-adversal"></i>
        <span>adversal</span>
      </div>
    ),
  },
  {
    value: "fab fa-affiliatetheme",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-affiliatetheme"></i>
        <span>affiliatetheme</span>
      </div>
    ),
  },
  {
    value: "fab fa-algolia",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-algolia"></i> <span>algolia</span>
      </div>
    ),
  },
  {
    value: "fab fa-amazon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-amazon"></i> <span>amazon</span>
      </div>
    ),
  },
  {
    value: "fab fa-amazon-pay",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-amazon-pay"></i>
        <span>amazon-pay</span>
      </div>
    ),
  },
  {
    value: "fab fa-amilia",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-amilia"></i> <span>amilia</span>
      </div>
    ),
  },
  {
    value: "fab fa-android",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-android"></i> <span>android</span>
      </div>
    ),
  },
  {
    value: "fab fa-angellist",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-angellist"></i>
        <span>angellist</span>
      </div>
    ),
  },
  {
    value: "fab fa-angrycreative",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-angrycreative"></i>
        <span>angrycreative</span>
      </div>
    ),
  },
  {
    value: "fab fa-angular",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-angular"></i> <span>angular</span>
      </div>
    ),
  },
  {
    value: "fab fa-app-store",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-app-store"></i>
        <span>app-store</span>
      </div>
    ),
  },
  {
    value: "fab fa-app-store-ios",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-app-store-ios"></i>
        <span>app-store-ios</span>
      </div>
    ),
  },
  {
    value: "fab fa-apper",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-apper"></i> <span>apper</span>
      </div>
    ),
  },
  {
    value: "fab fa-apple",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-apple"></i> <span>apple</span>
      </div>
    ),
  },
  {
    value: "fab fa-apple-pay",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-apple-pay"></i>
        <span>apple-pay</span>
      </div>
    ),
  },
  {
    value: "fab fa-asymmetrik",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-asymmetrik"></i>
        <span>asymmetrik</span>
      </div>
    ),
  },
  {
    value: "fab fa-audible",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-audible"></i> <span>audible</span>
      </div>
    ),
  },
  {
    value: "fab fa-autoprefixer",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-autoprefixer"></i>
        <span>autoprefixer</span>
      </div>
    ),
  },
  {
    value: "fab fa-avianex",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-avianex"></i> <span>avianex</span>
      </div>
    ),
  },
  {
    value: "fab fa-aviato",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-aviato"></i> <span>aviato</span>
      </div>
    ),
  },
  {
    value: "fab fa-aws",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-aws"></i> <span>aws</span>
      </div>
    ),
  },
  {
    value: "fab fa-bandcamp",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-bandcamp"></i>
        <span>bandcamp</span>
      </div>
    ),
  },
  {
    value: "fab fa-behance",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-behance"></i> <span>behance</span>
      </div>
    ),
  },
  {
    value: "fab fa-behance-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-behance-square"></i>
        <span>behance-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-bimobject",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-bimobject"></i>
        <span>bimobject</span>
      </div>
    ),
  },
  {
    value: "fab fa-bitbucket",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-bitbucket"></i>
        <span>bitbucket</span>
      </div>
    ),
  },
  {
    value: "fab fa-bitcoin",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-bitcoin"></i> <span>bitcoin</span>
      </div>
    ),
  },
  {
    value: "fab fa-bity",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-bity"></i> <span>bity</span>
      </div>
    ),
  },
  {
    value: "fab fa-black-tie",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-black-tie"></i>
        <span>black-tie</span>
      </div>
    ),
  },
  {
    value: "fab fa-blackberry",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-blackberry"></i>
        <span>blackberry</span>
      </div>
    ),
  },
  {
    value: "fab fa-blogger",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-blogger"></i> <span>blogger</span>
      </div>
    ),
  },
  {
    value: "fab fa-blogger-b",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-blogger-b"></i>
        <span>blogger-b</span>
      </div>
    ),
  },
  {
    value: "fab fa-bluetooth",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-bluetooth"></i>
        <span>bluetooth</span>
      </div>
    ),
  },
  {
    value: "fab fa-bluetooth-b",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-bluetooth-b"></i>
        <span>bluetooth-b</span>
      </div>
    ),
  },
  {
    value: "fab fa-btc",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-btc"></i> <span>btc</span>
      </div>
    ),
  },
  {
    value: "fab fa-buromobelexperte",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-buromobelexperte"></i>
        <span>buromobelexperte</span>
      </div>
    ),
  },
  {
    value: "fab fa-buysellads",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-buysellads"></i>
        <span>buysellads</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-amazon-pay",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-amazon-pay"></i>
        <span>cc-amazon-pay</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-amex",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-amex"></i> <span>cc-amex</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-apple-pay",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-apple-pay"></i>
        <span>cc-apple-pay</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-diners-club",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-diners-club"></i>
        <span>cc-diners-club</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-discover",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-discover"></i>
        <span>cc-discover</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-jcb",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-jcb"></i> <span>cc-jcb</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-mastercard",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-mastercard"></i>
        <span>cc-mastercard</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-paypal",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-paypal"></i>
        <span>cc-paypal</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-stripe",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-stripe"></i>
        <span>cc-stripe</span>
      </div>
    ),
  },
  {
    value: "fab fa-cc-visa",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cc-visa"></i> <span>cc-visa</span>
      </div>
    ),
  },
  {
    value: "fab fa-centercode",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-centercode"></i>
        <span>centercode</span>
      </div>
    ),
  },
  {
    value: "fab fa-chrome",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-chrome"></i> <span>chrome</span>
      </div>
    ),
  },
  {
    value: "fab fa-cloudscale",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cloudscale"></i>
        <span>cloudscale</span>
      </div>
    ),
  },
  {
    value: "fab fa-cloudsmith",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cloudsmith"></i>
        <span>cloudsmith</span>
      </div>
    ),
  },
  {
    value: "fab fa-cloudversify",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cloudversify"></i>
        <span>cloudversify</span>
      </div>
    ),
  },
  {
    value: "fab fa-codepen",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-codepen"></i> <span>codepen</span>
      </div>
    ),
  },
  {
    value: "fab fa-codiepie",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-codiepie"></i>
        <span>codiepie</span>
      </div>
    ),
  },
  {
    value: "fab fa-connectdevelop",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-connectdevelop"></i>
        <span>connectdevelop</span>
      </div>
    ),
  },
  {
    value: "fab fa-contao",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-contao"></i> <span>contao</span>
      </div>
    ),
  },
  {
    value: "fab fa-cpanel",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cpanel"></i> <span>cpanel</span>
      </div>
    ),
  },
  {
    value: "fab fa-creative-commons",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-creative-commons"></i>
        <span>creative-commons</span>
      </div>
    ),
  },
  {
    value: "fab fa-css3",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-css3"></i> <span>css3</span>
      </div>
    ),
  },
  {
    value: "fab fa-css3-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-css3-alt"></i>
        <span>css3-alt</span>
      </div>
    ),
  },
  {
    value: "fab fa-cuttlefish",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-cuttlefish"></i>
        <span>cuttlefish</span>
      </div>
    ),
  },
  {
    value: "fab fa-d-and-d",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-d-and-d"></i> <span>d-and-d</span>
      </div>
    ),
  },
  {
    value: "fab fa-dashcube",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-dashcube"></i>
        <span>dashcube</span>
      </div>
    ),
  },
  {
    value: "fab fa-delicious",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-delicious"></i>
        <span>delicious</span>
      </div>
    ),
  },
  {
    value: "fab fa-deploydog",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-deploydog"></i>
        <span>deploydog</span>
      </div>
    ),
  },
  {
    value: "fab fa-deskpro",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-deskpro"></i> <span>deskpro</span>
      </div>
    ),
  },
  {
    value: "fab fa-deviantart",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-deviantart"></i>
        <span>deviantart</span>
      </div>
    ),
  },
  {
    value: "fab fa-digg",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-digg"></i> <span>digg</span>
      </div>
    ),
  },
  {
    value: "fab fa-digital-ocean",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-digital-ocean"></i>
        <span>digital-ocean</span>
      </div>
    ),
  },
  {
    value: "fab fa-discord",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-discord"></i> <span>discord</span>
      </div>
    ),
  },
  {
    value: "fab fa-discourse",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-discourse"></i>
        <span>discourse</span>
      </div>
    ),
  },
  {
    value: "fab fa-dochub",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-dochub"></i> <span>dochub</span>
      </div>
    ),
  },
  {
    value: "fab fa-docker",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-docker"></i> <span>docker</span>
      </div>
    ),
  },
  {
    value: "fab fa-draft2digital",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-draft2digital"></i>
        <span>draft2digital</span>
      </div>
    ),
  },
  {
    value: "fab fa-dribbble",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-dribbble"></i>
        <span>dribbble</span>
      </div>
    ),
  },
  {
    value: "fab fa-dribbble-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-dribbble-square"></i>
        <span>dribbble-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-dropbox",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-dropbox"></i> <span>dropbox</span>
      </div>
    ),
  },
  {
    value: "fab fa-drupal",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-drupal"></i> <span>drupal</span>
      </div>
    ),
  },
  {
    value: "fab fa-dyalog",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-dyalog"></i> <span>dyalog</span>
      </div>
    ),
  },
  {
    value: "fab fa-earlybirds",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-earlybirds"></i>
        <span>earlybirds</span>
      </div>
    ),
  },
  {
    value: "fab fa-edge",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-edge"></i> <span>edge</span>
      </div>
    ),
  },
  {
    value: "fab fa-elementor",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-elementor"></i>
        <span>elementor</span>
      </div>
    ),
  },
  {
    value: "fab fa-ember",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-ember"></i> <span>ember</span>
      </div>
    ),
  },
  {
    value: "fab fa-empire",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-empire"></i> <span>empire</span>
      </div>
    ),
  },
  {
    value: "fab fa-envira",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-envira"></i> <span>envira</span>
      </div>
    ),
  },
  {
    value: "fab fa-erlang",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-erlang"></i> <span>erlang</span>
      </div>
    ),
  },
  {
    value: "fab fa-ethereum",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-ethereum"></i>
        <span>ethereum</span>
      </div>
    ),
  },
  {
    value: "fab fa-etsy",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-etsy"></i> <span>etsy</span>
      </div>
    ),
  },
  {
    value: "fab fa-expeditedssl",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-expeditedssl"></i>
        <span>expeditedssl</span>
      </div>
    ),
  },
  {
    value: "fab fa-facebook",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-facebook"></i>
        <span>facebook</span>
      </div>
    ),
  },
  {
    value: "fab fa-facebook-f",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-facebook-f"></i>
        <span>facebook-f</span>
      </div>
    ),
  },
  {
    value: "fab fa-facebook-messenger",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-facebook-messenger"></i>
        <span>facebook-messenger</span>
      </div>
    ),
  },
  {
    value: "fab fa-facebook-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-facebook-square"></i>
        <span>facebook-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-firefox",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-firefox"></i> <span>firefox</span>
      </div>
    ),
  },
  {
    value: "fab fa-first-order",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-first-order"></i>
        <span>first-order</span>
      </div>
    ),
  },
  {
    value: "fab fa-firstdraft",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-firstdraft"></i>
        <span>firstdraft</span>
      </div>
    ),
  },
  {
    value: "fab fa-flickr",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-flickr"></i> <span>flickr</span>
      </div>
    ),
  },
  {
    value: "fab fa-fly",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-fly"></i> <span>fly</span>
      </div>
    ),
  },
  {
    value: "fab fa-font-awesome",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-font-awesome"></i>
        <span>font-awesome</span>
      </div>
    ),
  },
  {
    value: "fab fa-font-awesome-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-font-awesome-alt"></i>
        <span>font-awesome-alt</span>
      </div>
    ),
  },
  {
    value: "fab fa-font-awesome-flag",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-font-awesome-flag"></i>
        <span>font-awesome-flag</span>
      </div>
    ),
  },
  {
    value: "fab fa-fonticons",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-fonticons"></i>
        <span>fonticons</span>
      </div>
    ),
  },
  {
    value: "fab fa-fonticons-fi",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-fonticons-fi"></i>
        <span>fonticons-fi</span>
      </div>
    ),
  },
  {
    value: "fab fa-fort-awesome",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-fort-awesome"></i>
        <span>fort-awesome</span>
      </div>
    ),
  },
  {
    value: "fab fa-fort-awesome-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-fort-awesome-alt"></i>
        <span>fort-awesome-alt</span>
      </div>
    ),
  },
  {
    value: "fab fa-forumbee",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-forumbee"></i>
        <span>forumbee</span>
      </div>
    ),
  },
  {
    value: "fab fa-foursquare",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-foursquare"></i>
        <span>foursquare</span>
      </div>
    ),
  },
  {
    value: "fab fa-free-code-camp",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-free-code-camp"></i>
        <span>free-code-camp</span>
      </div>
    ),
  },
  {
    value: "fab fa-freebsd",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-freebsd"></i> <span>freebsd</span>
      </div>
    ),
  },
  {
    value: "fab fa-get-pocket",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-get-pocket"></i>
        <span>get-pocket</span>
      </div>
    ),
  },
  {
    value: "fab fa-gg",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gg"></i> <span>gg</span>
      </div>
    ),
  },
  {
    value: "fab fa-gg-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gg-circle"></i>
        <span>gg-circle</span>
      </div>
    ),
  },
  {
    value: "fab fa-git",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-git"></i> <span>git</span>
      </div>
    ),
  },
  {
    value: "fab fa-git-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-git-square"></i>
        <span>git-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-github",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-github"></i> <span>github</span>
      </div>
    ),
  },
  {
    value: "fab fa-github-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-github-alt"></i>
        <span>github-alt</span>
      </div>
    ),
  },
  {
    value: "fab fa-github-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-github-square"></i>
        <span>github-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-gitkraken",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gitkraken"></i>
        <span>gitkraken</span>
      </div>
    ),
  },
  {
    value: "fab fa-gitlab",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gitlab"></i> <span>gitlab</span>
      </div>
    ),
  },
  {
    value: "fab fa-gitter",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gitter"></i> <span>gitter</span>
      </div>
    ),
  },
  {
    value: "fab fa-glide",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-glide"></i> <span>glide</span>
      </div>
    ),
  },
  {
    value: "fab fa-glide-g",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-glide-g"></i> <span>glide-g</span>
      </div>
    ),
  },
  {
    value: "fab fa-gofore",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gofore"></i> <span>gofore</span>
      </div>
    ),
  },
  {
    value: "fab fa-goodreads",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-goodreads"></i>
        <span>goodreads</span>
      </div>
    ),
  },
  {
    value: "fab fa-goodreads-g",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-goodreads-g"></i>
        <span>goodreads-g</span>
      </div>
    ),
  },
  {
    value: "fab fa-google",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-google"></i> <span>google</span>
      </div>
    ),
  },
  {
    value: "fab fa-google-drive",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-google-drive"></i>
        <span>google-drive</span>
      </div>
    ),
  },
  {
    value: "fab fa-google-play",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-google-play"></i>
        <span>google-play</span>
      </div>
    ),
  },
  {
    value: "fab fa-google-plus",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-google-plus"></i>
        <span>google-plus</span>
      </div>
    ),
  },
  {
    value: "fab fa-google-plus-g",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-google-plus-g"></i>
        <span>google-plus-g</span>
      </div>
    ),
  },
  {
    value: "fab fa-google-plus-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-google-plus-square"></i>
        <span>google-plus-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-google-wallet",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-google-wallet"></i>
        <span>google-wallet</span>
      </div>
    ),
  },
  {
    value: "fab fa-gratipay",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gratipay"></i>
        <span>gratipay</span>
      </div>
    ),
  },
  {
    value: "fab fa-grav",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-grav"></i> <span>grav</span>
      </div>
    ),
  },
  {
    value: "fab fa-gripfire",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gripfire"></i>
        <span>gripfire</span>
      </div>
    ),
  },
  {
    value: "fab fa-grunt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-grunt"></i> <span>grunt</span>
      </div>
    ),
  },
  {
    value: "fab fa-gulp",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-gulp"></i> <span>gulp</span>
      </div>
    ),
  },
  {
    value: "fab fa-hacker-news",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-hacker-news"></i>
        <span>hacker-news</span>
      </div>
    ),
  },
  {
    value: "fab fa-hacker-news-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-hacker-news-square"></i>
        <span>hacker-news-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-hire-a-helper",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-hire-a-helper"></i>
        <span>hire-a-helper</span>
      </div>
    ),
  },
  {
    value: "fab fa-hooli",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-hooli"></i> <span>hooli</span>
      </div>
    ),
  },
  {
    value: "fab fa-hotjar",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-hotjar"></i> <span>hotjar</span>
      </div>
    ),
  },
  {
    value: "fab fa-houzz",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-houzz"></i> <span>houzz</span>
      </div>
    ),
  },
  {
    value: "fab fa-html5",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-html5"></i> <span>html5</span>
      </div>
    ),
  },
  {
    value: "fab fa-hubspot",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-hubspot"></i> <span>hubspot</span>
      </div>
    ),
  },
  {
    value: "fab fa-imdb",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-imdb"></i> <span>imdb</span>
      </div>
    ),
  },
  {
    value: "fab fa-instagram",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-instagram"></i>
        <span>instagram</span>
      </div>
    ),
  },
  {
    value: "fab fa-internet-explorer",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-internet-explorer"></i>
        <span>internet-explorer</span>
      </div>
    ),
  },
  {
    value: "fab fa-ioxhost",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-ioxhost"></i> <span>ioxhost</span>
      </div>
    ),
  },
  {
    value: "fab fa-itunes",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-itunes"></i> <span>itunes</span>
      </div>
    ),
  },
  {
    value: "fab fa-itunes-note",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-itunes-note"></i>
        <span>itunes-note</span>
      </div>
    ),
  },
  {
    value: "fab fa-jenkins",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-jenkins"></i> <span>jenkins</span>
      </div>
    ),
  },
  {
    value: "fab fa-joget",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-joget"></i> <span>joget</span>
      </div>
    ),
  },
  {
    value: "fab fa-joomla",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-joomla"></i> <span>joomla</span>
      </div>
    ),
  },
  {
    value: "fab fa-js",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-js"></i> <span>js</span>
      </div>
    ),
  },
  {
    value: "fab fa-js-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-js-square"></i>
        <span>js-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-jsfiddle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-jsfiddle"></i>
        <span>jsfiddle</span>
      </div>
    ),
  },
  {
    value: "fab fa-keycdn",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-keycdn"></i> <span>keycdn</span>
      </div>
    ),
  },
  {
    value: "fab fa-kickstarter",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-kickstarter"></i>
        <span>kickstarter</span>
      </div>
    ),
  },
  {
    value: "fab fa-kickstarter-k",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-kickstarter-k"></i>
        <span>kickstarter-k</span>
      </div>
    ),
  },
  {
    value: "fab fa-korvue",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-korvue"></i> <span>korvue</span>
      </div>
    ),
  },
  {
    value: "fab fa-laravel",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-laravel"></i> <span>laravel</span>
      </div>
    ),
  },
  {
    value: "fab fa-lastfm",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-lastfm"></i> <span>lastfm</span>
      </div>
    ),
  },
  {
    value: "fab fa-lastfm-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-lastfm-square"></i>
        <span>lastfm-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-leanpub",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-leanpub"></i> <span>leanpub</span>
      </div>
    ),
  },
  {
    value: "fab fa-less",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-less"></i> <span>less</span>
      </div>
    ),
  },
  {
    value: "fab fa-line",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-line"></i> <span>line</span>
      </div>
    ),
  },
  {
    value: "fab fa-linkedin",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-linkedin"></i>
        <span>linkedin</span>
      </div>
    ),
  },
  {
    value: "fab fa-linkedin-in",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-linkedin-in"></i>
        <span>linkedin-in</span>
      </div>
    ),
  },
  {
    value: "fab fa-linode",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-linode"></i> <span>linode</span>
      </div>
    ),
  },
  {
    value: "fab fa-linux",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-linux"></i> <span>linux</span>
      </div>
    ),
  },
  {
    value: "fab fa-lyft",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-lyft"></i> <span>lyft</span>
      </div>
    ),
  },
  {
    value: "fab fa-magento",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-magento"></i> <span>magento</span>
      </div>
    ),
  },
  {
    value: "fab fa-maxcdn",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-maxcdn"></i> <span>maxcdn</span>
      </div>
    ),
  },
  {
    value: "fab fa-medapps",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-medapps"></i> <span>medapps</span>
      </div>
    ),
  },
  {
    value: "fab fa-medium",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-medium"></i> <span>medium</span>
      </div>
    ),
  },
  {
    value: "fab fa-medium-m",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-medium-m"></i>
        <span>medium-m</span>
      </div>
    ),
  },
  {
    value: "fab fa-medrt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-medrt"></i> <span>medrt</span>
      </div>
    ),
  },
  {
    value: "fab fa-meetup",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-meetup"></i> <span>meetup</span>
      </div>
    ),
  },
  {
    value: "fab fa-microsoft",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-microsoft"></i>
        <span>microsoft</span>
      </div>
    ),
  },
  {
    value: "fab fa-mix",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-mix"></i> <span>mix</span>
      </div>
    ),
  },
  {
    value: "fab fa-mixcloud",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-mixcloud"></i>
        <span>mixcloud</span>
      </div>
    ),
  },
  {
    value: "fab fa-mizuni",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-mizuni"></i> <span>mizuni</span>
      </div>
    ),
  },
  {
    value: "fab fa-modx",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-modx"></i> <span>modx</span>
      </div>
    ),
  },
  {
    value: "fab fa-monero",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-monero"></i> <span>monero</span>
      </div>
    ),
  },
  {
    value: "fab fa-napster",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-napster"></i> <span>napster</span>
      </div>
    ),
  },
  {
    value: "fab fa-node",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-node"></i> <span>node</span>
      </div>
    ),
  },
  {
    value: "fab fa-node-js",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-node-js"></i> <span>node-js</span>
      </div>
    ),
  },
  {
    value: "fab fa-npm",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-npm"></i> <span>npm</span>
      </div>
    ),
  },
  {
    value: "fab fa-ns8",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-ns8"></i> <span>ns8</span>
      </div>
    ),
  },
  {
    value: "fab fa-nutritionix",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-nutritionix"></i>
        <span>nutritionix</span>
      </div>
    ),
  },
  {
    value: "fab fa-odnoklassniki",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-odnoklassniki"></i>
        <span>odnoklassniki</span>
      </div>
    ),
  },
  {
    value: "fab fa-odnoklassniki-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-odnoklassniki-square"></i>
        <span>odnoklassniki-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-opencart",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-opencart"></i>
        <span>opencart</span>
      </div>
    ),
  },
  {
    value: "fab fa-openid",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-openid"></i> <span>openid</span>
      </div>
    ),
  },
  {
    value: "fab fa-opera",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-opera"></i> <span>opera</span>
      </div>
    ),
  },
  {
    value: "fab fa-optin-monster",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-optin-monster"></i>
        <span>optin-monster</span>
      </div>
    ),
  },
  {
    value: "fab fa-osi",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-osi"></i> <span>osi</span>
      </div>
    ),
  },
  {
    value: "fab fa-page4",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-page4"></i> <span>page4</span>
      </div>
    ),
  },
  {
    value: "fab fa-pagelines",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-pagelines"></i>
        <span>pagelines</span>
      </div>
    ),
  },
  {
    value: "fab fa-palfed",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-palfed"></i> <span>palfed</span>
      </div>
    ),
  },
  {
    value: "fab fa-patreon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-patreon"></i> <span>patreon</span>
      </div>
    ),
  },
  {
    value: "fab fa-paypal",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-paypal"></i> <span>paypal</span>
      </div>
    ),
  },
  {
    value: "fab fa-periscope",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-periscope"></i>
        <span>periscope</span>
      </div>
    ),
  },
  {
    value: "fab fa-phabricator",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-phabricator"></i>
        <span>phabricator</span>
      </div>
    ),
  },
  {
    value: "fab fa-phoenix-framework",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-phoenix-framework"></i>
        <span>phoenix-framework</span>
      </div>
    ),
  },
  {
    value: "fab fa-pied-piper",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-pied-piper"></i>
        <span>pied-piper</span>
      </div>
    ),
  },
  {
    value: "fab fa-pied-piper-alt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-pied-piper-alt"></i>
        <span>pied-piper-alt</span>
      </div>
    ),
  },
  {
    value: "fab fa-pied-piper-pp",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-pied-piper-pp"></i>
        <span>pied-piper-pp</span>
      </div>
    ),
  },
  {
    value: "fab fa-pinterest",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-pinterest"></i>
        <span>pinterest</span>
      </div>
    ),
  },
  {
    value: "fab fa-pinterest-p",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-pinterest-p"></i>
        <span>pinterest-p</span>
      </div>
    ),
  },
  {
    value: "fab fa-pinterest-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-pinterest-square"></i>
        <span>pinterest-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-playstation",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-playstation"></i>
        <span>playstation</span>
      </div>
    ),
  },
  {
    value: "fab fa-product-hunt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-product-hunt"></i>
        <span>product-hunt</span>
      </div>
    ),
  },
  {
    value: "fab fa-pushed",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-pushed"></i> <span>pushed</span>
      </div>
    ),
  },
  {
    value: "fab fa-python",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-python"></i> <span>python</span>
      </div>
    ),
  },
  {
    value: "fab fa-qq",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-qq"></i> <span>qq</span>
      </div>
    ),
  },
  {
    value: "fab fa-quora",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-quora"></i> <span>quora</span>
      </div>
    ),
  },
  {
    value: "fab fa-ravelry",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-ravelry"></i> <span>ravelry</span>
      </div>
    ),
  },
  {
    value: "fab fa-react",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-react"></i> <span>react</span>
      </div>
    ),
  },
  {
    value: "fab fa-rebel",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-rebel"></i> <span>rebel</span>
      </div>
    ),
  },
  {
    value: "fab fa-red-river",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-red-river"></i>
        <span>red-river</span>
      </div>
    ),
  },
  {
    value: "fab fa-reddit",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-reddit"></i> <span>reddit</span>
      </div>
    ),
  },
  {
    value: "fab fa-reddit-alien",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-reddit-alien"></i>
        <span>reddit-alien</span>
      </div>
    ),
  },
  {
    value: "fab fa-reddit-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-reddit-square"></i>
        <span>reddit-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-renren",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-renren"></i> <span>renren</span>
      </div>
    ),
  },
  {
    value: "fab fa-replyd",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-replyd"></i> <span>replyd</span>
      </div>
    ),
  },
  {
    value: "fab fa-resolving",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-resolving"></i>
        <span>resolving</span>
      </div>
    ),
  },
  {
    value: "fab fa-rocketchat",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-rocketchat"></i>
        <span>rocketchat</span>
      </div>
    ),
  },
  {
    value: "fab fa-rockrms",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-rockrms"></i> <span>rockrms</span>
      </div>
    ),
  },
  {
    value: "fab fa-safari",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-safari"></i> <span>safari</span>
      </div>
    ),
  },
  {
    value: "fab fa-sass",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-sass"></i> <span>sass</span>
      </div>
    ),
  },
  {
    value: "fab fa-schlix",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-schlix"></i> <span>schlix</span>
      </div>
    ),
  },
  {
    value: "fab fa-scribd",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-scribd"></i> <span>scribd</span>
      </div>
    ),
  },
  {
    value: "fab fa-searchengin",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-searchengin"></i>
        <span>searchengin</span>
      </div>
    ),
  },
  {
    value: "fab fa-sellcast",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-sellcast"></i>
        <span>sellcast</span>
      </div>
    ),
  },
  {
    value: "fab fa-sellsy",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-sellsy"></i> <span>sellsy</span>
      </div>
    ),
  },
  {
    value: "fab fa-servicestack",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-servicestack"></i>
        <span>servicestack</span>
      </div>
    ),
  },
  {
    value: "fab fa-shirtsinbulk",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-shirtsinbulk"></i>
        <span>shirtsinbulk</span>
      </div>
    ),
  },
  {
    value: "fab fa-simplybuilt",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-simplybuilt"></i>
        <span>simplybuilt</span>
      </div>
    ),
  },
  {
    value: "fab fa-sistrix",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-sistrix"></i> <span>sistrix</span>
      </div>
    ),
  },
  {
    value: "fab fa-skyatlas",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-skyatlas"></i>
        <span>skyatlas</span>
      </div>
    ),
  },
  {
    value: "fab fa-skype",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-skype"></i> <span>skype</span>
      </div>
    ),
  },
  {
    value: "fab fa-slack",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-slack"></i> <span>slack</span>
      </div>
    ),
  },
  {
    value: "fab fa-slack-hash",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-slack-hash"></i>
        <span>slack-hash</span>
      </div>
    ),
  },
  {
    value: "fab fa-slideshare",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-slideshare"></i>
        <span>slideshare</span>
      </div>
    ),
  },
  {
    value: "fab fa-snapchat",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-snapchat"></i>
        <span>snapchat</span>
      </div>
    ),
  },
  {
    value: "fab fa-snapchat-ghost",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-snapchat-ghost"></i>
        <span>snapchat-ghost</span>
      </div>
    ),
  },
  {
    value: "fab fa-snapchat-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-snapchat-square"></i>
        <span>snapchat-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-soundcloud",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-soundcloud"></i>
        <span>soundcloud</span>
      </div>
    ),
  },
  {
    value: "fab fa-speakap",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-speakap"></i> <span>speakap</span>
      </div>
    ),
  },
  {
    value: "fab fa-spotify",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-spotify"></i> <span>spotify</span>
      </div>
    ),
  },
  {
    value: "fab fa-stack-exchange",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-stack-exchange"></i>
        <span>stack-exchange</span>
      </div>
    ),
  },
  {
    value: "fab fa-stack-overflow",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-stack-overflow"></i>
        <span>stack-overflow</span>
      </div>
    ),
  },
  {
    value: "fab fa-staylinked",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-staylinked"></i>
        <span>staylinked</span>
      </div>
    ),
  },
  {
    value: "fab fa-steam",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-steam"></i> <span>steam</span>
      </div>
    ),
  },
  {
    value: "fab fa-steam-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-steam-square"></i>
        <span>steam-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-steam-symbol",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-steam-symbol"></i>
        <span>steam-symbol</span>
      </div>
    ),
  },
  {
    value: "fab fa-sticker-mule",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-sticker-mule"></i>
        <span>sticker-mule</span>
      </div>
    ),
  },
  {
    value: "fab fa-strava",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-strava"></i> <span>strava</span>
      </div>
    ),
  },
  {
    value: "fab fa-stripe",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-stripe"></i> <span>stripe</span>
      </div>
    ),
  },
  {
    value: "fab fa-stripe-s",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-stripe-s"></i>
        <span>stripe-s</span>
      </div>
    ),
  },
  {
    value: "fab fa-studiovinari",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-studiovinari"></i>
        <span>studiovinari</span>
      </div>
    ),
  },
  {
    value: "fab fa-stumbleupon",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-stumbleupon"></i>
        <span>stumbleupon</span>
      </div>
    ),
  },
  {
    value: "fab fa-stumbleupon-circle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-stumbleupon-circle"></i>
        <span>stumbleupon-circle</span>
      </div>
    ),
  },
  {
    value: "fab fa-superpowers",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-superpowers"></i>
        <span>superpowers</span>
      </div>
    ),
  },
  {
    value: "fab fa-supple",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-supple"></i> <span>supple</span>
      </div>
    ),
  },
  {
    value: "fab fa-telegram",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-telegram"></i>
        <span>telegram</span>
      </div>
    ),
  },
  {
    value: "fab fa-telegram-plane",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-telegram-plane"></i>
        <span>telegram-plane</span>
      </div>
    ),
  },
  {
    value: "fab fa-tencent-weibo",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-tencent-weibo"></i>
        <span>tencent-weibo</span>
      </div>
    ),
  },
  {
    value: "fab fa-themeisle",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-themeisle"></i>
        <span>themeisle</span>
      </div>
    ),
  },
  {
    value: "fab fa-trello",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-trello"></i> <span>trello</span>
      </div>
    ),
  },
  {
    value: "fab fa-tumblr",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-tumblr"></i> <span>tumblr</span>
      </div>
    ),
  },
  {
    value: "fab fa-tumblr-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-tumblr-square"></i>
        <span>tumblr-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-twitch",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-twitch"></i> <span>twitch</span>
      </div>
    ),
  },
  {
    value: "fab fa-twitter",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-twitter"></i> <span>twitter</span>
      </div>
    ),
  },
  {
    value: "fab fa-twitter-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-twitter-square"></i>
        <span>twitter-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-typo3",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-typo3"></i> <span>typo3</span>
      </div>
    ),
  },
  {
    value: "fab fa-uber",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-uber"></i> <span>uber</span>
      </div>
    ),
  },
  {
    value: "fab fa-uikit",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-uikit"></i> <span>uikit</span>
      </div>
    ),
  },
  {
    value: "fab fa-uniregistry",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-uniregistry"></i>
        <span>uniregistry</span>
      </div>
    ),
  },
  {
    value: "fab fa-untappd",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-untappd"></i> <span>untappd</span>
      </div>
    ),
  },
  {
    value: "fab fa-usb",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-usb"></i> <span>usb</span>
      </div>
    ),
  },
  {
    value: "fab fa-ussunnah",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-ussunnah"></i>
        <span>ussunnah</span>
      </div>
    ),
  },
  {
    value: "fab fa-vaadin",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-vaadin"></i> <span>vaadin</span>
      </div>
    ),
  },
  {
    value: "fab fa-viacoin",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-viacoin"></i> <span>viacoin</span>
      </div>
    ),
  },
  {
    value: "fab fa-viadeo",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-viadeo"></i> <span>viadeo</span>
      </div>
    ),
  },
  {
    value: "fab fa-viadeo-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-viadeo-square"></i>
        <span>viadeo-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-viber",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-viber"></i> <span>viber</span>
      </div>
    ),
  },
  {
    value: "fab fa-vimeo",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-vimeo"></i> <span>vimeo</span>
      </div>
    ),
  },
  {
    value: "fab fa-vimeo-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-vimeo-square"></i>
        <span>vimeo-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-vimeo-v",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-vimeo-v"></i> <span>vimeo-v</span>
      </div>
    ),
  },
  {
    value: "fab fa-vine",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-vine"></i> <span>vine</span>
      </div>
    ),
  },
  {
    value: "fab fa-vk",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-vk"></i> <span>vk</span>
      </div>
    ),
  },
  {
    value: "fab fa-vnv",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-vnv"></i> <span>vnv</span>
      </div>
    ),
  },
  {
    value: "fab fa-vuejs",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-vuejs"></i> <span>vuejs</span>
      </div>
    ),
  },
  {
    value: "fab fa-weibo",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-weibo"></i> <span>weibo</span>
      </div>
    ),
  },
  {
    value: "fab fa-weixin",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-weixin"></i> <span>weixin</span>
      </div>
    ),
  },
  {
    value: "fab fa-whatsapp",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-whatsapp"></i>
        <span>whatsapp</span>
      </div>
    ),
  },
  {
    value: "fab fa-whatsapp-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-whatsapp-square"></i>
        <span>whatsapp-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-whmcs",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-whmcs"></i> <span>whmcs</span>
      </div>
    ),
  },
  {
    value: "fab fa-wikipedia-w",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-wikipedia-w"></i>
        <span>wikipedia-w</span>
      </div>
    ),
  },
  {
    value: "fab fa-windows",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-windows"></i> <span>windows</span>
      </div>
    ),
  },
  {
    value: "fab fa-wordpress",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-wordpress"></i>
        <span>wordpress</span>
      </div>
    ),
  },
  {
    value: "fab fa-wordpress-simple",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-wordpress-simple"></i>
        <span>wordpress-simple</span>
      </div>
    ),
  },
  {
    value: "fab fa-wpbeginner",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-wpbeginner"></i>
        <span>wpbeginner</span>
      </div>
    ),
  },
  {
    value: "fab fa-wpexplorer",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-wpexplorer"></i>
        <span>wpexplorer</span>
      </div>
    ),
  },
  {
    value: "fab fa-wpforms",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-wpforms"></i> <span>wpforms</span>
      </div>
    ),
  },
  {
    value: "fab fa-xbox",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-xbox"></i> <span>xbox</span>
      </div>
    ),
  },
  {
    value: "fab fa-xing",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-xing"></i> <span>xing</span>
      </div>
    ),
  },
  {
    value: "fab fa-xing-square",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-xing-square"></i>
        <span>xing-square</span>
      </div>
    ),
  },
  {
    value: "fab fa-y-combinator",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-y-combinator"></i>
        <span>y-combinator</span>
      </div>
    ),
  },
  {
    value: "fab fa-yahoo",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-yahoo"></i> <span>yahoo</span>
      </div>
    ),
  },
  {
    value: "fab fa-yandex",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-yandex"></i> <span>yandex</span>
      </div>
    ),
  },
  {
    value: "fab fa-yandex-international",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-yandex-international"></i>
        <span>yandex-international</span>
      </div>
    ),
  },
  {
    value: "fab fa-yelp",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-yelp"></i> <span>yelp</span>
      </div>
    ),
  },
  {
    value: "fab fa-yoast",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-yoast"></i> <span>yoast</span>
      </div>
    ),
  },
  {
    value: "fab fa-youtube",
    label: (
      <div className="text-truncate">
        <i className="fa-lg fa-fw me-2 fab fa-youtube"></i> <span>youtube</span>
      </div>
    ),
  },
];

export default function CreatePage({ updatePage, getPages }) {
  const context = useContext(AppSettings);

  const [loader, setLoader] = useState(false);
  const [myCustomer, setMyCustomer] = useState(null);

  const [customerValue, setCustomerValue] = useState({
    value: null,
    label: context && context.t && context.t.all,
  });
  const [pageOrder, setPageOrder] = useState(1);
  const [parentValue, setParentValue] = useState(null);
  const [pageName, setPageName] = useState("");
  const [pageUrl, setPageUrl] = useState("");
  const [pageIframe, setPageIframe] = useState("");
  const [iconValue, setIconValue] = useState(null);

  const [birimlerData, setBirimlerData] = useState(null);
  const [createdPageID, setCreatedPageID] = useState(null);
  const [groupSection, setGroupSection] = useState(false);
  const [groupId, setGroupId] = useState(false);

  useEffect(() => {
    if (context && context.me) {
      setMyCustomer(context?.me?.role?.id === 1 ? null : context.me.customer);
      setCustomerValue({
        value: null,
        label: context && context.t && context.t.all,
      });
    }
  }, [context]);

  function findGroupId(data, targetUserId) {
    let result = null;

    function traverse(items) {
      for (const item of items) {
        // Eğer bir `users` array'i varsa, içinde arama yap
        if (item.users) {
          for (const user of item.users) {
            if (user.pivot && user.pivot.user_id === targetUserId) {
              result = user.pivot.group_id;
              return; // Hedefi bulunca daha fazla aramaya gerek yok
            }
          }
        }

        // Eğer `children` array'i varsa, içine dal
        if (item.children) {
          traverse(item.children);
        }
      }
    }

    traverse(data);
    return result;
  }

  async function fetchBirimler() {
    setBirimlerData(null);

    try {
      const response = await apiClient.get(`/groups`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.length !== 0) {
        setBirimlerData(response.data);
      }
    } catch (error) {
      setBirimlerData([]);
      console.error("Birimler", error);
    }
  }

  useEffect(() => {
    if (context?.me) {
      fetchBirimler();
    }
  }, [context?.me]);

  async function handleSubmit(type) {
    const urlRegex = /^(http|https):\/\/\S+$/;

    if (!pageOrder) {
      toastMessage(context?.t?.pageOrder, "error");
      return;
    }

    if (!pageName || pageName.length === 0) {
      toastMessage(context?.t?.pageName, "error");
      return;
    }

    if (pageName.length < 3) {
      toastMessage(context?.t?.pageNameCharLimit, "error");
      return;
    }

    if (pageUrl && !urlRegex.test(pageUrl)) {
      toastMessage(context?.t?.externalLinkError, "error");
      return;
    }

    if (pageIframe && !urlRegex.test(pageIframe)) {
      toastMessage(context?.t?.iframeLinkError, "error");
      return;
    }

    const data = {
      icon: iconValue ? iconValue.value : null,
      order: pageOrder,
      name: pageName,
      type: pageUrl && pageUrl.length !== 0 ? "link" : "page",
      url: pageUrl,
      customer_id: customerValue.value,
      page_type: customerValue.value
        ? "custom"
        : context?.me?.role?.id === 1
        ? "default"
        : "custom",
      parent_id: parentValue ? parentValue.value : null,
      iframe: pageIframe || null,
    };

    setLoader(true);

    try {
      const response = updatePage
        ? await apiClient.put(`/page/${updatePage?.id}`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await apiClient.customPost("/page", data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

      if (response) {
        setPageOrder(1);
        setParentValue(null);
        setPageName("");
        setPageUrl("");
        setPageIframe("");
        setIconValue(null);
        setCustomerValue({
          value: myCustomer ? myCustomer.id : null,
          label: myCustomer
            ? myCustomer.customer.name
            : context && context.t && context.t.all,
        });

        if (type === "group") {
          setGroupSection(true);
          handleAssignGroup(
            response?.id,
            findGroupId(birimlerData, context?.me?.id)
          );
        } else {
          getPages();
          setLoader(false);
          document.getElementById("createPageModalCloseButton").click();
          toastMessage(
            `${context?.t?.page} ${
              updatePage ? context?.t?.updateed : context?.t?.created
            }`,
            "success"
          );
        }

        // setTimeout(() => {
        //   window.location.reload();
        // }, 300);
      }
    } catch (error) {
      setLoader(false);
      toastMessage(
        `${context?.t?.page} ${
          updatePage ? context?.t?.updating : context?.t?.creating
        } Hata`,
        "error"
      );
      console.error("Page Add Error", error);
    }
  }

  async function handleAssignGroup(page_id, group_id) {
    if (page_id && group_id) {
      setLoader(true);

      try {
        const response = await apiClient.customPost(
          "/pages/assignment",
          {
            page_id: page_id,
            group_id: group_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage(context?.t?.departman_atandi, "success");

          setLoader(false);

          document.getElementById("createPageModalCloseButton").click();

          context?.fetchAssigmentGroupsPages();
        }
      } catch (error) {
        setLoader(false);
        console.error("Page Add Error", error);
      }
    }
  }

  useEffect(() => {
    if (updatePage) {
      setPageOrder(updatePage?.order);
      setPageName(updatePage?.name);
      setPageUrl(updatePage?.url);
      setPageIframe(updatePage?.iframe);

      if (updatePage?.customer_id) {
        setCustomerValue({
          value: updatePage?.customer_id,
          label: context.allCustomerSelect.find(
            (customer) => customer.value === updatePage?.customer_id
          )?.label,
        });
      } else {
        setCustomerValue({
          value: myCustomer ? myCustomer.id : null,
          label: myCustomer
            ? myCustomer.customer.name
            : context && context.t && context.t.all,
        });
      }

      if (updatePage?.parent_id) {
        setParentValue({
          value: updatePage?.parent_id,
          label: context.pages.find((page) => page.id === updatePage?.parent_id)
            ?.name,
        });
      } else {
        setParentValue(null);
      }

      if (updatePage?.icon) {
        setIconValue({
          value: updatePage?.icon,
          label: (
            <div className="text-truncate">
              <i className={`fa-lg fa-fw me-2 ${updatePage?.icon}`}></i>
              <span>{updatePage?.icon.split("fa-")[1]}</span>
            </div>
          ),
        });
      } else {
        setIconValue(null);
      }
    } else {
      setPageOrder(1);
      setParentValue(null);
      setPageName("");
      setPageUrl("");
      setPageIframe("");
      setIconValue(null);
      setCustomerValue({
        value: myCustomer ? myCustomer.id : null,
        label: myCustomer
          ? myCustomer.customer.name
          : context && context.t && context.t.all,
      });
    }
  }, [updatePage]);

  const flattenPagesData = (data) => {
    if (data) {
      const result = [];
      const traverse = (items) => {
        items.forEach((item) => {
          result.push(item);
          if (item.children) {
            traverse(item.children);
          }
        });
      };
      traverse(data);
      return result;
    } else {
      return [];
    }
  };

  return (
    context &&
    context.me && (
      <>
        {!updatePage && context?.me?.role?.id === 1 && (
          <div className="mb-3">
            <span className="mb-1 d-block">
              {context && context.t && context.t.select_customer}
            </span>

            {context.allCustomerSelect ? (
              <Select
                options={[
                  {
                    value: null,
                    label: context && context.t && context.t.all,
                  },
                  ...context.allCustomerSelect
                    .sort((a, b) => a.value - b.value)
                    .map((customer) => {
                      return {
                        value: customer.value,
                        label: customer.label,
                      };
                    }),
                ]}
                placeholder=""
                classNamePrefix="react-select"
                noOptionsMessage={() => "Seçenek Yok"}
                value={customerValue}
                onChange={(e) => setCustomerValue(e)}
              />
            ) : (
              <div className="spinner-border spinner-border-sm text-theme"></div>
            )}
          </div>
        )}

        <div className="mb-3">
          <span className="mb-1 d-block">
            {context && context.t && context.t.page_order}
          </span>
          <input
            type="number"
            className="form-control"
            min={1}
            value={pageOrder}
            onChange={(e) => setPageOrder(e.target.value)}
          />
        </div>

        {context?.me?.role?.id !== 8 && (
          <div className="mb-3">
            <span className="mb-1 d-block">
              {context && context.t && context.t.parent_menu}
            </span>

            {context.pages ? (
              <Select
                options={
                  updatePage
                    ? [
                        {
                          value: "",
                          label: context && context.t && context.t.none,
                        },
                        ...flattenPagesData(context.pages)
                          .filter((page) => {
                            // if (
                            //   !page.name.startsWith("Anket Sayfası") &&
                            //   page.parent_id !== 44 &&
                            //   page.page_type &&
                            //   !page.url &&
                            //   context?.me?.role?.id === 1
                            // ) {
                            //   return true;
                            // }
                            return (
                              updatePage &&
                              updatePage?.id !== page?.id &&
                              !page.name.startsWith("Anket Sayfası") &&
                              page.parent_id !== 44 &&
                              page.page_type &&
                              !page.url &&
                              (context?.me?.role?.id === 1 ||
                                page.page_type !== "default")
                            );
                          })
                          .sort((a, b) => a.order - b.order)
                          .map((page) => {
                            return {
                              value: page.id,
                              label: page.name,
                            };
                          }),
                      ]
                    : [
                        {
                          value: "",
                          label: context && context.t && context.t.none,
                        },
                        ...flattenPagesData(context.pages)
                          .filter((page) => {
                            // if (
                            //   !page.name.startsWith("Anket Sayfası") &&
                            //   page.parent_id !== 44 &&
                            //   page.page_type &&
                            //   !page.url &&
                            //   context?.me?.role?.id === 1
                            // ) {
                            //   return true;
                            // }
                            return (
                              !page.name.startsWith("Anket Sayfası") &&
                              page.parent_id !== 44 &&
                              page.page_type &&
                              !page.url &&
                              (context?.me?.role?.id === 1 ||
                                page.page_type !== "default")
                            );
                          })
                          .sort((a, b) => a.order - b.order)
                          .map((page) => {
                            return {
                              value: page.id,
                              label: page.name,
                            };
                          }),
                      ]
                }
                placeholder=""
                classNamePrefix="react-select"
                noOptionsMessage={() => "Seçenek Yok"}
                value={parentValue}
                onChange={(e) => setParentValue(e)}
              />
            ) : (
              <div className="spinner-border spinner-border-sm text-theme"></div>
            )}
          </div>
        )}

        <div className="mb-3">
          <span className="mb-1 d-block">
            {context && context.t && context.t.page_name}
          </span>
          <input
            type="text"
            className="form-control"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <span className="mb-1 d-block">
            {context && context.t && context.t.external_link}
          </span>
          <input
            type="text"
            className="form-control"
            value={pageUrl}
            onChange={(e) => setPageUrl(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <span className="mb-1 d-block">
            {context && context.t && context.t.iframe}
          </span>
          <input
            type="text"
            className="form-control"
            value={pageIframe}
            onChange={(e) => setPageIframe(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <span className="mb-1 d-block">
            {context && context.t && context.t.icon}
          </span>
          <Select
            options={iconsOptions}
            placeholder=""
            classNamePrefix="react-select"
            noOptionsMessage={() => "Seçenek Yok"}
            value={iconValue}
            onChange={(e) => setIconValue(e)}
          />
        </div>

        <div className="row g-2">
          <div className="col-6">
            <button
              type="button"
              className="btn btn-outline-theme w-100"
              data-bs-dismiss="modal"
            >
              {context && context.t && context.t.cancel}
            </button>
          </div>

          {context?.me?.role?.id === 8 ? (
            <div className="col-6">
              {birimlerData && (
                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  disabled={loader}
                  onClick={() => handleSubmit("group")}
                >
                  {context && context.t && context.t.create}
                  {loader && (
                    <div className="spinner-border spinner-border-sm text-white ms-2"></div>
                  )}
                </button>
              )}
            </div>
          ) : (
            <div className="col-6">
              <button
                type="button"
                className="btn btn-outline-theme w-100"
                disabled={loader}
                onClick={() => handleSubmit()}
              >
                {updatePage
                  ? context && context.t && context.t.update
                  : context && context.t && context.t.create}
                {loader && (
                  <div className="spinner-border spinner-border-sm text-white ms-2"></div>
                )}
              </button>
            </div>
          )}

          {loader && (
            <div
              className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
              style={{ background: "var(--bs-dark3)", zIndex: 99 }}
            >
              <div className="spinner-border text-theme"></div>
            </div>
          )}
        </div>
      </>
    )
  );
}
