import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";

import {
  Card,
  CardBody,
  CardExpandToggler,
} from "../../components/card/card.jsx";

import Todo from "./todo.jsx";
import AddTodo from "./addTodo.jsx";
import toastMessage from "../../components/ToastMessage.js";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings.js";
import { useContext } from "react";
import apiClient from "../../ApiClient.js";

import ImportFile from "../../components/import-file/index.jsx";
import MenuItem from "./MenuItem.jsx";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
  Polygon,
} from "@react-google-maps/api";
import Fancybox from "../../components/Fancybox.jsx";
import AnketCevaplariModal from "../../components/AnketCevaplariModal.jsx";
import SahadanGelenlerItem from "./SahadanGelenlerItem.jsx";
import UpdateMapAnalizModal from "./UpdateMapAnalizModal.jsx";
import MapAnalysisFilter from "./MapAnalysisFilter.jsx";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");

const getColorByValue = (value, min, max, title) => {
  const percentage = (value - min) / (max - min);

  const clampedPercentage = Math.max(0, Math.min(1, percentage));

  const r = Math.floor(255 * clampedPercentage);
  const g = Math.floor(255 * (1 - clampedPercentage));
  const b = 0;

  const color = `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

  if (color === "#NaNNaN00") {
    return "#ffffff90";
  } else {
    return color + "90";
  }
};

const center = { lat: 39.1667, lng: 35.6667 };
let options;
setTimeout(() => {
  options = {
    center,
    zoom: 5.8,
    fullscreenControl: false,
    styles:
      document.querySelector("html").getAttribute("data-bs-theme") === "light"
        ? []
        : [
            {
              featureType: "all",
              elementType: "all",
              stylers: [
                { invert_lightness: true },
                { saturation: -50 },
                { lightness: 40 },
                { gamma: 0.5 },
                {
                  hue: window.location.host.includes("datakokpit")
                    ? "#17248a"
                    : "#3cd2a5",
                },
              ],
            },
          ],
  };
}, 3000);
// Poligonun merkezini hesaplama fonksiyonu
const getPolygonCenter = (paths) => {
  let lat = 0;
  let lng = 0;
  paths.forEach((point) => {
    lat += point.lat;
    lng += point.lng;
  });
  return {
    lat: lat / paths.length,
    lng: lng / paths.length,
  };
};

const getLabelFontSize = (zoom) => {
  if (zoom > 10) return "10px"; // Zoom seviyesi yüksekse büyük yazı
  if (zoom > 8) return "8px"; // Orta zoom için orta boyut
  return "0px"; // Zoom düşükse daha küçük yazı
};

export default function StratejiHaritasi({
  title,
  show = true,
  card = false,
  scrum = true,
  setCityFilter = false,
  setDistrictFilter = false,
  setNeighbourhoodFilter = false,
  // Report
  setHeadmanData = false,
  associationsData = null,
  setAssociationsData = null,
  cityProps = null,
  districtProps = null,
  neighborhoodProps = null,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [zoomLevel, setZoomLevel] = useState(6);

  const polygonsRef = useRef([]);
  const districtPolygonsRef = useRef([]);
  const neighborhoodPolygonsRef = useRef([]);
  const mapInstanceRef = useRef(null);
  const tooltipRef = useRef(null);

  const [createMissionModalShow, setCreateMissionModalShow] = useState(false);

  const [cityName, setCityName] = useState(null);
  const [districtName, setDistrictName] = useState(null);
  const [neighborhoodName, setNeighborhoodName] = useState(null);

  const [cityPolygons, setCityPolygons] = useState(null);
  const [districtPolygons, setDistrictPolygons] = useState([]);
  const [neighborhoodPolygons, setNeighborhoodPolygons] = useState([]);
  const [activeCityId, setActiveCityId] = useState(null);
  const [activeDistrictId, setActiveDistrictId] = useState(null);
  const [loading, setLoading] = useState(false); // Yükleme durumu
  // const [bounds, setBounds] = useState(null); // Zoom yapacak sınırlar

  const [removeMarker, setRemoveMarker] = useState(false);
  const [mapModalData, setMapModalData] = useState(null);
  const [modalMapCity, setModalMapCity] = useState(false);

  // Import Data
  const [customerValue, setCustomerValue] = useState(null);
  const [fileColumns, setFileColumns] = useState(null);

  const [menuActive, setMenuActive] = useState(0);

  const [mapInfoGraphicData1, setMapInfoGraphicData1] = useState(null);
  const [mapInfoGraphicData2, setMapInfoGraphicData2] = useState(null);
  const [mapInfoGraphicData3, setMapInfoGraphicData3] = useState(null);

  const [mapInfoMenuTitle, setMapInfoMenuTitle] = useState(null);

  const [showUpdateMapAnalizModal, setShowUpdateMapAnalizModal] =
    useState(false);
  const [pageName, setPageName] = useState("");
  const [uploadPage, setUploadPage] = useState(false);
  const [createPageLoader, setCreatePageLoader] = useState(false);

  const [activeMapAnaliz, setActiveMapAnaliz] = useState(null);

  // async function handleFileTypes(adminShow) {
  //   setFileColumns(null);

  //   const data = await context.getFileTypes(context?.me?.customer?.id, true);
  //   if (data.length !== 0) {
  //     const fileNameCustomer = `harita-data-customer-${context?.me?.customer?.id}`;
  //     const exists = data.some((item) => item.type === fileNameCustomer);

  //     if (exists) {
  //       getFileColumns(fileNameCustomer);
  //     } else {
  //       setFileColumns([]);
  //     }
  //   } else {
  //     setFileColumns([]);
  //   }
  // }

  // useEffect(() => {
  //   handleFileTypes();
  // }, []);

  // async function getFileColumns(name) {
  //   setFileColumns(null);

  //   if (name) {
  //     try {
  //       const response = await apiClient.get(`/columns/${name}?connection_type=local`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       });

  //       if (response) {
  //         setFileColumns(response.data.columns);
  //       }
  //     } catch (error) {
  //       setFileColumns([]);
  //       console.error("Get File Types Error", error);
  //     }
  //   } else {
  //     setFileColumns(null);
  //   }
  // }

  // Map Analysis Select
  const [mapPolygonCityData, setMapPolygonCityData] = useState(null);
  const [mapPolygonDistrictData, setMapPolygonDistrictData] = useState(null);
  const [mapPolygonNeighborhoodData, setMapPolygonNeighborhoodData] =
    useState(null);

  const [mapAnalysisDate, setMapAnalysisDate] = useState(null);
  const [mapAnalysisDateSelect, setMapAnalysisDateSelect] = useState(null);

  const [mapAnalysisCategory, setMapAnalysisCategory] = useState(null);
  const [mapAnalysisCategorySelect, setMapAnalysisCategorySelect] =
    useState(null);

  const [mapAnalysisParameter, setMapAnalysisParameter] = useState([]);
  const [mapAnalysisParameterSelect, setMapAnalysisParameterSelect] =
    useState(null);

  async function fetchMapAnalysisSelect(column, condition = []) {
    const options = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `map-analysis`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: column,
            value: "select",
          },
        ],
        condition: condition,
        group: [],
      },
    };

    if (column === "DATE") {
      setMapAnalysisDate(null);
    }
    if (column === "SUB-CATEGORY-1") {
      setMapAnalysisCategory(null);
    }

    try {
      const response = await apiClient.customPost(`/query/preview`, options, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (column === "DATE") {
        setMapAnalysisDate(response);
      }
      if (column === "SUB-CATEGORY-1") {
        setMapAnalysisCategory(response);
      }
    } catch (error) {
      if (column === "DATE") {
        setMapAnalysisDate([]);
      }
      if (column === "SUB-CATEGORY-1") {
        setMapAnalysisCategory([]);
      }
      console.error(error);
    }
  }

  useEffect(() => {
    fetchMapAnalysisSelect("DATE");
  }, []);

  // Map Analysis Category Change
  function handleMapAnalysisCategory(e) {
    fetchMapAnalysisSelect("PARAMETER", e);
    setMapAnalysisCategorySelect(e);
  }

  // Map Analysis Parameter Change
  function handleMapAnalysisParameter(e) {
    setMapAnalysisParameterSelect(e);
  }

  // Map Analysis Filter
  const [mapAnalysisFilterLoading, setMapAnalysisFilterLoading] =
    useState(false);

  async function handleMapAnalysisFilter() {
    const options = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `map-analysis`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: [
          {
            key: "CITY",
            value: "select",
          },
          {
            key: "VALUE",
            value: "sum",
          },
        ],
        condition: [
          mapAnalysisDateSelect
            ? {
                key: "DATE",
                value: mapAnalysisDateSelect.value,
                operator: "=",
                type: "and",
              }
            : null,
          mapAnalysisCategorySelect
            ? {
                key: "SUB-CATEGORY-1",
                value: mapAnalysisCategorySelect.value,
                operator: "=",
                type: "and",
              }
            : null,
          mapAnalysisParameterSelect
            ? {
                key: "PARAMETER",
                value: mapAnalysisParameterSelect.value,
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    setMapAnalysisFilterLoading(true);

    try {
      const response = await apiClient.customPost(`/query/preview`, options, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setMapAnalysisFilterLoading(false);
    } catch (error) {
      setMapAnalysisFilterLoading(false);
      console.error(error);
    }
  }

  // Sahadan Gelenler
  const [selectedSahadanGelenler, setSelectedSahadanGelenler] = useState(null);
  const [locationSahadanGelenler, setLocationSahadanGelenler] = useState(null);

  function handleMarkersSahadanGelenler(item) {
    if (item) {
      const data = item;

      // İlk answer'ı al
      const firstAnswer = data.answer[0][0]; // İlk answer nesnesi
      const otherAnswers = data.answer.slice(1); // Diğer answer nesneleri

      // firstAnswer ve otherAnswers'ı item.answer_id ile filtreleme
      const filteredFirstAnswer =
        firstAnswer.id === item.answer_id ? firstAnswer : null;
      const filteredOtherAnswers = otherAnswers.reduce((acc, curr) => {
        if (typeof curr === "object") {
          const key = Object.keys(curr)[0]; // Anahtar almak
          if (curr[key].id === item.answer_id) {
            acc.push(curr[key]); // Anahtarı kullanarak nesneyi ekle
          }
        }
        return acc;
      }, []);

      // Diğer answer nesnelerini birleştir
      const combinedAnswers = {
        id: item.reply_id,
        items: [
          filteredFirstAnswer, // Filtrelenmiş firstAnswer
          ...filteredOtherAnswers, // Filtrelenmiş otherAnswers
        ].filter(Boolean), // null olanları filtrele
      };

      setLocationSahadanGelenler(combinedAnswers);
    } else {
      setLocationSahadanGelenler(null);
    }
  }

  //-

  const [sahadanGelenlerData, setSahadanGelenlerData] = useState(null);

  const fetchSahadanGelenler = useCallback(async () => {
    setSahadanGelenlerData(null);

    try {
      const response = await apiClient.get(`/groups/assignment`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setSahadanGelenlerData(response.data);
    } catch (error) {
      setSahadanGelenlerData([]);
      console.error(error);
    }
  }, []); // Fonksiyon sadece bir kez oluşturulacak

  useEffect(() => {
    fetchSahadanGelenler();
  }, [fetchSahadanGelenler]); // Bağımlılıklar: context ve fetch fonksiyonu

  // Todo Edit
  const [todoEditData, setTodoEditData] = useState(null);

  // Get Todo
  const [todoData, setTodoData] = useState(null);
  const [pureTodoData, setPureTodoData] = useState(null);
  const [todoCategoryData, setTodoCategoryData] = useState(null);
  const [pinFiltre, setPinFiltre] = useState();

  async function getTodoData() {
    setTodoData(null);

    try {
      const response = await apiClient.get(
        `/todos${
          context?.activeCustomer
            ? `?customer_id=${context?.activeCustomer}`
            : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.length !== 0) {
          const resultArray = response.data.reduce((acc, item) => {
            const existingCategory = acc.find(
              (category) => category.category.id === item.category.id
            );

            if (existingCategory) {
              existingCategory.list.push({
                id: item.id,
                title: item.title,
                description: item.description,
                summary: item.summary,
                completed: item.completed,
                tags: item.tags,
                location: item.location,
                image: item.image,
                file: item.file,
                started_at: item.started_at,
                finished_at: item.finished_at,
                sort: item.sort,
                city: item.city,
                district: item.district,
                neighbourhood: item.neighbourhood,
                street: item.street,
                building: item.building,
                independent: item.independent,
                assigned: item.assigned,
                user: item.user
                  ? {
                      id: item.user.id,
                      customer: item.user.customer,
                      role: item.user.role,
                      name: item.user.name,
                      username: item.user.username,
                      email: item.user.email,
                      phone: item.user.phone,
                      address: item.user.address,
                    }
                  : null,
                updated_user: item.updated_user
                  ? {
                      id: item.updated_user.id,
                      customer: item.updated_user.customer,
                      role: item.updated_user.role,
                      name: item.updated_user.name,
                      username: item.updated_user.username,
                      email: item.updated_user.email,
                      phone: item.updated_user.phone,
                      address: item.updated_user.address,
                    }
                  : null,
                created_at: item.created_at || null,
                updated_at: item.updated_at || null,
              });
            } else {
              acc.push({
                category: {
                  id: item.category.id,
                  name: item.category.name,
                  color: item.category.color,
                  sort: item.category.sort,
                },
                list: [
                  {
                    id: item.id,
                    title: item.title,
                    description: item.description,
                    summary: item.summary,
                    completed: item.completed,
                    tags: item.tags,
                    location: item.location,
                    image: item.image,
                    file: item.file,
                    started_at: item.started_at,
                    finished_at: item.finished_at,
                    city: item.city,
                    district: item.district,
                    neighbourhood: item.neighbourhood,
                    street: item.street,
                    building: item.building,
                    independent: item.independent,
                    assigned: item.assigned,
                    sort: item.sort,
                    user: item.user
                      ? {
                          id: item.user.id,
                          customer: item.user.customer,
                          role: item.user.role,
                          name: item.user.name,
                          username: item.user.username,
                          email: item.user.email,
                          phone: item.user.phone,
                          address: item.user.address,
                        }
                      : null,
                    updated_user: item.updated_user
                      ? {
                          id: item.updated_user.id,
                          customer: item.updated_user.customer,
                          role: item.updated_user.role,
                          name: item.updated_user.name,
                          username: item.updated_user.username,
                          email: item.updated_user.email,
                          phone: item.updated_user.phone,
                          address: item.updated_user.address,
                        }
                      : null,
                    created_at: item.created_at || null,
                    updated_at: item.updated_at || null,
                  },
                ],
              });
            }

            return acc;
          }, []);

          resultArray.sort((a, b) => a.category.sort - b.category.sort);

          resultArray.forEach((category) => {
            category.list.sort((a, b) => a.sort - b.sort);
          });

          setTodoCategoryData([
            { value: "remove", label: context?.t?.reset },
            { value: null, label: context?.t?.all },
            ...resultArray
              .filter((item) => item.category !== null)
              .map(function (item) {
                return {
                  label: item.category.name,
                  value: item.category.id,
                };
              }),
          ]);
          setTodoData(resultArray);
          setPureTodoData(resultArray);
        } else {
          setTodoCategoryData([]);
          setPureTodoData([]);
          setTodoData([]);
        }
      }
    } catch (error) {
      console.error("Todo Error", error);
      setTodoData(null);

      if (error.response.status === 500) {
        toastMessage(context?.t?.server_error, "error");
      } else if (error.response.status === 404) {
        navigate("/404");
      } else if (error.response.status === 403) {
        toastMessage(context?.t?.cok_fazla_istek_atildi, "error");
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  const [mapAnalysisFilterShow, setMapAnalysisFilterShow] = useState(false);

  function handlePinFiltre(e) {
    setPinFiltre(e);

    const filtered = pureTodoData.filter((item) => {
      if (e.value === null) return true;
      return item.category && item.category.id === e.value;
    });

    setTodoData(e.value === "removed" ? [] : filtered);
  }

  function handleRemoveMarker() {
    setRemoveMarker(true);
    setMapModalData(null);
  }

  // Mahalleye tıklanınca sadece bounds ayarlama işlemi yapılıyor
  function handlePolygonClick(district, neighborhood) {
    setNeighborhoodName(neighborhood);

    const selectedPolygon = neighborhoodPolygons.find(
      (polygon) => polygon.name === neighborhood
    );

    if (selectedPolygon) {
      const coords = JSON.parse(
        selectedPolygon.geo.replace(/\"/g, "").trim()
      )[0][0].map((coord) => ({
        lat: coord[1],
        lng: coord[0],
      }));
      setBounds(coords);
    }
  }

  function getInfoData(district, neighborhood) {
    // İlk olarak zoom ve bounds ayarlamasını yapıyoruz
    handlePolygonClick(district, neighborhood);
  }

  // Tooltip
  const handleTooltipMouseMove = (event) => {
    if (tooltipRef.current) {
      const mapElement = document.getElementById("map-item");
      tooltipRef.current.style.top = `${
        event.clientY - mapElement.getBoundingClientRect().top - 30
      }px`;
      tooltipRef.current.style.left = `${
        event.clientX - mapElement.getBoundingClientRect().left
      }px`;
    }
  };

  // Şehirleri Getir
  const getAllCities = async (customerCity) => {
    if (context && context.allCity) {
      const formattedCities = context.allCity.map((item) => ({
        id: item.value,
        name: item.label,
        geo: item.geo,
      }));

      if (customerCity) {
        setCityPolygons(
          formattedCities.filter((item) => item.id === customerCity)
        );
      } else {
        setCityPolygons(formattedCities);
      }
    }
  };

  const handleMouseOverCity = (polygon, item, value = null, total = null) => {
    tooltipRef.current.innerText = `${item.name}${
      value ? " " + value.toFixed(2) + "" : ""
    }`;
    tooltipRef.current.classList.add("active");
    if (polygon) {
      polygon.setOptions({
        fillOpacity: cityName ? 1 : 0,
      });
    }
  };

  const handleMouseOutCity = (polygon) => {
    tooltipRef.current.classList.remove("active");
    if (polygon) {
      polygon.setOptions({
        fillOpacity: cityName ? 0.1 : 1,
      });
    }
  };

  // İlçe Polygonları Getir
  const getDistrict = async (city, customerDistrict) => {
    setLoading(true); // Yüklemeyi başlat
    setCityName(city.name);
    setDistrictName(null);
    setNeighborhoodName(null);

    if (setCityFilter) {
      setCityFilter({
        value: city.id,
        label: city.name,
      });

      setDistrictFilter(null);
      setNeighbourhoodFilter(null);
    }

    try {
      const response = await apiClient.get(`/district/${city.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.length !== 0) {
        const data = response.data
          .map(({ id, name, ...rest }) => ({
            id,
            name:
              name === "Eyüp"
                ? "EYUPSULTAN"
                : context
                    .removeTurkishCharacters(name)
                    .toUpperCase()
                    .includes("MERKEZ")
                ? "MERKEZ"
                : context.removeTurkishCharacters(name).toUpperCase(),
            ...rest,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        if (customerDistrict) {
          setDistrictPolygons(
            data.filter((item) => item.id === customerDistrict)
          );
        } else {
          setDistrictPolygons(data);
        }

        setActiveCityId(city.id); // Aktif şehri ayarla
        setNeighborhoodPolygons([]); // Mahalle polygonlarını sıfırla
        // Harita üzerinde zoom yapacak sınırları belirle
        const coords = data.flatMap((district) =>
          JSON.parse(district.geo)[0][0].map((coord) => ({
            lat: coord[1],
            lng: coord[0],
          }))
        );
        setBounds(coords);
      }
    } catch (error) {
      console.error("Harita İlçe", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseOverDistrict = (
    polygon,
    item,
    value = null,
    total = null
  ) => {
    tooltipRef.current.innerText = `${item.name}${
      value ? " " + value.toFixed(2) + "" : ""
    }`;
    tooltipRef.current.classList.add("active");
    if (polygon) {
      polygon.setOptions({
        fillOpacity: districtName ? 1 : 0,
        // fillColor: "#88ffdb",
      });
    }
  };

  const handleMouseOutDistrict = (polygon) => {
    tooltipRef.current.classList.remove("active");
    if (polygon) {
      polygon.setOptions({
        fillOpacity: districtName ? 0.1 : 1,
        // fillColor: "#15202bb5",
      });
    }
  };

  // Mahalle Polygonları Getir
  const getNeighborhood = async (district) => {
    setLoading(true); // Yüklemeyi başlat
    setDistrictName(district.name);

    try {
      const response = await apiClient.get(`/neighborhoods/${district.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.length !== 0) {
        const data = response.data
          .map(({ id, name, ...rest }) => ({
            id,
            name: context.removeTurkishCharacters(name).toUpperCase() + " MAH.",
            ...rest,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setNeighborhoodPolygons(data);
        setActiveDistrictId(district.id); // Aktif ilçeyi ayarla
        // Harita üzerinde zoom yapacak sınırları belirle
        const coords = data.flatMap((neighborhood) =>
          JSON.parse(neighborhood.geo.replace(/\"/g, "").trim())[0][0].map(
            (coord) => ({
              lat: coord[1],
              lng: coord[0],
            })
          )
        );
        setBounds(coords);
      }
    } catch (error) {
      console.error("Harita Mahalle", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseOverNeighborhood = (
    polygon,
    item,
    value = null,
    total = null
  ) => {
    tooltipRef.current.innerText = `${item.name}${
      value ? " " + value.toFixed(2) + "" : ""
    }`;
    tooltipRef.current.classList.add("active");
    if (polygon) {
      polygon.setOptions({
        fillOpacity: 0,
        // fillColor: "#15202bb5",
      });
    }
  };

  const handleMouseOutNeighborhood = (polygon) => {
    tooltipRef.current.classList.remove("active");
    if (polygon) {
      polygon.setOptions({
        fillOpacity: 1,
        // fillColor: "#91ffde",
      });
    }
  };

  // Zoom
  // const fitBounds = (map, paths) => {
  //   setTimeout(() => {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     paths.forEach((path) => {
  //       bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
  //     });
  //     map.fitBounds(bounds);
  //   }, 100);
  // };

  function setBounds(coords) {
    setTimeout(() => {
      const bounds = new window.google.maps.LatLngBounds();
      coords.forEach((coord) => bounds.extend(coord));
      mapInstanceRef.current.fitBounds(bounds);
    }, 1000);
  }

  // useEffect(() => {
  //   if (mapInstanceRef.current && bounds) {
  //     fitBounds(mapInstanceRef.current, bounds);
  //     setBounds(null);
  //   }
  // }, [bounds]);

  // Active Marker Window
  const [hoveredMarkerId, setHoveredMarkerId] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  //--

  const [customerColor, setCustomerColor] = useState("null");
  const [customerDistrict, setCustomerDistrict] = useState(null);

  const handleGetCustomer = useCallback(async () => {
    const customerData = await context.getCustomer(
      context.activeCustomer || context?.me?.customer?.id
    );

    getAllCities(customerData?.settings?.city?.value);
    setCustomerDistrict(customerData?.settings?.district?.value);

    setCustomerColor(
      customerData?.settings?.theme
        ? customerData?.settings?.theme.split("|")[1]
        : "null"
    );
  }, [context.allCity, context.activeCustomer, context?.me?.customer?.id]);

  useEffect(() => {
    handleGetCustomer();
  }, [context.activeCustomer, handleGetCustomer]);

  //--

  const [tableLocationData, setTableLocationData] = useState(null);
  const [tableLocationMapData, setTableLocationMapData] = useState(null);
  const [tableLocationButton, setTableLocationButton] = useState(true);

  const [tableShow, setTableShow] = useState(false);

  function handleTableMarker() {
    if (tableLocationButton) {
      setTableLocationMapData(tableLocationData);
    } else {
      setTableLocationMapData(null);
    }
  }

  const [tableSelectedData, setTableSelectedData] = useState(null);
  const [dataColumn, setDataColumn] = useState(null);

  async function fetchColumn(type) {
    setDataColumn(null);

    try {
      const response = await apiClient.get(
        `/columns/${`harita-data-${activeMapAnaliz.id}-customer-${activeMapAnaliz.customer_id}?connection_type=local`}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        setDataColumn(response.data.columns);
      }
    } catch (error) {
      setDataColumn(null);
      toastMessage(context?.t?.downloading_text, "info");
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    if (activeMapAnaliz) {
      fetchColumn();
    }
  }, [activeMapAnaliz]);

  async function handleCreatePageImportFile() {
    if (!pageName) {
      toastMessage(context?.t?.analysisRequired, "error");
      return;
    }

    const data = {
      icon: null,
      order: 1,
      name: pageName,
      type: "page",
      url: null,
      customer_id:
        context?.me?.role?.id === 1
          ? customerValue.value
          : context?.me
          ? context?.me?.customer.id
          : null,
      page_type: customerValue.value
        ? "custom"
        : context?.me?.role?.id === 1
        ? "default"
        : "custom",
      parent_id: 44,
      iframe: null,
    };

    try {
      const response = await apiClient.customPost("/page", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setUploadPage(response);
      }
    } catch (error) {
      console.error("Page Add Error", error);
    }
  }

  //--

  const [updateMapPage, setUpdateMapPage] = useState(false);
  const [updatePageLoader, setUpdatePageLoader] = useState(false);

  useEffect(() => {
    if (updateMapPage) {
      setPageName(updateMapPage.name);
    } else {
      setPageName("");
    }
  }, [updateMapPage]);

  async function handleUpdatePage() {
    if (!pageName) {
      toastMessage(context?.t?.analysisRequired, "error");
      return;
    }

    setUpdatePageLoader(true);

    const data = updateMapPage;
    data.name = pageName;

    try {
      const response = await apiClient.put(`/page/${updateMapPage.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setUpdatePageLoader(false);
        context.getPages();

        toastMessage(context?.t?.analiz_adi_guncellendi, "success");
        setShowUpdateMapAnalizModal(false);
        // document.getElementById("updateMapAnalizModalCloseButton").click();
      }
    } catch (error) {
      setUpdatePageLoader(false);
      console.error("Page Add Error", error);
    }
  }

  async function fetchMapData(info) {
    const columns = dataColumn.map((item) => ({
      key: item,
      value: "select",
    }));

    const options = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `harita-data-${activeMapAnaliz.id}-customer-${activeMapAnaliz.customer_id}`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      connection_type: "local",
      sql: {
        column: columns,
        condition: [
          {
            key: "Latitude",
            value: info.latitude,
            operator: "=",
            type: "and",
          },
          {
            key: "Longitude",
            value: info.longitude,
            operator: "=",
            type: "and",
          },
        ].filter((item) => item !== null),
        group: [],
      },
    };

    setTableSelectedData(null);

    try {
      const response = await apiClient.customPost(`/query/preview`, options, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setTableSelectedData(response);
    } catch (error) {
      setTableSelectedData(null);
      console.error(error);
    }
  }

  // Anket Select
  const [reportsNameData, setReportsNameData] = useState(null);
  const [reportNameSelect, setReportNameSelect] = useState(null);

  const [reportMarkers, setReportMarkers] = useState(null);
  const [reportMarkerDetail, setReportMarkerDetail] = useState(null);

  const [reportMapShow, setReportMapShow] = useState(false);
  const [reportSelectedAnswerMarker, setReportSelectedAnswerMarker] =
    useState(null);
  const [reportSelectedMarker, setReportSelectedMarker] = useState(null);

  async function handleGetReports(customerId) {
    setReportsNameData(null);

    try {
      const response = await apiClient.get(
        `/street-report?customer=${customerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length !== 0) {
        const formattedData = [
          { value: null, label: context?.t?.hide },
          ...response.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ];

        setReportsNameData(formattedData);
      } else {
        setReportsNameData([]);
      }
    } catch (error) {
      setReportsNameData(null);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (scrum && context.me) {
      handleGetReports(context.activeCustomer || context.me.customer.id);
    }
  }, [context.activeCustomer, context.me, scrum]);

  async function handleReportSelect(val) {
    setReportNameSelect(val);

    if (val.value) {
      setReportMarkers(null);

      try {
        const response = await apiClient.get(
          `/street-report-reply/${val.value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.length === 0) {
          toastMessage(context?.t?.anket_cevaplanmamis, "error");
        }

        setReportMarkers(response.data);
      } catch (error) {
        setReportMarkers(null);
        console.error(error);
      }
    } else {
      setReportMarkers(null);
    }
  }

  async function handleReportDetail(item, trigger = false) {
    if (!trigger) {
      document.getElementById("report-detail-modal-button").click();
    }

    setReportMarkerDetail(null);

    try {
      const response = await apiClient.get(
        `/street-report-reply/${item.id}/detail`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setReportMarkerDetail(response.data);
    } catch (error) {
      setReportMarkerDetail(null);
      console.error(error);
    }
  }

  const [surveyDeleteLoader, setSurveyDeleteLoader] = useState(false);

  async function handleDeleteSurvey() {
    if (reportMarkerDetail) {
      setSurveyDeleteLoader(true);

      try {
        const response = await apiClient.delete(
          `/street-report-reply/${reportMarkerDetail?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage(context?.t?.cevap_silindi, "success");
          setSurveyDeleteLoader(false);

          setTimeout(() => {
            handleReportDetail();
            handleReportSelect(reportNameSelect);
          }, 300);
        }
      } catch (error) {
        setSurveyDeleteLoader(false);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  // Analiz Seç
  const [selectedAnalizColor, setSelectedAnalizColor] =
    useState("rgba(255, 0, 0, 1)");
  const [polygonAnalizSelectCategory, setPolygonAnalizSelectCategory] =
    useState(null);
  const [polygonAnalizSelect, setPolygonAnalizSelect] = useState(null);
  const [polygonAnalizSehirData, setPolygonAnalizSehirData] = useState([]);
  const [polygonAnalizIlceData, setPolygonAnalizIlceData] = useState([]);
  const [polygonAnalizMahalleData, setPolygonAnalizMahalleData] = useState([]);

  async function fetchPolygonAnaliz(type, column, condition) {
    setLoading(true);

    if (type && type.value) {
      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "-",
            type:
              type?.group === "2024 BELEDIYE YEREL SECIM"
                ? "2024-Belediye-Yerel-Secim"
                : type?.group === "SOSYOEKONOMIK"
                ? "ses-raporu"
                : type?.group === "EGITIM"
                ? "egitim"
                : "demograf",
            description: "",
            customer: null,
            status: true,
            order: 1,
            connection_type: "local",
            sql: {
              column: [
                {
                  key: column,
                  value: "select",
                },
                type?.group === "MEDENI DURUM"
                  ? {
                      key: "Alt-Kategori-2",
                      value: "select",
                    }
                  : null,
                type?.group === "2024 BELEDIYE YEREL SECIM"
                  ? {
                      key: "Parametre",
                      value: "select",
                    }
                  : null,
                {
                  key: "Deger",
                  value: "sum",
                },
              ].filter((item) => item !== null),
              condition: [
                type?.group === "DEMOGRAFI"
                  ? {
                      key: "Alt-Kategori-1",
                      value: type.value,
                      operator: "=",
                      type: "and",
                    }
                  : null,
                type?.group === "MEDENI DURUM"
                  ? {
                      key: "Alt-Kategori-1",
                      value: "MEDENI DURUM",
                      operator: "=",
                      type: "and",
                    }
                  : null,
                type?.group === "MEDENI DURUM"
                  ? {
                      key: "Alt-Kategori-2",
                      value: "YAS GRUBUNA GORE ILK DEFA EVLENEN SAYISI",
                      operator: "!=",
                      type: "and",
                    }
                  : null,
                type?.group === "MEDENI DURUM"
                  ? {
                      key: "Alt-Kategori-2",
                      value: "OLAY YERINE GORE EVLENME SAYISI",
                      operator: "!=",
                      type: "and",
                    }
                  : null,
                type?.group === "MEDENI DURUM"
                  ? {
                      key: "Alt-Kategori-2",
                      value: "EVLILIGIN BITIS NEDENINE GORE BOSANMA SAYILARI",
                      operator: "!=",
                      type: "and",
                    }
                  : null,
                type?.group === "YAS DAGILIMI"
                  ? {
                      key: "Alt-Kategori-1",
                      value: "YAS DAGILIMI",
                      operator: "=",
                      type: "and",
                    }
                  : null,
                type?.group === "YAS DAGILIMI"
                  ? {
                      key: "Parametre",
                      value: type.value,
                      operator: "=",
                      type: "and",
                    }
                  : null,
                type?.group === "EGITIM"
                  ? {
                      key: "Alt-Kategori-1",
                      value: "GENEL EGITIM DUZEYLERI",
                      operator: "=",
                      type: "and",
                    }
                  : null,
                type?.group === "EGITIM"
                  ? {
                      key: "Parametre",
                      value: type.value,
                      operator: "=",
                      type: "and",
                    }
                  : null,
                type?.group === "SOSYOEKONOMIK"
                  ? {
                      key: "Parametre",
                      value: type.value,
                      operator: "=",
                      type: "and",
                    }
                  : null,
                // type?.group === "2024 BELEDIYE YEREL SECIM"
                //   ? {
                //       key: "Parametre",
                //       value: type.value,
                //       operator: "=",
                //       type: "and",
                //     }
                //   : null,
                condition,
              ].filter((item) => item !== null),
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (column === "Sehir") {
          setPolygonAnalizSehirData(response);
        }

        if (column === "Ilce") {
          setPolygonAnalizIlceData(response);
        }

        if (column === "Mahalle") {
          setPolygonAnalizMahalleData(response);
        }

        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    } else {
      setLoading(false);

      setPolygonAnalizSelect(null);
      setPolygonAnalizSehirData([]);
      setPolygonAnalizIlceData([]);
      setPolygonAnalizMahalleData([]);
    }
  }

  useEffect(() => {
    fetchPolygonAnaliz(polygonAnalizSelect, "Sehir", null);
  }, [polygonAnalizSelect]);

  useEffect(() => {
    if (cityName) {
      fetchPolygonAnaliz(
        polygonAnalizSelect,
        districtName ? "Mahalle" : cityName ? "Ilce" : "Sehir",
        districtName
          ? {
              key: "Ilce",
              value: districtName,
              operator: "=",
              type: "and",
            }
          : cityName
          ? {
              key: "Sehir",
              value: cityName,
              operator: "=",
              type: "and",
            }
          : null
      );
    }
  }, [polygonAnalizSelect, cityName, districtName]);

  // fillColor Function
  function colorTooltip(item, data, variable) {
    return data && data.length !== 0
      ? data.find((dataItem) => dataItem[variable] === item.name)?.value_sum
      : null;
  }

  function fillColorFunction(colorItem, data, variable) {
    return data && data.length !== 0
      ? getColorByValue(
          data.find((dataItem) => dataItem[variable] === colorItem.name)
            ?.value_sum,
          Math.min(...data.map((item) => item.value_sum)),
          Math.max(...data.map((item) => item.value_sum))
        )
      : window.location.host.includes("datakokpit")
      ? "#17248a50"
      : "#3cd2a550";
  }

  return (
    show && (
      <div
        className={`${!card ? "scrum-map-container" : ""} position-relative ${
          card ? "h-100" : "mb-5 pb-5"
        }`}
        onMouseMove={handleTooltipMouseMove}
      >
        {context && context?.me?.customer?.settings ? (
          <>
            {scrum && (
              <nav className="zoom-item">
                <div className="nav nav-tabs pb-4 mb-4 gap-3">
                  <div className="border-end pe-3">
                    <button
                      type="button"
                      className="btn btn-lg btn-outline-theme"
                      data-bs-toggle="modal"
                      data-bs-target="#mapImportDataModal"
                    >
                      {context?.t?.analiz_yukle}
                    </button>
                  </div>

                  <Link
                    to="/strateji-haritasi"
                    className="btn btn-lg btn-theme flex-1"
                  >
                    {context?.t?.strateji_haritasi}
                  </Link>

                  <Link
                    to="/kentmetre-haritasi"
                    className="btn btn-lg btn-outline-theme flex-1"
                  >
                    {context?.t?.kentmetre_haritasi}
                  </Link>
                </div>
              </nav>
            )}

            <div
              className={`d-xl-flex h-100 position-relative ${
                scrum ? "zoom-item" : ""
              }`}
            >
              <Card className={`flex-1 scrum-map ${card && "h-100"}`}>
                <CardBody>
                  {loading && cityPolygons && (
                    <div
                      style={{
                        background: "#18202cd4",
                        zIndex: 999,
                      }}
                      className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                    >
                      <div className="spinner-border text-theme"></div>
                    </div>
                  )}

                  {todoCategoryData && (
                    <div
                      style={{
                        zIndex: 1,
                        padding: 5,
                        right: 50,
                        top: 10,
                        borderRadius: 4,
                      }}
                      className="position-absolute bg-white d-flex flex-wrap gap-2"
                    >
                      <div>
                        <span
                          className="d-block fw-bold mb-1"
                          style={{ lineHeight: 1, color: "black" }}
                        >
                          {context?.t?.gorev_kategorileri}
                        </span>
                        <div
                          style={{
                            minWidth: 180,
                          }}
                        >
                          <Select
                            options={todoCategoryData}
                            className="select-map"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            placeholder={context?.t?.pin_filtre}
                            value={pinFiltre}
                            onFocus={() => setMapAnalysisFilterShow(false)}
                            onChange={(e) => {
                              handlePinFiltre(e);
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <span
                          className="d-block fw-bold mb-1"
                          style={{ lineHeight: 1, color: "black" }}
                        >
                          {context?.t?.analiz}
                        </span>
                        <div
                          className="position-relative"
                          style={{
                            minWidth: 100,
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-dark btn-sm w-100"
                            onClick={() =>
                              setMapAnalysisFilterShow(!mapAnalysisFilterShow)
                            }
                          >
                            {context?.t?.show_hide}
                          </button>

                          <div
                            style={{
                              padding: 5,
                              right: -5,
                              top: "100%",
                              borderRadius: 4,
                            }}
                            className={`position-absolute bg-white ${
                              mapAnalysisFilterShow ? "d-flex" : "d-none"
                            } flex-column gap-2`}
                          >
                            <MapAnalysisFilter
                              context={context}
                              setLoading={setLoading}
                              districtName={districtName}
                              cityName={cityName}
                              setMapPolygonCityData={setMapPolygonCityData}
                              setMapPolygonDistrictData={
                                setMapPolygonDistrictData
                              }
                              setMapPolygonNeighborhoodData={
                                setMapPolygonNeighborhoodData
                              }
                            />

                            {/* <div className="">
                      <span
                        className=" d-block fw-bold mb-1"
                        style={{ lineHeight: 1, color: "black" }}
                      >
                        {context?.t?.year}
                      </span>
                      {mapAnalysisDate ? (
                        <div
                          style={{
                            minWidth: 180,
                          }}
                        >
                          <Select
                            options={mapAnalysisDate
                              .sort(
                                (a, b) => new Date(b.date) - new Date(a.date)
                              )
                              .map((item) => {
                                return {
                                  value: item.date,
                                  label: item.date.split("-")[0],
                                };
                              })}
                            className="select-map"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            placeholder={""}
                            value={mapAnalysisDateSelect}
                            onChange={(e) => {
                              setMapAnalysisDateSelect(e);
                              fetchMapAnalysisSelect("SUB-CATEGORY-1", [
                                {
                                  key: "DATE",
                                  value: e.value,
                                  operator: "=",
                                  type: "and",
                                },
                              ]);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div> */}

                            {/* {mapAnalysisCategory && (
                      <div className="">
                        <span
                          className=" d-block fw-bold mb-1"
                          style={{ lineHeight: 1, color: "black" }}
                        >
                          {context?.t?.category} 1
                        </span>

                        {mapAnalysisCategory === "loading" ? (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        ) : (
                          <div
                            style={{
                              minWidth: 180,
                            }}
                          >
                            <Select
                              options={mapAnalysisCategory.map((item) => {
                                return {
                                  value: item["sub-category-1"],
                                  label: item["sub-category-1"],
                                };
                              })}
                              className="select-map"
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              placeholder={""}
                              value={mapAnalysisCategorySelect}
                              onChange={(e) => handleMapAnalysisCategory(e)}
                            />
                          </div>
                        )}
                      </div>
                    )} */}

                            {/* <div className="">
                      <span
                        className="d-block fw-bold mb-1"
                        style={{ lineHeight: 1, color: "black" }}
                      >
                        {context?.t?.analiz_sec}
                      </span>
                      {mapAnalysisParameter ? (
                        <div
                          style={{
                            minWidth: 180,
                          }}
                        >
                          <Select
                            options={mapAnalysisParameter.map((item) => {
                              return {
                                value: item["parameter"],
                                label: item["parameter"],
                              };
                            })}
                            className="select-map"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            placeholder={""}
                            value={mapAnalysisParameterSelect}
                            onChange={(e) => handleMapAnalysisParameter(e)}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className="">
                      {mapAnalysisDateSelect &&
                        mapAnalysisCategorySelect &&
                        mapAnalysisParameterSelect && (
                          <button
                            type="button"
                            className="btn btn-theme"
                            disabled={mapAnalysisFilterLoading}
                            onClick={() =>
                              !mapAnalysisFilterLoading &&
                              handleMapAnalysisFilter()
                            }
                          >
                            {context?.t?.getir}
                            {mapAnalysisFilterLoading && (
                              <div className="spinner-border spinner-border-sm ms-1"></div>
                            )}
                          </button>
                        )}
                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="map-zoom-item">
                    <CardExpandToggler />
                  </div>

                  <div
                    id="tooltip-item"
                    ref={tooltipRef}
                    style={{ position: "absolute" }}
                  ></div>

                  <div id="map-item" className="h-100">
                    <GoogleMap
                      mapContainerStyle={{
                        height: "100%",
                        width: "100%",
                      }}
                      options={options}
                      onLoad={(map) => (mapInstanceRef.current = map)}
                      onZoomChanged={() => {
                        if (mapInstanceRef.current) {
                          setZoomLevel(mapInstanceRef.current.getZoom()); // Zoom seviyesini alıyoruz
                        }
                      }}
                    >
                      {locationSahadanGelenler &&
                        locationSahadanGelenler?.items.map((additionalAnswer) =>
                          additionalAnswer.answer.images.map(
                            (imageData, index) => (
                              <Marker
                                icon={{
                                  path: "M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10ZM10.3536 13.3536L12.5 15.5L18.1464 9.85355C18.4614 9.53857 19 9.76165 19 10.2071V19H5V18L9.64645 13.3536C9.84171 13.1583 10.1583 13.1583 10.3536 13.3536Z",
                                  fillColor: additionalAnswer.answer?.status
                                    ?.is_solved
                                    ? "#00ff00"
                                    : "#de8e00",
                                  fillOpacity: 1,
                                  strokeColor: "#000000",
                                  scale: 1,
                                  size: new window.google.maps.Size(40, 40),
                                  anchor: new window.google.maps.Point(20, 20),
                                }}
                                key={`sahadan-gelenler-${additionalAnswer.id}-${index}`}
                                position={{
                                  lat: imageData.location.lat,
                                  lng: imageData.location.lng,
                                }}
                                title={imageData.description}
                                onMouseOver={() => {
                                  tooltipRef.current.style.opacity = 0;
                                }}
                                onMouseOut={() => {
                                  tooltipRef.current.style.opacity = 1;
                                }}
                                onClick={() =>
                                  setSelectedSahadanGelenler({
                                    question:
                                      additionalAnswer.question.question,
                                    answer:
                                      additionalAnswer.answer.answer.answer,
                                    image: imageData,
                                    status: additionalAnswer.answer.status,
                                  })
                                }
                              >
                                {selectedSahadanGelenler &&
                                  selectedSahadanGelenler.image.location.lat ===
                                    imageData.location.lat &&
                                  selectedSahadanGelenler.image.location.lng ===
                                    imageData.location.lng && (
                                    <InfoWindow
                                      position={{
                                        lat: selectedSahadanGelenler.image
                                          .location.lat,
                                        lng: selectedSahadanGelenler.image
                                          .location.lng,
                                      }}
                                      onMouseOver={() => {
                                        tooltipRef.current.style.opacity = 0;
                                      }}
                                      onMouseOut={() => {
                                        tooltipRef.current.style.opacity = 1;
                                      }}
                                      onCloseClick={() =>
                                        setSelectedSahadanGelenler(null)
                                      }
                                    >
                                      <div
                                        className="me-4 px-2"
                                        style={{
                                          maxWidth: 450,
                                          maxHeight: 600,
                                          overflowY: "auto",
                                          overflowX: "hidden",
                                        }}
                                      >
                                        <strong>{context?.t?.question}:</strong>{" "}
                                        {selectedSahadanGelenler?.question}
                                        <br />
                                        <strong>
                                          {context?.t?.answer}:
                                        </strong>{" "}
                                        {selectedSahadanGelenler?.answer}
                                        <br />
                                        <Fancybox
                                          options={{
                                            Carousel: {
                                              infinite: false,
                                            },
                                          }}
                                        >
                                          <div className="row g-2 my-2">
                                            {selectedSahadanGelenler?.image?.list.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    className="col-6"
                                                    key={index}
                                                  >
                                                    <a
                                                      data-fancybox={`map-sahadan-gelenler-${
                                                        selectedSahadanGelenler?.answer +
                                                        "-" +
                                                        selectedSahadanGelenler?.question
                                                      }-gallery`}
                                                      href={item}
                                                      className="d-block border border-black"
                                                    >
                                                      <img
                                                        src={item}
                                                        alt={
                                                          selectedSahadanGelenler
                                                            ?.image?.description
                                                        }
                                                        className="w-100 img-cover"
                                                        style={{
                                                          height: "100px",
                                                        }}
                                                      />
                                                    </a>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </Fancybox>
                                        <br />
                                        <strong>
                                          {context?.t?.description}:
                                        </strong>{" "}
                                        {
                                          selectedSahadanGelenler?.image
                                            ?.description
                                        }
                                        <br />
                                        {selectedSahadanGelenler?.status
                                          ?.image &&
                                          selectedSahadanGelenler?.status
                                            ?.image !== "https://-.com/" && (
                                            <>
                                              <hr className="my-2" />

                                              <span className="d-block fw-bold mb-0">
                                                {context?.t?.guncel_hali}
                                              </span>
                                              <Fancybox
                                                options={{
                                                  Carousel: {
                                                    infinite: false,
                                                  },
                                                }}
                                              >
                                                <div className="row g-2 my-2">
                                                  <div className="col-12">
                                                    <a
                                                      data-fancybox={`map-sahadan-gelenler-${
                                                        selectedSahadanGelenler?.answer +
                                                        "-" +
                                                        selectedSahadanGelenler
                                                          ?.status?.image
                                                      }-gallery`}
                                                      href={
                                                        selectedSahadanGelenler
                                                          ?.status?.image
                                                      }
                                                      className="d-block border border-black"
                                                    >
                                                      <img
                                                        src={
                                                          selectedSahadanGelenler
                                                            ?.status?.image
                                                        }
                                                        alt={
                                                          selectedSahadanGelenler
                                                            ?.image?.description
                                                        }
                                                        className="w-100 img-cover"
                                                        style={{
                                                          height: "100px",
                                                        }}
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              </Fancybox>
                                            </>
                                          )}
                                        {selectedSahadanGelenler?.status
                                          ?.is_solved && (
                                          <>
                                            <hr className="my-2" />
                                            <strong className="text-theme">
                                              {context?.t?.tamamlandi}
                                            </strong>
                                          </>
                                        )}
                                      </div>
                                    </InfoWindow>
                                  )}
                              </Marker>
                            )
                          )
                        )}

                      {todoData && todoData.length !== 0 && (
                        <MarkerClusterer
                          options={{
                            maxZoom: 20, // Set this to control when clusters should stop grouping markers
                            styles: [
                              {
                                textColor: "white", // Set the cluster text color to white
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                                height: 50,
                                width: 50,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                                height: 56,
                                width: 56,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                                height: 66,
                                width: 66,
                              },
                            ],
                          }}
                        >
                          {(clusterer) =>
                            todoData.map((todo) =>
                              todo.list.map((marker, index) => {
                                if (marker?.location) {
                                  return (
                                    <Marker
                                      key={index}
                                      clusterer={clusterer}
                                      position={{
                                        lat: parseFloat(marker?.location?.lat),
                                        lng: parseFloat(marker?.location?.lng),
                                      }}
                                      icon={{
                                        path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
                                        fillColor: todo?.category?.color,
                                        fillOpacity: 1,
                                        strokeWeight:
                                          hoveredMarkerId === marker.id ? 2 : 0,
                                        strokeColor:
                                          context?.theme === "light"
                                            ? "#000"
                                            : "#fff",
                                        scale: 0.07,
                                        size: new window.google.maps.Size(
                                          800,
                                          800
                                        ),
                                        anchor: new window.google.maps.Point(
                                          200,
                                          400
                                        ),
                                      }}
                                      title={marker.title}
                                      onClick={() => {
                                        setSelectedMarker(marker);
                                        setTableSelectedData(null);
                                      }}
                                      onMouseOver={() => {
                                        setHoveredMarkerId(marker.id);
                                        tooltipRef.current.style.opacity = 0;
                                      }}
                                      onMouseOut={() => {
                                        setHoveredMarkerId(null);
                                        tooltipRef.current.style.opacity = 1;
                                      }}
                                    >
                                      {selectedMarker &&
                                        selectedMarker.id === marker.id && (
                                          <InfoWindow
                                            position={selectedMarker.position}
                                            onCloseClick={() =>
                                              setSelectedMarker(null)
                                            }
                                          >
                                            <div
                                              className="me-4 px-2"
                                              style={{
                                                maxWidth: 450,
                                                maxHeight: 600,
                                                overflowY: "auto",
                                                overflowX: "hidden",
                                              }}
                                            >
                                              <span className="h4 text-black mb-2 d-block">
                                                {selectedMarker.title.includes(
                                                  "#"
                                                )
                                                  ? selectedMarker.title.split(
                                                      "#"
                                                    )[1]
                                                  : selectedMarker.title}
                                              </span>

                                              <div>
                                                {selectedMarker.image && (
                                                  <div
                                                    className="carousel slide"
                                                    id={`mapMarkerCarousel_${index}`}
                                                  >
                                                    <div className="carousel-inner">
                                                      {selectedMarker.image.map(
                                                        (image, imageIndex) => (
                                                          <Fancybox
                                                            options={{
                                                              Carousel: {
                                                                infinite: false,
                                                              },
                                                            }}
                                                          >
                                                            <div
                                                              key={imageIndex}
                                                              className={`carousel-item${
                                                                imageIndex === 0
                                                                  ? " active"
                                                                  : ""
                                                              }`}
                                                            >
                                                              <a
                                                                data-fancybox={`todo-${selectedMarker.id}-gallery`}
                                                                href={image}
                                                              >
                                                                <img
                                                                  src={image}
                                                                  className="d-block mx-auto"
                                                                  alt={
                                                                    selectedMarker.title
                                                                  }
                                                                  height="200"
                                                                />
                                                              </a>
                                                            </div>
                                                          </Fancybox>
                                                        )
                                                      )}
                                                    </div>

                                                    <button
                                                      className="carousel-control-prev"
                                                      type="button"
                                                      data-bs-target={`#mapMarkerCarousel_${index}`}
                                                      data-bs-slide="prev"
                                                    >
                                                      <span
                                                        className="carousel-control-prev-icon"
                                                        aria-hidden="true"
                                                      ></span>
                                                      <span className="visually-hidden">
                                                        {context?.t?.prev}
                                                      </span>
                                                    </button>

                                                    <button
                                                      className="carousel-control-next"
                                                      type="button"
                                                      data-bs-target={`#mapMarkerCarousel_${index}`}
                                                      data-bs-slide="next"
                                                    >
                                                      <span
                                                        className="carousel-control-next-icon"
                                                        aria-hidden="true"
                                                      ></span>
                                                      <span className="visually-hidden">
                                                        {context?.t?.next}
                                                      </span>
                                                    </button>
                                                  </div>
                                                )}
                                              </div>

                                              <div className="text-start p-2">
                                                {selectedMarker.completed && (
                                                  <span className="d-block text-theme fw-bold mb-2">
                                                    {context?.t?.tamamlandi}
                                                  </span>
                                                )}
                                                {selectedMarker.description && (
                                                  <>
                                                    <span className="d-block fw-bold mb-2">
                                                      {context?.t?.description}
                                                    </span>
                                                    <div
                                                      className="fw-normal"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          selectedMarker.description,
                                                      }}
                                                    ></div>
                                                    <hr />
                                                  </>
                                                )}
                                                {selectedMarker.summary && (
                                                  <>
                                                    <span className="d-block fw-bold mb-2">
                                                      {
                                                        context?.t
                                                          ?.yonetici_ozeti
                                                      }
                                                    </span>
                                                    <div
                                                      className="fw-normal"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          selectedMarker.summary,
                                                      }}
                                                    ></div>
                                                    <hr />
                                                  </>
                                                )}
                                                {selectedMarker.district && (
                                                  <span className="d-block fw-normal mb-1">
                                                    {context?.t?.address}:
                                                    {selectedMarker.neighbourhood &&
                                                      selectedMarker
                                                        .neighbourhood
                                                        .label}{" "}
                                                    {selectedMarker.street &&
                                                      selectedMarker.street
                                                        .label}
                                                    <br />
                                                    {context?.t?.building}:
                                                    {selectedMarker.building ||
                                                      "-"}{" "}
                                                    {context?.t?.apartment}:
                                                    {selectedMarker.independent ||
                                                      "-"}
                                                    <br />
                                                    {selectedMarker.city &&
                                                      selectedMarker.city.label}
                                                    /
                                                    {selectedMarker.district &&
                                                      selectedMarker.district
                                                        .label &&
                                                      selectedMarker.district.label.toUpperCase()}
                                                  </span>
                                                )}
                                                <span className="d-block fw-normal">
                                                  {context?.t?.started_date}:
                                                  {moment(
                                                    selectedMarker.started_at,
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ).format("DD MMMM YYYY")}
                                                </span>
                                                <span className="d-block fw-normal mt-1">
                                                  {context?.t?.end_date}:
                                                  {moment(
                                                    selectedMarker.finished_at,
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  ).format("DD MMMM YYYY")}
                                                </span>

                                                {selectedMarker.file && (
                                                  <a
                                                    href={
                                                      selectedMarker.file[0]
                                                    }
                                                    target="_blank"
                                                    className="mt-2 d-block fw-medium"
                                                    rel="noreferrer"
                                                  >
                                                    {
                                                      context?.t
                                                        ?.dosyayi_goruntule
                                                    }
                                                  </a>
                                                )}

                                                {selectedMarker.tags.length !==
                                                  0 && (
                                                  <>
                                                    <hr className="my-2" />
                                                    <div className="d-flex flex-wrap gap-1">
                                                      {selectedMarker.tags.map(
                                                        (tag, tagIndex) => (
                                                          <span
                                                            key={tagIndex}
                                                            className="badge bg-black"
                                                          >
                                                            {tag}
                                                          </span>
                                                        )
                                                      )}
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </InfoWindow>
                                        )}
                                    </Marker>
                                  );
                                } else {
                                  return null;
                                }
                              })
                            )
                          }
                        </MarkerClusterer>
                      )}

                      {tableLocationMapData &&
                        tableLocationMapData.length !== 0 && (
                          <MarkerClusterer
                            options={{
                              maxZoom: 20, // Set this to control when clusters should stop grouping markers
                              styles: [
                                {
                                  textColor: "white", // Set the cluster text color to white
                                  url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                                  height: 50,
                                  width: 50,
                                },
                                {
                                  textColor: "white",
                                  url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                                  height: 56,
                                  width: 56,
                                },
                                {
                                  textColor: "white",
                                  url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                                  height: 66,
                                  width: 66,
                                },
                              ],
                            }}
                          >
                            {(clusterer) =>
                              tableLocationMapData.map((marker, index) => (
                                <Marker
                                  key={index}
                                  clusterer={clusterer}
                                  position={{
                                    lat: parseFloat(
                                      marker.latitude.replace(",", ".")
                                    ),
                                    lng: parseFloat(
                                      marker.longitude.replace(",", ".")
                                    ),
                                  }}
                                  onMouseOver={() => {
                                    tooltipRef.current.style.opacity = 0;
                                  }}
                                  onMouseOut={() => {
                                    tooltipRef.current.style.opacity = 1;
                                  }}
                                  onClick={() => {
                                    setSelectedMarker(null);
                                    fetchMapData(marker);
                                  }}
                                >
                                  {tableSelectedData &&
                                    tableSelectedData[0].latitude ===
                                      marker.latitude &&
                                    tableSelectedData[0].longitude ===
                                      marker.longitude && (
                                      <InfoWindow
                                        position={{
                                          lat: parseFloat(
                                            tableSelectedData[0].latitude.replace(
                                              ",",
                                              "."
                                            )
                                          ),
                                          lng: parseFloat(
                                            tableSelectedData[0].longitude.replace(
                                              ",",
                                              "."
                                            )
                                          ),
                                        }}
                                        onCloseClick={() =>
                                          setTableSelectedData(null)
                                        }
                                      >
                                        <div
                                          className="me-4 px-2 text-start"
                                          style={{
                                            maxWidth: 300,
                                            maxHeight: 600,
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                          }}
                                        >
                                          <ul>
                                            {tableSelectedData.map(
                                              (item, index) =>
                                                Object.entries(item).map(
                                                  ([key, value]) => (
                                                    <li key={key}>
                                                      <strong>{key}:</strong>{" "}
                                                      {value}
                                                    </li>
                                                  )
                                                )
                                            )}
                                          </ul>
                                        </div>
                                      </InfoWindow>
                                    )}
                                </Marker>
                              ))
                            }
                          </MarkerClusterer>
                        )}

                      {reportMarkers &&
                        reportMarkers.length !== 0 &&
                        reportMarkers.map((marker, index) => (
                          <Marker
                            key={index}
                            icon={{
                              url:
                                "data:image/svg+xml;charset=utf-8," +
                                encodeURIComponent(`
                                <svg width="800px" height="800px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5.5 5.5C5.5 4.11929 6.61929 3 8 3C9.38071 3 10.5 4.11929 10.5 5.5C10.5 6.88071 9.38071 8 8 8H7V11H8C11.0376 11 13.5 8.53757 13.5 5.5C13.5 2.46243 11.0376 0 8 0C4.96243 0 2.5 2.46243 2.5 5.5H5.5Z" stroke="#000000" fill="${
                                    marker.tk ? "#00ff00" : "#de8e00"
                                  }"/>
                                  <path d="M10 13H7V16H10V13Z" stroke="#000000" fill="${
                                    marker.tk ? "#00ff00" : "#de8e00"
                                  }"/>
                                </svg>
                              `),
                              scale: 0.04,
                              scaledSize: new window.google.maps.Size(30, 30),
                              anchor: new window.google.maps.Point(15, 15),
                            }}
                            position={{
                              lat: parseFloat(marker.location.lat),
                              lng: parseFloat(marker.location.lng),
                            }}
                            onMouseOver={() => {
                              tooltipRef.current.style.opacity = 0;
                            }}
                            onMouseOut={() => {
                              tooltipRef.current.style.opacity = 1;
                            }}
                            onClick={() => {
                              setSelectedMarker(null);
                              setTableSelectedData(null);
                              handleReportDetail(marker);

                              setReportMapShow(false);
                              setReportSelectedAnswerMarker(null);
                              setReportSelectedMarker(null);
                            }}
                          />
                        ))}

                      {cityPolygons &&
                        cityPolygons
                          .filter((item) => item.id !== activeCityId)
                          .map((item, index) => {
                            let itemGEO;
                            if (item.id === 34) {
                              itemGEO = JSON.parse(
                                item.geo.replace(/\"/g, "").trim()
                              );
                            } else {
                              itemGEO = JSON.parse(
                                item.geo.replace(/\"/g, "").trim()
                              )[0][0];
                            }

                            const polygonCoords = itemGEO
                              .map((coordGroup, coordGroupIndex) => {
                                if (
                                  item.id === 34 &&
                                  (coordGroupIndex === 0 ||
                                    coordGroupIndex === 1)
                                ) {
                                  return coordGroup[0].map((coord) => ({
                                    lat: coord[1],
                                    lng: coord[0],
                                  }));
                                } else if (item.id !== 34) {
                                  return {
                                    lat: coordGroup[1],
                                    lng: coordGroup[0],
                                  };
                                }
                              })
                              .flat()
                              .filter(Boolean);

                            return (
                              <React.Fragment key={index}>
                                <Polygon
                                  paths={polygonCoords}
                                  options={{
                                    fillColor: fillColorFunction(
                                      item,
                                      mapPolygonCityData,
                                      "city"
                                    ),
                                    fillOpacity: cityName ? 0.1 : 1,
                                    strokeColor:
                                      context?.theme === "light"
                                        ? "#000"
                                        : "#fff",
                                    strokeWeight: 2,
                                  }}
                                  onLoad={(polygon) => {
                                    polygonsRef.current[index] = polygon;
                                  }}
                                  onMouseOver={() =>
                                    handleMouseOverCity(
                                      polygonsRef.current[index],
                                      item,
                                      colorTooltip(
                                        item,
                                        mapPolygonCityData,
                                        "city"
                                      )
                                    )
                                  }
                                  onMouseOut={() =>
                                    handleMouseOutCity(
                                      polygonsRef.current[index]
                                    )
                                  }
                                  onClick={() => {
                                    getDistrict(item, customerDistrict); // İlçeleri getir
                                  }}
                                />
                              </React.Fragment>
                            );
                          })}

                      {districtPolygons
                        .filter((district) => district.id !== activeDistrictId) // Aktif ilçeyi hariç tut
                        .map((district, index) => {
                          const districtCoords = JSON.parse(
                            district.geo
                          )[0][0].map((coord) => ({
                            lat: coord[1],
                            lng: coord[0],
                          }));

                          const polygonCenter =
                            getPolygonCenter(districtCoords);

                          return (
                            <React.Fragment key={`district-${index}`}>
                              <Polygon
                                paths={districtCoords}
                                options={{
                                  fillColor: fillColorFunction(
                                    district,
                                    mapPolygonDistrictData,
                                    "district"
                                  ),
                                  fillOpacity: districtName ? 0.1 : 1,
                                  // fillColor: "#15202bb5",
                                  strokeColor:
                                    context?.theme === "light"
                                      ? "#000"
                                      : "#fff",
                                  strokeWeight: 1,
                                }}
                                onLoad={(polygon) => {
                                  districtPolygonsRef.current[index] = polygon;
                                }}
                                onMouseOver={() =>
                                  handleMouseOverDistrict(
                                    districtPolygonsRef.current[index],
                                    district,
                                    colorTooltip(
                                      district,
                                      mapPolygonDistrictData,
                                      "district"
                                    )
                                  )
                                }
                                onMouseOut={() =>
                                  handleMouseOutDistrict(
                                    districtPolygonsRef.current[index]
                                  )
                                }
                                onClick={() => {
                                  getNeighborhood(district); // Mahalleleri getir
                                  getInfoData(district.name, null);

                                  if (setDistrictFilter) {
                                    setDistrictFilter({
                                      value: district.id,
                                      label: district.name,
                                    });

                                    setNeighbourhoodFilter(null);
                                  }
                                }}
                              />
                              <Marker
                                position={polygonCenter}
                                // label={{
                                //   text: district.name,
                                //   color: "#fff",
                                //   fontWeight: "bold",
                                //   fontSize: getLabelFontSize(zoomLevel),
                                // }}
                                options={{
                                  icon: {
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 0,
                                  },
                                }}
                              />
                            </React.Fragment>
                          );
                        })}

                      {neighborhoodPolygons.map((neighborhood, index) => {
                        const neighborhoodCoords = JSON.parse(
                          neighborhood.geo.replace(/\"/g, "").trim()
                        )[0][0].map((coord) => ({
                          lat: coord[1],
                          lng: coord[0],
                        }));

                        const polygonCenter =
                          getPolygonCenter(neighborhoodCoords);

                        return (
                          <React.Fragment key={`neighborhood-${index}`}>
                            <Polygon
                              paths={neighborhoodCoords}
                              options={{
                                fillColor: fillColorFunction(
                                  neighborhood,
                                  mapPolygonNeighborhoodData,
                                  "neigbourhood"
                                ),
                                fillOpacity: 1,
                                // fillColor: "#91ffde",
                                strokeColor:
                                  context?.theme === "light" ? "#000" : "#fff",
                                strokeWeight: 1,
                              }}
                              onLoad={(polygon) => {
                                neighborhoodPolygonsRef.current[index] =
                                  polygon;
                              }}
                              onMouseOver={() =>
                                handleMouseOverNeighborhood(
                                  neighborhoodPolygonsRef.current[index],
                                  neighborhood,
                                  colorTooltip(
                                    neighborhood,
                                    mapPolygonNeighborhoodData,
                                    "neigbourhood"
                                  )
                                )
                              }
                              onMouseOut={() =>
                                handleMouseOutNeighborhood(
                                  neighborhoodPolygonsRef.current[index]
                                )
                              }
                              onClick={() => {
                                getInfoData(districtName, neighborhood.name);

                                if (setNeighbourhoodFilter) {
                                  setNeighbourhoodFilter({
                                    label: neighborhood.name,
                                    value: neighborhood.id,
                                  });
                                }
                              }}
                            />
                            <Marker
                              position={polygonCenter}
                              // label={{
                              //   text: neighborhood.name,
                              //   color: "#fff",
                              //   fontWeight: "bold",
                              //   fontSize: getLabelFontSize(zoomLevel),
                              // }}
                              options={{
                                icon: {
                                  path: window.google.maps.SymbolPath.CIRCLE,
                                  scale: 0,
                                },
                              }}
                            />
                          </React.Fragment>
                        );
                      })}
                    </GoogleMap>
                  </div>

                  <MenuItem
                    city={cityName}
                    district={districtName}
                    neighborhood={neighborhoodName}
                    setTableLocationData={setTableLocationData}
                    tableShow={tableShow}
                    customerColor={customerColor}
                    setTableShow={setTableShow}
                    handleTableMarker={handleTableMarker}
                    setTableLocationButton={setTableLocationButton}
                    tableLocationButton={tableLocationButton}
                    activeMapAnaliz={activeMapAnaliz}
                    setActiveMapAnaliz={setActiveMapAnaliz}
                    setUpdateMapPage={setUpdateMapPage}
                    setShowUpdateMapAnalizModal={setShowUpdateMapAnalizModal}
                  />
                </CardBody>
              </Card>

              <div
                className="scrum-container d-flex flex-column"
                style={
                  scrum
                    ? {
                        width: 500,
                        padding: "0 24px",
                        zIndex: 1,
                      }
                    : {
                        padding: "24px 24px 0",
                        zIndex: 1,
                      }
                }
              >
                <div className="row g-2 align-items-end mb-4">
                  <div className="col-12">
                    <div className="row g-2">
                      <div className="col-12 col-md-6">
                        <button
                          type="button"
                          className="btn btn-outline-theme w-100"
                          onClick={() => {
                            setTodoEditData(false);
                            setCreateMissionModalShow(true);
                          }}
                        >
                          {context?.t?.gorev_ekle}
                        </button>
                      </div>

                      <div className="col-12 col-md-6">
                        <button
                          type="button"
                          className="btn btn-outline-theme w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#categoryAddModal"
                        >
                          {context?.t?.kategori_ekle}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <span className="d-block">{context?.t?.anket_sec}</span>
                    {reportsNameData ? (
                      <Select
                        options={reportsNameData}
                        placeholder=""
                        classNamePrefix="react-select"
                        noOptionsMessage={() => context?.t?.no_option}
                        value={reportNameSelect}
                        onChange={(e) => handleReportSelect(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>
                  <div className="col">
                    <span className="d-block">{context?.t?.pin_filtre}</span>
                    {todoCategoryData ? (
                      <Select
                        options={todoCategoryData}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => context?.t?.no_option}
                        placeholder=""
                        value={pinFiltre}
                        onChange={(e) => handlePinFiltre(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>
                </div>

                <div
                  className="flex-1 pe-2 me-n2"
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Todo
                    todoData={todoData}
                    getTodoData={getTodoData}
                    setTodoEditData={setTodoEditData}
                    scrum={scrum}
                    setCreateMissionModalShow={setCreateMissionModalShow}
                  />

                  <div className="row">
                    <div className="col-12">
                      <hr />

                      <h4 className="my-4">{context?.t?.coming_from_field}</h4>
                    </div>

                    <div className="col-12">
                      {sahadanGelenlerData ? (
                        sahadanGelenlerData.length !== 0 ? (
                          sahadanGelenlerData.map((response, index) => (
                            <SahadanGelenlerItem
                              locationSahadanGelenler={locationSahadanGelenler}
                              handleMarkersSahadanGelenler={
                                handleMarkersSahadanGelenler
                              }
                              fetchSahadanGelenler={fetchSahadanGelenler}
                              key={index}
                              response={response}
                              index={index}
                            />
                          ))
                        ) : (
                          <p>{context?.t?.noanswer_sent_you}</p>
                        )
                      ) : (
                        <div className="spinner-border text-theme"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {scrum && (
              <AddTodo
                todoEditData={todoEditData}
                setTodoEditData={setTodoEditData}
                mapModalData={mapModalData}
                handleRemoveMarker={handleRemoveMarker}
                getTodoData={getTodoData}
                setModalMapCity={setModalMapCity}
                createMissionModalShow={createMissionModalShow}
                setCreateMissionModalShow={setCreateMissionModalShow}
              />
            )}

            <button
              type="button"
              className="d-none"
              id="report-detail-modal-button"
              data-bs-toggle="modal"
              data-bs-target="#reportDetailMapModal"
            ></button>

            <div className="modal modal-cover fade" id="reportDetailMapModal">
              <div
                className="modal-dialog modal-lg py-4"
                style={{ maxWidth: 1500 }}
              >
                <div
                  className="modal-content rounded"
                  style={{ background: "var(--bs-dark4)" }}
                >
                  <div className="modal-header mb-0 pb-0">
                    <h3 className="modal-title d-flex align-items-center gap-2">
                      <span>{context?.t?.arastirma_cevaplari}</span>
                      <br />
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDeleteSurvey()}
                        disabled={surveyDeleteLoader}
                      >
                        {context?.t?.bu_cevabi_sil}
                        {surveyDeleteLoader && (
                          <div className="spinner-border spinner-border-sm ms-2"></div>
                        )}
                      </button>
                    </h3>

                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                    ></button>
                  </div>

                  <AnketCevaplariModal
                    data={reportMarkerDetail}
                    handleReportDetail={handleReportDetail}
                    reportMapShow={reportMapShow}
                    setReportMapShow={setReportMapShow}
                    reportSelectedAnswerMarker={reportSelectedAnswerMarker}
                    setReportSelectedAnswerMarker={
                      setReportSelectedAnswerMarker
                    }
                    reportSelectedMarker={reportSelectedMarker}
                    setReportSelectedMarker={setReportSelectedMarker}
                  />
                </div>
              </div>
            </div>

            <UpdateMapAnalizModal
              show={showUpdateMapAnalizModal}
              onClose={() => setShowUpdateMapAnalizModal(false)}
              pageName={pageName}
              setPageName={setPageName}
              updatePageLoader={updatePageLoader}
              context={context}
              handleUpdatePage={handleUpdatePage}
            />

            <div
              className="modal modal-cover fade"
              id="importMapAnalysisDataModal"
            >
              <div className="modal-dialog">
                <div
                  className="modal-content rounded"
                  style={{ background: "var(--bs-dark4)" }}
                >
                  <div className="modal-header">
                    <h3 className="modal-title">{context?.t?.analiz_yukle}</h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      id="importMapAnalysisDataModalCloseButton"
                    ></button>
                  </div>

                  <div className="modal-body">
                    <ImportFile
                      closeId="importMapAnalysisDataModalCloseButton"
                      pageImport={true}
                      manuelButton={false}
                      dataname={`map-analysis`}
                      onlyCustomer={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal modal-cover fade" id="mapImportDataModal">
              <div className="modal-dialog">
                <div
                  className="modal-content rounded"
                  style={{ background: "var(--bs-dark4)" }}
                >
                  <div className="modal-header">
                    <h3 className="modal-title">{context?.t?.analiz_yukle}</h3>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      id="mapImportDataModalCloseButton"
                    ></button>
                  </div>

                  <div className="modal-body">
                    {context?.me?.role?.id === 1 && (
                      <div className="mb-3">
                        <span className="mb-1 d-block">
                          {context?.t?.customer}
                        </span>
                        {context.allCustomerSelect ? (
                          <Select
                            options={[
                              // {
                              //   value: null,
                              //   label: context?.t?.none,
                              //   user_id: null,
                              // },
                              ...context.allCustomerSelect,
                            ]}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={customerValue}
                            onChange={(e) => setCustomerValue(e)}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                    )}

                    <div className="mb-3">
                      <span className="mb-1 d-block">
                        {context?.t?.analiz_adi}
                      </span>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control flex-1"
                          disabled={uploadPage ? true : false}
                          readOnly={uploadPage ? true : false}
                          value={pageName}
                          onChange={(e) => setPageName(e.target.value)}
                        />
                        {uploadPage && (
                          <button
                            className="btn btn-danger ms-2"
                            onClick={() => setUploadPage(null)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        )}
                      </div>
                    </div>

                    {!uploadPage && (
                      <button
                        type="button"
                        className="btn btn-theme w-100 mb-3"
                        disabled={createPageLoader}
                        onClick={() => handleCreatePageImportFile()}
                      >
                        {context?.t?.create}
                        {createPageLoader && (
                          <div className="spinner-border spinner-border-sm text-white ms-2"></div>
                        )}
                      </button>
                    )}

                    <p
                      className="text-white"
                      dangerouslySetInnerHTML={{
                        __html: context?.t?.map_import_data_info_message,
                      }}
                    />

                    {uploadPage && (
                      <ImportFile
                        closeId="mapImportDataModalCloseButton"
                        pageImport={true}
                        columnTypesString={true}
                        dataname={`harita-data-${uploadPage.id}-customer-${
                          context?.me?.role?.id === 1
                            ? customerValue?.value
                            : context?.me?.customer?.id
                        }-user-${customerValue?.user_id || null}`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="spinner-border spinner-border-sm text-theme"></div>
        )}
      </div>
    )
  );
}
