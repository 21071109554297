import React, { useContext } from "react";
import { AppSettings } from "../../config/app-settings";

function Footer({ appFooterFull, appScrollButton }) {
  const context = useContext(AppSettings);

  return (
    <footer
      id="footer"
      className={`app-footer${appFooterFull ? " app-footer-full" : ""}`}
    >
      <p
        className={`${appScrollButton ? "pe-5" : ""}`}
        dangerouslySetInnerHTML={{
          __html: context?.t?.footer_text,
        }}
      />

      <div className="d-flex align-items-center justify-content-between border-top pt-3">
        <p className="mb-0">
          &copy; 2024 KENTMETRE {context?.t?.GROUP_OF_COMPANIES}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
